import React, { useState, useEffect } from "react";
import {
  Flex,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Icon,
  Box,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { rateOfEffortData } from "./data";

export default function RateOfEffort({ isLoading }) {
  const StatsBar = ({
    firstTotal,
    secondTotal,
    thirdTotal,
    firstLabel,
    secondLabel,
    thirdLabel,
    image,
    imageSize,
    marginTop,
    initialValue,
    targetValue,
  }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      if (!isLoading) {
        const interval = setInterval(() => {
          setValue((prevValue) =>
            prevValue < targetValue ? prevValue + 1 : prevValue
          );
        }, 2);

        return () => clearInterval(interval);
      }
    }, [targetValue]);

    return (
      <Flex width="100%" flexDirection="row" px="5px" alignItems="center">
        <CircularProgress
          my="3px"
          color="cyan.400"
          size="75px"
          thickness="5px"
          value={value}
          borderRadius="5px"
        >
          <CircularProgressLabel
            display="flex"
            flexDirection="column"
            gap="0px"
            alignItems="center"
            justifyContent="center"
            color="gray.500"
          >
            <Icon as={image} fontSize={imageSize} />
            <Box
              fontWeight="500"
              fontSize={{ base: "0.85rem", lg: "0.9rem" }}
              mt={marginTop}
            >
              {value}%
            </Box>
          </CircularProgressLabel>
        </CircularProgress>
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          px={{ base: "10px", "3xl": "5px" }}
          color="gray.500"
          fontWeight="500"
          justifyContent="center"
          fontSize={{ base: "0.75rem", lg: "0.8rem" }}
          gap="5px"
          lineHeight="1.4"
        >
          <Flex width="100%" gap="5px" minHeight={{ base: "16px", lg: "18px" }}>
            <Text minWidth="20px" textAlign="right">
              {firstTotal}
            </Text>
            <Text>{firstLabel}</Text>
          </Flex>
          <Flex width="100%" gap="5px" minHeight={{ base: "16px", lg: "18px" }}>
            <Text minWidth="20px" textAlign="right">
              {secondTotal}
            </Text>
            <Text>{secondLabel}</Text>
          </Flex>
          <Flex width="100%" gap="5px" minHeight={{ base: "16px", lg: "18px" }}>
            <Text minWidth="20px" textAlign="right">
              {thirdTotal}
            </Text>
            <Text>{thirdLabel}</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const currentDate = new Date();
  const currentMonth = format(currentDate, "MMM");

  return (
    <Flex
      width="100%"
      flexDirection="column"
      borderRadius="10px"
      border="1px solid"
      borderColor="#e5e5e5"
      bg="white"
      boxShadow="0px 10px 40px -15px rgba(0, 45, 78, 0.15)"
    >
      <Flex
        minHeight="44px"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        width="100%"
        color="light.100"
        borderBottom="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        fontWeight="700"
        textTransform="uppercase"
      >
        Rate of Effort
      </Flex>
      <Flex
        gap="5px"
        width="100%"
        flexDirection={{ base: "column", "3xl": "row" }}
      >
        <Flex
          gap="5px"
          width="100%"
          flexDirection="column"
          pt="15px"
          pb={{ base: "0px", "3xl": "20px" }}
          px="10px"
        >
          {rateOfEffortData
            .filter((feat) => feat.firstLabel !== "FSE Actioned")
            .filter((feat) => feat.firstLabel !== "Staff Levels")
            .map((item, index) => (
              <StatsBar key={index} {...item} />
            ))}
        </Flex>
        <Flex
          gap="5px"
          width="100%"
          flexDirection="column"
          pt={{ base: "0px", "3xl": "15px" }}
          pb="20px"
          px="10px"
        >
          {rateOfEffortData
            .filter((feat) => feat.firstLabel !== "Tasking Authorised")
            .filter((feat) => feat.firstLabel !== "Maintenance")
            .map((item, index) => (
              <StatsBar key={index} {...item} />
            ))}
        </Flex>
      </Flex>
      <Flex
        minHeight="44px"
        width="100%"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        color="light.100"
        borderTop="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        justifyContent="flex-end"
        fontWeight="700"
        textTransform="uppercase"
      >
        <Text fontSize="0.7rem" color="gray.500" fontWeight="600" pr="10px">
          Select Month:
        </Text>
        <Flex
          px="15px"
          minWidth="70px"
          height="24px"
          color="gray.500"
          fontSize="0.65rem"
          fontWeight="600"
          borderRadius="25px"
          bg="gray.50"
          border="1px solid"
          borderColor="#e5e5e5"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          _hover={{ bg: "gray.100" }}
        >
          {currentMonth} 2024
        </Flex>
      </Flex>
    </Flex>
  );
}
