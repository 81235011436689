import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useDisclosure,
  Text,
  LightMode,
  ModalCloseButton,
  Icon,
  Box,
  Link,
} from "@chakra-ui/react";
import { riskMatrixModalState } from "../globalState";
import { ReactComponent as RiskMatrix } from "../Assets/risk-matrix.svg";
import { ReactComponent as PdfIcon } from "../Assets/pdf.svg";
import RiskMatrixPdf from "../Assets/pdf/FOMS-Demo-Risk-Matrix-dar_100.pdf";

export default function RiskMatrixModal(props) {
  const { boxColors, setSelectedRisk, riskItem, setRiskItem, setRiskIndex } =
    props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showRiskMatrixModal, setShowRiskMatrixModal] =
    useRecoilState(riskMatrixModalState);
  const [selectedSquareValue, setSelectedSquareValue] = useState(null);

  useEffect(() => {
    showRiskMatrixModal ? onOpen() : onClose();
  }, [showRiskMatrixModal, onOpen, onClose]);

  function onCloseHandler() {
    setShowRiskMatrixModal(false);
    setSelectedSquareValue(null);
    setRiskIndex(null);
    setRiskItem(null);
    onClose();
  }

  // set the value of the risk square
  const handleSquareClick = (row, col) => {
    const letter = String.fromCharCode("A".charCodeAt(0) + row);
    const number = col + 1;
    const squareValue = `${letter}${number}`;
    setSelectedSquareValue(squareValue);
    setRiskItem(squareValue);
  };

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  const selectedRisk = riskItem ? riskItem : selectedSquareValue;

  const rowLetters = ["A", "B", "C", "D", "E", "F"];

  const rowLabels = [
    "Almost Certain",
    "Very likely",
    "Likely",
    "Possible",
    "Unlikely",
    "Rare",
  ];

  const columnLabels = [
    "Catastrophic",
    "Severe",
    "Major",
    "Moderate",
    "Minor",
    "Insignificant",
  ];

  return (
    <>
      <LightMode>
        <Modal
          variant="primary"
          size="2xl"
          transition="all ease 300ms"
          onClose={onCloseHandler}
          isOpen={isOpen}
          closeOnOverlayClick={true}
          isCentered
          trapFocus={false}
          blockScrollOnMount={true}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              zIndex="1"
              textAlign="center"
              bg="white"
              color="gray.600"
              borderBottom="1px solid"
              borderColor="#e5e7e9"
            >
              <Flex
                gap="5px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex
                  gap="10px"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon as={RiskMatrix} width={"34px"} height="auto" />
                  <Text pr="10px">Risk Matrix</Text>
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              style={{ padding: 0 }}
              fontSize="0.8rem"
              fontFamily="'Open Sans',sans-serif"
            >
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                flexDirection="column"
                width="100%"
                minHeight="120px"
                fontSize="1.1rem"
                fontWeight="700"
                bg="white"
                pt={{ base: "20px", lg: "20px" }}
                pb={{ base: "30px", lg: "30px" }}
                px={{ base: "20px", lg: "30px" }}
                gap="10px"
                boxShadow="inset 0px 7px 15px -1px rgba(0,0,0,0.06)"
                zIndex="2"
                position="relative"
              >
                <Flex
                  width="100%"
                  gap={{ base: "15px", md: "5px" }}
                  px="10px"
                  flexDirection={{ base: "column-reverse", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    fontSize={{ base: "0.85rem", lg: "0.9rem", xl: "1rem" }}
                    fontWeight="400"
                    textAlign="center"
                  >
                    Select the{" "}
                    <span style={{ fontWeight: "600" }}>
                      {showRiskMatrixModal}
                    </span>{" "}
                    risk:
                  </Text>
                  <Link
                    href={RiskMatrixPdf}
                    isExternal
                    bg={"white"}
                    minWidth="200px"
                    px="15px"
                    height="28px"
                    borderRadius="25px"
                    gap="5px"
                    color={"gray.600"}
                    display="flex"
                    justifyContent="center"
                    fontWeight={"600"}
                    fontSize="0.85rem"
                    alignItems="center"
                    lineHeight="1.2"
                    border="1px solid"
                    borderColor="gray.200"
                    textTransform="capitalize"
                    cursor="pointer"
                    _hover={{ bg: "brand.500" }}
                  >
                    <Icon as={PdfIcon} boxSize="18px" />
                    DAR 100 Risk Matrix PDF
                  </Link>
                </Flex>

                <Box className="test">
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    p="15px"
                    pb="35px"
                    pr="40px"
                    bg="gray.50"
                    borderRadius="15px"
                    border="1px solid"
                    borderColor="#e5e7e9"
                  >
                    <Flex
                      width="20px"
                      height="100%"
                      fontWeight="600"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        transform="rotate(-90deg)"
                        pb="10px"
                        textTransform="uppercase"
                        fontSize="0.9rem"
                      >
                        Likelihood
                      </Text>
                    </Flex>
                    <Flex flexDirection="column">
                      <Flex
                        width="100%"
                        height="20px"
                        fontWeight="600"
                        alignItems="flex-end"
                        justifyContent="center"
                        pb="5px"
                        textTransform="uppercase"
                        fontSize="0.9rem"
                      >
                        Consequence
                      </Flex>
                      <Flex
                        flexDirection="column"
                        fontWeight="600"
                        borderLeft="1px solid"
                        borderTop="1px solid"
                        borderColor="#e5e7e9"
                      >
                        <Flex>
                          <Flex
                            width={{ base: "60px", lg: "100px" }}
                            height="35px"
                          ></Flex>
                          {columnLabels.map((label, col) => (
                            <Flex
                              marginRight={col < 5 ? "5px" : 0}
                              key={col}
                              width={{ base: "45px", lg: "65px" }}
                              height="35px"
                              pb="10px"
                              alignItems="flex-end"
                              justifyContent="center"
                              fontSize="0.65rem"
                            >
                              <Text>{label}</Text>
                            </Flex>
                          ))}
                        </Flex>

                        {Array.from({ length: 6 }, (_, row) => (
                          <Flex key={row} marginBottom={row < 5 ? "5px" : 0}>
                            <Flex
                              width={{ base: "60px", lg: "100px" }}
                              height="45px"
                              pr="15px"
                              alignItems="flex-end"
                              justifyContent="center"
                              textAlign="right"
                              flexDirection="column"
                              fontSize="0.65rem"
                            >
                              <Text>{rowLabels[row]}</Text>
                            </Flex>

                            {Array.from({ length: 6 }, (_, col) => {
                              const letter = rowLetters[row];
                              const number = col + 1;
                              const squareValue = `${letter}${number}`;
                              const bgColor =
                                boxColors[squareValue].color || "white";
                              const bgValue =
                                boxColors[squareValue].label || "";

                              return (
                                <Flex
                                  marginRight={col < 5 ? "5px" : 0}
                                  key={col}
                                  width={{ base: "45px", lg: "65px" }}
                                  height="45px"
                                  onClick={() => handleSquareClick(row, col)}
                                  cursor="pointer"
                                  borderRadius="8px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  flexDirection="column"
                                  fontWeight="700"
                                  textAlign="center"
                                  fontSize={{ base: "0.6rem", lg: "0.65rem" }}
                                  lineHeight="1.4"
                                  textTransform="capitalize"
                                  transition="all ease 100ms"
                                  bg={
                                    selectedRisk === squareValue
                                      ? "brand.100"
                                      : bgColor
                                  }
                                  transform={
                                    selectedRisk === squareValue
                                      ? "scale(1.08)"
                                      : "scale(1)"
                                  }
                                  color={
                                    selectedRisk === squareValue
                                      ? "white"
                                      : "gray.700"
                                  }
                                  border={
                                    selectedRisk === squareValue
                                      ? "3px solid #00d0ed"
                                      : "1px solid #CBD5E0"
                                  }
                                  _hover={
                                    selectedRisk !== squareValue && {
                                      boxShadow:
                                        "0px 4px 12px -5px rgba(10, 20, 50, 0.4)",
                                      transform: "translateY(-2px) scale(1.01)",
                                    }
                                  }
                                >
                                  <Text>{bgValue}</Text>
                                </Flex>
                              );
                            })}
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter borderTop="1px solid #e5e7e9" pt={4} pb={4}>
              <Flex
                flexDirection="row"
                gap="10px"
                width="100%"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  width="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="outline"
                  onClick={() => {
                    onCloseHandler();
                  }}
                  bg="gray.50"
                  color="gray.700"
                  _hover={{ background: "gray.100" }}
                  _focus={{ background: "gray.100" }}
                  _active={{ background: "gray.100" }}
                  borderRadius="25px"
                >
                  Cancel
                </Button>

                <Button
                  {...noFocus}
                  width="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="primary"
                  bg="brand.100"
                  _hover={{ background: "brand.200" }}
                  _focus={{ background: "brand.200" }}
                  _active={{ background: "brand.200" }}
                  borderRadius="25px"
                  onClick={() => {
                    setSelectedRisk(selectedSquareValue);
                    onCloseHandler();
                  }}
                >
                  Select
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  );
}
