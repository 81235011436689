import {
  useEffect,
  //  useCallback, useState, useRef
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useSetRecoilState,
  // useRecoilValue
} from "recoil";
import { userProfileState } from "../globalState";

export default function Auth0UserDemo() {
  const { user } = useAuth0();

  // console.log user details on load
  // useEffect(() => {
  //   console.log(user);
  //   console.log("user_metadata", user_metadata);
  // }, [user]);

  const user_metadata = user && user[`https://taskr.co.nz/user_metadata`];

  const setUserProfile = useSetRecoilState(userProfileState);

  useEffect(() => {
    if (user && user_metadata) {
      setUserProfile({
        firstName: user_metadata.given_name || user.given_name,
        lastName: user_metadata.family_name || user.family_name,
        email: user.email,
        squadron: user_metadata.squadron,
        organization: user_metadata.organization,
        notifications: user_metadata.notifications,
      });
    }
  }, [user, setUserProfile, user_metadata]);

  return null;
}

// export function Auth0Update() {
//   const { getAccessTokenSilently } = useAuth0();
//   const profileData = useRecoilValue(userProfileState);
//   // const [updatingAuth0, setUpdatingAuth0] = useState(false);

//   const mounted = useRef(false);

//   const updateAuth0Profile = useCallback(
//     async (profileData) => {
//       const accessToken = await getAccessTokenSilently();
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           user_metadata: {
//             // name: `${
//             //   profileData.firstName !== "" ? profileData.firstName : null
//             // } ${profileData.lastName !== "" ? profileData.lastName : null}`,
//             // given_name:
//             //   profileData.firstName !== "" ? profileData.firstName : null,
//             // family_name:
//             //   profileData.lastName !== "" ? profileData.lastName : null,
//             // squadron: profileData.squadron !== "" ? profileData.squadron : null,
//             // organization:
//             //   profileData.organization !== "" ? profileData.organization : null,
//             read_notifications:
//               profileData.readNotifications !== ""
//                 ? profileData.readNotifications
//                 : null,
//           },
//         }),
//       };

//       fetch(
//         `${window.location.origin}/user/updateuserdata`,
//         requestOptions
//       ).then((res) => {
//         if (!res.ok) {
//           console.log(res);
//           console.log("Error updating Auth0 Metadata");
//         }
//         console.log("Updating Auth0 MetaData");
//         // setUpdatingAuth0(false);
//         return;
//       });
//     },
//     [getAccessTokenSilently]
//   );

//   const previousProfileData = useRef(profileData);

//   // Function to fire the updateAuth0Profile callback (triggered if profileData has changed)
//   useEffect(() => {
//     if (mounted.current) {
//       updateAuth0Profile(profileData);
//     } else {
//       mounted.current = true;
//     }
//     // Update the previousProfileData ref whenever profileData changes
//     previousProfileData.current = profileData;
//   }, [profileData, updateAuth0Profile]);
// }
