import React, { useState, useMemo } from "react";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  // Spinner,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import pilotlogs from "../Data/pilotlogs";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

export default function PilotLog(props) {
  // const { authorisationData } = props;

  const columnHelper = createColumnHelper();
  const [sorting, setSorting] = useState([]);

  //  *** Authorisations from local file for testing only ***
  const bookings = useMemo(
    () =>
      pilotlogs().map((sortie) => ({
        ...sortie,
        channelUuid: sortie.airframe,
      })),
    []
  );

  const columns = [
    // columnHelper.accessor("id", {
    //   cell: (info) => (
    //     <Flex justifyContent="center" alignItems="center">
    //       <strong>{info.getValue()}</strong>
    //     </Flex>
    //   ),
    //   sortingFn: "sortId",
    // }),
    columnHelper.accessor("pilot", {
      header: "Pilot",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("approach", {
      header: "Approach",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("crewrole", {
      header: "Crew Role",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("day1st", {
      header: "Day 1st",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("day2nd", {
      header: "Day 2nd",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("daydual", {
      header: "Day Dual",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("night1st", {
      header: "Night 1st",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("night2nd", {
      header: "Night 2nd",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("nightdual", {
      header: "Night Dual",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("captain", {
      header: "Captain",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("di", {
      header: "D/I",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("Instact", {
      header: "Inst. Act",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("instsim", {
      header: "Inst. Sim",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: bookings,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
    sortingFns: {
      sortId: (rowA, rowB, columnId) => {
        // console.log("RowA: ", rowA.getValue(columnId));
        return rowA.getValue(columnId).value < rowB.getValue(columnId).value
          ? 1
          : -1;
      },
    },
  });

  return (
    <>
      <Flex
        flexDirection="column"
        zIndex="1"
        width="100%"
        height="100%"
        background="#FBFDFF"
      >
        <TableContainer width="100%">
          <Flex width="100%" height="100%" overflow="auto">
            {/* {!data && (
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="flex-start"
                pt="50px"
              >
                <Spinner
                  zIndex="1"
                  color={"brand.100"}
                  emptyColor={"white"}
                  thickness="3px"
                  speed="0.5s"
                  boxSize="40px"
                />
              </Flex>
            )} */}

            <Table variant="striped" size="sm" className="auth-table">
              <Thead
                position="sticky"
                top="0px"
                height="36px"
                maxHeight="36px"
                zIndex="90"
                borderRadius="10px"
                boxShadow={"0px 5px 10px -3px rgba(0,0,0,0.25)"}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr
                    key={headerGroup.id}
                    border="1px solid"
                    borderRadius="10px"
                  >
                    {headerGroup.headers.map((header) => {
                      const cellStyle = {
                        width:
                          header.column.id === "approach"
                            ? "125px"
                            : header.column.id === "pilot"
                            ? "90px"
                            : "initial",
                        fontWeight: 500,
                      };
                      return (
                        <Th
                          key={header.id}
                          style={cellStyle}
                          background={"#436781"}
                          color={"white"}
                          borderTop="hidden"
                          borderBottom="hidden"
                          borderColor="#5c87a5"
                        >
                          {header.isPlaceholder ? null : (
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              gap="3px"
                              fontSize="0.7rem"
                              cursor="pointer"
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <TiArrowSortedDown fontSize="16px" />,
                                desc: <TiArrowSortedUp fontSize="16px" />,
                              }[header.column.getIsSorted()] ?? null}
                            </Flex>
                          )}
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody
                borderTop="1px solid"
                borderColor="gray.400"
                overflowX="auto"
                overflowY={{ base: "hidden", lg: "auto" }}
              >
                {table.getRowModel().rows.map((row, index) => {
                  const isEvenRow = index % 2 === 0;
                  const backgroundColor = isEvenRow ? "#f3f7fb" : "white";

                  return (
                    <Tr
                      key={row.id}
                      border="1px solid"
                      borderColor="gray.200"
                      cursor={"pointer"}
                      backgroundColor={backgroundColor}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const cellStyle = {
                          background:
                            cell.column.id === "status" &&
                            (row.original.status === "approved"
                              ? "#00fb00"
                              : row.original.status === "pending"
                              ? "yellow"
                              : row.original.status === "completed"
                              ? "#00E0FF"
                              : row.original.status === "declined"
                              ? "#ff1b1b"
                              : "initial"),
                          color:
                            cell.column.id === "status" &&
                            (row.original.status === "approved" ||
                              row.original.status === "completed" ||
                              row.original.status === "declined")
                              ? "white"
                              : "initial",
                          fontWeight:
                            cell.column.id === "status" &&
                            row.original.status === "approved"
                              ? 700
                              : cell.column.id === "status"
                              ? 600
                              : 400,
                          letterSpacing:
                            cell.column.id === "status" ? "0.5px" : "0",
                          textAlign:
                            cell.column.id === "title" ? "left" : "center",
                          padding: "5px",
                          paddingLeft:
                            cell.column.id === "title" ? "10px" : "5px",
                          minHeight: "28px",
                          height: "28px",
                          width:
                            cell.column.id !== "id" &&
                            (cell.column.id === "actions"
                              ? "125px"
                              : cell.column.id === "status"
                              ? "90px"
                              : "initial"),
                        };
                        return (
                          <Td
                            key={cell.id}
                            style={cellStyle}
                            borderColor="gray.200"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Flex>
        </TableContainer>
      </Flex>
    </>
  );
}
