import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useDisclosure,
  Text,
  LightMode,
  ModalCloseButton,
  Container,
  Icon,
  Image,
  Box,
} from "@chakra-ui/react";
import {
  airframeModalState,
  airframeDataState,
  aircrewDataState,
  personnelModalState,
  editAircrewState,
} from "../globalState";
import { ReactComponent as Texan } from "../Assets/texan.svg";
import { ReactComponent as C130Icon } from "../Assets/c130.svg";
import { ReactComponent as B757Icon } from "../Assets/b757.svg";
import { ReactComponent as SimIcon } from "../Assets/simulator.svg";
import Pilot from "../Assets/avatar-pilot.png";
import { FiEdit } from "react-icons/fi";

export default function AirframeModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showAirframeModal, setShowAirframeModal] =
    useRecoilState(airframeModalState);

  const airframeData = useRecoilValue(airframeDataState);
  const aircrewData = useRecoilValue(aircrewDataState);
  const setShowPersonnelModal = useSetRecoilState(personnelModalState);
  const setEditAircrewData = useSetRecoilState(editAircrewState);

  const airframeSerials = airframeData;
  const aircrewSerials = aircrewData;
  const airframeSerial = showAirframeModal;

  const type =
    showAirframeModal && showAirframeModal.length && showAirframeModal[1];

  const selectedAirframe =
    showAirframeModal &&
    showAirframeModal.length &&
    showAirframeModal[1] &&
    ((showAirframeModal[1] === "airframe" &&
      airframeSerials.find(
        (airframe) => airframe.serial === airframeSerial[0]
      )) ||
      (showAirframeModal[1] === "aircrew" &&
        aircrewSerials.find(
          (aircrew) => aircrew.callsign === airframeSerial[0]
        )));

  const fillColor =
    selectedAirframe?.status?.airworthy === false
      ? "#FF0000"
      : selectedAirframe?.status?.airworthy === "caution"
      ? "#FFF500"
      : "gray.400";

  useEffect(() => {
    showAirframeModal ? onOpen() : onClose();
  }, [showAirframeModal, onOpen, onClose]);

  function onCloseHandler() {
    setShowAirframeModal(null);
    onClose();
  }

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  // console.log("selectedAirframe", selectedAirframe);

  return (
    <>
      <LightMode>
        <Modal
          variant="primary"
          size="xl"
          transition="all ease 300ms"
          onClose={onCloseHandler}
          isOpen={isOpen}
          closeOnOverlayClick={true}
          isCentered
          trapFocus={false}
          blockScrollOnMount={true}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              zIndex="1"
              textAlign="center"
              backgroundImage="linear-gradient(345deg, #235f88 10%, #0d3856 90%)"
            >
              <Flex
                gap="5px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  w="32px"
                  size="sm"
                  width="36px"
                  variant="solid"
                  onClick={() => {
                    if (showAirframeModal[1] === "aircrew") {
                      setEditAircrewData(selectedAirframe);
                      setShowPersonnelModal(true);
                      // onCloseHandler();
                    }
                  }}
                  bg="transparent"
                  color="white"
                  opacity="0"
                  _hover={{ opacity: "0.8" }}
                  _focus={{ opacity: "0.8" }}
                  _active={{ opacity: "0.8" }}
                  borderRadius="12px"
                  fontSize="1.2rem"
                  p="0px"
                  pb="4px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  ml="-5px"
                  mr="-31px"
                >
                  <FiEdit />
                </Button>
                <Flex
                  gap="5px"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="white">
                    {type === "airframe" ? "Airframe Status" : "Aircrew Member"}
                  </Text>
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              style={{ padding: 0 }}
              fontSize="0.8rem"
              fontFamily="'Open Sans',sans-serif"
            >
              <Flex
                alignItems={{ base: "flex-start", lg: "center" }}
                justifyContent="space-between"
                width="100%"
                fontSize="1.1rem"
                fontWeight="700"
                bg="white"
                borderBottom="1px solid"
                borderColor="gray.100"
                py={{ base: "20px", lg: "15px" }}
                px={{ base: "20px", lg: "30px" }}
                gap={type === "airframe" && { base: "5px", lg: "25px" }}
                flexDirection={{ base: "row", lg: "row" }}
                boxShadow="0px 0px 15px -1px rgba(0,0,0,0.1)"
                zIndex="2"
                position="relative"
              >
                <Flex
                  gap={{ base: "0px", lg: "25px" }}
                  width={{
                    base: "100%",
                    lg: "auto",
                  }}
                  minWidth={{
                    base: "auto",
                    lg: "auto",
                  }}
                  justifyContent={{
                    base: "flex-end",
                    lg: "flex-start",
                  }}
                  alignItems={{
                    base: "flex-start",
                    lg: "center",
                  }}
                  flexDirection={{
                    base: "column",
                    lg: "row",
                  }}
                >
                  <Flex
                    width="100%"
                    justifyContent={"flex-start"}
                    alignItems="baseline"
                    flexDirection="column"
                  >
                    <Text
                      width="100%"
                      fontSize={{ base: "1rem", lg: "1.1rem", xl: "1.2rem" }}
                      fontWeight="700"
                      textAlign="left"
                    >
                      {(type === "airframe" &&
                        airframeSerial &&
                        airframeSerial[0]) ||
                        (type === "aircrew" && selectedAirframe?.name)}
                    </Text>
                    <Text
                      width="100%"
                      fontSize={{ base: "0.8rem", lg: "0.85rem", xl: "0.9rem" }}
                      fontWeight="600"
                      color="gray.400"
                      textAlign="left"
                    >
                      {selectedAirframe?.callsign}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  pb={{ base: "0px", lg: "2px" }}
                  width={{ base: "100%", lg: "auto" }}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  flexDirection="column"
                >
                  {type === "airframe" && (
                    <Flex
                      gap="7px"
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Text
                        color="gray.400"
                        fontWeight="400"
                        fontSize="0.8rem"
                        width="max-content"
                        textAlign={{ base: "left", lg: "right" }}
                      >
                        {selectedAirframe?.manufacturer}
                      </Text>
                      <Text
                        minWidth="max-content"
                        whiteSpace="pre"
                        color="gray.600"
                        fontWeight="600"
                        fontSize="0.9rem"
                        width="max-content"
                        textAlign={{ base: "left", lg: "right" }}
                      >
                        {selectedAirframe?.model === "Boeing 757"
                          ? "757"
                          : selectedAirframe.model}
                      </Text>
                    </Flex>
                  )}
                  {type === "aircrew" && selectedAirframe?.squadron && (
                    <Flex
                      gap="5px"
                      justifyContent="flex-end"
                      alignItems="baseline"
                    >
                      <Text
                        minWidth="max-content"
                        whiteSpace="pre"
                        color="gray.600"
                        fontWeight="600"
                        fontSize="0.9rem"
                        width="max-content"
                        textAlign="right"
                      >
                        {selectedAirframe?.squadron}
                      </Text>
                      <Text
                        color="gray.400"
                        fontWeight="400"
                        fontSize="0.8rem"
                        width="max-content"
                        textAlign="right"
                      >
                        SQUADRON
                      </Text>
                    </Flex>
                  )}

                  {((type === "airframe" &&
                    !selectedAirframe?.status?.airworthy) ||
                    (type === "aircrew" && !selectedAirframe?.available)) && (
                    <Text
                      width="100%"
                      fontSize={{ base: "0.8rem", lg: "0.9rem" }}
                      fontWeight="600"
                      textAlign="right"
                      color="#ff0000"
                    >
                      UNAVAILABLE
                    </Text>
                  )}
                  {((type === "aircrew" && selectedAirframe?.available) ||
                    (type === "airframe" &&
                      selectedAirframe?.status?.airworthy)) && (
                    <Text
                      width="100%"
                      fontSize={{ base: "0.8rem", lg: "0.9rem" }}
                      fontWeight="600"
                      textAlign="right"
                      color="#00fb00"
                    >
                      AVAILABLE
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                pt="30px"
                pb="30px"
                width="100%"
                justifyContent="center"
                alignItems="center"
                background="#FBFDFF"
                pl={{ base: "10px", lg: "20px" }}
                pr={{ base: "20px", lg: "30px" }}
                zIndex="1"
                position="relative"
              >
                <Container maxW="3xl" px="0px">
                  <Flex
                    justifyContent="center"
                    alignItems="flex-start"
                    flexDirection="row"
                    width="100%"
                    gap={{ base: "15px", lg: "20px" }}
                  >
                    {type === "airframe" && (
                      <Icon
                        as={
                          selectedAirframe?.callsign === "SIMULATOR"
                            ? SimIcon
                            : selectedAirframe?.squadron === "40 SQN/CFS" &&
                              selectedAirframe?.model === "Boeing 757"
                            ? B757Icon
                            : selectedAirframe?.squadron === "40 SQN/CFS"
                            ? C130Icon
                            : Texan
                        }
                        mt={"-5px"}
                        ml={"5px"}
                        height={{ base: "50px", lg: "90px" }}
                        width={{ base: "50px", lg: "90px" }}
                        color={fillColor}
                        transform={
                          selectedAirframe?.callsign !== "SIMULATOR" &&
                          "rotate(45deg)"
                        }
                        boxShadow="0px 5px 15px -5px rgba(0,0,0,0.15)"
                        borderRadius="100%"
                        p="6px 4px 4px 4px"
                      />
                    )}

                    {type === "aircrew" && (
                      <Image
                        src={Pilot}
                        mt={"-3px"}
                        ml={"5px"}
                        height={{ base: "50px", lg: "90px" }}
                        width={{ base: "50px", lg: "90px" }}
                        boxShadow="0px 5px 15px -5px rgba(0,0,0,0.15)"
                        border="5px solid #ffffff"
                        borderRadius="100%"
                      />
                    )}

                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      width="100%"
                      bg={"brand.500"}
                      borderRadius="10px"
                      py="7px"
                      fontSize="0.8rem"
                      gap="3px"
                      mb="3px"
                    >
                      <Flex
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="row"
                        width="100%"
                        borderBottom="2px solid"
                        borderColor="#FBFDFF"
                        px="15px"
                        py="2px"
                      >
                        <Text
                          fontSize="0.8rem"
                          fontWeight={"700"}
                          minWidth={{
                            base: type === "airframe" ? "85px" : "50px",
                            lg: type === "airframe" ? "160px" : "50px",
                          }}
                        >
                          {selectedAirframe?.callsign === "SIMULATOR"
                            ? "Operational:"
                            : type === "airframe"
                            ? "Airworthy:"
                            : "Rank:"}
                        </Text>
                        <Flex
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                          width="100%"
                          mb="5px"
                        >
                          {type === "airframe" ? (
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              color={
                                selectedAirframe?.status?.airworthy ===
                                "caution"
                                  ? "#ffd600"
                                  : selectedAirframe?.status?.airworthy
                                  ? "#00fb00"
                                  : "#ff0000"
                              }
                            >
                              {selectedAirframe?.status?.airworthy
                                ? "YES"
                                : "NO"}
                            </Text>
                          ) : (
                            <Text
                              bg={
                                selectedAirframe?.role === "FLTLT"
                                  ? "cyan.100"
                                  : selectedAirframe?.role === "FLTOFF"
                                  ? "green.100"
                                  : "purple.100"
                              }
                              borderRadius="6px"
                              px="7px"
                              fontWeight="600"
                              minWidth={{ base: "18%", xl: "60px" }}
                              textAlign="center"
                              color={
                                selectedAirframe?.role === "FLTLT"
                                  ? "cyan.700"
                                  : selectedAirframe?.role === "FLTOFF"
                                  ? "green.700"
                                  : "purple.700"
                              }
                            >
                              {selectedAirframe?.role}
                            </Text>
                          )}

                          {(selectedAirframe?.status?.airworthy ||
                            selectedAirframe?.status?.airworthy ===
                              "caution") && (
                            <Text
                              fontSize="0.7rem"
                              fontWeight={"400"}
                              fontStyle="italic"
                              textTransform={"uppercase"}
                            >
                              {selectedAirframe?.status?.airworthy === "caution"
                                ? "– with current defects"
                                : selectedAirframe?.status?.airworthy
                                ? ""
                                : "– unavailable"}
                            </Text>
                          )}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent="flex-start"
                        alignItems="center"
                        flexDirection="row"
                        width="100%"
                        px="15px"
                        pt="5px"
                        textTransform="capitalize"
                      >
                        <Text
                          fontSize="0.8rem"
                          fontWeight={"700"}
                          minWidth={type === "airframe" ? "160px" : "95px"}
                        >
                          {type === "airframe" ? "Hours Total:" : "Currency:"}
                        </Text>
                        <Text
                          fontSize="0.8rem"
                          fontWeight={type === "airframe" ? "400" : "600"}
                          color={
                            type === "airframe"
                              ? "gray.600"
                              : selectedAirframe?.currency === "expired"
                              ? "#ff0000"
                              : selectedAirframe?.currency === "expiring"
                              ? "#FDAC30"
                              : "#11EC4E"
                          }
                        >
                          {type === "airframe"
                            ? selectedAirframe?.status?.hourstotal
                            : selectedAirframe?.currency}
                        </Text>
                      </Flex>
                      <Flex
                        justifyContent="flex-start"
                        alignItems="center"
                        flexDirection="row"
                        width="100%"
                        px="15px"
                        textTransform="capitalize"
                      >
                        <Text
                          fontSize="0.8rem"
                          fontWeight={"700"}
                          minWidth={type === "airframe" ? "160px" : "95px"}
                        >
                          {type === "airframe"
                            ? "Hours to Maintenance:"
                            : "Proficiency:"}
                        </Text>
                        <Text
                          fontSize="0.8rem"
                          fontWeight={type === "airframe" ? "400" : "600"}
                          color={
                            type === "airframe"
                              ? "gray.600"
                              : selectedAirframe?.proficiency === "poor"
                              ? "#ff0000"
                              : selectedAirframe?.proficiency === "average"
                              ? "#FDAC30"
                              : "#11EC4E"
                          }
                        >
                          {type === "airframe"
                            ? selectedAirframe?.status?.hourstomaintenance
                            : selectedAirframe?.proficiency}
                        </Text>
                      </Flex>
                      <Flex
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection={{
                          base:
                            type === "aircrew" ||
                            selectedAirframe?.status?.defects.length === 0
                              ? "row"
                              : "column",
                          lg: "row",
                        }}
                        width="100%"
                        px="15px"
                        pb="5px"
                        textTransform="capitalize"
                      >
                        <Text
                          fontSize="0.8rem"
                          fontWeight={"700"}
                          minWidth={type === "airframe" ? "160px" : "95px"}
                        >
                          {type === "airframe" ? "Defects:" : "Fatigue:"}
                        </Text>
                        <Flex
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          width="100%"
                          pl={{
                            base:
                              type === "aircrew" ||
                              selectedAirframe?.status?.defects.length === 0
                                ? "0px"
                                : "10px",
                            lg: "0px",
                          }}
                          ml={
                            selectedAirframe?.status?.defects.length
                              ? "15px"
                              : "0px"
                          }
                        >
                          {type === "airframe" &&
                          selectedAirframe?.status?.defects.length ? (
                            <ul>
                              {selectedAirframe?.status?.defects.map(
                                (defect, i) => (
                                  <li>
                                    <Flex
                                      key={`defect.${i}`}
                                      fontSize="0.8rem"
                                      fontWeight={"400"}
                                    >
                                      {defect}
                                    </Flex>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : type === "aircrew" &&
                            selectedAirframe?.fatigue ? (
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"600"}
                              color={
                                selectedAirframe?.fatigue === "fatigued"
                                  ? "#ff0000"
                                  : "#11EC4E"
                              }
                            >
                              {selectedAirframe.fatigue}
                            </Text>
                          ) : (
                            "None"
                          )}
                        </Flex>
                      </Flex>

                      {type === "aircrew" && (
                        <Flex
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection={{
                            base:
                              type === "aircrew" ||
                              selectedAirframe?.status?.defects.length === 0
                                ? "row"
                                : "column",
                            lg: "row",
                          }}
                          width="100%"
                          px="15px"
                          pt="5px"
                          pb="5px"
                          textTransform="capitalize"
                        >
                          <Text
                            fontSize="0.8rem"
                            fontWeight={"700"}
                            minWidth={type === "airframe" ? "160px" : "95px"}
                          >
                            Comments:
                          </Text>
                          <Flex
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            width="100%"
                            pl={{
                              base:
                                type === "aircrew" ||
                                selectedAirframe?.status?.defects.length === 0
                                  ? "0px"
                                  : "10px",
                              lg: "0px",
                            }}
                            ml={
                              selectedAirframe?.status?.defects.length
                                ? "15px"
                                : "0px"
                            }
                          >
                            {selectedAirframe?.concern ? (
                              <ul>
                                <Box
                                  fontSize="0.8rem"
                                  fontWeight={"600"}
                                  color={
                                    selectedAirframe?.concerndate < 0
                                      ? "#FF1414"
                                      : "#FDAC30"
                                  }
                                >
                                  {selectedAirframe.concern}
                                </Box>
                              </ul>
                            ) : (
                              "None"
                            )}
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Container>
              </Flex>
            </ModalBody>
            <ModalFooter borderTop="1px solid #e5e7e9" pt={4} pb={4}>
              <Flex
                flexDirection="row"
                gap="10px"
                width="100%"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="outline"
                  onClick={() => {
                    onCloseHandler();
                  }}
                  bg="gray.50"
                  color="gray.700"
                  _hover={{ background: "gray.100" }}
                  _focus={{ background: "gray.100" }}
                  _active={{ background: "gray.100" }}
                  borderRadius="25px"
                >
                  Close
                </Button>

                {/* <Button
                  {...noFocus}
                  onClick={() => {
                    if (!authorisations) {
                      navigate("/authorisations");
                    }
                    setEditAuthorisation(true);
                    setShowAirframeModal(false);
                    onClose();
                  }}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  bg="white"
                  color="light.100"
                  variant="outline"
                  _hover={{ background: "gray.50" }}
                  _focus={{ background: "gray.50" }}
                  _active={{ background: "gray.50" }}
                  borderRadius="25px"
                >
                  Edit
                </Button>

                {
                  // isAdmin &&
                  authorisationData?.status !== "draft" &&
                    authorisationData?.status !== "approved" &&
                    authorisationData?.status !== "completed" && (
                      <Button
                        {...noFocus}
                        w="100%"
                        size="sm"
                        maxWidth="180px"
                        variant="primary"
                        bg="brand.100"
                        _hover={{ background: "brand.200" }}
                        _focus={{ background: "brand.200" }}
                        _active={{ background: "brand.200" }}
                        borderRadius="25px"
                        onClick={() => {
                          if (!authorisations) {
                            navigate("/authorisations");
                          }
                          setTab("approval");
                          setEditAuthorisation(true);
                          setShowAirframeModal(false);
                          onClose();
                        }}
                      >
                        Approve
                      </Button>
                    )
                } */}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  );
}
