import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useApproverRole } from "../Auth/Hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useDisclosure,
  Text,
  LightMode,
  ModalCloseButton,
  Icon,
} from "@chakra-ui/react";
import {
  formatDistanceToNow,
  parseISO,
  // formatDuration,
  intervalToDuration,
} from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { ReactComponent as Texan } from "../Assets/texan.svg";
import { ReactComponent as B757Icon } from "../Assets/b757.svg";
import { ReactComponent as C130Icon } from "../Assets/c130.svg";
import { ReactComponent as Alerts } from "../Assets/alerts.svg";
import { ReactComponent as SimIcon } from "../Assets/simulator.svg";
import { ReactComponent as AlertsDot } from "../Assets/alerts-notification.svg";
import {
  notificationModalState,
  notificationsState,
  // squadronSelectionState,
  authorisationDataState,
  authorisationsModalState,
  userProfileState,
} from "../globalState";

export default function NotificationModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isApprover = useApproverRole();
  const [showNotificationModal, setShowNotificationModal] = useRecoilState(
    notificationModalState
  );
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  // const squadronSelection = useRecoilValue(squadronSelectionState);
  const setShowAuthorisationsModal = useSetRecoilState(
    authorisationsModalState
  );
  const setAuthorisationData = useSetRecoilState(authorisationDataState);
  const { getAccessTokenSilently } = useAuth0();
  const [profileData, setProfileData] = useRecoilState(userProfileState);
  const mounted = useRef(false);
  const previousProfileData = useRef(profileData);

  useEffect(() => {
    showNotificationModal ? onOpen() : onClose();
  }, [showNotificationModal, onOpen, onClose]);

  function onCloseHandler() {
    setShowNotificationModal(false);
    onClose();
  }

  // *** function to update profileData when notifications.length changes ***
  useEffect(() => {
    console.log("Firing notifications update to user metadata");
    if (profileData && notifications) {
      const newNotifications = notifications || [];
      // Only update if notifications is different
      if (profileData.notifications !== newNotifications) {
        setProfileData((prevState) => ({
          ...prevState,
          notifications: newNotifications,
        }));
      }
    }
  }, [notifications, profileData, isOpen, setProfileData]);

  const data = useMemo(() => {
    if (!notifications) return [];

    // filter out duplicate objects that have same ID keeping the one with latest timestamp
    const filteredNotifications = Object.values(
      notifications.reduce((item, feat) => {
        if (
          !item[feat.id] ||
          new Date(feat.timestamp) > new Date(item[feat.id].timestamp)
        ) {
          item[feat.id] = feat;
        }
        return item;
      }, {})
    );

    return (
      filteredNotifications
        // .filter((feat) => feat?.squadron === squadronSelection)
        .filter((booking) => booking?.status !== "draft") // exclude draft bookins
        .filter((booking) => booking?.status !== "deleted") // exclude deleted bookings
        .filter(
          (
            booking // if approver only show Pending bookings
          ) => (isApprover ? booking?.status === "pending" : true)
        )
        .filter(
          (
            booking // if pilot exclude pending bookings
          ) => (isApprover ? true : booking?.status !== "pending")
        )
        .filter(
          (
            booking // if pilot exclude completed bookings
          ) => (isApprover ? true : booking?.status !== "completed")
        )
        .map((booking) => ({
          ...booking,
        }))
    );
  }, [notifications, isApprover]);

  // *** function to fire the updateAuth0Profile callback (triggered if profileData has changed) ***
  const updateAuth0Profile = useCallback(
    async (profileData) => {
      const accessToken = await getAccessTokenSilently();
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          user_metadata: {
            notifications:
              profileData.notifications !== ""
                ? profileData.notifications
                : null,
          },
        }),
      };

      fetch(
        `${window.location.origin}/user/updateprofile`,
        requestOptions
      ).then((res) => {
        if (!res.ok) {
          console.log(res);
          console.log("Error updating Auth0 Metadata");
        }
        console.log("Successfully Updatied Auth0 MetaData");
        // setUpdatingAuth0(false);
        return;
      });
    },
    [getAccessTokenSilently]
  );

  useEffect(() => {
    if (mounted.current) {
      updateAuth0Profile(profileData);
    } else {
      mounted.current = true;
    }
    // Update the previousProfileData ref whenever profileData changes
    previousProfileData.current = profileData;
  }, [profileData, updateAuth0Profile]);

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  const NotificationItem = (props) => {
    const {
      // id,
      title,
      aircraft,
      // activity,
      airframe,
      callsign,
      model,
      squadron,
      // priority,
      status,
      since,
      till,
      updated,
      updatedState,
    } = props;
    // determine duration
    const fromTime = parseISO(since);
    const toTime = parseISO(till);

    const now = Date.now();
    const timeZone = "Pacific/Auckland";
    // const upcoming = parseISO(since) > now;
    const expired = parseISO(till) < now;

    const duration = intervalToDuration({ start: fromTime, end: toTime });
    // const durationDetail = formatDuration(duration, {
    //   format: ["days", "hours", "minutes"],
    // });
    const padWithZero = (value) => (value < 10 ? `0${value}` : value);
    const durationHasDays = duration?.days ? duration.days * 24 : 0;
    const formattedDuration = `${
      durationHasDays + duration?.hours
    }:${padWithZero(duration?.minutes)}`;

    const color =
      status === "expired"
        ? "#FF1414"
        : status === "pending"
        ? "yellow"
        : status === "draft"
        ? "gray.200"
        : "#11EC4E";
    const borderColor =
      status === "expired"
        ? "red.500"
        : status === "pending"
        ? "#eded00"
        : status === "draft"
        ? "gray.300"
        : "#0bd844";

    // console.log("profileData", profileData);

    return (
      <Flex
        width="100%"
        flexDirection="row"
        pl="6px"
        pr="10px"
        py="6px"
        borderRadius="23px"
        border="1px solid"
        borderColor="#e5e5e5"
        minHeight="80px"
        justifyContent="center"
        alignItems="flex-start"
        gap="10px"
        bg="white"
        cursor="pointer"
        transition="all ease 150ms"
        _hover={{
          bg: "#FDFEFF",
          boxShadow: "0px 3px 15px -4px rgba(0, 45, 78, 0.2)",
        }}
        onClick={() => {
          setAuthorisationData(props);
          setShowAuthorisationsModal(true);
          setNotifications((prevItems) => {
            // remove clicked item from notifications list
            const updatedItems = prevItems.filter(
              (item) => item.id !== props.id
            );
            return updatedItems;
          });
          onCloseHandler();
        }}
      >
        <Flex
          width="32px"
          minWidth="32px"
          height="32px"
          borderRadius="50px"
          alignItems="center"
          justifyContent="center"
          bg={color}
          border="1px solid"
          borderColor={borderColor}
        >
          <Icon
            as={
              callsign === "SIMULATOR"
                ? SimIcon
                : squadron === "40 SQN/CFS" && model === "Boeing 757"
                ? B757Icon
                : squadron === "40 SQN/CFS"
                ? C130Icon
                : Texan
            }
            width="26px"
            height="26px"
            color={"white"}
            transform={callsign !== "SIMULATOR" && "rotate(45deg)"}
          />
        </Flex>
        <Flex flexDirection="column" width="100%">
          <Flex
            width="100%"
            gap="5px"
            minHeight="24px"
            fontSize={{ base: "0.7rem", lg: "0.75rem" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              fontSize={{ base: "0.85rem", lg: "0.9rem" }}
              fontWeight={"500"}
              color="gray.600"
              width="100%"
              pt="1px"
            >
              {title}
            </Text>

            <Flex
              justifyContent="center"
              alignItems="center"
              color={
                status === "approved" ||
                status === "completed" ||
                status === "declined"
                  ? "white"
                  : "gray.600"
              }
              mt="1px"
              mr="1px"
              fontWeight="700"
              textTransform="uppercase"
              minWidth="75px"
              height="20px"
              fontSize="0.65rem"
              letterSpacing="0.5px"
              borderRadius="15px"
              lineHeight="1"
              background={
                status === "approved"
                  ? "#00fb00"
                  : status === "pending"
                  ? "yellow"
                  : status === "completed"
                  ? "#00E0FF"
                  : status === "declined"
                  ? "#ff1b1b"
                  : "gray.100"
              }
            >
              {status ? status : "Draft"}
            </Flex>
          </Flex>

          <Flex
            mt="3px"
            width="100%"
            gap="5px"
            minHeight="20px"
            fontSize={{ base: "0.7rem", lg: "0.75rem" }}
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Flex
              minWidth="140px"
              gap="5px"
              fontSize={{ base: "0.7rem", lg: "0.75rem" }}
            >
              <Text
                minWidth="46px"
                textAlign="left"
                color="gray.600"
                fontWeight="600"
              >
                {airframe}
              </Text>
              <Text fontWeight="400" color="gray.500" width="auto">
                {aircraft}
              </Text>

              {/* {upcoming && (
                <Text
                  ml="15px"
                  minWidth="46px"
                  textAlign="left"
                  color="cyan.400"
                  fontWeight="400"
                  fontStyle="italic"
                  pl="5px"
                >
                  Upcoming
                </Text>
              )} */}
            </Flex>

            <Flex
              minWidth="140px"
              gap="5px"
              fontSize={{ base: "0.7rem", lg: "0.75rem" }}
              alignItems="baseline"
              justifyContent="flex-end"
            ></Flex>
          </Flex>

          <Flex
            width="100%"
            gap={{ base: "5px", md: "5px" }}
            minHeight="20px"
            fontSize={{ base: "0.7rem", lg: "0.75rem" }}
            alignItems="baseline"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex
              minWidth="140px"
              gap="5px"
              fontSize={{ base: "0.7rem", lg: "0.75rem" }}
            >
              <Text
                minWidth="46px"
                textAlign="left"
                color="gray.500"
                fontWeight="400"
              >
                {fromTime &&
                  formatToTimeZone(fromTime, "D MMM YY HH:mm", {
                    timeZone: timeZone,
                  })}
              </Text>
              –
              <Text
                minWidth="46px"
                textAlign="left"
                color="gray.500"
                fontWeight="400"
              >
                {toTime &&
                  formatToTimeZone(toTime, "D MMM YY HH:mm", {
                    timeZone: timeZone,
                  })}
              </Text>
              <Text
                minWidth="46px"
                textAlign="left"
                color="gray.400"
                fontWeight="400"
                fontStyle="italic"
                pl="5px"
              >
                {formattedDuration} hours
              </Text>
            </Flex>

            <Flex
              minWidth={{ base: "100%", md: "100px" }}
              gap="3px"
              fontSize={{ base: "0.7rem", lg: "0.75rem" }}
              alignItems="baseline"
              justifyContent="flex-end"
              pr="5px"
              fontStyle={"italic"}
            >
              <Text
                fontSize={{ base: "0.65rem", lg: "0.7rem" }}
                fontWeight={
                  status === "declined"
                    ? "400"
                    : updatedState === "updated"
                    ? "400"
                    : "600"
                }
                color={
                  status === "declined"
                    ? "red.400"
                    : updatedState === "new"
                    ? "brand.100"
                    : updatedState === "updated"
                    ? "gray.400"
                    : "gray.500"
                }
                width="max-content"
                whiteSpace="pre"
                textAlign="right"
              >
                {status === "declined"
                  ? "Declined"
                  : updatedState
                  ? updatedState === "updated"
                    ? "Updated"
                    : status === "approved" && updatedState === "new"
                    ? "Approved"
                    : status === "pending" && updatedState === "new"
                    ? "New Flight Authorisation"
                    : ""
                  : ""}
              </Text>

              <Text
                fontSize={{ base: "0.65rem", lg: "0.7rem" }}
                fontWeight={expired ? "500" : "400"}
                color={status === "declined" ? "red.400" : "gray.400"}
                width="max-content"
                whiteSpace="pre"
                textAlign="right"
              >
                {updated && formatDistanceToNow(parseISO(updated)) + " ago"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  // console.log("notifications: ", notifications);
  // console.log("data: ", data);

  return (
    <>
      <LightMode>
        <Modal
          variant="primary"
          size="2xl"
          transition="all ease 300ms"
          onClose={onCloseHandler}
          isOpen={isOpen}
          closeOnOverlayClick={true}
          isCentered
          trapFocus={false}
          blockScrollOnMount={true}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              zIndex="1"
              textAlign="center"
              backgroundImage="linear-gradient(345deg, #235f88 10%, #0d3856 90%)"
            >
              <Flex
                gap="10px"
                width="100%"
                justifyContent="center"
                alignItems="center"
                pr="10px"
              >
                <Icon
                  color="white"
                  as={data && data.length > 0 ? AlertsDot : Alerts}
                  zIndex="1"
                  width="24px"
                  height="auto"
                  cursor="pointer"
                />
                <Text color="white">Notifications</Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody
              style={{ padding: 0 }}
              fontSize="0.8rem"
              fontFamily="'Open Sans',sans-serif"
            >
              <Flex
                alignItems="center"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                minHeight="60px"
                fontSize="1.1rem"
                fontWeight="700"
                bg="#F7FAFC"
                py={{ base: "20px", md: "30px" }}
                px={{ base: "20px", md: "30px" }}
                gap={{ base: "0px", md: "15px" }}
                zIndex="2"
                position="relative"
              >
                {data && data.length > 0 ? (
                  data
                    .sort((a, b) => {
                      // then sort by since
                      if (a.since < b.since) {
                        return 1;
                      }
                      if (a.since > b.since) {
                        return -1;
                      }
                      // sort by pending status first
                      if (a.status === "pending" && b.status !== "pending") {
                        return -1;
                      }
                      if (a.status !== "pending" && b.status === "pending") {
                        return 1;
                      }
                      // sort by draft status last
                      if (a.status === "draft" && b.status !== "draft") {
                        return 1;
                      }
                      if (a.status !== "draft" && b.status === "draft") {
                        return -1;
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <NotificationItem key={index} {...item} />
                    ))
                ) : (
                  <Text
                    color="gray.500"
                    fontSize="0.9rem"
                    textAlign="center"
                    width="100%"
                    fontWeight="500"
                    py="30px"
                  >
                    No current notifications
                  </Text>
                )}
              </Flex>
            </ModalBody>
            <ModalFooter borderTop="1px solid #e5e7e9" pt={4} pb={4}>
              <Flex
                flexDirection="row"
                gap="10px"
                width="100%"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="outline"
                  onClick={() => {
                    onCloseHandler();
                  }}
                  bg="gray.50"
                  color="gray.700"
                  _hover={{ background: "gray.100" }}
                  _focus={{ background: "gray.100" }}
                  _active={{ background: "gray.100" }}
                  borderRadius="25px"
                >
                  Close
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  );
}
