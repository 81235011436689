import React, { useMemo } from "react";
import { useEpg, Epg, Layout } from "@nessprim/planby";
import Area from "./Area";
import Booking from "./Booking";
import { theme } from "../../Theme/Components/PlanningBoard";

export default function AreaBoard() {
  const texanSerials = useMemo(
    () => ["OH Circuit", "NZM507 - East Wairarapa"],
    []
  );

  const channels = useMemo(
    () =>
      texanSerials.map((serial) => ({
        logo: "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        serial: serial,
        uuid: serial,
      })),
    [texanSerials]
  );

  const epg = useMemo(
    () => [
      {
        channelUuid: "NZ1401",
        // description: "Ut anim nisi consequat minim deserunt...",
        id: "1",
        // image:
        //   "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        since: "2023-08-22T17:00:00",
        till: "2023-08-22T18:00:00",
        title: "Josh Sortie",
      },
      {
        channelUuid: "NZ1402",
        // description: "Ut anim nisi consequat minim deserunt...",
        id: "2",
        // image:
        //   "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        since: "2023-08-22T16:45:00",
        till: "2023-08-22T19:00:00",
        title: "Matt Sortie",
      },
      {
        channelUuid: "NZ1403",
        // description: "Ut anim nisi consequat minim deserunt...",
        id: "3",
        // image:
        //   "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        since: "2023-08-22T15:30:00",
        till: "2023-08-22T16:00:00",
        title: "Jason Sortie",
      },
    ],
    []
  );

  const {
    getEpgProps,
    getLayoutProps,
    // onScrollToNow,
    // onScrollLeft,
    // onScrollRight,
  } = useEpg({
    epg,
    channels,
    startDate: "2023-08-22T00:00", // or 2022-02-02T00:00:00
    // width: "50%",
    // height: "100%",
    theme,
  });

  return (
    <Epg {...getEpgProps()} style={{ borderRadius: "15px" }}>
      <Layout
        {...getLayoutProps()}
        renderChannel={({ channel }) => (
          <Area key={channel.serial} area={channel} />
        )}
        renderProgram={({ program, ...rest }) => (
          <Booking key={program.data.id} program={program} {...rest} />
        )}
      />
    </Epg>
  );
}
