import { useRecoilValue } from "recoil";
import {
  authorisationsModalState,
  airframeModalState,
  deleteModalState,
  personnelModalState,
  notificationModalState,
} from "../globalState";
import AuthorisationsModal from "./AuthorisationsModal";
import AirframeModal from "./AirframeModal";
import DeleteModal from "./DeleteModal";
import PersonnelModal from "./PersonnelModal";
import NotificationModal from "./NotificationModal";

export default function Modals() {
  const showAuthorisationsModal = useRecoilValue(authorisationsModalState);
  const showAirframeModal = useRecoilValue(airframeModalState);
  const showDeleteModal = useRecoilValue(deleteModalState);
  const showPersonnelModal = useRecoilValue(personnelModalState);
  const showNotificationModal = useRecoilValue(notificationModalState);

  return (
    <>
      {showAuthorisationsModal && <AuthorisationsModal />}
      {showAirframeModal && <AirframeModal />}
      {showDeleteModal && <DeleteModal />}
      {showPersonnelModal && <PersonnelModal />}
      {showNotificationModal && <NotificationModal />}
    </>
  );
}
