import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { Controller } from "react-hook-form";
import { parseISO, format, addMinutes } from "date-fns";
import Flatpickr from "react-flatpickr";
import { formatToTimeZone } from "date-fns-timezone";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Flex,
  Button,
  // Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import FlightAuthForm from "../../Forms/FlightAuthForm";
import FlightAuthSelected from "../../Forms/FlightAuthSelected";
import { overrideState } from "../../globalState";
// import PersonnelImage from "../../Assets/personnel.png";

export default function ApprovalTab(props) {
  const {
    airframeSerials,
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
    authorisationData,
    newAuthorisation,
    editAuthorisation,
    timeZone,
    selectStyle,
    isApprover,
  } = props;

  const [override, setOverride] = useRecoilState(overrideState);

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };
  // flatpickr refs
  const fpDateapproved = useRef(null);
  const now = Date.now();

  // Periodic update of dateapproved Date/Time
  const timeWatch = watch(["approveddate"]);
  const timeReset = parseISO(timeWatch[0]) < addMinutes(now, 5);
  useEffect(() => {
    if (!editAuthorisation && timeReset) {
      setValue(
        "approveddate",
        formatToTimeZone(addMinutes(now, 5), "YYYY-MM-DD HH:mmZ", {
          timeZone: timeZone,
        })
      );

      if (fpDateapproved.current) {
        fpDateapproved.current.flatpickr.set(
          // update approveddate minDate
          "minDate",
          formatToTimeZone(now, "D MMM YY HH:mm", {
            timeZone: timeZone,
          })
        );
      }
    }
  }, [
    now,
    editAuthorisation,
    timeReset,
    setValue,
    getValues,
    timeWatch,
    timeZone,
  ]);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
    >
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        width="100%"
        // height="100%"
        boxShadow={override && "inset 0px 0px 2px 1px #ff0000"}
        bg={override && "#fffafa"}
        borderBottom={{ base: "0px solid", lg: "1px solid #e5e7ea" }}
      >
        <Flex
          p={{ base: "20px", lg: "20px" }}
          paddingTop={{ base: "15px", lg: "15px" }}
          paddingBottom={{ base: "30px", lg: "30px" }}
          flexDirection="column"
          // width={{ base: "100%", lg: "calc(100% - 340px)" }}
          width={{ base: "100%", lg: "100%" }}
          // height="100%"
          // borderRight={{ base: "0px solid", lg: "1px solid #e5e7ea" }}
          // borderColor="#e5e7ea"
        >
          <Flex width="100%" gap="14px" flexDirection="column">
            <Flex
              width="100%"
              gap="50px"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                Flight Details
              </Text>

              <Button
                {...noFocus}
                borderRadius="25px"
                variant="outline"
                size="sm"
                mt="-2px"
                bg={override ? "gray.50" : "white"}
                color={override ? "red.400" : "gray.500"}
                width="90px"
                height="26px"
                fontSize="0.75rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "gray.100",
                  color: override ? "red.400" : "gray.600",
                }}
                onClick={() => {
                  setOverride((prevState) => !prevState);
                }}
              >
                Override
              </Button>
            </Flex>
            <FormControl isInvalid={errors.title}>
              <Flex
                alignItems="baseline"
                justifyContent="flex-start"
                flexDirection={{ base: "column", lg: "row" }}
                pb={override ? "30px" : isApprover ? "0px" : "20px"}
              >
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="title"
                  fontFamily="'Poppins'"
                  transition="all ease 250ms"
                  opacity={override ? "1" : "0"}
                  width={override ? "35px" : "0px"}
                  overflow="hidden"
                  height="20px"
                  maxHeight="20px"
                  marginRight={override ? "3" : "0"}
                >
                  Title
                </FormLabel>

                <Input
                  transition="all ease 250ms"
                  fontSize="0.9rem"
                  fontWeight="600"
                  width={{ base: "100%", lg: "100%" }}
                  variant="outline"
                  background={override ? "white" : "brand.400"}
                  pointerEvents={
                    (isApprover && !override) || !override ? "none" : "auto"
                  }
                  size="sm"
                  borderRadius="10px"
                  cursor="pointer"
                  id="title"
                  {...register(
                    "title",
                    override && {
                      required: "This is required",
                    }
                  )}
                />
              </Flex>
              <FormErrorMessage>
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          {override ? (
            <FlightAuthForm
              authorisationData={authorisationData}
              newAuthorisation={newAuthorisation}
              editAuthorisation={editAuthorisation}
              airframeSerials={airframeSerials}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              watch={watch}
              override={override}
              timeZone={timeZone}
              selectStyle={selectStyle}
            />
          ) : (
            !isApprover && (
              <FlightAuthSelected
                authorisationData={authorisationData}
                newAuthorisation={newAuthorisation}
                editAuthorisation={editAuthorisation}
                airframeSerials={airframeSerials}
                register={register}
                control={control}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                watch={watch}
                override={override}
                timeZone={timeZone}
                selectStyle={selectStyle}
              />
            )
          )}
        </Flex>
        {/* <Flex
          flexDirection={{ base: "column", lg: "row" }}
          width={{ base: "100%", lg: "340px" }}
          pt="5px"
          maxWidth="340px"
          height="100%"
        >
          <Image src={PersonnelImage} />
        </Flex> */}
      </Flex>

      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        width="100%"
        height="100%"
        background="#F1F6FA"
      >
        <Flex
          p={{ base: "20px", lg: "20px" }}
          paddingTop={{ base: "15px", lg: "15px" }}
          paddingBottom={{ base: "30px", lg: "80px" }}
          flexDirection="column"
          width={{ base: "100%", lg: "calc(100% - 340px)" }}
          height="100%"
          borderRight={{ base: "0px solid", lg: "1px solid #e5e7ea" }}
          borderColor="#e5e7ea"
        >
          <Flex width="100%" gap="50px">
            <Flex width="100%" gap="20px" flexDirection="column">
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                Flight Approval
              </Text>
              <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="restrictions"
                  fontFamily="'Poppins'"
                  overflow="hidden"
                  height="20px"
                  maxHeight="20px"
                >
                  Restrictions
                </FormLabel>

                <Input
                  transition="all ease 250ms"
                  fontSize="0.9rem"
                  fontWeight="400"
                  width={{ base: "100%", lg: "100%" }}
                  variant="outline"
                  background="white"
                  size="sm"
                  borderRadius="10px"
                  cursor="pointer"
                  id="restrictions"
                  {...register("restrictions", {})}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="approvedcomments"
                  fontFamily="'Poppins'"
                >
                  Comments
                </FormLabel>
                <Textarea
                  variant="outline"
                  background="white"
                  size="sm"
                  minHeight={{ base: "160px", lg: "120px" }}
                  borderRadius="10px"
                  cursor="pointer"
                  id="approvedcomments"
                  {...register("approvedcomments", {})}
                />
              </FormControl>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection={{ base: "column", lg: "column" }}
          width={{ base: "100%", lg: "340px" }}
          maxWidth={{ base: "100%", lg: "340px" }}
          height="100%"
          p={{ base: "20px", lg: "20px" }}
          paddingTop={{ base: "15px", lg: "15px" }}
          paddingBottom={{ base: "40px", lg: "40px" }}
          gap="20px"
        >
          <FormControl isInvalid={errors.approvedby}>
            <FormLabel
              fontSize="0.8rem"
              htmlFor="approvedby"
              fontFamily="'Poppins'"
              overflow="hidden"
              height="20px"
              maxHeight="20px"
            >
              Approved By
            </FormLabel>

            <Input
              transition="all ease 250ms"
              fontSize="0.9rem"
              fontWeight="400"
              width={{ base: "100%", lg: "100%" }}
              variant="outline"
              background="white"
              size="sm"
              borderRadius="10px"
              cursor="pointer"
              id="approvedby"
              {...register(
                "approvedby",
                isApprover && {
                  required: "This is required",
                }
              )}
            />

            <FormErrorMessage>
              {errors.approvedby && errors.approvedby.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.approveddate}>
            <FormLabel
              fontSize="0.8rem"
              htmlFor="approveddate"
              fontFamily="'Poppins'"
            >
              Date of Approval
            </FormLabel>
            <Controller
              control={control}
              name="approveddate"
              rules={
                isApprover && {
                  validate: {
                    notInvalid: (value) =>
                      (value !== null && value !== "Invalid Date") ||
                      "Please select date/time",
                  },
                  required: "Please select date/time",
                }
              }
              render={({ field: { onChange, value } }) => (
                <Flatpickr
                  id="approveddate"
                  ref={fpDateapproved}
                  placeholder="Select date/time..."
                  style={{
                    width: "100%",
                    fontFamily: "'Open Sans', sans-serif",
                    height: "32px",
                    borderRadius: "7px",
                    fontSize: "0.875rem",
                    paddingLeft: "0.75rem",
                    paddingRight: "0.75rem",
                    border: "1px solid",
                    borderColor: errors.approveddate ? "#E53E3E" : "#ced4da",
                  }}
                  className="flatpickr form-control form-control-sm"
                  data-enable-time
                  value={formatToTimeZone(value, "D MMM YY HH:mm", {
                    timeZone: timeZone,
                  })}
                  onChange={(value) => {
                    onChange(
                      value.length ? format(value[0], "yyyy-MM-dd HH:mm") : null
                    );
                  }}
                  options={{
                    allowClearing: false,
                    // disableMobile: true,
                    dateFormat: "j M y H:i",
                    time_24hr: true,
                    minuteIncrement: 1,
                    minDate: formatToTimeZone(now, "D MMM YY HH:mm", {
                      timeZone: timeZone,
                    }),
                    defaultDate: formatToTimeZone(
                      addMinutes(now, 5),
                      "D MMM YY HH:mm",
                      { timeZone: timeZone }
                    ),
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors.approveddate && errors.approveddate.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel
              fontSize="0.8rem"
              htmlFor="approvedref"
              fontFamily="'Poppins'"
            >
              References
            </FormLabel>
            <Textarea
              variant="outline"
              background="white"
              size="sm"
              minHeight={{ base: "120px", lg: "80px" }}
              borderRadius="10px"
              cursor="pointer"
              id="approvedref"
              {...register("approvedref", {})}
            />
          </FormControl>
        </Flex>
      </Flex>
    </Flex>
  );
}
