import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { Routes, Route, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Container,
  Flex,
  VStack,
  Text,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { isSameDay, subDays } from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";

import Auth0UserDemo from "./Auth/Auth0UserDemo";

import Airframe from "./Airframe";
import Aircrew from "./Aircrew";
import Area from "./Area";
import Authorisations from "./Authorisations";
import CheckInsLink from "./CheckInsLink";
import DeparturesLink from "./DeparturesLink";
import CrewManagement from "./CrewManagement";
import SafetyAndQualityLink from "./SafetyAndQualityLink";
import RiskMatrixLink from "./RiskMatrixLink";
import HazardReporting from "./HazardReportingLink";
import "./App.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Menu from "./Menu";
import CrewMenu from "./Menu/CrewMenu";
import FacilitationMenu from "./Menu/FacilitationMenu";
import SafetyMenu from "./Menu/SafetyMenu";
import Dashboard from "./Dashboard";
import { ReactComponent as Logo } from "./Assets/logo.svg";
// import BgBlur from "./Assets/bg-blur.jpg";
import Modals from "./Modals";
import airframeData from "./Data/airframe.json";
import aircrewData from "./Data/aircrew.json";
import {
  editAuthorisationState,
  newAuthorisationState,
  // deleteAuthorisationState,
  isRenderedState,
  userState,
  airframeDataState,
  aircrewDataState,
  notificationsState,
} from "./globalState";

export default function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(1);

  const [loadAirframeData, setLoadAirframeData] =
    useRecoilState(airframeDataState);
  const [loadAircrewData, setLoadAircrewData] =
    useRecoilState(aircrewDataState);
  const editAuthorisation = useRecoilValue(editAuthorisationState);
  const newAuthorisation = useRecoilValue(newAuthorisationState);
  // const deleteAuthorisation = useRecoilValue(deleteAuthorisationState);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [showHeaderTools, setShowHeaderTools] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileMenuFacilitation, setShowMobileMenuFacilitation] =
    useState(false);
  const [showMobileMenuCrew, setShowMobileMenuCrew] = useState(false);
  const [showMobileMenuSafety, setShowMobileMenuSafety] = useState(false);
  const [isRendered, setIsRendered] = useRecoilState(isRenderedState);
  const notifications = useRecoilValue(notificationsState);
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (!loadAirframeData || loadAirframeData.length === 0) {
      setLoadAirframeData(airframeData);
    }
  }, [loadAirframeData, setLoadAirframeData]);

  useEffect(() => {
    if (!loadAircrewData || loadAircrewData.length === 0) {
      setLoadAircrewData(aircrewData);
    }
  }, [loadAircrewData, setLoadAircrewData]);

  // function to detect if UTC is day before or current day compared to local time
  const [utcDateState, setUtcDateState] = useState({
    isUTCSameDay: false,
    isUTCPreviousDay: false,
  });
  const checkUTCDate = () => {
    const now = new Date();
    const utcTime = convertToTimeZone(now, {
      timeZone: "UTC",
    });
    const localTime = convertToTimeZone(now, {
      timeZone: "Pacific/Auckland",
    });
    const isUTCSameDay = isSameDay(utcTime, localTime);
    const isUTCPreviousDay =
      !isUTCSameDay && isSameDay(subDays(localTime, 1), utcTime);

    // console.log("now:", now);
    // console.log("utcTime:", utcTime);
    // console.log("localTime:", localTime);
    // console.log("isUTCSameDay:", isUTCSameDay);
    // console.log("isUTCPreviousDay", isUTCPreviousDay);
    return {
      isUTCSameDay,
      isUTCPreviousDay,
    };
  };

  useEffect(() => {
    setUtcDateState(checkUTCDate());
  }, []);
  // console.log("utcDateState", utcDateState);

  const homepage = location.pathname === "/";
  const taskings = location.pathname === "/taskings";
  const authorisations = location.pathname === "/authorisations";
  const checkIns = location.pathname === "/check-ins";
  const departures = location.pathname === "/departures";
  const aircrew = location.pathname === "/aircrew-management";
  const maintenanceCrew = location.pathname === "/maintenance-crew-management";
  const airMovementsCrew =
    location.pathname === "/air-movements-crew-management";
  const trainingRecords = location.pathname === "/training-records";
  const safetyAndQuality =
    location.pathname === "/safety-and-quality-assurance";
  const riskMatrix = location.pathname === "/risk-matrix";
  const hazardReporting = location.pathname === "/hazard-reporting";

  useEffect(() => {
    if (count < 5) {
      setLoading(true);
      const tick = () => {
        setCount((prevState) => prevState + 1);
      };
      const timer = setInterval(() => tick(), 1000);
      return () => clearInterval(timer);
    }
    if (count >= 5) {
      setCount(6);
      setLoading(false);
    }
  }, [count, setCount, setLoading]);

  useEffect(() => {
    document.title = "TASKR";
  }, []);

  const getBookings = useCallback(async () => {
    const res = await fetch(`${window.location.origin}/data/booking`);

    return res.json();
  }, []);

  const { data, refetch } = useQuery("booking", getBookings, {
    placeholderData: [],
    refetchOnWindowFocus: false,
    cacheTime: 15000, // disable caching (max 15 seconds)
  });

  useEffect(() => {
    if (data && !editAuthorisation && !newAuthorisation) {
      refetch();
      console.log("Refetching Data"); //  refetch data after saving/closing authorisation
    }
  }, [data, newAuthorisation, editAuthorisation, refetch]);

  useEffect(() => {
    // need this separate useEffect to reload data again after approving
    refetch();
    console.log("Refetching Data after notifications update"); //  refetch data after notifications update
  }, [notifications, refetch]);

  const handleRefetch = () => {
    //  manually refetch data by clicking INFO button in header
    refetch();
    console.log("Manually Refetching Data");
  };

  const updateBookings = async () => {
    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(updatedData),
    // };

    try {
      if (utcDateState?.isUTCSameDay) {
        await fetch("/admin/updateallbookingstocurrentday");
        console.log("Data updated to account for current date state");
      }
      if (utcDateState?.isUTCPreviousDay) {
        await fetch("/admin/updateallutcbookingstocurrentday"); // if UTC the day before compared to local time
        console.log("Data updated to account for UTC state");
      }
      console.log("Data updated successfully");

      refetch();
    } catch (error) {
      console.error(
        "An error occurred while updating Bookings data:",
        error.message
      );
    }
  };

  // const saveUpdatedData = async (updatedData) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(updatedData),
  //   };

  //   try {
  //     await fetch("/post/booking", requestOptions); // TODO - need to check this is correct

  //     console.log("Data updated successfully: ", requestOptions);
  //     console.log("updatedData", updatedData);

  //     refetch();
  //   } catch (error) {
  //     console.error(
  //       "An error occurred while updating Bookings data:",
  //       error.message
  //     );
  //   }
  // };

  const handleDataUpdate = async () => {
    updateBookings();
  };

  // const handleDataUpdate = async () => {
  //   const updatedData = data.map((bookings) => {
  //     const sinceDateTime = bookings.data.since.split(" ");
  //     const tillDateTime = bookings.data.till.split(" ");
  //     const sinceDate = sinceDateTime[0];
  //     const tillDate = tillDateTime[0];
  //     const sinceTime = sinceDateTime[1];
  //     const tillTime = tillDateTime[1];

  //     const today = new Date();
  //     const tomorrow = addDays(today, 1);
  //     const updatedSince = `${format(today, "yyyy-MM-dd")} ${sinceTime}`;

  //     // check if 'till' is the next day so it doesn't post time earlier than 'since'
  //     const isNextDay = new Date(tillDate) - new Date(sinceDate) > 0;

  //     let updatedTill;
  //     if (isNextDay) {
  //       updatedTill = `${format(tomorrow, "yyyy-MM-dd")} ${tillTime}`;
  //     } else {
  //       updatedTill = `${format(today, "yyyy-MM-dd")} ${tillTime}`;
  //     }

  //     return {
  //       ...bookings,
  //       data: {
  //         ...bookings.data,
  //         since: updatedSince,
  //         till: updatedTill,
  //       },
  //     };
  //   });
  //   saveUpdatedData(updatedData);
  // };

  // delete a booking
  // const deleteSelectedAuthorisation = () => {
  //   const newData =
  //     deleteAuthorisation &&
  //     data &&
  //     data.filter((obj) => obj.id !== deleteAuthorisation.id);

  //   return newData;
  // };
  // const updatedData = deleteAuthorisation && deleteSelectedAuthorisation();

  useEffect(() => {
    if (!homepage && !isRendered) {
      setIsRendered(true);
    }
  }, [homepage, isRendered, setIsRendered]);

  const loadedData =
    airframeData &&
    airframeData.length > 0 &&
    aircrewData &&
    aircrewData.length > 0;

  const fillColor = "#00E0FF";
  const emptyColor = "rgba(255,255,255,0.2)";
  const emptyColorSecond = "rgba(255,255,255,0.2)";

  // useEffect(() => {
  //   console.log("Database Data: ", data);
  //   // console.log("Delete: ", updatedData);
  // }, [data]);

  // console.log("firing app");

  if (isLoading || !loadedData) {
    return (
      <Center
        zIndex="200"
        flexDirection="column"
        position="fixed"
        w="100%"
        h="100%"
        pt={{
          base: "0px",
          lg: "5px",
        }}
        background={"#0c131f"}
        // backgroundImage={BgBlur}
        // backgroundPosition="center"
        // backgroundSize="cover"
        backgroundImage={
          "radial-gradient(#29364e 0%, #242e46 30%, #0c131f 90%)"
        }
        transition="all ease-in 500ms"
      >
        {!homepage && (
          <Spinner
            mb={{
              base: "40px",
              lg: "0px",
            }}
            size="xl"
            color={fillColor}
            style={{
              borderBottomColor: emptyColorSecond,
              borderLeftColor: emptyColor,
            }}
            emptyColor={emptyColor}
            thickness="3px"
            speed="0.8s"
            boxSize="60px"
          />
        )}
      </Center>
    );
  }

  if (error) {
    return (
      <Center
        flexDirection="column"
        position="fixed"
        w="100%"
        h="100%"
        pb={{
          base: "70px",
          lg: "0px",
        }}
        background="#0c131f"
        backgroundImage="radial-gradient(#29364e 0%, #242e46 30%, #0c131f 90%)"
        // backgroundImage={BgBlur}
        // backgroundPosition="center"
        // backgroundSize="cover"
        transition="all ease-in 500ms"
      >
        <Text>Oops... {error.message}</Text>
      </Center>
    );
  }

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  if (isAuthenticated && loadedData) {
    return (
      <>
        <Auth0UserDemo />
        <Container
          display="flex"
          flexDir="column"
          height="100%"
          width="100%"
          maxWidth="100%"
          margin={0}
          padding={0}
          // overflow="hidden"
        >
          {count < 6 &&
            location &&
            homepage &&
            loading && ( // Loading screen
              <Container
                zIndex="9999"
                position="absolute"
                left="0"
                top="0"
                width="100%"
                height="100%"
                maxWidth="unset"
                padding="unset"
                background="#0c131f"
                backgroundImage="radial-gradient(#29364e 0%, #242e46 30%, #0c131f 90%)"
                // backgroundImage={BgBlur}
                // backgroundPosition="center"
                // backgroundSize="cover"
                transition="all ease-in 500ms"
                opacity={count >= 0 && count < 4 ? 1 : 0}
                visibility={count >= 0 && count < 4 ? "visible" : "hidden"}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <VStack
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    <Flex
                      pb={{
                        base: "50px",
                        lg: "0px",
                      }}
                      maxWidth="300px"
                      opacity={count >= 1 && count < 3 ? 1 : 0}
                      transform={
                        count >= 1
                          ? "translate(0%, 0%) scale(1)"
                          : "translate(0%, 8%) scale(0.85)"
                      }
                      transition="all ease-out 1500ms"
                    >
                      <Logo width="300px" height="auto" />
                    </Flex>
                  </VStack>
                </Flex>
              </Container>
            )}
          <Header
            homepage={homepage}
            showHeaderMenu={showHeaderMenu}
            setShowHeaderMenu={setShowHeaderMenu}
            showHeaderTools={showHeaderTools}
            setShowHeaderTools={setShowHeaderTools}
            handleDataUpdate={handleDataUpdate}
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
            showMobileMenuCrew={showMobileMenuCrew}
            setShowMobileMenuCrew={setShowMobileMenuCrew}
            showMobileMenuFacilitation={showMobileMenuFacilitation}
            setShowMobileMenuFacilitation={setShowMobileMenuFacilitation}
            showMobileMenuSafety={showMobileMenuSafety}
            setShowMobileMenuSafety={setShowMobileMenuSafety}
            handleRefetch={handleRefetch}
          />
          <Flex width="100%" height="100%">
            <Sidebar
              showMobileMenu={showMobileMenu}
              setShowMobileMenu={setShowMobileMenu}
              showMobileMenuCrew={showMobileMenuCrew}
              setShowMobileMenuCrew={setShowMobileMenuCrew}
              showMobileMenuFacilitation={showMobileMenuFacilitation}
              setShowMobileMenuFacilitation={setShowMobileMenuFacilitation}
              showMobileMenuSafety={showMobileMenuSafety}
              setShowMobileMenuSafety={setShowMobileMenuSafety}
              showHeaderMenu={showHeaderMenu}
              setShowHeaderMenu={setShowHeaderMenu}
              showHeaderTools={showHeaderTools}
              setShowHeaderTools={setShowHeaderTools}
              homepage={homepage}
              user={user}
            />
            {!homepage && (taskings || authorisations) && <Menu />}
            {!homepage && (checkIns || departures) && <FacilitationMenu />}
            {!homepage &&
              (aircrew ||
                maintenanceCrew ||
                airMovementsCrew ||
                trainingRecords) && <CrewMenu />}
            {!homepage &&
              (safetyAndQuality || riskMatrix || hazardReporting) && (
                <SafetyMenu />
              )}

            {homepage && (
              <Flex
                margin="0px"
                padding="0px"
                flex={1}
                height="100%"
                minHeight="100%"
                background="#F0F5FA"
                width="100%"
                marginLeft={{ base: "0px", lg: "0px" }}
                paddingTop={{ base: "50px", lg: "50px" }}
              >
                <Dashboard isLoading={isLoading} data={data} />
              </Flex>
            )}
            {!homepage && (
              <Flex
                margin={0}
                padding={0}
                flex={1}
                height="100%"
                minHeight="100%"
                background="#F0F5FA"
                backgroundImage={{
                  base:
                    !departures &&
                    !safetyAndQuality &&
                    !riskMatrix &&
                    !hazardReporting &&
                    "linear-gradient(to bottom, #F0F5FA 30%, #FFFFFF 30%)",
                  smd: "#F0F5FA",
                }}
                width="100%"
                marginLeft={{ base: "0px", lg: "304px" }}
                paddingTop={{ base: "50px", lg: "50px" }}
                flexDirection="column"
                overflowY="auto"
              >
                <Routes>
                  <Route path="/aircrew" element={<Aircrew />} />
                  <Route
                    path="/taskings"
                    element={
                      <Airframe
                        data={data}
                        zoomLevel={zoomLevel}
                        setZoomLevel={setZoomLevel}
                      />
                    }
                  />
                  <Route path="/area" element={<Area />} />
                  <Route
                    path="/authorisations"
                    element={<Authorisations data={data} />}
                  />
                  <Route
                    path="/check-ins"
                    element={<CheckInsLink data={data} type={"check-ins"} />}
                  />
                  <Route
                    path="/departures"
                    element={<DeparturesLink data={data} type={"departures"} />}
                  />
                  <Route
                    path="/aircrew-management"
                    element={<CrewManagement data={data} type={"aircrew"} />}
                  />
                  <Route
                    path="/maintenance-crew-management"
                    element={
                      <CrewManagement data={data} type={"maintenance"} />
                    }
                  />
                  <Route
                    path="/air-movements-crew-management"
                    element={
                      <CrewManagement data={data} type={"air-movements"} />
                    }
                  />
                  <Route
                    path="/training-records"
                    element={<CrewManagement type={"training-records"} />}
                  />
                  <Route
                    path="/safety-and-quality-assurance"
                    element={<SafetyAndQualityLink type={"safety"} />}
                  />
                  <Route
                    path="/risk-matrix"
                    element={<RiskMatrixLink type={"risk-matrix"} />}
                  />
                  <Route
                    path="/hazard-reporting"
                    element={<HazardReporting type={"hazard-reporting"} />}
                  />
                </Routes>
              </Flex>
            )}
          </Flex>
        </Container>
        <Modals />
      </>
    );
  }
}
