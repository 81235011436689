import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useDisclosure,
  Text,
  LightMode,
  ModalCloseButton,
  Container,
  Image,
  RadioGroup,
  Radio,
  Stack,
  Textarea,
  HStack,
  Input,
  useNumberInput,
} from "@chakra-ui/react";
import Pilot from "../Assets/avatar-pilot.png";
import { Select } from "chakra-react-select";
import Flatpickr from "react-flatpickr";
import { formatToTimeZone } from "date-fns-timezone";
import { differenceInDays, format, addDays, subDays, parseISO } from "date-fns";
import {
  personnelModalState,
  aircrewDataState,
  editAircrewState,
} from "../globalState";

export default function PersonnelModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPersonnelModal, setShowPersonnelModal] =
    useRecoilState(personnelModalState);
  const setAircrewData = useSetRecoilState(aircrewDataState);
  const [editAircrewData, setEditAircrewData] =
    useRecoilState(editAircrewState);
  // const setTab = useSetRecoilState(authorisationTabState);
  const timeZone = "Pacific/Auckland";
  const [availability, setAvailability] = useState(false);
  const [availabilityDate, setAvailabilityDate] = useState(false);
  const [unavailableFromDate, setUnavailableFromDate] = useState(null);
  const [unavailableToDate, setUnavailableToDate] = useState(null);

  const [currency, setCurrency] = useState(null);
  const [proficiency, setProficiency] = useState(null);
  const [fatigue, setFatigue] = useState(null);
  const [concern, setConcern] = useState(null);
  const [concerndate, setConcerndate] = useState(null);

  const today = new Date();

  // function countDays(date) {
  //   const today = new Date();
  //   return date ? differenceInDays(new Date(date), today) : null;
  // }
  function countDays(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return date ? differenceInDays(new Date(date), today) : null;
  }

  // Exemptions number input
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: editAircrewData?.exemptions || 0,
      min: 0,
      max: 10,
      precision: 0,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const currencyOptions = [
    {
      value: "current",
      label: "Current",
      activeBgColor: "#00fb00",
      activeBorderColor: "#00e900",
      hoverBgColor: "#00e900",
    },
    {
      value: "expiring",
      label: "Expiring",
      activeBgColor: "#ffdb00",
      activeBorderColor: "#ffd100",
      hoverBgColor: "#ffd100",
    },
    {
      value: "expired",
      label: "Expired",
      activeBgColor: "#ff6a00",
      activeBorderColor: "#fa4c00",
      hoverBgColor: "#fa4c00",
    },
  ];

  const proficiencyOptions = [
    {
      value: "good",
      label: "Good",
      activeBgColor: "#00fb00",
      activeBorderColor: "#00e900",
      hoverBgColor: "#00e900",
    },
    {
      value: "average",
      label: "Average",
      activeBgColor: "#ffdb00",
      activeBorderColor: "#ffd100",
      hoverBgColor: "#ffd100",
    },
    {
      value: "poor",
      label: "Poor",
      activeBgColor: "#ff6a00",
      activeBorderColor: "#fa4c00",
      hoverBgColor: "#fa4c00",
    },
  ];

  const fatigueOptions = [
    {
      value: "OK",
      label: "OK",
      activeBgColor: "#00fb00",
      activeBorderColor: "#00e900",
      hoverBgColor: "#00e900",
    },
    {
      value: "fatigued",
      label: "Fatigued",
      activeBgColor: "#ff6a00",
      activeBorderColor: "#fa4c00",
      hoverBgColor: "#fa4c00",
    },
  ];

  const availabilityOptions = [
    {
      value: true,
      label: "Available",
      activeBgColor: "#00fb00",
      activeBorderColor: "#00e900",
      hoverBgColor: "#00e900",
    },
    {
      value: false,
      label: "Unavailable",
      activeBgColor: "#ff6a00",
      activeBorderColor: "#fa4c00",
      hoverBgColor: "#fa4c00",
    },
  ];

  useEffect(() => {
    if (unavailableFromDate && unavailableToDate) {
      const fromDate =
        parseISO(unavailableFromDate) < today ? "unavailble" : "available";
      const toDate =
        parseISO(unavailableToDate) > today ? "unavailble" : "available";
      const isUnavailable =
        fromDate === "unavailble" && toDate === "unavailble";
      setAvailabilityDate(isUnavailable);

      const available = availabilityOptions.find(
        (available) => available.value
      );
      const unavailable = availabilityOptions.find(
        (available) => !available.value
      );
      if (!isUnavailable) {
        setAvailability(available);
      }
      if (isUnavailable) {
        setAvailability(unavailable);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unavailableFromDate, unavailableToDate, setAvailabilityDate]);

  useEffect(() => {
    const selectedAvailabilityOption = availabilityDate
      ? availabilityOptions.find(
          (available) => available.value !== editAircrewData.available
        )
      : availabilityOptions.find(
          (available) => available.value === editAircrewData.available
        );
    setAvailability(selectedAvailabilityOption);

    // Unavailable From Date
    if (
      editAircrewData &&
      editAircrewData.unavailablefromdate !== undefined &&
      editAircrewData.unavailablefromdate !== null
    ) {
      const updateDate = format(
        editAircrewData.unavailablefromdate === 0
          ? today
          : editAircrewData.unavailablefromdate > 0
          ? addDays(today, editAircrewData.unavailablefromdate)
          : subDays(today, Math.abs(editAircrewData.unavailablefromdate)),
        "yyyy-MM-dd 00:00"
      );
      setUnavailableFromDate(updateDate);
    }

    // Unavailable To Date
    if (
      editAircrewData &&
      editAircrewData.unavailabletodate !== undefined &&
      editAircrewData.unavailabletodate !== null
    ) {
      const updateDate = format(
        editAircrewData.unavailabletodate === 0
          ? today
          : editAircrewData.unavailabletodate > 0
          ? addDays(today, editAircrewData.unavailabletodate)
          : subDays(today, Math.abs(editAircrewData.unavailabletodate)),
        "yyyy-MM-dd 00:00"
      );
      setUnavailableToDate(updateDate);
    }

    if (editAircrewData?.currency) {
      const selectedCurrencyOption = currencyOptions.find(
        (currency) => currency.value === editAircrewData.currency
      );
      setCurrency(selectedCurrencyOption.value);
    }
    if (editAircrewData?.proficiency) {
      const selectedProficiencyOption = proficiencyOptions.find(
        (proficiency) => proficiency.value === editAircrewData.proficiency
      );
      setProficiency(selectedProficiencyOption.value);
    }
    if (editAircrewData?.fatigue) {
      const selectedFatigueOption = fatigueOptions.find(
        (fatigue) => fatigue.value === editAircrewData.fatigue
      );
      setFatigue(selectedFatigueOption.value);
    }

    // Concens / Comments
    if (editAircrewData?.concern) {
      setConcern(editAircrewData.concern);
    }
    if (
      editAircrewData &&
      editAircrewData.concerndate !== undefined &&
      editAircrewData.concerndate !== null
    ) {
      const updateDate = format(
        editAircrewData.concerndate === 0
          ? today
          : editAircrewData.concerndate > 0
          ? addDays(today, editAircrewData.concerndate)
          : subDays(today, Math.abs(editAircrewData.concerndate)),
        "yyyy-MM-dd 00:00"
      );
      setConcerndate(updateDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAircrewData, showPersonnelModal]);

  useEffect(() => {
    showPersonnelModal ? onOpen() : onClose();
  }, [showPersonnelModal, onOpen, onClose]);

  function onCloseHandler() {
    setShowPersonnelModal(false);
    setEditAircrewData(null);
    setAvailability(false);
    setUnavailableFromDate(null);
    setUnavailableToDate(null);
    setCurrency(null);
    setProficiency(null);
    setFatigue(null);
    setConcern(null);
    setConcerndate(null);
    onClose();
  }

  const memberIdToUpdate = editAircrewData?.id;
  const availabilityValue = availability?.value;
  const newExemptions = Number(input.value) !== editAircrewData?.exemptions;

  const handleUpdate = () => {
    setAircrewData((prevAircrewData) =>
      prevAircrewData.map((member, i) =>
        member.id === memberIdToUpdate
          ? {
              ...member,
              available: availabilityValue,
              unavailablefromdate: countDays(unavailableFromDate),
              unavailabletodate: countDays(unavailableToDate),
              currency: currency,
              fatigue: fatigue,
              proficiency: proficiency,
              exemptions: newExemptions
                ? Number(input.value)
                : editAircrewData?.exemptions,
              concern: concern,
              concerndate: countDays(concerndate),

              // add 'Unavailable' booking to Flight Authorisations for aircrew member
              booking:
                unavailableFromDate && unavailableToDate
                  ? {
                      id: 200 + i,
                      pilot: memberIdToUpdate,
                      squadron:
                        editAircrewData?.squadron === 14
                          ? "14 SQN/CFS"
                          : "40 SQN/CFS",
                      since: unavailableFromDate,
                      till: unavailableToDate,
                      title: "Temporarily Unavailable",
                      status: "tempUnavailable",
                    }
                  : null,
            }
          : member
      )
    );
    setAircrewData((updatedAircrewData) => {
      // save updated aircrewData to local storage for persistant record
      localStorage.setItem("aircrewData", JSON.stringify(updatedAircrewData));
      return updatedAircrewData;
    });
  };

  const SelectButtons = ({ value, options, onChange }) => {
    return (
      <RadioGroup value={value} onChange={onChange} width="100%">
        <Stack direction="row" height="32px" spacing="0px">
          {options.map((option, index) => (
            <Flex
              key={index}
              className="radio-button"
              marginInlineStart="0rem !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              color={value === option.value ? "white" : "light.100"}
              border="1px solid"
              borderRight={index === options.length - 1 ? "1px solid" : "none"}
              borderTopLeftRadius={index === 0 ? "12px" : "none"}
              borderBottomLeftRadius={index === 0 ? "12px" : "none"}
              borderTopRightRadius={
                index === options.length - 1 ? "12px" : "none"
              }
              borderBottomRightRadius={
                index === options.length - 1 ? "12px" : "none"
              }
              borderColor={
                value === option.value ? option.activeBorderColor : "#ced4da"
              }
              bg={value === option.value ? option.activeBgColor : "gray.100"}
              w="100%"
              _hover={{
                background:
                  value === option.value ? option.hoverBgColor : "gray.200",
              }}
            >
              <Radio
                hidden
                display="flex"
                justifyContent="center"
                alignItems="center"
                as="button"
                height="32px"
                width="100%"
                m="0"
                style={{
                  marginInlineStart: "0 !important",
                }}
                value={option.value}
              >
                <Text
                  fontSize={{
                    base: "0.75rem",
                    lg: "0.85rem",
                  }}
                  fontWeight="500"
                  margin="0"
                  marginLeft="-0.5rem"
                  marginTop={{ base: "-1px", lg: "0px" }}
                >
                  {option.label}
                </Text>
              </Radio>
            </Flex>
          ))}
        </Stack>
      </RadioGroup>
    );
  };

  const selectStyle = {
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      paddingTop: "6px",
      paddingBottom: "6px",
      background: data.header
        ? "white !important"
        : isFocused
        ? "#e7edf3"
        : isSelected
        ? "#e7edf3"
        : null,
      pointerEvents: data.header ? "none" : "auto",
      cursor: data.header ? "default" : "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
      borderColor: "#e2e8f0",
      minHeight: "32px",
      width: "100%",
      minWidth: { base: "145px", lg: "160px" },
      background: "#ffffff",
      textTransform: "uppercase",
      // flexDirection: "row-reverse",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: "4px",
      paddingBottom: "4px",
      _hover: {
        background: "#e7edf3",
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: data.value ? "#00fb00" : "#ff0000",
      fontSize: { base: "0.8rem", lg: "0.9rem" },
      textTransform: "uppercase",
      fontWeight: "600",
      letterSpacing: "0.5px",
      textAlign: "right",
      _hover: {
        background: "#f5f7fa",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100",
      marginTop: "4px",
      boxShadow: "0px 10px 40px -15px rgba(10, 20, 80, 0.4)",
      borderRadius: "10px",
      textTransform: "uppercase",
    }),
  };

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  // console.log("showPersonnelModal", showPersonnelModal);
  // console.log("editAircrewData", editAircrewData);
  // console.log("currency", currency);
  // console.log("availability", availability);

  return (
    <>
      <LightMode>
        <Modal
          variant="primary"
          size="4xl"
          transition="all ease 300ms"
          onClose={onCloseHandler}
          isOpen={isOpen}
          closeOnOverlayClick={true}
          isCentered
          trapFocus={false}
          blockScrollOnMount={true}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              zIndex="1"
              textAlign="center"
              backgroundImage="linear-gradient(345deg, #235f88 10%, #0d3856 90%)"
            >
              <Flex
                gap="5px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex
                  gap="5px"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="white">Edit Aircrew Details</Text>
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody
              style={{ padding: 0 }}
              fontSize="0.8rem"
              fontFamily="'Open Sans',sans-serif"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                minHeight="60px"
                fontSize="1.1rem"
                fontWeight="700"
                bg="white"
                borderBottom="1px solid"
                borderColor="gray.100"
                py={{ base: "20px", md: "15px" }}
                px={{ base: "20px", md: "30px" }}
                gap={{ base: "0px", md: "15px" }}
                flexDirection={{ base: "column", md: "row" }}
                boxShadow="0px 0px 15px -1px rgba(0,0,0,0.1)"
                zIndex="2"
                position="relative"
              >
                <Flex
                  gap={{ base: "20px", md: "25px" }}
                  width={{
                    base: "100%",
                    md: "auto",
                  }}
                  minWidth={{
                    base: "auto",
                    md: "auto",
                  }}
                  justifyContent={{
                    base: "flex-end",
                    md: "flex-start",
                  }}
                  alignItems={{
                    base: "flex-start",
                    md: "center",
                  }}
                  flexDirection={{
                    base: "row",
                    md: "row",
                  }}
                >
                  <Image
                    src={Pilot}
                    height={{ base: "50px", sm: "70px" }}
                    width={{ base: "50px", sm: "70px" }}
                    boxShadow="0px 5px 15px -5px rgba(0,0,0,0.2)"
                    border="5px solid #ffffff"
                    borderRadius="100%"
                  />
                  <Flex
                    width="100%"
                    justifyContent={"flex-start"}
                    alignItems="baseline"
                    flexDirection="column"
                  >
                    <Flex
                      justifyContent={"flex-start"}
                      alignItems="center"
                      gap="15px"
                    >
                      <Text
                        width="100%"
                        fontSize={{ base: "1rem", lg: "1.1rem", xl: "1.2rem" }}
                        fontWeight="700"
                        textAlign="left"
                      >
                        {editAircrewData?.name}
                      </Text>
                      <Text
                        bg={
                          editAircrewData?.role === "FLTLT"
                            ? "cyan.100"
                            : editAircrewData?.role === "FLTOFF"
                            ? "green.100"
                            : "purple.100"
                        }
                        borderRadius="6px"
                        px="7px"
                        mb="1px"
                        fontWeight="600"
                        fontSize="0.75rem"
                        minWidth={{ base: "60px", xl: "60px" }}
                        textAlign="center"
                        color={
                          editAircrewData?.role === "FLTLT"
                            ? "cyan.700"
                            : editAircrewData?.role === "FLTOFF"
                            ? "green.700"
                            : "purple.700"
                        }
                      >
                        {editAircrewData?.role}
                      </Text>
                    </Flex>
                    <Text
                      width="100%"
                      fontSize={{
                        base: "0.85rem",
                        lg: "0.9rem",
                        xl: "0.9rem",
                      }}
                      fontWeight="600"
                      color="gray.400"
                      textAlign="left"
                    >
                      {editAircrewData?.callsign}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  pb={{ base: "0px", md: "2px" }}
                  width={{ base: "100%", md: "auto" }}
                  justifyContent={{ base: "space-between", md: "flex-end" }}
                  alignItems={{ base: "center", md: "flex-end" }}
                  flexDirection={{ base: "row", md: "column" }}
                  pl={{ base: "70px", sm: "90px", md: "0px" }}
                  gap="5px"
                >
                  {editAircrewData?.squadron && (
                    <Flex
                      gap="5px"
                      justifyContent={{ base: "flex-start", md: "flex-end" }}
                      alignItems="baseline"
                    >
                      <Text
                        minWidth="max-content"
                        whiteSpace="pre"
                        color="gray.600"
                        fontWeight="600"
                        fontSize="0.9rem"
                        width="max-content"
                        textAlign={{ base: "left", md: "right" }}
                      >
                        {editAircrewData?.squadron}
                      </Text>
                      <Text
                        color="gray.400"
                        fontWeight="400"
                        fontSize="0.8rem"
                        width="max-content"
                        textAlign={{ base: "left", md: "right" }}
                      >
                        SQUADRON
                      </Text>
                    </Flex>
                  )}
                  {availabilityDate ? (
                    <Flex
                      height="32px"
                      alignItems="center"
                      whiteSpace="nowrap"
                      color="#ff0000"
                      fontSize="0.9rem"
                      textTransform="uppercase"
                      fontWeight="600"
                      letterSpacing="0.5px"
                      textAlign="right"
                    >
                      UNAVAILABLE
                    </Flex>
                  ) : (
                    <Select // AVAILABILITY
                      {...noFocus}
                      chakraStyles={selectStyle}
                      colorScheme="cyan"
                      variant="outline"
                      background="white"
                      size="xs"
                      cursor="pointer"
                      value={availability}
                      options={availabilityOptions}
                      onChange={(v) => {
                        setAvailability(v);
                      }}
                    />
                  )}
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                pt="20px"
                pb="45px"
                width="100%"
                justifyContent="flex-start"
                alignItems="center"
                background="#FBFDFF"
                px={{ base: "20px", lg: "30px" }}
                zIndex="1"
                position="relative"
              >
                <Container
                  maxW="3xl"
                  px="0px"
                  pt={{ base: "5px", lg: "10px" }}
                  spacing="30px"
                >
                  <Flex
                    gap="30px"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Flex
                      alignItems={{ base: "flex-end", smd: "flex-start" }}
                      justifyContent="stretch"
                      gap={{ base: "30px", smd: "30px" }}
                      width="100%"
                      flexDirection={{ base: "column", smd: "row" }}
                    >
                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        width="100%"
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        >
                          Date Unavailable From:
                        </Text>

                        <Flatpickr
                          placeholder="Select date..."
                          style={{
                            width: "100%",
                            fontFamily: "'Open Sans', sans-serif",
                            height: "32px",
                            borderRadius: "7px",
                            fontSize: "0.875rem",
                            paddingLeft: "0.75rem",
                            paddingRight: "0.75rem",
                            border: "1px solid",
                            borderColor: "#ced4da",
                          }}
                          className="flatpickr form-control form-control-sm"
                          data-enable-time
                          value={
                            unavailableFromDate &&
                            formatToTimeZone(
                              unavailableFromDate,
                              "D MMM YY HH:mm",
                              {
                                timeZone: timeZone,
                              }
                            )
                          }
                          onChange={(value) => {
                            setUnavailableFromDate(
                              value.length
                                ? format(value[0], "yyyy-MM-dd HH:mm")
                                : null
                            );
                          }}
                          options={{
                            enableTime: false,
                            dateFormat: "j M y",
                            time_24hr: true,
                            minuteIncrement: 30,
                            defaultDate: formatToTimeZone(
                              today,
                              "D MMM YY HH:mm",
                              { timeZone: timeZone }
                            ),
                          }}
                        />
                      </Flex>

                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        width="100%"
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        >
                          Date Unavailable To:
                        </Text>

                        <Flatpickr
                          placeholder="Select date..."
                          style={{
                            width: "100%",
                            fontFamily: "'Open Sans', sans-serif",
                            height: "32px",
                            borderRadius: "7px",
                            fontSize: "0.875rem",
                            paddingLeft: "0.75rem",
                            paddingRight: "0.75rem",
                            border: "1px solid",
                            borderColor: "#ced4da",
                          }}
                          className="flatpickr form-control form-control-sm"
                          data-enable-time
                          value={
                            unavailableToDate &&
                            formatToTimeZone(
                              unavailableToDate,
                              "D MMM YY HH:mm",
                              {
                                timeZone: timeZone,
                              }
                            )
                          }
                          onChange={(value) => {
                            setUnavailableToDate(
                              value.length
                                ? format(value[0], "yyyy-MM-dd HH:mm")
                                : null
                            );
                          }}
                          options={{
                            enableTime: false,
                            dateFormat: "j M y",
                            time_24hr: true,
                            minuteIncrement: 30,
                            defaultDate: formatToTimeZone(
                              today,
                              "D MMM YY HH:mm",
                              { timeZone: timeZone }
                            ),
                          }}
                        />
                      </Flex>

                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        mt={{ base: "-30px", smd: "0px" }}
                        width="auto"
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        ></Text>
                        <Button
                          {...noFocus}
                          w="100%"
                          size="sm"
                          fontWeight="400"
                          fontSize="0.8rem"
                          minWidth="80px"
                          variant="outline"
                          onClick={() => {
                            setUnavailableFromDate(null);
                            setUnavailableToDate(null);
                            setAvailabilityDate(false);
                            setAvailability(
                              availabilityOptions.find(
                                (available) => available.value
                              )
                            );
                          }}
                          borderColor="gray.300"
                          bg="gray.50"
                          color="gray.600"
                          _hover={{ background: "gray.100" }}
                          _focus={{ background: "gray.100" }}
                          _active={{ background: "gray.100" }}
                          borderRadius="12px"
                        >
                          Clear
                        </Button>
                      </Flex>
                    </Flex>

                    <Flex
                      alignItems="flex-start"
                      justifyContent="stretch"
                      gap={{ base: "30px", md: "30px" }}
                      width="100%"
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        width={{ base: "100%", lg: "100%" }}
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        >
                          Currency:
                        </Text>
                        <SelectButtons
                          value={currency}
                          options={currencyOptions}
                          onChange={(value) => {
                            setCurrency(value);
                          }}
                        />
                      </Flex>

                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        width={{ base: "100%", lg: "100%" }}
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        >
                          Fatigue:
                        </Text>
                        <SelectButtons
                          value={fatigue}
                          options={fatigueOptions}
                          onChange={(value) => {
                            setFatigue(value);
                          }}
                        />
                      </Flex>

                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        width={{ base: "100%", lg: "100%" }}
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        >
                          Proficiency:
                        </Text>
                        <SelectButtons
                          value={proficiency}
                          options={proficiencyOptions}
                          onChange={(value) => {
                            setProficiency(value);
                          }}
                        />
                      </Flex>
                    </Flex>

                    <Flex
                      alignItems="flex-start"
                      gap="30px"
                      width={{ base: "100%", lg: "100%" }}
                      flexDirection={{ base: "column", lg: "row" }}
                    >
                      <Flex
                        alignItems="flex-start"
                        gap="7px"
                        width={{ base: "100%", lg: "68%" }}
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="500"
                          color="gray.500"
                          fontSize="0.8rem"
                          fontFamily="'Poppins'"
                          height="20px"
                          maxHeight="20px"
                        >
                          Comments / Issues:
                        </Text>
                        <Textarea
                          variant="outline"
                          background="white"
                          size="sm"
                          minHeight={{ base: "140px", lg: "122px" }}
                          borderRadius="10px"
                          cursor="pointer"
                          value={concern ? concern : ""}
                          onChange={(v) => {
                            setConcern(v.target.value);
                          }}
                        />
                      </Flex>

                      <Flex
                        alignItems="flex-start"
                        gap="30px"
                        width={{ base: "100%", lg: "32%" }}
                        flexDirection={{
                          base: "column",
                          xs: "column",
                          lg: "column",
                        }}
                      >
                        <Flex
                          alignItems="flex-start"
                          gap="7px"
                          width="100%"
                          flexDirection="column"
                        >
                          <Text
                            fontWeight="500"
                            color="gray.500"
                            fontSize="0.8rem"
                            fontFamily="'Poppins'"
                            height="20px"
                            maxHeight="20px"
                          >
                            Comments Date:
                          </Text>

                          <Flatpickr
                            // id="till"
                            // ref={fpTill}
                            placeholder="Select date..."
                            style={{
                              width: "100%",
                              fontFamily: "'Open Sans', sans-serif",
                              height: "32px",
                              borderRadius: "7px",
                              fontSize: "0.875rem",
                              paddingLeft: "0.75rem",
                              paddingRight: "0.75rem",
                              border: "1px solid",
                              borderColor: "#ced4da",
                            }}
                            className="flatpickr form-control form-control-sm"
                            data-enable-time
                            value={
                              concerndate &&
                              formatToTimeZone(concerndate, "D MMM YY HH:mm", {
                                timeZone: timeZone,
                              })
                            }
                            onChange={(value) => {
                              setConcerndate(
                                value.length
                                  ? format(value[0], "yyyy-MM-dd HH:mm")
                                  : null
                              );
                            }}
                            options={{
                              enableTime: false,
                              dateFormat: "j M y",
                              time_24hr: true,
                              minuteIncrement: 30,
                              defaultDate: formatToTimeZone(
                                today,
                                "D MMM YY HH:mm",
                                { timeZone: timeZone }
                              ),
                            }}
                          />
                        </Flex>

                        <Flex
                          alignItems="flex-start"
                          gap="7px"
                          width="100%"
                          flexDirection="column"
                        >
                          <Text
                            fontWeight="500"
                            color="gray.500"
                            fontSize="0.8rem"
                            fontFamily="'Poppins'"
                            height="20px"
                            maxHeight="20px"
                          >
                            Exemptions:
                          </Text>

                          <HStack w="100%">
                            <Button
                              {...dec}
                              {...noFocus}
                              w="36px"
                              size="sm"
                              variant="outline"
                              bg="gray.100"
                              color="gray.700"
                              fontSize="1.2rem"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              pb="3px"
                              _hover={{ background: "gray.200" }}
                              _focus={{ background: "gray.200" }}
                              _active={{ background: "gray.200" }}
                              borderColor="#ced4da"
                            >
                              -
                            </Button>
                            <Input
                              {...input}
                              {...noFocus}
                              w="100%"
                              size="sm"
                              bg="white"
                              variant="outline"
                              borderColor="#ced4da"
                              textAlign="right"
                            />
                            <Button
                              {...inc}
                              {...noFocus}
                              w="36px"
                              size="sm"
                              variant="outline"
                              bg="gray.100"
                              color="gray.700"
                              fontSize="1.2rem"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              _hover={{ background: "gray.200" }}
                              _focus={{ background: "gray.200" }}
                              _active={{ background: "gray.200" }}
                              borderColor="#ced4da"
                            >
                              +
                            </Button>
                          </HStack>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Container>
              </Flex>
            </ModalBody>
            <ModalFooter borderTop="1px solid #e5e7e9" pt={4} pb={4}>
              <Flex
                flexDirection="row"
                gap="10px"
                width="100%"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="outline"
                  onClick={() => {
                    onCloseHandler();
                  }}
                  bg="gray.50"
                  color="gray.700"
                  _hover={{ background: "gray.100" }}
                  _focus={{ background: "gray.100" }}
                  _active={{ background: "gray.100" }}
                  borderRadius="25px"
                >
                  Close
                </Button>

                <Button
                  {...noFocus}
                  // isLoading={isSubmitting}
                  borderRadius="25px"
                  variant="outline"
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  bg="gray.500"
                  color="white"
                  borderColor="gray.600"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  _hover={{
                    background: "gray.600",
                  }}
                  _focus={{ background: "gray.600" }}
                  _active={{ background: "gray.600" }}
                  onClick={() => {
                    handleUpdate();
                    onCloseHandler();
                  }}
                >
                  Save
                </Button>

                {/* {
                  // isAdmin &&
                  authorisationData?.status !== "draft" &&
                    authorisationData?.status !== "approved" &&
                    authorisationData?.status !== "completed" && (
                      <Button
                        {...noFocus}
                        w="100%"
                        size="sm"
                        maxWidth="180px"
                        variant="primary"
                        bg="brand.100"
                        _hover={{ background: "brand.200" }}
                        _focus={{ background: "brand.200" }}
                        _active={{ background: "brand.200" }}
                        borderRadius="25px"
                        onClick={() => {
                          if (!authorisations) {
                            navigate("/authorisations");
                          }
                          setTab("approval");
                          setEditAuthorisation(true);
                          setShowPersonnelModal(false);
                          onClose();
                        }}
                      >
                        Approve
                      </Button>
                    )
                } */}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  );
}
