export const theme = {
  primary: {
    600: "#1a202c",
    900: "#ffffff",
  },
  grey: { 300: "#d1d1d1" },
  white: "#fff",
  green: {
    300: "#00acc4ad",
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#ffffffdb",
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e2e4e7",
    },
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937",
    },
  },
  text: {
    grey: {
      300: "#a0aec0",
      500: "#718096",
    },
  },
  timeline: {
    // fontSize: "8px",
    divider: {
      bg: "#718096",
    },
  },
};

export const globalStyles = `
    @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap");
  
    /* Available in sponsors plan */
    .planby {
      font-family: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    
      /* Add Poppins as a fallback font */
      font-family: "Open-Sans", "Inter", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    
      /* Layout */
      .planby-layout {}
    
      /* Line */
      .planby-line {}
    
      /* Current time */
      .planby-current-time {}
      .planby-current-content {}
    
      /* Channels */
      .planby-channels {}
    
      /* Channel */
      .planby-channel {}
    
      /* Program */
      .planby-program {}
      .planby-program-content {}
      .planby-program-flex {}
      .planby-program-stack {}
      .planby-program-title {}
      .planby-program-text {}
    
      /* Timeline */
      .planby-timeline-wrapper {}
      .planby-timeline-box {}
      .planby-timeline-time {}
      .planby-timeline-dividers {}
      .planby-timeline-wrapper {}
    }
  `;
