import React, { useState, useRef, useMemo } from "react";
import { useRecoilState } from "recoil";

import {
  ButtonGroup,
  Button,
  Text,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Tooltip,
  Icon,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Tabs from "../Menu/Tabs";
import { Select } from "chakra-react-select";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";

import { FiEdit } from "react-icons/fi";
import { MdDeleteForever, MdOutlineAdd } from "react-icons/md";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import {
  BsSearch,
  BsFiletypePdf,
  BsEnvelopeAt,
  BsPrinter,
  BsFolderPlus,
} from "react-icons/bs";
import { squadronSelectionState } from "../globalState";
import airMovementsCrewData from "../Data/air-movements-crew.json";

export default function AirMovementsCrewManagementTable() {
  const location = useLocation();
  const [squadronSelection, setSquadronSelection] = useRecoilState(
    squadronSelectionState
  );
  const [tab, setTab] = useState("all");
  const crewManagement = location.pathname === "/air-movements-crew-management";
  const columnHelper = createColumnHelper();
  const [sorting, setSorting] = useState([]);

  const selectStyle = {
    container: (styles) => ({
      ...styles,
      fontWeight: "500",
    }),
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
      paddingTop: "6px",
      paddingBottom: "6px",
      background: "#ffffff",
      color: "light.100",
      fontWeight: "500",
      _hover: {
        background: "cyan.400",
        color: "white",
        fontWeight: "500",
      },
      transition: "all ease 100ms",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
      borderColor: "#e2e8f0",
      minHeight: "30px",
      height: "30px",
      background: "#ffffff",
      minWidth: { base: "120px", md: "120px", lg: "158px" },
      _focusVisible: { boxShadow: "none !important", borderColor: "cyan.500" },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: "4px",
      paddingBottom: "4px",
      background: "gray.50",
      _hover: {
        background: "#e7edf3",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100",
      marginTop: "4px",
    }),
  };

  const unitOptions = [
    { value: "14 SQN/CFS", label: "14 Squadron" },
    { value: "40 SQN/CFS", label: "40 Squadron" },
  ];

  const squadron =
    squadronSelection === "40 SQN/CFS" ? unitOptions[1] : unitOptions[0];
  const [selectedOptions, setSelectedOptions] = useState(squadron);

  const noFocus = {
    _focus: { boxShadow: "none !important", borderColor: "initial" },
  };

  const airMovementsCrew = useMemo(
    () =>
      airMovementsCrewData &&
      airMovementsCrewData
        .filter((crew) => crew?.id)
        .filter((crew) =>
          tab === "currencies" ? crew?.currency !== "current" : true
        )
        .filter((crew) => (tab === "fatigue" ? crew?.fatigue !== "OK" : true))
        .filter((crew) =>
          tab === "proficiency" ? crew?.proficiency !== "good" : true
        )
        .filter((crew) => (tab === "exemptions" ? crew?.exemptions > 0 : true))
        .map((crew) => ({
          ...crew,
        })),
    [tab]
  );

  const columns = [
    columnHelper.accessor("squadron", {
      header: "SQDN",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("currency", {
      header: "Currency",
      cell: ({ row }) => (
        <Flex
          fontSize="0.7rem"
          minWidth="10px"
          textTransform="uppercase"
          fontWeight="600"
          justifyContent="center"
          alignItems="center"
          color={
            row.original.currency === "expired"
              ? "#fa4c00"
              : row.original.currency === "expiring"
              ? "#ffd100"
              : row.original?.currency === "current"
              ? "#00fb00"
              : "white"
          }
        >
          {row.original.currency}
        </Flex>
      ),
    }),
    columnHelper.accessor("fatigue", {
      header: "Fatigue",
      cell: ({ row }) => (
        <Flex
          fontSize="0.7rem"
          minWidth="10px"
          textTransform="uppercase"
          fontWeight="600"
          justifyContent="center"
          alignItems="center"
          color={
            row.original.fatigue === "fatigued"
              ? "#fa4c00"
              : row.original.fatigue === "ok"
              ? "#00fb00"
              : "#00fb00"
          }
        >
          {row.original.fatigue}
        </Flex>
      ),
    }),
    columnHelper.accessor("proficiency", {
      header: "Proficiency",
      cell: ({ row }) => (
        <Flex
          fontSize="0.7rem"
          minWidth="10px"
          textTransform="uppercase"
          fontWeight="600"
          justifyContent="center"
          alignItems="center"
          color={
            row.original.proficiency === "poor"
              ? "#fa4c00"
              : row.original.proficiency === "average"
              ? "#ffd100"
              : row.original?.proficiency === "good"
              ? "#00fb00"
              : "white"
          }
        >
          {row.original.proficiency}
        </Flex>
      ),
    }),
    columnHelper.accessor("exemptions", {
      header: "Exemptions",
      cell: ({ row }) => (
        <Flex
          fontSize="0.7rem"
          minWidth="10px"
          textTransform="uppercase"
          fontWeight="600"
          justifyContent="center"
          alignItems="center"
          color={row.original.exemptions ? "#fa4c00" : "gray.300"}
        >
          {row.original.exemptions}
        </Flex>
      ),
    }),
    columnHelper.accessor("available", {
      header: "Available",
      cell: ({ row }) => (
        <Flex
          fontSize="0.7rem"
          minWidth="10px"
          textTransform="uppercase"
          fontWeight="600"
          justifyContent="center"
          alignItems="center"
          color={row.original?.available ? "#00fb00" : "#fa4c00"}
        >
          {row.original.available ? "YES" : "NO"}
        </Flex>
      ),
    }),
    columnHelper.accessor(() => {}, {
      id: "actions",
      cell: ({ row }) => (
        <ButtonGroup
          size="sm"
          borderRadius="10px"
          isAttached
          variant="outline"
          px="5px"
        >
          <Tooltip
            hasArrow
            placement="top"
            label="Edit Air Movements Crew Details"
            bg="gray.600"
            color="white"
            borderRadius="5px"
            fontSize="0.7rem"
          >
            <Button
              fontSize="1.1rem"
              minWidth="50px"
              borderRadius="10px"
              background={"gray.50"}
              color={"#00c4ce"}
              borderColor={"#D5DEE7"}
              _hover={{
                background: "#00c4ce",
                borderColor: "#00c4ce",
                color: "white",
              }}
              transition="all ease 200ms"
              // onClick={() => {
              //   // setEditAirMovementsCrewData(row.original);
              //   setShowPersonnelModal(true);
              // }}
            >
              <FiEdit />
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            placement="top"
            label="Delete Air Movements Crew Member"
            bg="gray.600"
            color="white"
            borderRadius="5px"
            fontSize="0.7rem"
          >
            <Button
              fontSize="1.3rem"
              minWidth="50px"
              borderRadius="10px"
              background={"gray.50"}
              color={"#DB2828"}
              borderColor={"#D5DEE7"}
              _hover={{
                background: "#DB2828",
                borderColor: "#DB2828",
                color: "white",
              }}
              transition="all ease 200ms"
              onClick={() => {
                // setDeleteAuthorisation(row.original);
                // setShowDeleteModal(true);
              }}
            >
              <MdDeleteForever />
            </Button>
          </Tooltip>
        </ButtonGroup>
      ),
    }),
  ];

  const table = useReactTable({
    data: airMovementsCrew,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
    sortingFns: {
      sortId: (rowA, rowB, columnId) => {
        // console.log("RowA: ", rowA.getValue(columnId));
        return rowA.getValue(columnId).value < rowB.getValue(columnId).value
          ? 1
          : -1;
      },
    },
  });

  //PORTAL REFERENCES
  const footerPlacement = useRef();
  // const footerPlacement = { containerRef: footerPlacement };

  const nameColumnMinWidth = useBreakpointValue({
    base: "150px",
    md: "100%",
  });

  const columnMinWidth = useBreakpointValue({
    base: "100px",
    md: "100px",
    lg: "90px",
    xl: "100px",
    "2xl": "120px",
  });

  // console.log(data, "data");
  // console.log("airMovementsCrew: ", airMovementsCrew);
  // console.log(table, "table");
  // console.log("tab: ", tab);

  return (
    <>
      <Flex
        margin={0}
        px={{ base: "0px", smd: "15px", lg: "15px" }}
        pt={{ base: "15px", smd: "10px", lg: "15px" }}
        pb={{ base: "79px", smd: "95px", lg: "15px" }}
        flex={1}
        flexDirection="column"
        // overflowY="auto"
        overflowY={{ base: "auto", lg: "hidden" }}
        zIndex="1"
        width="100%"
        height="100%"
      >
        <Flex
          position="absolute"
          marginTop={{ base: "70px", "2xl": "38px" }}
          background="gray.200"
          height="60px"
          width="50px"
        ></Flex>
        <Flex
          width="100%"
          px={{ base: "10px", smd: "0px" }}
          height={{ base: "76px", "2xl": "40px" }}
          minHeight={{ base: "76px", "2xl": "40px" }}
          justifyContent="space-between"
          alignItems={{ base: "flex-start", "2xl": "flex-end" }}
          zIndex="2"
          flexDirection={{ base: "column-reverse", "2xl": "row" }}
          mb={{ base: "0px", smd: "0px" }}
          gap={{ base: "0px", "2xl": "10px" }}
        >
          <Tabs
            authorisations={crewManagement}
            tab={tab}
            setTab={setTab}
            tab1={"all"}
            // tab2={"active"}
            tab3={"currencies"}
            tab4={"fatigue"}
            tab5={"proficiency"}
            tab6={"exemptions"}
          />

          <Flex
            gap={{ base: "0.5rem", md: "15px", lg: "20px" }}
            width={{ base: "100%", lg: "auto" }}
            mb={{ base: "0px", "2xl": "10px" }}
          >
            <Flex
              alignItems="center"
              gap={{ base: "0px", smd: "7px" }}
              width={{ base: "100%", lg: "auto" }}
            >
              <Text
                display={{ base: "none", smd: "block" }}
                fontSize="0.9rem"
                htmlFor="aircraft"
                fontFamily="'Poppins'"
                fontWeight="500"
                color="gray.500"
              >
                UNIT:
              </Text>
              <Select
                {...noFocus}
                chakraStyles={selectStyle}
                colorScheme="cyan"
                variant="outline"
                background="white"
                size="xs"
                cursor="pointer"
                id="aircraft"
                value={selectedOptions}
                options={unitOptions}
                onChange={(v) => {
                  setSelectedOptions(v);
                  setSquadronSelection(v.value);
                }}
              />
            </Flex>
            <ButtonGroup spacing={{ base: "5px", md: "0.5rem" }}>
              <IconButton
                aria-label="Search database"
                icon={<BsSearch />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Save to PDF"
                icon={<BsFiletypePdf />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Email"
                icon={<BsEnvelopeAt />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Print"
                icon={<BsPrinter />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.95rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Save to File"
                icon={<BsFolderPlus />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.95rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
            </ButtonGroup>
          </Flex>
        </Flex>

        <Flex
          zIndex="1"
          width="100%"
          height={{
            base: "auto",
            lg: "calc(100% - 40px)",
          }}
          minHeight={{
            base: "auto",
            lg: "calc(100% - 40px)",
          }}
          bg="white"
          borderRadius={{
            base: "0px",
            smd: "15px",
          }}
          borderTopLeftRadius={{
            base: "0px",
            smd: tab === "all" ? "0px" : "15px",
          }}
          boxShadow={{
            base: "none",
            smd: "0px 10px 40px -15px rgba(10, 20, 80, 0.4)",
          }}
        >
          <Flex flexDirection="column" width="100%">
            <Flex
              pl="17px"
              pr="13px"
              justifyContent={{ base: "space-between", smd: "space-between" }}
              alignItems="center"
              width="100%"
              height={{ base: "68px", smd: "48px" }}
              minHeight={{ base: "68px", smd: "48px" }}
              borderBottom="1px solid"
              borderColor="#e5e7ea"
              fontSize="0.9rem"
              flexDirection={{ base: "row", smd: "row" }}
            >
              <Flex
                width={{ base: "100%", smd: "auto" }}
                justifyContent="flex-start"
                alignItems="center"
                gap="5px"
              >
                <Text
                  color="gray.700"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {tab !== "all" ? "Air Movements Crew" : ""}
                </Text>
                <Text
                  color="brand.100"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {tab === "currencies"
                    ? "Currencies"
                    : tab === "fatigue"
                    ? "Fatigue"
                    : tab === "proficiency"
                    ? "Proficiency"
                    : tab === "exemptions"
                    ? "Exemptions"
                    : "All"}
                </Text>
                <Text
                  color="gray.700"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {tab === "all" ? "Air Movements Crew Management" : ""}
                </Text>
              </Flex>
              <ButtonGroup size="sm" isAttached={true} mr="5px">
                <Button
                  variant="solid"
                  borderRadius="20px"
                  size="sm"
                  bg="brand.100"
                  color="white"
                  pl="10px"
                  pr={{ base: "15px", sm: "15px" }}
                  width={{ base: "80px", "2xl": "90px" }}
                  minWidth={{ base: "auto", sm: "80px", "2xl": "90px" }}
                  height="30px"
                  fontSize="0.8rem"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  gap="2px"
                  _hover={{
                    background: "brand.200",
                  }}
                  // onClick={() => {
                  //   setNewAuthorisation(true);
                  //   // setShowAuthorisationsModal(true);
                  // }}
                >
                  <Icon
                    as={MdOutlineAdd}
                    color="white"
                    width="22px"
                    height="auto"
                  />
                  <Text display={{ base: "block", sm: "block" }}>NEW</Text>
                </Button>
              </ButtonGroup>
            </Flex>

            <Flex
              flexDirection="column"
              zIndex="1"
              width="100%"
              height="100%"
              background="#FBFDFF"
              overflowY={{ base: "hidden", lg: "auto" }}
              minHeight={{ base: "calc(100% - 360px)", lg: "auto" }}
            >
              <TableContainer width="100%">
                <Flex width="100%" height="100%" overflow="auto">
                  {!airMovementsCrewData && (
                    <Flex
                      width="100%"
                      justifyContent="center"
                      alignItems="flex-start"
                      pt="50px"
                    >
                      <Spinner
                        zIndex="1"
                        color={"brand.100"}
                        emptyColor={"white"}
                        thickness="3px"
                        speed="0.5s"
                        boxSize="40px"
                      />
                    </Flex>
                  )}
                  {airMovementsCrewData && (
                    <Table
                      variant="striped"
                      size="sm"
                      mx="15px"
                      mt="5px"
                      className="auth-table"
                      pr={{ base: "15px", smd: "initial" }}
                    >
                      <Thead
                        position="sticky"
                        top="-1px"
                        height="36px"
                        maxHeight="36px"
                        zIndex="90"
                        borderRadius="10px"
                        boxShadow={"0px 5px 10px -3px rgba(0,0,0,0.25)"}
                      >
                        {table.getHeaderGroups().map((headerGroup) => (
                          <Tr
                            key={headerGroup.id}
                            border="1px solid"
                            borderRadius="10px"
                          >
                            {headerGroup.headers.map((header) => {
                              const cellStyle = {
                                width:
                                  header.column.id === "actions"
                                    ? "125px"
                                    : header.column.id === "status"
                                    ? "90px"
                                    : "initial",
                                fontWeight: 500,
                              };
                              return (
                                <Th
                                  key={header.id}
                                  style={cellStyle}
                                  background={"#436781"}
                                  color={"white"}
                                  borderTop="hidden"
                                  borderBottom="hidden"
                                  borderColor="#5c87a5"
                                >
                                  {header.isPlaceholder ? null : (
                                    <Flex
                                      justifyContent="center"
                                      alignItems="center"
                                      gap="3px"
                                      fontSize="0.7rem"
                                      cursor="pointer"
                                      {...{
                                        className: header.column.getCanSort()
                                          ? "cursor-pointer select-none"
                                          : "",
                                        onClick:
                                          header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: (
                                          <TiArrowSortedDown fontSize="16px" />
                                        ),
                                        desc: (
                                          <TiArrowSortedUp fontSize="16px" />
                                        ),
                                      }[header.column.getIsSorted()] ?? null}
                                    </Flex>
                                  )}
                                </Th>
                              );
                            })}
                          </Tr>
                        ))}
                      </Thead>
                      {airMovementsCrewData && (
                        <Tbody
                          borderTop="1px solid"
                          borderColor="gray.400"
                          overflowX="auto"
                          overflowY={{ base: "hidden", lg: "auto" }}
                        >
                          {table.getRowModel().rows.map((row, index) => {
                            const isEvenRow = index % 2 === 0;
                            const backgroundColor = isEvenRow
                              ? "#f3f7fb"
                              : "white";

                            return (
                              <Tr
                                key={row.id}
                                border="1px solid"
                                borderColor="gray.200"
                                cursor={"pointer"}
                                backgroundColor={backgroundColor}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  const cellStyle = {
                                    background:
                                      cell.column.id === "status" &&
                                      (row.original.status === "fatigue"
                                        ? "#00fb00"
                                        : row.original.status === "currencies"
                                        ? "yellow"
                                        : row.original.status === "exemptions"
                                        ? "#00E0FF"
                                        : row.original.status === "proficiency"
                                        ? "#ff1b1b"
                                        : "initial"),
                                    color:
                                      cell.column.id === "status" &&
                                      (row.original.status === "fatigue" ||
                                        row.original.status === "exemptions" ||
                                        row.original.status === "proficiency")
                                        ? "white"
                                        : "initial",
                                    fontWeight:
                                      cell.column.id === "name" ? 700 : 500,
                                    letterSpacing:
                                      cell.column.id === "status"
                                        ? "0.5px"
                                        : "0",
                                    textAlign:
                                      cell.column.id === "callsign" ||
                                      cell.column.id === "name"
                                        ? "left"
                                        : "center",
                                    padding: "5px",
                                    paddingLeft:
                                      cell.column.id === "callsign" ||
                                      cell.column.id === "name"
                                        ? "15px"
                                        : "5px",
                                    width:
                                      cell.column.id === "squadron"
                                        ? "55px"
                                        : cell.column.id === "actions"
                                        ? "125px"
                                        : cell.column.id === "name"
                                        ? nameColumnMinWidth
                                        : columnMinWidth,
                                    minWidth:
                                      cell.column.id === "squadron"
                                        ? "55px"
                                        : cell.column.id === "actions"
                                        ? "125px"
                                        : cell.column.id === "name"
                                        ? nameColumnMinWidth
                                        : columnMinWidth,
                                  };
                                  return (
                                    <Td
                                      key={cell.id}
                                      style={cellStyle}
                                      borderColor="gray.200"
                                      // onClick={() => {
                                      //   if (cell.column.id !== "actions") {
                                      //     setAuthorisationData(row.original);
                                      //     setShowAuthorisationsModal(true);
                                      //   }
                                      // }}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </Td>
                                  );
                                })}
                              </Tr>
                            );
                          })}
                        </Tbody>
                      )}
                    </Table>
                  )}
                </Flex>
              </TableContainer>
            </Flex>
            <Flex
              pl="17px"
              pr="17px"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height={{ base: "54px", lg: "48px" }}
              minHeight={{ base: "54px", lg: "48px" }}
              borderTop="1px solid"
              borderColor="#e5e7ea"
              fontSize="0.95rem"
            >
              <Flex
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
                fontSize="0.75rem"
              >
                <Box ref={footerPlacement}></Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
