import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { RecoilRoot } from "recoil";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import PubNubClient from "./PubNubClient";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import theme from "./Theme";
import "./Theme/styles.css";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

console.log("firing index");

root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain="taskr.au.auth0.com"
    clientId="kxsQMoryyBbdOdfIns1c0KPBRyxPDwKO"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://taskr.au.auth0.com/api/v2/",
    }}
  >
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <PubNubClient>
            <Router>
              <App />
            </Router>
          </PubNubClient>
        </ChakraProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </Auth0Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
