import { useAuth0 } from "@auth0/auth0-react";

const auth0CustomDomain = "taskr.co.nz";

export default function useRoles() {
  const { user } = useAuth0();
  const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : [];

  return userRoles;
}

export function useAdminRole() {
  const { user } = useAuth0();
  const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : [];

  return userRoles.includes("Admin");
}

export function useApproverRole() {
  const { user } = useAuth0();
  const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : [];

  return userRoles.includes("Approver");
}

export function useUserMetaData() {
  const { user } = useAuth0();
  const metaData = user
    ? user[`https://${auth0CustomDomain}/user_metadata`]
    : null;

  return metaData;
}
