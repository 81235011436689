import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { Controller } from "react-hook-form";
import {
  formatDuration,
  intervalToDuration,
  parseISO,
  format,
  addMinutes,
  addHours,
  setMinutes,
  getMinutes,
} from "date-fns";
import Flatpickr from "react-flatpickr";
import { formatToTimeZone } from "date-fns-timezone";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  SimpleGrid,
  Icon,
  Text,
  Flex,
  Textarea,
  Collapse,
  useDisclosure,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { ReactComponent as Texan } from "../Assets/texan.svg";
import { ReactComponent as C130Icon } from "../Assets/c130.svg";
import { ReactComponent as B757Icon } from "../Assets/b757.svg";
import { ReactComponent as SimIcon } from "../Assets/simulator.svg";
import "flatpickr/dist/themes/material_blue.css";
import { aircrewDataState } from "../globalState";

export default function FlightAuthForm({
  authorisationData,
  newAuthorisation,
  editAuthorisation,
  airframeSerials,
  register,
  control,
  getValues,
  setValue,
  errors,
  watch,
  modalOpen,
  override,
  timeZone,
  selectStyle,
  setSquadronSelection,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const now = Date.now();

  // Calculate the next hour or half-hour - to keep PlanBy boards tidy
  const nextTime =
    getMinutes(now) < 30
      ? setMinutes(now, 30)
      : addMinutes(setMinutes(now, 0), 60);

  const customComponents = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        <Flex
          width="100%"
          justifyContent={{ base: "flex-start", xl: "space-between" }}
          alignItems={{ base: "flex-start", xl: "center" }}
          borderBottom={props.data.header && "1px solid #efefef"}
          paddingBottom={props.data.header && "5px"}
          pointerEvents={props.data.header && "none"}
          bg={props.data.header && "white !important"}
          flexDirection={{
            base: children ? "column" : "row",
            xl: "row",
          }}
          gap={{ base: "5px", xl: "0px" }}
          py={{ base: "5px", xl: "0px" }}
        >
          {props.data.header ? (
            <Text
              display={{ base: "none", xl: "block" }}
              textAlign="center"
              fontSize={props.data.header ? "0.65rem" : "xs"}
              textTransform={props.data.header ? "uppercase" : "capitalize"}
              color={
                props.data.header
                  ? "gray.500"
                  : props.data.fatigue === "fatigued"
                  ? "#ff0000"
                  : "#11EC4E"
              }
              fontWeight={props.data.header ? "300" : "600"}
            >
              NAME / Callsign
            </Text>
          ) : (
            <Flex
              justifyContent="flex-start"
              alignItems="baseline"
              gap="10px"
              width={{ base: "100%", xl: "30%" }}
              fontWeight={props.data.name ? "600" : "400"}
              textTransform={props.data.header ? "uppercase" : "capitalize"}
              whiteSpace="pre"
            >
              <Text fontWeight={props.data.type === "aircrew" ? "600" : "400"}>
                {children}
              </Text>
              {props.data.callsign && (
                <Text
                  fontWeight="400"
                  fontSize="0.75rem"
                  color="gray.400"
                >{`${props.data.callsign}`}</Text>
              )}
            </Flex>
          )}
          {props.data.type && props.data.type !== "aircrew" && (
            <Text
              fontSize="xs"
              fontWeight="700"
              whiteSpace="pre"
            >{`${props.data.type}`}</Text>
          )}
          {props.data.role && (
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              gap="5px"
              width={{ base: "100%", xl: "70%" }}
              fontWeight={props.data.header ? "300" : "600"}
              textTransform={props.data.header ? "uppercase" : "capitalize"}
              fontSize={{
                base: props.data.header ? "0.6rem" : "xs",
                xl: props.data.header ? "0.65rem" : "xs",
              }}
            >
              <Text
                bg={
                  props.data.header
                    ? "none"
                    : props.data.role === "FLTLT"
                    ? "cyan.100"
                    : props.data.role === "FLTOFF"
                    ? "green.100"
                    : "purple.100"
                }
                borderRadius="6px"
                px="7px"
                minWidth={{ base: "18%", xl: "60px" }}
                textAlign="center"
                color={
                  props.data.header
                    ? "gray.500"
                    : props.data.role === "FLTLT"
                    ? "cyan.700"
                    : props.data.role === "FLTOFF"
                    ? "green.700"
                    : "purple.700"
                }
              >{`${props.data.role}`}</Text>

              <Text // CURRENCY
                minWidth={{ base: "18%", xl: "70px" }}
                textAlign="center"
                color={
                  props.data.header
                    ? "gray.500"
                    : props.data.currency === "expired"
                    ? "#ff0000"
                    : props.data.currency === "expiring"
                    ? "#FDAC30"
                    : "#11EC4E"
                }
              >{`${props.data.currency}`}</Text>

              <Text // PROFICIENCY
                minWidth={{ base: "20%", xl: "70px" }}
                textAlign="center"
                color={
                  props.data.header
                    ? "gray.500"
                    : props.data.proficiency === "poor"
                    ? "#ff0000"
                    : props.data.proficiency === "average"
                    ? "#FDAC30"
                    : "#11EC4E"
                }
              >{`${props.data.proficiency}`}</Text>

              <Text // FATIGUE
                minWidth={{ base: "18%", xl: "70px" }}
                textAlign="center"
                color={
                  props.data.header
                    ? "gray.500"
                    : props.data.fatigue === "fatigued"
                    ? "#ff0000"
                    : "#11EC4E"
                }
              >{`${props.data.fatigue}`}</Text>

              <Flex // AVAILABLE
                minWidth={{ base: "18%", xl: "56px" }}
                justifyContent="center"
                alignItems="center"
                mr="5px"
              >
                {props.data.header ? (
                  <Text textAlign="center" color="gray.500">
                    Available
                  </Text>
                ) : (
                  <Flex // AVAILABLE
                    minWidth="10px"
                    width="10px"
                    height="10px"
                    borderRadius="100%"
                    bg={!props.data.available ? "#ff0000" : "#11EC4E"}
                  ></Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </chakraComponents.Option>
    ),
  };

  const aircrewData = useRecoilValue(aircrewDataState);

  const aircrewOptions = aircrewData.map((person) => ({
    value: person.id,
    label: person.name,
    callsign: person.callsign,
    squadron: person.squadron,
    type: person.type,
    role: person.role,
    currency: person.currency,
    proficiency: person.proficiency,
    fatigue: person.fatigue,
    available: person.available,
    colorScheme:
      person.role === "FLTLT"
        ? "cyan"
        : person.role === "FLTOFF"
        ? "green"
        : "purple",
    header: person.header,
  }));

  const aircraftOptions = [
    { value: "texan", label: "T-6C Texan II", squadron: "14 SQN/CFS" },
    { value: "b757", label: "Boeing 757", squadron: "40 SQN/CFS" },
    { value: "c130", label: "C-130H Hercules", squadron: "40 SQN/CFS" },
  ];

  const activityOptions = [
    { value: "transportop", label: "Transport Op" },
    { value: "vipop", label: "VIP Op" },
    { value: "sar", label: "Search and Rescue" },
    { value: "training", label: "Training" },
    { value: "circuits", label: "Circuits" },
    { value: "crosscountry", label: "Cross Country" },
    { value: "lowlevelnav", label: "Low Level Nav" },
    { value: "upperairwork", label: "Upper Airwork" },
  ];

  const areaOptions = [
    { value: "mamba", label: "MAMBA", type: "Ohakea Local" },
    { value: "coral", label: "CORAL", type: "Ohakea Local" },
    { value: "m307", label: "M307", type: "Ohakea Local" },
    { value: "m308", label: "M308", type: "Ohakea Local" },
    { value: "m309", label: "M309", type: "Ohakea Local" },
    { value: "m311", label: "M311", type: "Ohakea Local" },
    { value: "m312", label: "M312", type: "Ohakea Local" },
    { value: "conda", label: "CONDA", type: "IFR" },
    { value: "garta", label: "GARTA", type: "IFR" },
    { value: "ratla", label: "RATLA", type: "IFR" },
    { value: "adder", label: "ADDER", type: "IFR" },
    { value: "m302", label: "M302", type: "IFR" },
    { value: "m507", label: "M507", type: "IFR" },
    { value: "m507-north", label: "M507 North", type: "IFR" },
    { value: "m507-central", label: "M507 Central", type: "IFR" },
    { value: "m507-south", label: "M507 South", type: "IFR" },
    { value: "lismore-forest", label: "Lismore Forest", type: "Helicopter" },
    {
      value: "tararua-&-ruahine-mountains",
      label: "Tararua & Ruahine Mountains",
      type: "Helicopter",
    },
    {
      value: "richmond-mountains",
      label: "Richmond Mountains",
      type: "Helicopter",
    },
    {
      value: "south-marlborough-mountains",
      label: "South Marlborough Mountains",
      type: "Helicopter",
    },
    {
      value: "dip-flat-mountains",
      label: "Dip Flat Mountains",
      type: "Helicopter",
    },
    {
      value: "inland-kaikoura-mountains",
      label: "Inland Kaikoura Mountains",
      type: "Helicopter",
    },
  ];

  const aerodromeOptions = [
    { value: "ohakea", label: "Ohakea", type: "NZOH" },
    { value: "whenuapai", label: "Whenuapai", type: "NZWP" },
    { value: "palmerston-north", label: "Palmerston North", type: "NZPM" },
    { value: "paraparaumu", label: "Paraparaumu", type: "NZPP" },
    { value: "masterton", label: "Masterton", type: "NZMS" },
    { value: "woodbourne", label: "Woodbourne", type: "NZWB" },
    { value: "nelson", label: "Nelson", type: "NZNS" },
  ];

  const transportOptions = [
    { value: "domestic", label: "NZ Domestic", type: "" },
    { value: "international", label: "International", type: "" },
  ];

  const squadronOptions = [
    { value: "14 SQN/CFS", label: "14 Squadron" },
    { value: "40 SQN/CFS", label: "40 Squadron" },
  ];

  // const watchAll = watch();
  // useEffect(() => {
  //   console.log(watchAll, "Form Values");
  // }, [watchAll]);
  const squadron = watch("squadron");
  const aircraftSelected = watch("aircraft");
  const airframeSerial = watch("airframe");
  const pilot = watch("pilot");
  const passenger = watch("passenger");
  const activity = watch("activity");
  const area = watch("area");

  // const aircrew = [pilot, passenger];

  const selectedAirframe = airframeSerials.find(
    (airframe) => airframe.serial === airframeSerial
  );

  const areaSelectionOptions =
    activity === "Transport Op" || activity === "VIP Op"
      ? transportOptions
      : activity === "Circuits"
      ? aerodromeOptions
      : areaOptions;

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  useEffect(() => {
    // if aircraft is already selected, then open airframe collapse component
    if (!isOpen && aircraftSelected) {
      onOpen();
    }
  }, [isOpen, aircraftSelected, onOpen]);

  useEffect(() => {
    // clear aircraft and airframe when loading new form
    if (newAuthorisation) {
      onClose();
      setValue("aircraft", null);
      setValue("airframe", null);
    }
  }, [newAuthorisation, onClose, setValue]);

  // flatpickr refs
  const fpSince = useRef(null);
  const fpTill = useRef(null);
  const editSince = useRef(null);
  const timeWatch = watch(["since", "till"]);

  useEffect(() => {
    // update since ref
    editSince.current = timeWatch[0];
  }, [editSince, timeWatch]);

  useEffect(() => {
    // if 'since' date/time is after 'till', update 'till' to at least 'since' date/time
    if (timeWatch[0] > timeWatch[1]) {
      setValue("till", timeWatch[0]);
    }
  }, [timeWatch, setValue]);

  // Periodic update of Since Date/Time
  const timeReset = parseISO(timeWatch[0]) < addMinutes(now, 5);
  useEffect(() => {
    if (!editAuthorisation && timeReset) {
      setValue(
        "since",
        formatToTimeZone(addMinutes(now, 5), "YYYY-MM-DD HH:mmZ", {
          timeZone: timeZone,
        })
      );

      if (fpSince.current) {
        fpSince.current.flatpickr.set(
          // update Since minDate
          "minDate",
          formatToTimeZone(now, "D MMM YY HH:mm", {
            timeZone: timeZone,
          })
        );
      }
      if (fpTill.current) {
        fpTill.current.flatpickr.set(
          // update Till maxDate
          "maxDate",
          formatToTimeZone(
            addHours(parseISO(timeWatch[0]), 48),
            "D MMM YY HH:mm",
            { timeZone: timeZone }
          )
        );
      }
    }
  }, [
    now,
    editAuthorisation,
    timeReset,
    setValue,
    getValues,
    timeWatch,
    timeZone,
  ]);

  // determine duration
  const since = timeWatch?.[0] !== undefined && parseISO(timeWatch[0]);
  const till = timeWatch?.[1] !== undefined && parseISO(timeWatch[1]);
  const duration =
    since && till && since <= till
      ? intervalToDuration({ start: since, end: till })
      : null;
  const durationDetail =
    timeWatch &&
    timeWatch.length &&
    duration &&
    formatDuration(duration, {
      format: ["days", "hours", "minutes"],
    });
  const padWithZero = (value) => (value < 10 ? `0${value}` : value);
  const durationHasDays = duration?.days ? duration.days * 24 : 0;
  const formattedDuration = `${durationHasDays + duration?.hours}:${padWithZero(
    duration?.minutes
  )}`;

  // useEffect(() => {
  //   console.log(authorisationData, "FORM authorisationData");
  //   console.log(squadron, "squadron");
  //   console.log(airframeSerials, "airframeSerials");
  // }, [authorisationData, squadron, passenger]);

  return (
    <>
      <SimpleGrid
        width="100%"
        columns={{ base: 1, lg: 3 }}
        spacingX="25px"
        spacingY="30px"
        alignItems="baseline"
        fontSize="0.8rem"
      >
        <FormControl isInvalid={errors.since}>
          <FormLabel fontSize="0.8rem" htmlFor="since" fontFamily="'Poppins'">
            Flight Start
          </FormLabel>
          <Controller
            control={control}
            name="since"
            rules={{
              validate: {
                notInvalid: (value) =>
                  (value !== null && value !== "Invalid Date") ||
                  "Please select date/time",
              },
              required: "Please select date/time",
            }}
            render={({ field: { onChange, value } }) => (
              <Flatpickr
                id="since"
                ref={fpSince}
                placeholder="Select date/time..."
                style={{
                  width: "100%",
                  fontFamily: "'Open Sans', sans-serif",
                  height: "32px",
                  borderRadius: "7px",
                  fontSize: "0.875rem",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                  border: "1px solid",
                  borderColor: errors.since ? "#E53E3E" : "#ced4da",
                }}
                className="flatpickr form-control form-control-sm"
                data-enable-time
                value={formatToTimeZone(value, "D MMM YY HH:mm", {
                  timeZone: timeZone,
                })}
                onChange={(value) => {
                  onChange(
                    value.length ? format(value[0], "yyyy-MM-dd HH:mm") : null
                  );
                }}
                options={{
                  allowClearing: false,
                  // disableMobile: true,
                  dateFormat: "j M y H:i",
                  time_24hr: true,
                  minuteIncrement: 30,
                  minDate:
                    newAuthorisation &&
                    formatToTimeZone(nextTime, "D MMM YY HH:mm", {
                      timeZone: timeZone,
                    }),
                  defaultDate: formatToTimeZone(
                    addMinutes(nextTime, 5),
                    "D MMM YY HH:mm",
                    { timeZone: timeZone }
                  ),
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.since && errors.since.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.till}>
          <FormLabel fontSize="0.8rem" htmlFor="till" fontFamily="'Poppins'">
            Flight End
          </FormLabel>
          <Controller
            control={control}
            name="till"
            rules={{
              validate: {
                notInvalid: (value) =>
                  (value !== null && value !== "Invalid Date") ||
                  "Please select date/time",
              },
              required: "Please select date/time",
            }}
            render={({ field: { onChange, value } }) => (
              <Flatpickr
                id="till"
                ref={fpTill}
                placeholder="Select date/time..."
                style={{
                  width: "100%",
                  fontFamily: "'Open Sans', sans-serif",
                  height: "32px",
                  borderRadius: "7px",
                  fontSize: "0.875rem",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                  border: "1px solid",
                  borderColor: errors.till ? "#E53E3E" : "#ced4da",
                }}
                className="flatpickr form-control form-control-sm"
                data-enable-time
                value={formatToTimeZone(value, "D MMM YY HH:mm", {
                  timeZone: timeZone,
                })}
                onChange={(value) => {
                  onChange(
                    value.length ? format(value[0], "yyyy-MM-dd HH:mm") : null
                  );
                }}
                options={{
                  // allowClearing: false,
                  // disableMobile: true,
                  dateFormat: "j M y H:i",
                  time_24hr: true,
                  minuteIncrement: 30,
                  minDate: formatToTimeZone(
                    editAuthorisation ? editSince.current : nextTime,
                    "D MMM YY HH:mm",
                    { timeZone: timeZone }
                  ),
                  // maxDate: formatToTimeZone(
                  //   addHours(parseISO(timeWatch[0]), 48),
                  //   "D MMM YY HH:mm",
                  //   { timeZone: timeZone }
                  // ),
                  defaultDate: formatToTimeZone(
                    addHours(parseISO(timeWatch[0]), 1),
                    "D MMM YY HH:mm",
                    { timeZone: timeZone }
                  ),
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.till && errors.till.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel
            fontSize="0.8rem"
            htmlFor="duration"
            fontFamily="'Poppins'"
          >
            Duration
          </FormLabel>
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            bg="#fdfeff"
            borderRadius="10px"
            height="32px"
            px="12px"
            border="1px solid"
            borderColor="gray.100"
            fontSize="0.875rem"
          >
            {duration && (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                gap="10px"
              >
                <Text fontSize="0.875rem">{formattedDuration}</Text>
                <Text
                  color="gray.400"
                  opacity="0.7"
                  fontStyle="italic"
                  fontSize="0.7rem"
                  pr="2px"
                >{`${durationDetail}`}</Text>
              </Flex>
            )}
          </Flex>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid
        width="100%"
        columns={{ base: 1, lg: 3 }}
        spacingX="25px"
        spacingY="30px"
        alignItems="baseline"
        fontSize="0.8rem"
        pt="30px"
      >
        <FormControl isInvalid={errors.activity}>
          <FormLabel
            fontSize="0.8rem"
            htmlFor="activity"
            fontFamily="'Poppins'"
          >
            Activity
          </FormLabel>
          <Select
            id="activity"
            {...noFocus}
            chakraStyles={selectStyle}
            variant="outline"
            style={{ background: "white", zIndex: "10" }}
            size="sm"
            cursor="pointer"
            {...register("activity")}
            value={activityOptions.filter((option) => {
              return option.label === getValues("activity");
            })}
            onChange={(value) => setValue("activity", value.label)}
            options={activityOptions}
          />
          <FormErrorMessage>
            {errors.activity && errors.activity.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.area}>
          <FormLabel
            fontSize="0.8rem"
            htmlFor="area"
            fontFamily="'Poppins'"
            opacity={area ? "1" : "1"}
          >
            Area
          </FormLabel>
          <Select
            id="area"
            {...noFocus}
            chakraStyles={selectStyle}
            variant="outline"
            background="white"
            size="sm"
            cursor="pointer"
            {...register("area")}
            value={areaSelectionOptions.find((option) => option.label === area)}
            // onChange={(value) => {
            //   setValue("area", value?.label || "");
            //   setSelectedArea(value?.label || "");
            // }}
            onChange={(value) => setValue("area", value?.label || "")}
            options={areaSelectionOptions}
            components={customComponents}
          />

          <FormErrorMessage>
            {errors.area && errors.area.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.priority}>
          <FormLabel
            fontSize="0.8rem"
            htmlFor="priority"
            fontFamily="'Poppins'"
          >
            Priority
          </FormLabel>
          <Controller
            control={control}
            name="priority"
            // rules={{
            //   required: "Please select date/time",
            // }}
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <Stack direction="row" height="32px" spacing="0px">
                  <Flex
                    className="radio-button"
                    marginInlineStart="0rem !important"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    color={value === "Low" ? "white" : "light.100"}
                    border="1px solid"
                    borderRight="none"
                    borderTopLeftRadius="12px"
                    borderBottomLeftRadius="12px"
                    borderTopRightRadius="none"
                    borderBottomRightRadius="none"
                    borderColor={value === "Low" ? "#00e900" : "#ced4da"}
                    bg={value === "Low" ? "#00fb00" : "gray.100"}
                    w="100%"
                    _hover={{
                      background: value === "Low" ? "#00e900" : "gray.200",
                    }}
                  >
                    <Radio
                      hidden
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      as="button"
                      height="32px"
                      width="100%"
                      m="0"
                      style={{
                        marginInlineStart: "0 !important",
                      }}
                      value="Low"
                    >
                      <Text
                        fontSize={{
                          base: "0.75rem",
                          lg: "0.85rem",
                        }}
                        fontWeight="500"
                        margin="0"
                        marginLeft="-0.5rem"
                        marginTop={{ base: "-1px", lg: "0px" }}
                      >
                        Low
                      </Text>
                    </Radio>
                  </Flex>

                  <Flex
                    className="radio-button"
                    marginInlineStart="0rem !important"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    color={value === "Medium" ? "white" : "light.100"}
                    border="1px solid"
                    borderRight="none"
                    borderTopLeftRadius="none"
                    borderBottomLeftRadius="none"
                    borderTopRightRadius="none"
                    borderBottomRightRadius="none"
                    borderColor={value === "Medium" ? "#ffd100" : "#ced4da"}
                    bg={value === "Medium" ? "#ffdb00" : "gray.100"}
                    w="100%"
                    _hover={{
                      background: value === "Medium" ? "#ffd100" : "gray.200",
                    }}
                  >
                    <Radio
                      hidden
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      as="button"
                      height="32px"
                      width="100%"
                      m="0"
                      style={{
                        marginInlineStart: "0 !important",
                      }}
                      value="Medium"
                    >
                      <Text
                        fontSize={{
                          base: "0.75rem",
                          lg: "0.85rem",
                        }}
                        fontWeight="500"
                        margin="0"
                        marginLeft="-0.5rem"
                        marginTop={{ base: "-1px", lg: "0px" }}
                      >
                        Medium
                      </Text>
                    </Radio>
                  </Flex>

                  <Flex
                    className="radio-button"
                    marginInlineStart="0rem !important"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    color={value === "High" ? "white" : "light.100"}
                    border="1px solid"
                    borderTopLeftRadius="none"
                    borderBottomLeftRadius="none"
                    borderTopRightRadius="12px"
                    borderBottomRightRadius="12px"
                    borderColor={value === "High" ? "#fa4c00" : "#ced4da"}
                    bg={value === "High" ? "#ff6a00" : "gray.100"}
                    w="100%"
                    _hover={{
                      background: value === "High" ? "#fa4c00" : "gray.200",
                    }}
                  >
                    <Radio
                      hidden
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      as="button"
                      height="32px"
                      width="100%"
                      m="0"
                      style={{
                        marginInlineStart: "0 !important",
                      }}
                      value="High"
                    >
                      <Text
                        fontSize={{
                          base: "0.75rem",
                          lg: "0.85rem",
                        }}
                        fontWeight="500"
                        margin="0"
                        marginLeft="-0.5rem"
                        marginTop={{ base: "-1px", lg: "0px" }}
                      >
                        High
                      </Text>
                    </Radio>
                  </Flex>
                </Stack>
              </RadioGroup>
            )}
          />
          <FormErrorMessage>
            {errors.priority && errors.priority.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <Grid
        templateColumns={{
          base: "1fr",
          lg: "repeat(4, 1fr)",
          xl: override ? "repeat(4, 1fr)" : "repeat(6, 1fr)",
        }}
        // templateRows={{
        //   base: "repeat(3, 1fr)",
        //   lg: "repeat(2, 1fr)",
        //   xl: "repeat(2, 1fr)",
        // }}
        templateAreas={{
          base: `"squadron"
                  "aircraft"
                  "airframe"
                  "status"`,
          lg: `"squadron aircraft status"
                  "airframe airframe airframe"`,
          xl: override
            ? `"squadron aircraft status"
                  "airframe airframe airframe"`
            : `"squadron aircraft status"
                  "airframe airframe status"`,
        }}
        width="100%"
        columns={{ base: 1, lg: 4, xl: 4 }}
        gap={{ base: "15px", lg: "25px" }}
        alignItems="baseline"
        fontSize="0.8rem"
        pt="30px"
      >
        <GridItem
          area={"squadron"}
          width="100%"
          colSpan={{ base: 1, lg: 2, xl: 2 }}
          maxWidth={{
            base: modalOpen ? "calc(100vw - 70px)" : "calc(100vw - 40px)",
            smd: "calc(100vw - 70px)",
            lg: "100%",
          }}
        >
          <FormControl isInvalid={errors.squadron}>
            <FormLabel
              fontSize="0.8rem"
              htmlFor="aircraft"
              fontFamily="'Poppins'"
            >
              Squadron
            </FormLabel>
            <Select
              {...noFocus}
              chakraStyles={selectStyle}
              variant="outline"
              background="white"
              size="sm"
              cursor="pointer"
              id="squadron"
              {...register("squadron")}
              value={squadronOptions.filter((option) => {
                return option.value === getValues("squadron");
              })}
              onChange={(v) => {
                if (v.value !== squadron) {
                  onClose();
                  setValue("aircraft", null);
                  setValue("airframe", null);
                }
                setValue("squadron", v.value);
                setSquadronSelection(v.value);
              }}
              options={squadronOptions}
            />
            <FormErrorMessage>
              {errors.squadron && errors.squadron.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem
          area={"aircraft"}
          width="100%"
          colSpan={{ base: 1, lg: 2, xl: 2 }}
          maxWidth={{
            base: modalOpen ? "calc(100vw - 70px)" : "calc(100vw - 40px)",
            smd: "calc(100vw - 70px)",
            lg: "100%",
          }}
        >
          <FormControl isInvalid={errors.aircraft}>
            <FormLabel
              fontSize="0.8rem"
              htmlFor="aircraft"
              fontFamily="'Poppins'"
            >
              Aircraft
            </FormLabel>
            <Select
              {...noFocus}
              chakraStyles={selectStyle}
              variant="outline"
              background="white"
              size="sm"
              cursor="pointer"
              id="aircraft"
              {...register("aircraft")}
              value={aircraftOptions.filter((option) => {
                return option.label === getValues("aircraft");
              })}
              onChange={(value) => {
                setValue("aircraft", value.label);
                if (!isOpen) {
                  onOpen();
                }
              }}
              options={aircraftOptions.filter((option) => {
                return option.squadron === getValues("squadron");
              })}
            />
            <FormErrorMessage>
              {errors.aircraft && errors.aircraft.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem
          area={"status"}
          width="100%"
          colSpan={{ base: 1, lg: 2, xl: 2 }}
          maxWidth={{
            base: modalOpen ? "calc(100vw - 70px)" : "calc(100vw - 40px)",
            smd: "calc(100vw - 70px)",
            lg: "100%",
          }}
        >
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
          >
            <FormLabel
              fontSize="0.8rem"
              htmlFor="status"
              fontFamily="'Poppins'"
              width="100%"
            >
              <Flex
                justifyContent="space-between"
                alignItems="baseline"
                flexDirection="row"
                width="100%"
                gap="10px"
                transition="all ease 250ms"
                opacity={selectedAirframe?.serial ? "1" : "0.3"}
              >
                <Text>Selected Airframe Status</Text>
                {selectedAirframe?.serial && (
                  <Text fontSize="0.8rem" fontWeight={"300"} color="gray.400">
                    {selectedAirframe?.serial}
                  </Text>
                )}
              </Flex>
            </FormLabel>
            <Flex
              width="100%"
              height="32px"
              bg="white"
              borderRadius="10px"
              border="1px solid #e2e8f0"
              marginBottom={{
                base: selectedAirframe?.serial ? "-32px" : "0px",
                lg: "-32px",
              }}
              zIndex="1"
              opacity={selectedAirframe?.serial ? "0" : "0.4"}
              transition="all ease 250ms"
            ></Flex>
            <Collapse
              style={{ width: "100%", zIndex: "2" }}
              in={selectedAirframe?.serial}
              animateOpacity
              unmountOnExit={true}
            >
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="column"
                width="100%"
                bg="gray.100"
                borderRadius="10px"
                px="12px"
                py="7px"
                fontSize="0.75rem"
                gap="2px"
                minHeight={{
                  base: "auto",
                  xl:
                    (authorisationData?.airframe || getValues("airframe")) &&
                    selectedAirframe?.serial &&
                    isOpen
                      ? "135px"
                      : "93px",
                }}
                transition="all ease 250ms"
              >
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="row"
                  width="100%"
                >
                  <Text fontSize="0.75rem" fontWeight={"700"} minWidth="150px">
                    Airworthy:
                  </Text>
                  <Flex
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexDirection="column"
                    width="100%"
                    mb="5px"
                  >
                    <Text
                      fontSize="0.75rem"
                      fontWeight={"700"}
                      color={
                        selectedAirframe?.status?.airworthy === "caution"
                          ? "#ffd600"
                          : selectedAirframe?.status?.airworthy
                          ? "#00fb00"
                          : "#ff0000"
                      }
                    >
                      {selectedAirframe?.status?.airworthy ? "YES" : "NO"}
                    </Text>

                    {(selectedAirframe?.status?.airworthy ||
                      selectedAirframe?.status?.airworthy === "caution") && (
                      <Text
                        fontSize="0.7rem"
                        fontWeight={"400"}
                        fontStyle="italic"
                        textTransform={"uppercase"}
                      >
                        {selectedAirframe?.status?.airworthy === "caution"
                          ? "– with current defects"
                          : selectedAirframe?.status?.airworthy
                          ? ""
                          : "– unavailable"}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                >
                  <Text fontSize="0.75rem" fontWeight={"700"} minWidth="150px">
                    Hours Total:
                  </Text>
                  <Text fontSize="0.75rem" fontWeight={"400"}>
                    {selectedAirframe?.status?.hourstotal}
                  </Text>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                >
                  <Text fontSize="0.75rem" fontWeight={"700"} minWidth="150px">
                    Hours to Maintenance:
                  </Text>
                  <Text fontSize="0.75rem" fontWeight={"400"}>
                    {selectedAirframe?.status?.hourstomaintenance}
                  </Text>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="row"
                  width="100%"
                >
                  <Text fontSize="0.75rem" fontWeight={"700"} minWidth="150px">
                    Defects:
                  </Text>
                  <Flex
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexDirection="column"
                    width="100%"
                    ml={
                      selectedAirframe?.status?.defects.length ? "15px" : "0px"
                    }
                  >
                    {selectedAirframe?.status?.defects.length ? (
                      <ul>
                        {selectedAirframe?.status?.defects.map((defect, i) => (
                          <li>
                            <Flex
                              key={`defect.${i}`}
                              fontSize="0.75rem"
                              fontWeight={"400"}
                            >
                              {defect}
                            </Flex>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "None"
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Collapse>
          </Flex>
        </GridItem>

        <GridItem
          area={"airframe"}
          width="100%"
          colSpan={{ base: 1, lg: 4, xl: 4 }}
          transition="all ease 250ms"
          maxWidth={{
            base: modalOpen ? "calc(100vw - 70px)" : "calc(100vw - 40px)",
            smd: "calc(100vw - 70px)",
            lg: "100%",
          }}
          mt={{
            base: "15px",
            lg: selectedAirframe ? "-15px" : "5px",
            xl: "5px",
          }}
        >
          <Collapse
            in={isOpen || aircraftSelected}
            animateOpacity
            unmountOnExit={true}
          >
            <FormControl isInvalid={errors.airframe}>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="airframe"
                fontFamily="'Poppins'"
              >
                Airframe
              </FormLabel>
              <Flex
                width="auto"
                height="100%"
                overflowX="auto"
                mt="-5px"
                pt="5px"
                px="2px"
                pb="5px"
                mb={{ base: "10px", lg: "20px", xl: "0px" }}
                transition="all ease 250ms"
              >
                <Controller
                  control={control}
                  name="airframe"
                  rules={{
                    required: "Please select an aircraft type",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={getValues("airframe")}
                      pb="5px"
                      width="100%"
                    >
                      <Stack direction="row">
                        {airframeSerials
                          .filter((option) => {
                            return option.squadron === getValues("squadron");
                          })
                          .filter((type) => {
                            return squadron === "40 SQN/CFS"
                              ? type.model === getValues("aircraft")
                              : true;
                          })
                          .map((airframe) => (
                            <Flex
                              key={`airframe.${airframe.serial}`}
                              marginInlineStart="0rem !important"
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              width="100%"
                              height="46px"
                            >
                              <Flex
                                className="radio-button"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                color={
                                  value === airframe.serial
                                    ? "white"
                                    : "gray.600"
                                }
                                border="1px solid"
                                borderRadius="12px"
                                borderColor={
                                  value === airframe.serial
                                    ? "brand.200"
                                    : "#ced4da"
                                }
                                bg={
                                  value === airframe.serial
                                    ? "brand.100"
                                    : "gray.100"
                                }
                                transform={
                                  value === airframe.serial
                                    ? "translateY(-1px) scale(1.04)"
                                    : "translateY(0px) scale(1)"
                                }
                                opacity={
                                  !airframe?.status?.airworthy ? "0.4" : "1"
                                }
                                transition="all ease 100ms"
                                cursor="pointer"
                                minWidth="54px"
                                maxWidth="118px"
                                height="46px"
                                _hover={{
                                  background:
                                    value === airframe.serial
                                      ? "brand.200"
                                      : "gray.200",
                                }}
                                fontSize="0rem !important"
                              >
                                <Radio
                                  key={airframe.serial}
                                  value={airframe.serial}
                                  hidden
                                  display="flex"
                                  flexDirection={"column"}
                                  justifyContent="center"
                                  alignItems="center"
                                  as="button"
                                  height="40px"
                                  width="100%"
                                  m="0"
                                  cursor="pointer"
                                  style={{ marginInlineStart: "0 !important" }}
                                >
                                  <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    width="100%"
                                    marginLeft="-0.25rem"
                                    cursor="pointer"
                                  >
                                    <Icon
                                      margin="0"
                                      color={
                                        airframe?.status?.airworthy ===
                                        "caution"
                                          ? "#faf001"
                                          : !airframe?.status?.airworthy
                                          ? "#ff0000"
                                          : value === airframe.serial
                                          ? "white"
                                          : "gray.400"
                                      }
                                      cursor="pointer"
                                      as={
                                        airframe?.callsign === "SIMULATOR"
                                          ? SimIcon
                                          : airframe?.squadron ===
                                              "40 SQN/CFS" &&
                                            airframe?.model === "Boeing 757"
                                          ? B757Icon
                                          : airframe?.squadron === "40 SQN/CFS"
                                          ? C130Icon
                                          : Texan
                                      }
                                      transform={
                                        airframe?.callsign !== "SIMULATOR" &&
                                        "rotate(45deg)"
                                      }
                                      style={{
                                        marginInlineStart: "0rem !important",
                                      }}
                                      width={{ base: "24px", lg: "26px" }}
                                      height={{ base: "24px", lg: "26px" }}
                                      mb={{ base: "-2px", lg: "-3px" }}
                                    />
                                    <Text
                                      fontSize="0.7rem"
                                      letterSpacing="0.5px"
                                      fontWeight={
                                        value === airframe.serial
                                          ? "600"
                                          : "500"
                                      }
                                      whiteSpace="pre"
                                      cursor="pointer"
                                    >
                                      {airframe.serial}
                                    </Text>
                                  </Flex>
                                </Radio>
                              </Flex>
                              {!airframe?.status?.airworthy ||
                              airframe?.status?.airworthy === "caution" ? (
                                <Flex
                                  zIndex="2"
                                  marginLeft="0px"
                                  pointerEvents="none"
                                >
                                  <Flex
                                    width="12px"
                                    height="12px"
                                    borderRadius="20px"
                                    bg={
                                      airframe?.status?.airworthy === "caution"
                                        ? "#faf001"
                                        : "#ff0000"
                                    }
                                    // position="absolute"
                                    marginLeft="-9px"
                                    marginRight="-4px"
                                    marginTop="-3px"
                                  ></Flex>
                                </Flex>
                              ) : null}
                            </Flex>
                          ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Flex>
              <FormErrorMessage>
                {errors.airframe && errors.airframe.message}
              </FormErrorMessage>
            </FormControl>
          </Collapse>
        </GridItem>
      </Grid>

      <Grid
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          xl: override ? "repeat(2, 1fr)" : "repeat(3, 1fr)",
        }}
        templateAreas={{
          base: `"aircrew"
                  "qualifications"`,
          lg: `"aircrew qualifications"`,
        }}
        width="100%"
        columns={{ base: 1, lg: 2, xl: override ? 2 : 3 }}
        gap={{ base: "30px", lg: "25px" }}
        alignItems="baseline"
        fontSize="0.8rem"
        transition="all ease 250ms"
        pt={{
          base: "20px",
          lg: "0px",
          xl: isOpen ? "20px" : "0px",
        }}
      >
        <GridItem
          area={"aircrew"}
          width="100%"
          colSpan={{ base: 1, xl: override ? 1 : 2 }}
          maxWidth={{
            base: modalOpen ? "calc(100vw - 70px)" : "calc(100vw - 40px)",
            smd: "calc(100vw - 70px)",
            lg: "100%",
          }}
          zIndex="2"
        >
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
            pb="5px"
          >
            <FormLabel
              fontSize="0.8rem"
              htmlFor="pilot"
              fontFamily="'Poppins'"
              opacity={aircraftSelected ? "1" : "0.3"}
            >
              {aircraftSelected ? "Pilot in Command" : "Aircrew"}
            </FormLabel>
            <Flex
              width="100%"
              height="32px"
              bg="white"
              borderRadius="10px"
              border="1px solid #e2e8f0"
              marginBottom={{
                base: aircraftSelected ? "-32px" : "0px",
                lg: aircraftSelected ? "-32px" : "0px",
              }}
              zIndex="1"
              opacity={aircraftSelected ? "0" : "0.4"}
            ></Flex>
            <Collapse
              style={{
                width: "100%",
                zIndex: "2",
                overflow: "visible",
              }}
              in={aircraftSelected}
              animateOpacity
              unmountOnExit={true}
            >
              <Flex gap="25px" width="100%" flexDirection="column">
                <FormControl isInvalid={errors.pilot}>
                  <Select
                    id="pilot"
                    showClearButton
                    {...noFocus}
                    chakraStyles={selectStyle}
                    variant="outline"
                    style={{ background: "white", zIndex: "10" }}
                    size="sm"
                    cursor="pointer"
                    {...register("pilot")}
                    value={aircrewOptions
                      .filter((option) => {
                        // filter out header option (used for select dropdown only)
                        return option.label !== "Name";
                      })
                      .filter(
                        (sqd) =>
                          sqd?.squadron ===
                          (squadron === "40 SQN/CFS" ? 40 : 14)
                      )
                      .filter((option) => {
                        return option.value === getValues("pilot");
                      })}
                    onChange={(v) => setValue("pilot", v.value)}
                    options={aircrewOptions
                      .filter((sqd) =>
                        sqd.label !== "Name"
                          ? sqd?.squadron ===
                            (squadron === "40 SQN/CFS" ? 40 : 14)
                          : true
                      )
                      .filter((option) => {
                        // filter out passenger selected from options
                        return option.value ? option.value !== passenger : true;
                      })}
                    components={customComponents}
                  />
                  <FormErrorMessage>
                    {errors.pilot && errors.pilot.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="passenger"
                    fontFamily="'Poppins'"
                    opacity={aircraftSelected ? "1" : "0.3"}
                  >
                    Co-Pilot / Instructor / Passenger
                  </FormLabel>
                  <Select
                    id="passenger"
                    {...noFocus}
                    chakraStyles={selectStyle}
                    variant="outline"
                    style={{ background: "white", zIndex: "10" }}
                    size="sm"
                    cursor="pointer"
                    {...register("passenger")}
                    value={aircrewOptions
                      .filter((option) => {
                        // filter out header option (used for select dropdown only)
                        return option.label !== "Name";
                      })
                      .filter(
                        (sqd) =>
                          sqd?.squadron ===
                          (getValues("squadron") === "40 SQN/CFS" ? 40 : 14)
                      )
                      .filter((option) => {
                        return option.value === getValues("passenger");
                      })}
                    onChange={(v) => setValue("passenger", v.value)}
                    options={aircrewOptions
                      .filter((sqd) =>
                        sqd.label !== "Name"
                          ? sqd?.squadron ===
                            (squadron === "40 SQN/CFS" ? 40 : 14)
                          : true
                      )
                      .filter((option) => {
                        // filter out pilot selected from options
                        return option.value ? option.value !== pilot : true;
                      })}
                    components={customComponents}
                  />
                </FormControl>
              </Flex>
            </Collapse>
          </Flex>
        </GridItem>

        <GridItem
          area={"qualifications"}
          width="100%"
          colSpan={1}
          maxWidth={{
            base: modalOpen ? "calc(100vw - 70px)" : "calc(100vw - 40px)",
            smd: "calc(100vw - 70px)",
            lg: "100%",
          }}
          zIndex="1"
        >
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
            pb="5px"
          >
            <FormLabel
              fontSize="0.8rem"
              htmlFor="qualifications"
              fontFamily="'Poppins'"
              opacity={activity ? "1" : "0.3"}
              transition="all ease 250ms"
            >
              Qualifications & Currencies Required
            </FormLabel>
            <Flex
              width="100%"
              height="32px"
              bg="white"
              borderRadius="10px"
              border="1px solid #e2e8f0"
              marginBottom={{
                base: activity ? "-32px" : "0px",
                lg: "-32px",
              }}
              zIndex="1"
              opacity={activity ? "0" : "0.4"}
              transition="all ease 250ms"
            ></Flex>
            <Collapse
              style={{ width: "100%", zIndex: "2" }}
              in={activity}
              animateOpacity
              unmountOnExit={true}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="100%"
                bg="gray.100"
                borderRadius="10px"
                fontSize="0.75rem"
                gap="5px"
                pb="12px"
              >
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                  height="32px"
                  borderBottom="2px solid white"
                  px="12px"
                  py="5px"
                  mb="3px"
                >
                  <Text fontSize="0.75rem" fontWeight={"700"} width="50%">
                    Qualification
                  </Text>
                  <Text fontSize="0.75rem" fontWeight={"700"} width="50%">
                    Currency
                  </Text>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                  px="12px"
                >
                  <Text fontSize="0.75rem" fontWeight={"400"} width="50%">
                    Radar Operator
                  </Text>
                  <Text fontSize="0.75rem" fontWeight={"400"} width="50%">
                    1 per year
                  </Text>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                  px="12px"
                >
                  <Text fontSize="0.75rem" fontWeight={"400"} width="50%">
                    SAR
                  </Text>
                  <Text fontSize="0.75rem" fontWeight={"400"} width="50%">
                    3 per year
                  </Text>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="row"
                  width="100%"
                  px="12px"
                >
                  <Text fontSize="0.75rem" fontWeight={"400"} width="50%">
                    Night Flying
                  </Text>
                  <Text fontSize="0.75rem" fontWeight={"400"} width="50%">
                    1 per 90 days
                  </Text>
                </Flex>
              </Flex>
            </Collapse>
          </Flex>
        </GridItem>
      </Grid>

      <SimpleGrid
        width="100%"
        columns={1}
        spacingX="25px"
        spacingY="30px"
        alignItems="baseline"
        fontSize="0.8rem"
        pt={{ base: "30px", lg: aircraftSelected ? "25px" : "30px" }}
        transition="all ease 250ms"
      >
        <FormControl isInvalid={errors.intentions}>
          <FormLabel
            fontSize="0.8rem"
            htmlFor="intentions"
            fontFamily="'Poppins'"
          >
            Intentions / Description
          </FormLabel>
          <Textarea
            variant="outline"
            background="white"
            size="sm"
            minHeight={{ base: "160px", lg: "80px" }}
            borderRadius="10px"
            cursor="pointer"
            id="intentions"
            {...register("intentions", {
              required: "This is required",
            })}
          />
          <FormErrorMessage>
            {errors.intentions && errors.intentions.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
}
