import React, { useState, useRef, useEffect, useCallback } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import {
  ButtonGroup,
  Button,
  Text,
  Flex,
  Portal,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseISO } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import Tabs from "../Menu/Tabs";
import PreFlightTab from "./Components/PreFlightTab";
import ApprovalTab from "./Components/ApprovalTab";
import BriefingTab from "./Components/BriefingTab";
import {
  editAuthorisationState,
  authorisationDataState,
  newAuthorisationState,
  authorisationTabState,
  menuSelectionState,
  riskItemsState,
  squadronSelectionState,
  userState,
  overrideState,
  airframeDataState,
  aircrewDataState,
  hasNotificationState,
} from "../globalState";
import RiskAssessmentTab from "./Components/RiskAssessmentTab";
import PostFlightTab from "./Components/PostFlightTab";
import { useApproverRole } from "../Auth/Hooks";
import { usePubNub } from "pubnub-react";
import FlightAuthPDF from "../Assets/pdf/FOMS-Demo-FLIGHT-AUTHORISATION.pdf";

export default function Authorisation() {
  const location = useLocation();
  const navigate = useNavigate();
  const isApprover = useApproverRole();
  const pubnub = usePubNub();
  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone = "Pacific/Auckland";

  const [authorisationData, setAuthorisationData] = useRecoilState(
    authorisationDataState
  );
  const [newAuthorisation, setNewAuthorisation] = useRecoilState(
    newAuthorisationState
  );
  const [editAuthorisation, setEditAuthorisation] = useRecoilState(
    editAuthorisationState
  );
  const [squadronSelection, setSquadronSelection] = useRecoilState(
    squadronSelectionState
  );
  const airframeData = useRecoilValue(airframeDataState);
  const aircrewData = useRecoilValue(aircrewDataState);
  const menuSelection = useRecoilValue(menuSelectionState);
  const [submitError, setSubmitError] = useState(null);
  const [closeDelay, setCloseDelay] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [tab, setTab] = useRecoilState(authorisationTabState);
  const [override, setOverride] = useRecoilState(overrideState);
  const user = useRecoilValue(userState);
  const [riskItems, setRiskItems] = useRecoilState(riskItemsState);
  const setHasNotification = useSetRecoilState(hasNotificationState);
  const authorisations = location.pathname === "/authorisations";
  const editingAuth =
    editAuthorisation && location.pathname === "/authorisations";
  const airframeSerials = airframeData;

  const {
    handleSubmit,
    register,
    control,
    formState: {
      errors,
      isValid,
      isSubmitting,
      // isSubmitted,
      isSubmitSuccessful,
    },
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      status: "draft",
      // aircrew: [],
      // airframe: airframeSerials[0].serial
      // startTime: formatToTimeZone(now, "YYYY-MM-DD HH:mmZ", {
      //   timeZone: timeZone,
      // }),
    },
  });

  const updateSuccessful = closeDelay && isSubmitSuccessful && isValid;
  useEffect(() => {
    if (updateSuccessful) setHasNotification(true);
  }, [updateSuccessful, setHasNotification]);

  // on close reset state
  const handleClose = () => {
    if (menuSelection === 1 || menuSelection === 2) {
      // if editing or adding new from planning board, return to planning board after close of editing screen
      navigate("/taskings");
    }
    setHasNotification(false);
    setEditAuthorisation(false);
    setNewAuthorisation(false);
    setAuthorisationData(null);
    setSubmitError(null);
    setSaveAsDraft(false);
    setOverride(false);
    setRiskItems([]);
    setDeclined(false);
    setTab("pre-flight");
  };

  useEffect(() => {
    setTab("pre-flight");
  }, [user, setTab]);

  //  send pubnub message to Approvers channel
  const sendMessage = useCallback(
    (requestOptions) => {
      // console.log("Sending PubNub Message");
      if (requestOptions) {
        pubnub.publish({
          channel: "approvers",
          message: requestOptions,
        });
      }
    },
    [pubnub]
  );

  // function onSubmit(values) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       alert(JSON.stringify(values, null, 2));
  //       resolve();
  //     }, 3000);
  //     setCloseDelay(true);
  //     setTimeout(() => {
  //       handleClose();
  //       setCloseDelay(false);
  //     }, 2000);
  //   });
  // }

  const handleSave = async (data, status) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // Pre-flight
        id: data.id,
        title: data.title,
        costcentre: data.costcentre,
        since: formatToTimeZone(parseISO(data.since), "YYYY-MM-DD HH:mmZ", {
          timeZone: "UTC",
        }),
        till: formatToTimeZone(parseISO(data.till), "YYYY-MM-DD HH:mmZ", {
          timeZone: "UTC",
        }),
        squadron: data.squadron,
        aircraft: data.aircraft,
        airframe: data.airframe,
        pilot: data.pilot,
        passenger: data.passenger,
        activity: data.activity,
        area: data.area,
        priority: data.priority,
        intentions: data.intentions,
        status: data.status,

        // Risk Assessment
        riskstatus: data.riskstatus,
        riskscompileddate: data.riskscompileddate,
        riskdata: data.riskdata,
        risklimitations: data.risklimitations,
        riskref: data.riskref,
        authorisedby: data.authorisedby,
        risksapproveddate: data.risksapproveddate,
        riskapprovedcomment: data.riskapprovedcomment,

        // Briefing
        acceptedbyopssqd: data.acceptedbyopssqd,
        accepteddateopssqd: data.accepteddateopssqd,
        acceptedrefopssqd: data.acceptedrefopssqd,
        acceptedby230sqd: data.acceptedby230sqd,
        accepteddate230sqd: data.accepteddate230sqd,
        acceptedref230sqd: data.acceptedref230sqd,
        acceptedbybasehq: data.acceptedbybasehq,
        accepteddatebasehq: data.accepteddatebasehq,
        acceptedrefbasehq: data.acceptedrefbasehq,

        // Approval
        restrictions: data.restrictions,
        approvedcomments: data.approvedcomments,
        approvedby: data.approvedby,
        approveddate: data.approveddate,
        approvedref: data.approvedref,

        // Post-flight
        actualdeparturetime: data.actualdeparturetime,
        actualarrivaltime: data.actualarrivaltime,
        flightcompletion: data.flightcompletion,
        cabinalt: data.cabinalt,
        flightcompletionreason: data.flightcompletionreason,
        postflightcomments: data.postflightcomments,
        fuelab: data.fuelab,
        burnrate: data.burnrate,
        fuelland: data.fuelland,
        fuelcategory: data.fuelcategory,
        new: newAuthorisation ? true : undefined,
      }),
    };

    try {
      if (tab === "risk assessment") {
        console.log(
          `Authorisation ${saveAsDraft ? "saved as draft" : "pending"}`
        );
        setValue("status", saveAsDraft ? "draft" : "pending");
      } else if (tab === "approval") {
        console.log("Authorisation approved");
        setValue("status", "approved");
      }
      // console.log("requestOptions", requestOptions);
      await fetch("/post/booking", requestOptions);
      const submitHandler = () => {
        sendMessage(JSON.parse(requestOptions.body));
        setCloseDelay(true);
        setTimeout(() => {
          handleClose();
          setCloseDelay(false);
        }, 2000);
      };
      submitHandler();
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  useEffect(() => {
    if (authorisationData && editAuthorisation) {
      console.log("loading data");
      // console.log(authorisationData, "DATA");

      // Pre-Flight
      authorisationData.id && setValue("id", authorisationData.id);
      authorisationData.title && setValue("title", authorisationData.title);
      authorisationData.costcentre &&
        setValue("costcentre", authorisationData.costcentre);
      authorisationData.since && setValue("since", authorisationData.since);
      authorisationData.till && setValue("till", authorisationData.till);
      authorisationData.squadron &&
        setValue("squadron", authorisationData.squadron);
      authorisationData.aircraft &&
        setValue("aircraft", authorisationData.aircraft);
      authorisationData.airframe &&
        setValue("airframe", authorisationData.airframe);
      authorisationData.pilot && setValue("pilot", authorisationData.pilot);
      authorisationData.passenger &&
        setValue("passenger", authorisationData.passenger);
      authorisationData.activity &&
        setValue("activity", authorisationData.activity);
      authorisationData.area && setValue("area", authorisationData.area);
      authorisationData.priority &&
        setValue("priority", authorisationData.priority);
      authorisationData.intentions &&
        setValue("intentions", authorisationData.intentions);
      authorisationData.status && setValue("status", authorisationData.status);

      // Risk Assessment
      authorisationData.riskstatus &&
        setValue("riskstatus", authorisationData.riskstatus);
      authorisationData.riskdata &&
        setValue("riskdata", authorisationData.riskdata);
      authorisationData.riskscompileddate &&
        setValue("riskscompileddate", authorisationData.riskscompileddate);
      authorisationData.risklimitations &&
        setValue("risklimitations", authorisationData.risklimitations);
      authorisationData.riskref &&
        setValue("riskref", authorisationData.riskref);
      authorisationData.authorisedby &&
        setValue("authorisedby", authorisationData.authorisedby);
      authorisationData.risksapproveddate &&
        setValue("risksapproveddate", authorisationData.risksapproveddate);
      authorisationData.riskapprovedcomment &&
        setValue("riskapprovedcomment", authorisationData.riskapprovedcomment);

      // Briefing
      authorisationData?.acceptedbyopssqd &&
        setValue("acceptedbyopssqd", authorisationData.acceptedbyopssqd);
      authorisationData?.accepteddateopssqd &&
        setValue("accepteddateopssqd", authorisationData.accepteddateopssqd);
      authorisationData?.acceptedrefopssqd &&
        setValue("acceptedrefopssqd", authorisationData.acceptedrefopssqd);

      authorisationData?.acceptedby230sqd &&
        setValue("acceptedby230sqd", authorisationData.acceptedby230sqd);
      authorisationData?.accepteddate230sqd &&
        setValue("accepteddate230sqd", authorisationData.accepteddate230sqd);
      authorisationData?.acceptedref230sqd &&
        setValue("acceptedref230sqd", authorisationData.acceptedref230sqd);

      authorisationData?.acceptedbybasehq &&
        setValue("acceptedbybasehq", authorisationData.acceptedbybasehq);
      authorisationData?.accepteddatebasehq &&
        setValue("accepteddatebasehq", authorisationData.accepteddatebasehq);
      authorisationData?.acceptedrefbasehq &&
        setValue("acceptedrefbasehq", authorisationData.acceptedrefbasehq);

      // Approval
      authorisationData.approvedcomments &&
        setValue("approvedcomments", authorisationData.approvedcomments);
      authorisationData.restrictions &&
        setValue("restrictions", authorisationData.restrictions);
      authorisationData.approvedby &&
        setValue("approvedby", authorisationData.approvedby);
      authorisationData.approveddate &&
        setValue("approveddate", authorisationData.approveddate);
      authorisationData.approvedref &&
        setValue("approvedref", authorisationData.approvedref);

      // Post Flight
      authorisationData.actualdeparturetime &&
        setValue("actualdeparturetime", authorisationData.actualdeparturetime);
      authorisationData.actualarrivaltime &&
        setValue("actualarrivaltime", authorisationData.actualarrivaltime);
      authorisationData.flightcompletion &&
        setValue("flightcompletion", authorisationData.flightcompletion);
      authorisationData.cabinalt &&
        setValue("cabinalt", authorisationData.cabinalt);
      authorisationData.flightcompletionreason &&
        setValue(
          "flightcompletionreason",
          authorisationData.flightcompletionreason
        );
      authorisationData.postflightcomments &&
        setValue("postflightcomments", authorisationData.postflightcomments);
      authorisationData.fuelab && setValue("fuelab", authorisationData.fuelab);
      authorisationData.burnrate &&
        setValue("burnrate", authorisationData.burnrate);
      authorisationData.fuelland &&
        setValue("fuelland", authorisationData.fuelland);
      authorisationData.fuelcategory &&
        setValue("fuelcategory", authorisationData.fuelcategory);
    }
  }, [authorisationData, editAuthorisation, setValue]);

  //PORTAL REFERENCES
  const footerPlacement = useRef();
  // const footerPlacement = { containerRef: footerPlacement };

  const selectStyle = {
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      paddingTop: "6px",
      paddingBottom: "6px",
      background: data.header
        ? "white !important"
        : isFocused
        ? "#e7edf3"
        : isSelected
        ? "#e7edf3"
        : null,
      pointerEvents: data.header ? "none" : "auto",
      cursor: data.header ? "default" : "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
      borderColor: "#e2e8f0",
      minHeight: "32px",
      background: isApprover ? "#F5F8FB" : "white",
      pointerEvents: isApprover ? "none" : "auto",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: "4px",
      paddingBottom: "4px",
      display: isApprover ? "none" : "flex",
      _hover: {
        background: "#e7edf3",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      _hover: {
        background: "#f5f7fa",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100",
      marginTop: "4px",
      boxShadow: "0px 10px 40px -15px rgba(10, 20, 80, 0.4)",
      borderRadius: "10px",
    }),
  };

  const popoverPlacement = useBreakpointValue({
    base: "top",
    lg: "top-start",
  });

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  const activity = watch("activity");

  const showBriefingTab =
    user === "hq" ||
    activity === "Transport Op" ||
    activity === "VIP Op" ||
    authorisationData?.activity === "Transport Op" ||
    authorisationData?.activity === "VIP Op";

  // console.log(authorisationData, "authorisationData");

  return (
    <>
      <Flex
        margin={0}
        px={{ base: "0px", smd: "15px", lg: "15px" }}
        pt={{ base: "15px", smd: "10px", lg: "15px" }}
        pb={{ base: "79px", smd: "110px", lg: "15px" }}
        flex={1}
        flexDirection="column"
        // overflowY="auto"
        overflowY={{ base: "auto", lg: "hidden" }}
        zIndex="1"
        width="100%"
        height="100%"
      >
        <Flex
          position="absolute"
          marginTop={{ base: "78px", smd: "38px" }}
          background="gray.200"
          height="60px"
          width="50px"
        ></Flex>
        <Flex
          width="100%"
          px={{
            base: "10px",
            smd: "0px",
          }}
          height={{ base: "36px", smd: "40px" }}
          minHeight={{ base: "36px", smd: "40px" }}
          justifyContent="space-between"
          alignItems={{ base: "flex-start", smd: "flex-end" }}
          zIndex="2"
          flexDirection={{ base: "column-reverse", smd: "row" }}
        >
          <Tabs
            authorisations={authorisations}
            editingAuth={editingAuth}
            newAuthorisation={newAuthorisation}
            user={user}
            tab={tab}
            setTab={setTab}
            tab1={"pre-flight"}
            tab2={"risk assessment"}
            tab3={
              showBriefingTab &&
              authorisationData?.status &&
              authorisationData?.status !== "draft" &&
              "briefing"
            }
            tab4={
              authorisationData?.status &&
              authorisationData?.status !== "draft" &&
              "approval"
            }
            tab5={
              !isApprover &&
              (authorisationData?.status === "approved" ||
                authorisationData?.status === "completed") &&
              "post-flight"
            }
          />
        </Flex>

        <Flex
          zIndex="1"
          width="100%"
          height={{
            base: "auto",
            lg: "calc(100% - 40px)",
          }}
          minHeight={{
            base: "auto",
            lg: "calc(100% - 40px)",
          }}
          bg="white"
          borderRadius={{
            base: "0px",
            smd: "15px",
          }}
          borderTopLeftRadius={{
            base: "0px",
            smd: tab === "pre-flight" ? "0px" : "15px",
          }}
          boxShadow={{
            base: "none",
            smd: "0px 10px 40px -15px rgba(10, 20, 80, 0.4)",
          }}
        >
          <Flex flexDirection="column" width="100%">
            <Flex
              pl="17px"
              pr="13px"
              justifyContent={{ base: "center", smd: "space-between" }}
              alignItems="center"
              width="100%"
              height={{ base: "80px", smd: "48px" }}
              minHeight={{ base: "80px", smd: "48px" }}
              borderBottom="1px solid"
              borderColor="#e5e7ea"
              fontSize="1rem"
              gap="5px"
              flexDirection={{ base: "column", smd: "row" }}
            >
              <Flex
                width={{ base: "100%", smd: "auto" }}
                justifyContent="flex-start"
                alignItems="center"
                gap="5px"
              >
                <Text
                  color={
                    user === "hq"
                      ? tab === "pre-flight"
                        ? "#ff0000"
                        : tab === "risk assessment"
                        ? "#ffb300"
                        : tab === "briefing"
                        ? "#faf001"
                        : tab === "approval"
                        ? "#5700fb"
                        : tab === "post-flight"
                        ? "#cb00fb"
                        : "brand.100"
                      : "brand.100"
                  }
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {authorisationData && tab === "pre-flight"
                    ? user === "hq"
                      ? "Proposed"
                      : "Edit"
                    : authorisationData && tab === "risk assessment"
                    ? user === "hq"
                      ? "Scoping"
                      : "Evaluate"
                    : authorisationData && tab === "briefing"
                    ? user === "hq"
                      ? "Accepted"
                      : "Prepare"
                    : authorisationData && tab === "approval"
                    ? user === "hq"
                      ? "Authorise"
                      : "Approve"
                    : authorisationData && tab === "post-flight"
                    ? "Complete"
                    : "New"}
                </Text>
                <Text
                  color="gray.700"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {user === "hq" ? "Tasking" : "Flight Authorisation"}
                </Text>
              </Flex>
              <Flex
                gap={{ base: "20px", md: "40px" }}
                justifyContent="space-between"
                alignItems="center"
                fontSize="0.75rem"
                width={{ base: "100%", smd: "auto" }}
              >
                <Flex
                  gap="10px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text color="gray.400" fontWeight="400">
                    Flight ID:
                  </Text>
                  <Text color="gray.600" fontWeight="600">
                    {authorisationData?.id
                      ? `${
                          squadronSelection === "40 SQN/CFS" ? "40" : "14"
                        }SQN-${
                          authorisationData.id > 99
                            ? ""
                            : authorisationData.id > 9
                            ? "0"
                            : "00"
                        }${authorisationData.id}`
                      : "TBC"}
                  </Text>
                </Flex>
                <Flex
                  gap="10px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text color="gray.400" fontWeight="400">
                    Status:
                  </Text>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    color={
                      authorisationData?.status === "approved" ||
                      authorisationData?.status === "completed" ||
                      authorisationData?.status === "declined"
                        ? "white"
                        : "gray.600"
                    }
                    letterSpacing={"0.5px"}
                    fontWeight="600"
                    textTransform="uppercase"
                    width="100px"
                    height="24px"
                    fontSize="0.75rem"
                    borderRadius="7px"
                    background={
                      authorisationData?.status === "approved"
                        ? "#00fb00"
                        : authorisationData?.status === "pending"
                        ? "yellow"
                        : authorisationData?.status === "completed"
                        ? "#00E0FF"
                        : authorisationData?.status === "declined"
                        ? "#ff1b1b"
                        : "gray.100"
                    }
                  >
                    {authorisationData?.status
                      ? authorisationData?.status
                      : "Draft"}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              flexDirection="column"
              zIndex="1"
              width="100%"
              height="100%"
              background="#FBFDFF"
              overflowY={{ base: "hidden", lg: "auto" }}
            >
              <form
                id="authorisationForm"
                // onSubmit={handleSubmit(onSubmit)}
                onSubmit={(e) => {
                  // clear previous error on submit
                  submitError && setSubmitError(null);
                  return handleSubmit(handleSave)(e).catch((err) => {
                    console.log(err);
                    setSubmitError(true);
                  });
                }}
                style={{ height: "100%" }}
                noValidate
              >
                <>
                  {tab === "pre-flight" && (
                    <PreFlightTab
                      authorisationData={authorisationData}
                      newAuthorisation={newAuthorisation}
                      editAuthorisation={editAuthorisation}
                      airframeSerials={airframeSerials}
                      register={register}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      timeZone={timeZone}
                      selectStyle={selectStyle}
                      setSquadronSelection={setSquadronSelection}
                      isApprover={isApprover}
                    />
                  )}

                  {tab === "risk assessment" && (
                    <RiskAssessmentTab
                      authorisationData={authorisationData}
                      newAuthorisation={newAuthorisation}
                      editAuthorisation={editAuthorisation}
                      airframeSerials={airframeSerials}
                      register={register}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      timeZone={timeZone}
                      tab={tab}
                      riskItems={riskItems}
                      setRiskItems={setRiskItems}
                      selectStyle={selectStyle}
                      squadronSelection={squadronSelection}
                      isApprover={isApprover}
                    />
                  )}

                  {tab === "briefing" && (
                    <BriefingTab
                      authorisationData={authorisationData}
                      newAuthorisation={newAuthorisation}
                      editAuthorisation={editAuthorisation}
                      airframeSerials={airframeSerials}
                      aircrewData={aircrewData}
                      register={register}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      timeZone={timeZone}
                      tab={tab}
                      riskItems={riskItems}
                      setRiskItems={setRiskItems}
                      selectStyle={selectStyle}
                      squadronSelection={squadronSelection}
                    />
                  )}

                  {tab === "approval" && (
                    <ApprovalTab
                      authorisationData={authorisationData}
                      newAuthorisation={newAuthorisation}
                      editAuthorisation={editAuthorisation}
                      airframeSerials={airframeSerials}
                      register={register}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      timeZone={timeZone}
                      selectStyle={selectStyle}
                      isApprover={isApprover}
                    />
                  )}

                  {!isApprover && tab === "post-flight" && (
                    <PostFlightTab
                      authorisationData={authorisationData}
                      newAuthorisation={newAuthorisation}
                      editAuthorisation={editAuthorisation}
                      airframeSerials={airframeSerials}
                      aircrewData={aircrewData}
                      register={register}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      timeZone={timeZone}
                      tab={tab}
                      riskItems={riskItems}
                      setRiskItems={setRiskItems}
                      selectStyle={selectStyle}
                    />
                  )}

                  <Portal containerRef={footerPlacement}>
                    <Flex gap={{ base: "15px", lg: "20px" }}>
                      {!closeDelay && (
                        <ButtonGroup
                          size="sm"
                          variant="outline"
                          gap={{ base: "5px", lg: "5px" }}
                          flexWrap="wrap"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            {...noFocus}
                            borderRadius="25px"
                            variant="solid"
                            size="sm"
                            bg="white"
                            color="gray.500"
                            width={{ base: "60px", lg: "60px" }}
                            height="28px"
                            fontSize="0.8rem"
                            justifyContent="center"
                            alignItems="center"
                            lineHeight="1.4"
                            _hover={{
                              background: "white",
                              color: "brand.200",
                            }}
                            display={
                              tab === "post-flight" &&
                              (authorisationData?.status === "completed" ||
                                authorisationData?.status === "deleted")
                                ? "none"
                                : "initial"
                            }
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Button>

                          {tab !== "pre-flight" && (
                            <Button
                              {...noFocus}
                              borderRadius="25px"
                              variant="outline"
                              size="sm"
                              bg="gray.50"
                              color="gray.500"
                              width={{ base: "85px", lg: "120px" }}
                              height="28px"
                              fontSize="0.8rem"
                              justifyContent="center"
                              alignItems="center"
                              lineHeight="1.4"
                              _hover={{
                                background: "gray.100",
                                color: "gray.600",
                              }}
                              onClick={() => {
                                if (tab === "risk assessment") {
                                  setTab("pre-flight");
                                } else if (tab === "briefing") {
                                  setTab("risk assessment");
                                } else if (
                                  user === "hq" &&
                                  tab === "approval"
                                ) {
                                  setTab("briefing");
                                } else if (tab === "approval") {
                                  setTab("risk assessment");
                                } else if (tab === "post-flight") {
                                  setTab("approval");
                                }
                              }}
                            >
                              Back
                            </Button>
                          )}
                          {(tab === "pre-flight" ||
                            (tab === "risk assessment" &&
                              ((isApprover &&
                                authorisationData?.status === "draft") ||
                                authorisationData?.status === "pending" ||
                                authorisationData?.status === "approved" ||
                                authorisationData?.status === "completed" ||
                                authorisationData?.status === "declined")) ||
                            (user === "hq" &&
                              tab === "briefing" &&
                              (authorisationData?.status === "pending" ||
                                authorisationData?.status === "approved" ||
                                authorisationData?.status === "completed" ||
                                authorisationData?.status === "declined")) ||
                            (tab === "approval" &&
                              (authorisationData?.status === "approved" ||
                                authorisationData?.status === "completed")) ||
                            (tab === "post-flight" &&
                              authorisationData?.status === "completed")) && (
                            <Button
                              {...noFocus}
                              onClick={() => {
                                if (tab === "pre-flight") {
                                  setTab("risk assessment");
                                }
                                if (
                                  user === "hq" &&
                                  tab === "risk assessment"
                                ) {
                                  setTab("briefing");
                                } else if (tab === "risk assessment") {
                                  setTab("approval");
                                }
                                if (tab === "briefing") {
                                  setTab("approval");
                                }
                                if (
                                  (isApprover &&
                                    tab === "risk assessment" &&
                                    authorisationData?.status === "draft") ||
                                  (isApprover &&
                                    tab === "approval" &&
                                    (authorisationData?.status === "approved" ||
                                      authorisationData?.status ===
                                        "completed"))
                                ) {
                                  handleClose();
                                }
                                if (tab === "approval") {
                                  setTab("post-flight");
                                }
                                if (tab === "post-flight") {
                                  handleClose();
                                }
                              }}
                              borderRadius="25px"
                              variant="outline"
                              size="sm"
                              bg="gray.500"
                              color="white"
                              borderColor="gray.600"
                              width={{ base: "85px", lg: "120px" }}
                              height="28px"
                              fontSize="0.8rem"
                              justifyContent="center"
                              alignItems="center"
                              lineHeight="1.4"
                              _hover={{
                                background: "gray.600",
                              }}
                              _focus={{ background: "gray.600" }}
                              _active={{ background: "gray.600" }}
                            >
                              {(isApprover &&
                                tab === "risk assessment" &&
                                authorisationData?.status === "draft") ||
                              (isApprover &&
                                tab === "approval" &&
                                (authorisationData?.status === "approved" ||
                                  authorisationData?.status === "completed")) ||
                              (tab === "post-flight" &&
                                authorisationData?.status === "completed")
                                ? "Close"
                                : "Next"}
                            </Button>
                          )}

                          {!isApprover &&
                            ((tab === "risk assessment" &&
                              (!authorisationData?.status ||
                                authorisationData?.status === "draft" ||
                                authorisationData?.status === "pending")) ||
                              (user === "hq" &&
                                tab === "briefing" &&
                                (!authorisationData?.status ||
                                  authorisationData?.status === "draft" ||
                                  authorisationData?.status === "pending")) ||
                              (tab === "approval" &&
                                !(
                                  authorisationData?.status === "pending" ||
                                  authorisationData?.status === "approved" ||
                                  authorisationData?.status === "completed" ||
                                  authorisationData?.status === "deleted"
                                )) ||
                              override) && (
                              <Button
                                {...noFocus}
                                isLoading={isSubmitting}
                                form="authorisationForm"
                                type="submit"
                                borderRadius="25px"
                                variant="outline"
                                size="sm"
                                bg="gray.500"
                                color="white"
                                borderColor="gray.600"
                                width={{ base: "85px", lg: "120px" }}
                                height="28px"
                                fontSize="0.8rem"
                                justifyContent="center"
                                alignItems="center"
                                lineHeight="1.4"
                                _hover={{
                                  background: "gray.600",
                                }}
                                _focus={{ background: "gray.600" }}
                                _active={{ background: "gray.600" }}
                                onClick={() => {
                                  setSaveAsDraft(true);
                                  if (
                                    authorisationData &&
                                    tab === "risk-assessment"
                                  ) {
                                    setValue("status", "draft");
                                  }
                                }}
                              >
                                {tab === "risk assessment" &&
                                authorisationData?.status !== "pending"
                                  ? "Save Draft "
                                  : "Save"}
                              </Button>
                            )}

                          {tab !== "pre-flight" &&
                            ((tab === "risk assessment" &&
                              !isApprover &&
                              authorisationData?.status !== "pending" &&
                              authorisationData?.status !== "approved" &&
                              authorisationData?.status !== "completed" &&
                              authorisationData?.status !== "deleted") ||
                              (tab === "approval" &&
                                !(
                                  authorisationData?.status === "approved" ||
                                  authorisationData?.status === "completed" ||
                                  authorisationData?.status === "deleted"
                                )) ||
                              (tab === "post-flight" &&
                                authorisationData?.status !== "completed" &&
                                authorisationData?.status !== "deleted")) && (
                              <Button
                                {...noFocus}
                                isLoading={isSubmitting}
                                form="authorisationForm"
                                type="submit"
                                borderRadius="25px"
                                variant="outline"
                                size="sm"
                                bg="brand.100"
                                color="white"
                                borderColor="brand.200"
                                width={{ base: "85px", lg: "120px" }}
                                height="28px"
                                fontSize="0.8rem"
                                justifyContent="center"
                                alignItems="center"
                                lineHeight="1.4"
                                _hover={{
                                  background: "brand.200",
                                }}
                                _focus={{ background: "brand.200" }}
                                _active={{ background: "brand.200" }}
                                onClick={() => {
                                  setSaveAsDraft(false);
                                  setValue(
                                    "status",
                                    authorisationData && tab === "post-flight"
                                      ? "completed"
                                      : isApprover &&
                                        authorisationData &&
                                        tab === "approval"
                                      ? "approved"
                                      : "pending"
                                  );
                                }}
                              >
                                {isApprover &&
                                authorisationData &&
                                tab === "approval"
                                  ? "Approve"
                                  : authorisationData && tab === "post-flight"
                                  ? "Complete"
                                  : authorisationData?.status !== "draft" &&
                                    authorisationData?.status
                                  ? "Update"
                                  : "Submit"}
                              </Button>
                            )}

                          {isApprover &&
                            tab === "approval" &&
                            authorisationData?.status === "pending" && (
                              <Button
                                {...noFocus}
                                isLoading={isSubmitting}
                                form="authorisationForm"
                                type="submit"
                                borderRadius="25px"
                                variant="outline"
                                size="sm"
                                bg="red.500"
                                color="white"
                                borderColor="red.500"
                                width={{ base: "85px", lg: "120px" }}
                                height="28px"
                                fontSize="0.8rem"
                                justifyContent="center"
                                alignItems="center"
                                lineHeight="1.4"
                                _hover={{
                                  background: "red.600",
                                }}
                                _focus={{ background: "red.600" }}
                                _active={{ background: "red.600" }}
                                onClick={() => {
                                  setDeclined(true);
                                  setSaveAsDraft(false);
                                  setValue("status", "declined");
                                }}
                              >
                                {"Decline"}
                              </Button>
                            )}
                        </ButtonGroup>
                      )}
                      {updateSuccessful && (
                        <Flex
                          color={declined ? "red.400" : "brand.100"}
                          width="100%"
                          justifyContent={{
                            base: editAuthorisation ? "flex-end" : "flex-end",
                            md: "flex-end",
                          }}
                          alignItems="center"
                          flexDirection="row"
                          fontWeight="500"
                          fontSize="0.85rem"
                          mr={{ base: "0", md: "10px" }}
                        >
                          <Text>
                            {declined
                              ? "Declined"
                              : editAuthorisation
                              ? "Updated"
                              : "Submitted"}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Portal>
                </>
              </form>
            </Flex>
            <Flex
              pl="17px"
              pr="17px"
              pb={{ base: "20px", smd: "0px" }}
              gap="15px"
              justifyContent={{ base: "center", lg: "space-between" }}
              alignItems="center"
              flexDirection={{ base: "column", lg: "row" }}
              width="100%"
              height={{ base: "130px", smd: "110px", lg: "48px" }}
              minHeight={{ base: "130px", smd: "110px", lg: "48px" }}
              borderTop="1px solid"
              borderColor="#e5e7ea"
              fontSize="0.95rem"
            >
              <Flex gap="20px" alignItems="center" justifyContent="flex-start">
                <Popover placement={popoverPlacement} closeOnBlur={true}>
                  <PopoverTrigger>
                    <Flex
                      {...noFocus}
                      borderRadius="25px"
                      variant="outline"
                      size="sm"
                      bg="white"
                      cursor="pointer"
                      border="1px solid"
                      borderColor="#e2e8f0"
                      color="gray.500"
                      width="110px"
                      height="28px"
                      fontWeight="500"
                      fontSize="0.75rem"
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.4"
                      _hover={{
                        background: "gray.100",
                        color: "gray.600",
                      }}
                    >
                      Import / Export
                    </Flex>
                  </PopoverTrigger>
                  <PopoverContent
                    color="white"
                    bg="blue.800"
                    borderColor="blue.800"
                    px="5px"
                    borderRadius="15px"
                  >
                    <PopoverHeader
                      pt={4}
                      fontWeight="bold"
                      fontSize="0.9rem"
                      border="0"
                    >
                      Import / Export Authorisation
                    </PopoverHeader>
                    <PopoverArrow bg="blue.800" />
                    <PopoverCloseButton cursor="pointer" />
                    <PopoverBody fontSize="0.75rem">
                      Import from or, Export to, third party applications, i.e.
                      ForeFlight
                    </PopoverBody>
                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pb={5}
                      gap="20px"
                    >
                      <Button
                        width="100%"
                        // onClick={handleDataUpdate}
                        colorScheme="gray"
                        size="xs"
                        borderRadius="25px"
                      >
                        Import
                      </Button>
                      <Link
                        href={FlightAuthPDF}
                        isExternal
                        width="100%"
                        height="24px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        background="gray.100"
                        color="gray.800"
                        _hover={{ background: "gray.200" }}
                        fontSize="xs"
                        fontWeight="600"
                        borderRadius="25px"
                      >
                        Export
                      </Link>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Flex
                width="100%"
                justifyContent={{ base: "center", lg: "flex-end" }}
                alignItems="center"
                fontSize="0.75rem"
              >
                <Box ref={footerPlacement}></Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
