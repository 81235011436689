import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { aircraftSystemsData } from "./data";

export default function AircraftSystems() {
  const StatusBar = ({ aircraft, issue, date, color, expired }) => {
    return (
      <Flex
        width="100%"
        flexDirection="row"
        px="6px"
        py="5px"
        borderRadius="15px"
        border="1px solid"
        borderColor="#e5e5e5"
        minHeight="32px"
        justifyContent="center"
        alignItems="flex-start"
        gap="5px"
        bg="#F7FAFC"
        cursor="pointer"
        transition="all ease 150ms"
        _hover={{
          bg: "#FDFEFF",
          boxShadow: "0px 3px 15px -4px rgba(0, 45, 78, 0.2)",
        }}
      >
        <Flex
          width={{ base: "18px", lg: "20px" }}
          minWidth={{ base: "18px", lg: "20px" }}
          minHeight={{ base: "18px", lg: "20px" }}
          borderRadius="50px"
          justifyContent="center"
          alignItems="center"
          bg={expired ? "#F7FAFC" : "F7FAFC"}
          border={expired ? "2px solid #FF1414" : "none"}
          mr="10px"
        >
          <Flex
            width={{
              base: expired ? "10px" : "12px",
              lg: expired ? "12px" : "14px",
            }}
            minWidth={{
              base: expired ? "10px" : "12px",
              lg: expired ? "12px" : "14px",
            }}
            height={{
              base: expired ? "10px" : "12px",
              lg: expired ? "12px" : "14px",
            }}
            borderRadius="50px"
            bg={color}
          ></Flex>
        </Flex>
        <Flex
          width="100%"
          gap="5px"
          minHeight={{ base: "16px", lg: "18px" }}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          pt="1px"
        >
          <Text
            minWidth="50px"
            textAlign="left"
            color="gray.600"
            fontWeight="600"
          >
            {aircraft}
          </Text>
          <Text
            fontWeight={expired ? "600" : "500"}
            color={
              expired ? "#FF1414" : color === "#11EC4E" ? "#718096" : "#FDAC30"
            }
            width="100%"
          >
            {issue}
          </Text>
          <Text
            fontSize={{ base: "0.65rem", lg: "0.7rem" }}
            fontWeight={expired ? "500" : "400"}
            color="gray.400"
            width="100%"
            textAlign="right"
            pr="5px"
            pt="1px"
          >
            {date}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      borderRadius="10px"
      border="1px solid"
      borderColor="#e5e5e5"
      bg="white"
      boxShadow="0px 10px 40px -15px rgba(0, 45, 78, 0.15)"
    >
      <Flex
        minHeight="44px"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        width="100%"
        color="light.100"
        borderBottom="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        fontWeight="700"
        textTransform="uppercase"
      >
        Aircraft and Systems Notifications
      </Flex>
      <Flex
        gap="10px"
        width="100%"
        flexDirection="column"
        pt="20px"
        pb="25px"
        px="15px"
      >
        {aircraftSystemsData.map((item, index) => (
          <StatusBar key={index} {...item} />
        ))}
      </Flex>
    </Flex>
  );
}
