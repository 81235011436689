import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as ActivityImage } from "../Assets/activity.svg";

export default function UpcomingActivity() {
  return (
    <Flex
      width="100%"
      flexDirection="column"
      borderRadius="10px"
      border="1px solid"
      borderColor="#e5e5e5"
      bg="white"
      boxShadow="0px 10px 40px -15px rgba(0, 45, 78, 0.15)"
    >
      <Flex
        minHeight="44px"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        width="100%"
        color="light.100"
        borderBottom="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        fontWeight="700"
        textTransform="uppercase"
      >
        Upcoming Unit Activity
      </Flex>
      <Flex
        gap="5px"
        width="100%"
        flexDirection="row"
        py="10px"
        px="10px"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          gap="5px"
          width="100%"
          height="100%"
          minWidth="40px"
          flexDirection="column"
          py="10px"
          px="10px"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          {/* <Switch id="activity" colorScheme="cyan" /> */}
        </Flex>
        <Icon
          as={ActivityImage}
          width="100%"
          height="auto"
          px="10px"
          ml="-50px"
        />
      </Flex>
      {/* <Flex
        height="44px"
        px="15px"
        width="100%"
        borderTop="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        justifyContent="space-between"
        textTransform="uppercase"
      >
        <Text fontSize="0.7rem" color="gray.500" fontWeight="600">
          Individuals
        </Text>
        <Flex
          px="15px"
          height="24px"
          color="gray.500"
          fontSize="0.65rem"
          fontWeight="600"
          borderRadius="25px"
          bg="gray.50"
          border="1px solid"
          borderColor="#e5e5e5"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          _hover={{ bg: "gray.100" }}
        >
          OPTIONS
        </Flex>
      </Flex> */}
    </Flex>
  );
}
