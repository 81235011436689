import React, { useEffect } from "react";
import AircrewBoard from "./Board";
import { ButtonGroup, Button, Flex, Icon } from "@chakra-ui/react";
import Tabs from "../Menu/Tabs";
import { TbZoomOutArea, TbZoomInArea } from "react-icons/tb";

export default function Aircrew(props) {
  const { zoomLevel, setZoomLevel } = props;
  useEffect(() => {
    document.title = "Aircrew Bookings // TASKR";
  }, []);

  return (
    <Flex
      margin={0}
      px={{ base: "10px", sm: "15px", lg: "15px" }}
      pt={{ base: "15px", smd: "10px", lg: "15px" }}
      pb={{ base: "110px", lg: "15px" }}
      flex={1}
      flexDirection="column"
      overflowY="auto"
      zIndex="1"
      width="100%"
      height="calc(100% - 40px)"
      minHeight={{
        base: zoomLevel === 0 ? "350px" : zoomLevel === 1 ? "650px" : "900px",
        lg: "calc(100% - 40px)",
      }}
    >
      <Flex
        position="absolute"
        marginTop={{ base: "78px", lg: "38px" }}
        background="gray.200"
        height="60px"
        width="50px"
      ></Flex>
      <Flex
        width="100%"
        height={{ base: "76px", lg: "40px" }}
        minHeight={{ base: "76px", lg: "40px" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", lg: "flex-end" }}
        zIndex="2"
        flexDirection={{ base: "column-reverse", lg: "row" }}
      >
        <Tabs tab1={"airframe"} tab2={"aircrew"} tab3={"area"} />

        <ButtonGroup
          size="sm"
          isAttached={true}
          variant="outline"
          mb="10px"
          marginLeft={{ base: "auto", lg: "0px" }}
        >
          <Button
            borderTopLeftRadius="20px"
            borderBottomLeftRadius="20px"
            onClick={() => {
              if (zoomLevel > 0) {
                setZoomLevel(zoomLevel - 1);
              }
            }}
            isDisabled={zoomLevel === 0}
            variant="outline"
            size="sm"
            bg="gray.50"
            width="60px"
            height="30px"
            _hover={{
              background: "white",
            }}
          >
            <Icon
              as={TbZoomOutArea}
              color="gray.600"
              width="20px"
              height="auto"
            />
          </Button>
          <Button
            borderTopRightRadius="20px"
            borderBottomRightRadius="20px"
            onClick={() => {
              if (zoomLevel < 2) {
                setZoomLevel(zoomLevel + 1);
              }
            }}
            isDisabled={zoomLevel === 2}
            variant="outline"
            size="sm"
            bg="gray.50"
            width="60px"
            height="30px"
            _hover={{
              background: "white",
            }}
          >
            <Icon
              as={TbZoomInArea}
              color="gray.600"
              width="20px"
              height="auto"
            />
          </Button>
        </ButtonGroup>
      </Flex>

      <Flex
        zIndex="1"
        width="100%"
        height="calc(100% - 40px)"
        minHeight={{
          base: zoomLevel === 0 ? "350px" : zoomLevel === 1 ? "650px" : "900px",
          lg: "calc(100% - 40px)",
        }}
      >
        <AircrewBoard zoomLevel={zoomLevel} />
      </Flex>
    </Flex>
  );
}
