import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAuth0 } from "@auth0/auth0-react";
import { usePubNub } from "pubnub-react";
import { useApproverRole } from "../Auth/Hooks";
import {
  Flex,
  Icon,
  Collapse,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useBreakpointValue,
  Badge,
  Tooltip,
  Text,
  Link,
  Box,
  keyframes,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../Assets/logo.svg";
import { ReactComponent as Search } from "../Assets/search.svg";
import { ReactComponent as Alerts } from "../Assets/alerts.svg";
import { ReactComponent as AlertsDot } from "../Assets/alerts-notification.svg";
import { ReactComponent as Clipboard } from "../Assets/clipboard.svg";
import { ReactComponent as Info } from "../Assets/info.svg";
import { ReactComponent as Settings } from "../Assets/settings.svg";
import { ReactComponent as Profile } from "../Assets/profile.svg";
import { ReactComponent as Hamburger } from "../Assets/hamburger.svg";
import { ReactComponent as Mobile } from "../Assets/mobile.svg";
import { ReactComponent as MobileAlert } from "../Assets/mobile-alert.svg";
import { RiLogoutCircleLine } from "react-icons/ri";
import SitRepPDF from "../Assets/pdf/FOMS-Demo-SITREP-Report.pdf";
// import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { CgClose } from "react-icons/cg";
import { format } from "date-fns";
import {
  userState,
  notificationsState,
  notificationModalState,
} from "../globalState";

const HeaderMenu = (props) => {
  const { homepage, showHeaderMenu, setShowHeaderMenu, navigate } = props;
  const popoverPlacement = useBreakpointValue({
    base: "auto",
    lg: "bottom",
  });
  return (
    <Flex
      px={{ base: "20px", lg: "0px" }}
      width={{ base: "100%", lg: "auto" }}
      height={{ base: "66px", lg: "auto" }}
      gap={{ base: "5px", lg: "10px" }}
      textTransform={"uppercase"}
      color="#EAEAEA"
      fontSize="0.8rem"
      justifyContent={{ base: "space-around", lg: "flex-start" }}
      alignItems="center"
      backgroundColor="#0D3856"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        px="15px"
        height="28px"
        borderRadius="30px"
        cursor="pointer"
        background={homepage ? "#235F88" : "#0D3856"}
        fontWeight="400"
        fontSize="0.85rem"
        // boxShadow="0px 2px 15px -3px rgba(5, 30, 50, 0.7)"
        _hover={{
          background: "#235F88",
          color: "#FFFFFF",
          transform: "scale(1.02) translateY(-1px)",
          boxShadow: "0px 2px 15px -3px rgba(5, 30, 50, 0.7)",
          textShadow: "0px 2px 5px rgba(5, 30, 50, 0.5)",
        }}
        onClick={() => {
          navigate("/");
          if (showHeaderMenu) {
            setShowHeaderMenu(false);
          }
        }}
      >
        Dashboard
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        px="15px"
        height="28px"
        borderRadius="30px"
        cursor="pointer"
        background="#0D3856"
        fontWeight="400"
        fontSize="0.85rem"
        // boxShadow="0px 2px 15px -3px rgba(5, 30, 50, 0.7)"
        _hover={{
          background: "#235F88",
          color: "#FFFFFF",
          transform: "scale(1.02) translateY(-1px)",
          boxShadow: "0px 2px 15px -3px rgba(5, 30, 50, 0.7)",
          textShadow: "0px 2px 5px rgba(5, 30, 50, 0.5)",
        }}
        onClick={() => {
          navigate("/");
          if (showHeaderMenu) {
            setShowHeaderMenu(false);
          }
        }}
      >
        Events
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        px="15px"
        height="28px"
        borderRadius="30px"
        cursor="pointer"
        background="#0D3856"
        fontWeight="400"
        fontSize="0.85rem"
        // boxShadow="0px 2px 15px -3px rgba(5, 30, 50, 0.7)"
        _hover={{
          background: "#235F88",
          color: "#FFFFFF",
          transform: "scale(1.02) translateY(-1px)",
          boxShadow: "0px 2px 15px -3px rgba(5, 30, 50, 0.7)",
          textShadow: "0px 2px 5px rgba(5, 30, 50, 0.5)",
        }}
        onClick={() => {
          navigate("/");
          if (showHeaderMenu) {
            setShowHeaderMenu(false);
          }
        }}
      >
        Actions
      </Flex>
      <Popover placement={popoverPlacement} closeOnBlur={true}>
        <PopoverTrigger>
          <Flex
            justifyContent="center"
            alignItems="center"
            px="15px"
            height="28px"
            borderRadius="30px"
            cursor="pointer"
            background="#0D3856"
            fontWeight="400"
            fontSize="0.85rem"
            // boxShadow="0px 2px 15px -3px rgba(5, 30, 50, 0.7)"
            _hover={{
              background: "#235F88",
              color: "#FFFFFF",
              transform: "scale(1.02) translateY(-1px)",
              boxShadow: "0px 2px 15px -3px rgba(5, 30, 50, 0.7)",
              textShadow: "0px 2px 5px rgba(5, 30, 50, 0.5)",
            }}
          >
            Reports
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          color="white"
          bg="blue.800"
          borderRadius="15px"
          borderColor="blue.800"
          mr="20px"
          width="240px"
        >
          <PopoverHeader
            pt={4}
            fontWeight="bold"
            fontSize="0.8rem"
            border="0"
          ></PopoverHeader>
          <PopoverArrow bg="blue.800" ml="10px" />
          <PopoverCloseButton cursor="pointer" />
          <PopoverBody
            fontSize="0.8rem"
            textTransform="initial"
            pt="10px"
            pb="15px"
          >
            <Link
              href={SitRepPDF}
              isExternal
              width="100%"
              height="32px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              background="gray.100"
              color="gray.800"
              _hover={{ background: "gray.200" }}
              fontSize="0.9rem"
              fontWeight="600"
              borderRadius="25px"
            >
              SITREP Report
            </Link>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

const HeaderTools = (props) => {
  const { handleDataUpdate, notifications, handleRefetch } = props;
  const popoverPlacement = useBreakpointValue({
    base: "auto",
    lg: "bottom",
  });

  const [user, setUser] = useRecoilState(userState);
  const [showNotificationModal, setShowNotificationModal] = useRecoilState(
    notificationModalState
  );
  const isApprover = useApproverRole();
  const { logout } = useAuth0();

  const handleUser = () => {
    if (user === "ops") {
      setUser("hq");
    }
    if (user === "hq") {
      setUser("ops");
    }
  };

  //BUTTON PULSE
  const pulsate = keyframes`
    0% {-webkit-transform: scale(0.8, 0.8); opacity: 0.0; }
    30% {opacity: 0.4; }
    60% {opacity: 0.15; }
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;  }`;

  const pulseAnimation = `${pulsate} infinite 2s linear`;

  return (
    <Flex
      backgroundColor="#0D3856"
      px={{ base: "30px", lg: "0px" }}
      width={{ base: "100%", lg: "auto" }}
      textTransform={"uppercase"}
      color="#EAEAEA"
      fontSize="0.8rem"
      alignItems="center"
      minHeight={{ base: "66px", lg: "auto" }}
      gap="26px"
      justifyContent={{ base: "space-evenly", lg: "center" }}
      flexDirection="row"
    >
      <Tooltip
        hasArrow
        placement="bottom"
        label="Search"
        bg="gray.600"
        color="white"
        borderRadius="5px"
        fontSize="0.7rem"
      >
        <Icon
          as={Search}
          width="24px"
          height="auto"
          cursor="pointer"
          _hover={{
            color: "#FFFFFF",
            transform: "scale(1.02) translateY(-1px)",
          }}
        />
      </Tooltip>

      <Tooltip
        hasArrow
        placement="bottom"
        label={`${
          notifications && notifications.length > 0
            ? notifications.length
            : "No"
        } Notification${notifications.length > 1 ? "s" : ""}`}
        bg="gray.600"
        color="white"
        borderRadius="5px"
        fontSize="0.7rem"
      >
        <Flex
          cursor="pointer"
          _hover={{
            color: "#FFFFFF",
            transform: "scale(1.02) translateY(-1px)",
          }}
          onClick={() => setShowNotificationModal(true)}
        >
          <Icon
            as={
              showNotificationModal && notifications && notifications.length > 0
                ? AlertsDot
                : Alerts
            }
            zIndex="1"
            width="24px"
            height="auto"
            cursor="pointer"
          />
          {notifications && notifications.length > 0 && (
            <Flex zIndex="2">
              <Badge
                opacity={showNotificationModal ? "0" : "1"}
                transition="all ease 200ms"
                zIndex="2"
                variant="solid"
                bg="#7cff00"
                color="#0D3856"
                height="16px"
                minWidth="16px"
                ml="-11px"
                mr="-4px"
                px="2px"
                marginTop="-1px"
                borderRadius="25px"
                fontSize="0.65rem"
                display="flex"
                alignItems="center"
                fontWeight="700"
                justifyContent="center"
                cursor="pointer"
                border="2px solid"
                borderColor="#0D3856"
                fontFamily="'Open Sans',sans-serif"
              >
                {notifications.length}
              </Badge>
              {!showNotificationModal && (
                <Box // PULSE
                  zIndex="1"
                  marginLeft="-16px"
                  marginRight="-8px"
                  marginTop="-5px"
                  w="24px"
                  h="24px"
                  borderRadius="100%"
                  background="#7cff00"
                  sx={{
                    animationDuration: "1500ms",
                    // animationIterationCount: '1',
                    animationDelay: "0s",
                  }}
                  opacity={{
                    base: pulseAnimation ? "0" : "1",
                    md: pulseAnimation ? "0" : "1",
                  }}
                  transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 200ms"
                  animation={pulseAnimation}
                ></Box>
              )}
            </Flex>
          )}
        </Flex>
      </Tooltip>

      <Tooltip
        hasArrow
        placement="bottom"
        label="Clipboard"
        bg="gray.600"
        color="white"
        borderRadius="5px"
        fontSize="0.7rem"
      >
        <Icon
          as={Clipboard}
          width="24px"
          height="auto"
          cursor="pointer"
          _hover={{
            color: "#FFFFFF",
            transform: "scale(1.02) translateY(-1px)",
          }}
        />
      </Tooltip>

      <Tooltip
        hasArrow
        placement="bottom"
        label="Info / Help"
        bg="gray.600"
        color="white"
        borderRadius="5px"
        fontSize="0.7rem"
      >
        <Icon
          as={Info}
          width="24px"
          height="auto"
          cursor="pointer"
          _hover={{
            color: "#FFFFFF",
            transform: "scale(1.02) translateY(-1px)",
          }}
          onClick={() => handleRefetch()}
        />
      </Tooltip>

      <Popover placement={popoverPlacement} closeOnBlur={true}>
        <PopoverTrigger>
          <Flex>
            <Tooltip
              hasArrow
              placement="bottom"
              label="Settings"
              bg="gray.600"
              color="white"
              borderRadius="5px"
              fontSize="0.7rem"
            >
              <Icon
                as={Settings}
                width="24px"
                height="auto"
                cursor="pointer"
                _hover={{
                  color: "#FFFFFF",
                  transform: "scale(1.02) translateY(-1px)",
                }}
              />
            </Tooltip>
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          color="white"
          bg="blue.800"
          borderRadius="15px"
          borderColor="blue.800"
          mr="20px"
        >
          <PopoverHeader pt={4} fontWeight="bold" fontSize="0.8rem" border="0">
            Update all bookings to current day
          </PopoverHeader>
          <PopoverArrow bg="blue.800" ml="10px" />
          <PopoverCloseButton cursor="pointer" />
          <PopoverBody fontSize="0.8rem" textTransform="initial">
            Caution: this updates all demo bookings in the database and is not
            reversible
          </PopoverBody>
          <PopoverFooter
            border="0"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb={4}
          >
            <Button
              isDisabled
              width="100%"
              onClick={handleDataUpdate}
              colorScheme="cyan"
              size="sm"
              color="white"
              borderRadius="25px"
            >
              Update All Bookings
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <Popover placement={popoverPlacement} closeOnBlur={true}>
        <PopoverTrigger>
          <Flex>
            <Tooltip
              hasArrow
              placement="bottom"
              label="Profile"
              bg="gray.600"
              color="white"
              borderRadius="5px"
              fontSize="0.7rem"
            >
              <Flex
                _hover={{
                  color: "#FFFFFF",
                  transform: "scale(1.02) translateY(-1px)",
                }}
                cursor="pointer"
              >
                <Icon as={Profile} width="24px" height="auto" />
                {isApprover && (
                  <Badge
                    variant="solid"
                    colorScheme="cyan"
                    bg="blue.400"
                    color="rgba(255,255,255,0.8)"
                    height="12px"
                    width="28px"
                    ml="-12px"
                    mr="-12px"
                    marginTop="-3px"
                    borderRadius="25px"
                    fontSize="0.55rem"
                    display="flex"
                    alignItems="center"
                    fontWeight="600"
                    justifyContent="center"
                    cursor="pointer"
                  >
                    FAO
                  </Badge>
                )}
              </Flex>
            </Tooltip>
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          color="white"
          bg="blue.800"
          borderRadius="15px"
          borderColor="blue.800"
          // mr="20px"
          width="200px"
        >
          <PopoverArrow bg="blue.800" />
          <PopoverCloseButton cursor="pointer" />

          <PopoverFooter
            border="0"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pt={8}
            pb={4}
          >
            <Button
              width="100%"
              colorScheme="cyan"
              size="sm"
              color="white"
              borderRadius="25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              onClick={() =>
                logout({
                  returnTo: `${window.location.origin}`,
                })
              }
            >
              <RiLogoutCircleLine fontSize="1rem" />
              <Text>Log Out</Text>
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <Popover placement={popoverPlacement} closeOnBlur={true}>
        <PopoverTrigger>
          <Flex alignItems="center" justifyContent="center">
            <Tooltip
              hasArrow
              placement="bottom"
              label="Selected User (admin only)"
              bg="gray.600"
              color="white"
              borderRadius="5px"
              fontSize="0.7rem"
            >
              <Badge
                mr="-12px"
                variant="outline"
                colorScheme={user === "ops" ? "cyan" : "purple"}
                bg={user === "ops" ? "cyan.900" : "purple.900"}
                color={user === "ops" ? "cyan.200" : "purple.200"}
                height="22px"
                width="62px"
                borderRadius="25px"
                fontSize="0.6rem"
                display="flex"
                alignItems="center"
                fontWeight="600"
                opacity="0.9"
                justifyContent="center"
                cursor="pointer"
                _hover={{
                  opacity: "1",
                  transform: "scale(1.05) translateY(-1px)",
                }}
              >
                {user === "ops" ? "Base Ops" : "Def HQ"}
              </Badge>
            </Tooltip>
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          color="white"
          bg="blue.800"
          borderRadius="15px"
          borderColor="blue.800"
          mr="20px"
          maxWidth="260px"
        >
          <PopoverHeader pt={4} fontWeight="bold" fontSize="0.8rem" border="0">
            Selected User
          </PopoverHeader>
          <PopoverArrow bg="blue.800" ml="10px" />
          <PopoverCloseButton cursor="pointer" />
          <PopoverBody fontSize="0.8rem" textTransform="initial">
            Change the logged in user view
          </PopoverBody>
          <PopoverFooter
            border="0"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb={4}
          >
            <Button
              width="100%"
              onClick={handleUser}
              colorScheme="cyan"
              color="white"
              size="sm"
              borderRadius="25px"
            >
              {user === "ops"
                ? "Switch User to Def HQ"
                : "Switch User to Base Ops"}
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default function Header(props) {
  const {
    homepage,
    handleDataUpdate,
    showHeaderMenu,
    setShowHeaderMenu,
    showHeaderTools,
    setShowHeaderTools,
    showMobileMenu,
    setShowMobileMenu,
    showMobileMenuCrew,
    setShowMobileMenuCrew,
    showMobileMenuFacilitation,
    setShowMobileMenuFacilitation,
    showMobileMenuSafety,
    setShowMobileMenuSafety,
    handleRefetch,
  } = props;
  const navigate = useNavigate();

  const pubnub = usePubNub();
  const isApprover = useApproverRole();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const showNotificationModal = useRecoilValue(notificationModalState);
  const filteredNotifications = notifications
    // .filter((feat) => feat?.squadron === squadronSelection)
    .filter((booking) => booking?.status !== "draft") // exclude draft bookins
    .filter((booking) => booking?.status !== "deleted") // exclude deleted bookings
    .filter(
      (
        booking // if approver only show Pending bookings
      ) => (isApprover ? booking?.status === "pending" : true)
    )
    .filter(
      (
        booking // if pilot exclude pending bookings
      ) => (isApprover ? true : booking?.status !== "pending")
    )
    .filter(
      (
        booking // if pilot exclude completed bookings
      ) => (isApprover ? true : booking?.status !== "completed")
    )
    .map((booking) => ({
      ...booking,
    }));

  useEffect(() => {
    pubnub.addListener({
      message: (event) => {
        const now = new Date();
        const formattedNow = format(now, "yyyy-MM-dd'T'HH:mm:ssxxx");
        const updatedMessage = {
          ...event.message,
          updated: formattedNow,
        };

        // exclude bookings with Draft status
        if (updatedMessage.status !== "draft") {
          // console.log("updating pubnub", updatedMessage);
          setNotifications((prevItems) => {
            const existingIndex = prevItems.findIndex(
              (item) => item.id === updatedMessage.id
            );
            if (existingIndex !== -1) {
              // update if already in notifications
              const updatedItems = [...prevItems];
              updatedItems[existingIndex] = {
                ...updatedMessage,
                updatedState: "updated",
              };
              return updatedItems;
            } else {
              // else if new then add it
              return [
                ...prevItems,
                {
                  ...updatedMessage,
                  updatedState: "new",
                },
              ];
            }
          });
        }
      },
    });
    pubnub.subscribe({ channels: ["approvers"] });
  }, [pubnub, setNotifications]);

  //BUTTON PULSE
  const pulsate = keyframes`
  0% {-webkit-transform: scale(0.8, 0.8); opacity: 0.0; }
  30% {opacity: 0.4; }
  60% {opacity: 0.15; }
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;  }`;

  const pulseAnimation = `${pulsate} infinite 2s linear`;

  useEffect(() => {
    console.log("PubNub Message notifications: ", notifications);
  }, [notifications]);

  return (
    <>
      <Flex
        margin="0"
        padding="0"
        height="50px"
        minHeight="50px"
        width="100%"
        alignItems="center"
        // justifyContent="center"
        backgroundColor="#0D3856"
        boxShadow="0px 10px 20px -5px rgba(10, 20, 30, 0.2)"
        borderBottom="2px solid #FFFFFF"
        zIndex="100"
        position="fixed"
        top="0px"
        left="0px"
      >
        <Flex
          alignSelf="center"
          alignItems="center"
          justifyContent={{ base: "space-between", lg: "center" }}
          width={{ base: "100%", lg: "304px" }}
          px={{ base: "20px", lg: "10px" }}
          height="100%"
          backgroundColor="#235F88"
          cursor="pointer"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            width="50px"
            height="50px"
            cursor="pointer"
            display={{ base: "flex", lg: "none" }}
            onClick={() => {
              if (showHeaderTools) {
                setShowHeaderTools(false);
              }
              if (
                showMobileMenu ||
                showMobileMenuFacilitation ||
                showMobileMenuCrew ||
                showMobileMenuSafety
              ) {
                setShowMobileMenu(false);
                setShowMobileMenuFacilitation(false);
                setShowMobileMenuCrew(false);
                setShowMobileMenuSafety(false);
              }
              setShowHeaderMenu((prevState) => !prevState);
            }}
          >
            <Icon
              as={showHeaderMenu ? CgClose : Hamburger}
              width="26px"
              height="auto"
              color="white"
            />
          </Flex>
          <Logo
            height="28px"
            onClick={() => {
              navigate("/");
              if (showHeaderMenu) {
                setShowHeaderMenu(false);
              }
              if (showHeaderTools) {
                setShowHeaderTools(false);
              }
              if (
                showMobileMenu ||
                showMobileMenuFacilitation ||
                showMobileMenuCrew ||
                showMobileMenuSafety
              ) {
                setShowMobileMenu(false);
                setShowMobileMenuFacilitation(false);
                setShowMobileMenuCrew(false);
                setShowMobileMenuSafety(false);
              }
            }}
            cursor="pointer"
          />
          <Flex
            display={{ base: "flex", lg: "none" }}
            alignItems="center"
            justifyContent="center"
            width="50px"
            height="50px"
            onClick={() => {
              if (showHeaderMenu) {
                setShowHeaderMenu(false);
              }
              if (
                showMobileMenu ||
                showMobileMenuFacilitation ||
                showMobileMenuCrew ||
                showMobileMenuSafety
              ) {
                setShowMobileMenu(false);
                setShowMobileMenuFacilitation(false);
                setShowMobileMenuCrew(false);
                setShowMobileMenuSafety(false);
              }
              setShowHeaderTools((prevState) => !prevState);
            }}
            cursor="pointer"
          >
            <Icon
              ml="5px"
              as={
                showHeaderTools
                  ? CgClose
                  : showNotificationModal &&
                    notifications &&
                    notifications.length > 0
                  ? MobileAlert
                  : Mobile
              }
              width={showHeaderTools ? "26px" : "30px"}
              height="auto"
              color="white"
              display={{ base: "initial", lg: "none" }}
            />
            {!showHeaderTools && notifications && notifications.length > 0 && (
              <Flex zIndex="2">
                <Badge
                  opacity={showNotificationModal ? "0" : "1"}
                  transition="all ease 200ms"
                  zIndex="2"
                  variant="solid"
                  bg="#7cff00"
                  color="#235F88"
                  height="16px"
                  minWidth="16px"
                  ml="-15px"
                  mr="-1px"
                  px="2px"
                  marginTop="-16px"
                  borderRadius="25px"
                  fontSize="0.65rem"
                  display="flex"
                  alignItems="center"
                  fontWeight="700"
                  justifyContent="center"
                  cursor="pointer"
                  border="2px solid"
                  borderColor="#235F88"
                  fontFamily="'Open Sans',sans-serif"
                >
                  {notifications.length}
                </Badge>
                <Box // PULSE
                  zIndex="1"
                  marginLeft="-19px"
                  marginRight="-5px"
                  marginTop="-20px"
                  marginBottom="-4px"
                  w="24px"
                  h="24px"
                  borderRadius="100%"
                  background="#7cff00"
                  sx={{
                    animationDuration: "1500ms",
                    // animationIterationCount: '1',
                    animationDelay: "0s",
                  }}
                  opacity={{
                    base: pulseAnimation ? "0" : "1",
                    md: pulseAnimation ? "0" : "1",
                  }}
                  transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 200ms"
                  animation={pulseAnimation}
                ></Box>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex
          backgroundColor="#0D3856"
          pl={{ base: "0px", lg: "20px" }}
          pr={{ base: "0px", lg: "30px" }}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display={{ base: "none", lg: "flex" }}
        >
          <HeaderMenu
            homepage={homepage}
            navigate={navigate}
            showHeaderMenu={showHeaderMenu}
            setShowHeaderMenu={setShowHeaderMenu}
          />

          <HeaderTools
            handleDataUpdate={handleDataUpdate}
            notifications={filteredNotifications}
            handleRefetch={handleRefetch}
          />
        </Flex>
      </Flex>

      <Collapse // MOBILE NAV MENU
        style={{
          position: "fixed",
          top: "50px",
          zIndex: "10",
          width: "100%",
          background: "dark.20",
        }}
        in={showHeaderMenu}
        animateOpacity
      >
        <HeaderMenu
          homepage={homepage}
          navigate={navigate}
          showHeaderMenu={showHeaderMenu}
          setShowHeaderMenu={setShowHeaderMenu}
        />
        <Flex
          display={{ base: "flex", lg: "none" }}
          width={{ base: "100%", lg: "auto" }}
          alignItems="center"
          minHeight="100vh"
          justifyContent={{ base: "flex-start", lg: "center" }}
          flexDirection="column"
          onClick={() => {
            setShowHeaderMenu(false);
          }}
        ></Flex>
      </Collapse>

      <Collapse // MOBILE NAV MENU
        style={{
          position: "fixed",
          top: "50px",
          zIndex: "10",
          width: "100%",
          background: "dark.20",
        }}
        in={showHeaderTools}
        animateOpacity
      >
        <HeaderTools
          handleDataUpdate={handleDataUpdate}
          notifications={filteredNotifications}
          handleRefetch={handleRefetch}
        />
        <Flex
          display={{ base: "flex", lg: "none" }}
          width={{ base: "100%", lg: "auto" }}
          alignItems="center"
          minHeight="100vh"
          justifyContent={{ base: "flex-start", lg: "center" }}
          flexDirection="column"
          onClick={() => {
            setShowHeaderTools(false);
          }}
        ></Flex>
      </Collapse>
    </>
  );
}
