import React, { useEffect, useRef } from "react";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import {
  Flex,
  Button,
  VStack,
  Stack,
  Text,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { BsCaretRightFill } from "react-icons/bs";
import {
  editAuthorisationState,
  authorisationDataState,
  menuSelectionState,
  userState,
} from "../globalState";

export default function Menu() {
  const location = useLocation();
  const setAuthorisationData = useSetRecoilState(authorisationDataState);
  const setEditAuthorisation = useSetRecoilState(editAuthorisationState);
  const [menuSelection, setMenuSelection] = useRecoilState(menuSelectionState);
  const user = useRecoilValue(userState);

  const menuChanged = useRef(false);

  const planningBoard = // TODO - to update
    location.pathname === "/aircrew" ||
    location.pathname === "/taskings" ||
    location.pathname === "/area" ||
    location.pathname === "/sortie";

  const authorisations = location.pathname === "/authorisations";

  useEffect(() => {
    // added useRef to stop the menu changing when manually selecting menu item if still on same page (i.e. /taskings)
    if (!menuChanged.current) {
      if (planningBoard) {
        setMenuSelection(1);
      } else if (authorisations) {
        setMenuSelection(2);
      }
      menuChanged.current = true;
    }
  }, [planningBoard, authorisations, setMenuSelection]);

  // console.log(location, "location");

  return (
    <Flex
      margin="0"
      padding="0"
      height="100%"
      width="240px"
      minWidth="240px"
      alignItems="flex-start"
      // justifyContent="center"
      backgroundColor="#f5f7fa"
      // boxShadow="5px 0px 30px -5px rgba(10, 20, 30, 0.15)"
      borderRight="1px solid #dde4eb"
      zIndex="2"
      flexDirection="column"
      position="fixed"
      paddingTop="50px"
      left={{ base: "0px", lg: "64px" }}
      display={{ base: "none", lg: "flex" }} // TODO -to finish
    >
      <Flex
        width="100%"
        minHeight="55px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="#00ceea"
        // borderBottom="1px solid #e2e7eb"
      >
        <Text
          fontSize="1.05rem"
          width="100%"
          fontWeight="600"
          textAlign="center"
        >
          FLIGHT OPERATIONS
        </Text>
      </Flex>
      <VStack width="100%" spacing="0px">
        <Divider color="#e2e7eb" width="100%" />
        <Button
          as={Link}
          to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 1 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 1 ? "500" : "300"}
          mr={menuSelection === 1 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 1 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 1 ? "white" : "transparent"}
          borderRight={
            menuSelection === 1 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 1 ? "white" : "#FBFCFE",
            color: menuSelection !== 1 && "#00ceea",
            fontWeight: menuSelection === 1 ? "500" : "300",
          }}
          onClick={() => {
            // setSquadronSelection(user === "hq" ? "40 SQN/CFS" : "14 SQN/CFS");
            setMenuSelection(1);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              {user === "hq" ? "Tasking Board" : "Planning Board"}
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>

          {menuSelection === 1 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={Link}
          to="/authorisations"
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 2 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 2 ? "500" : "300"}
          mr={menuSelection === 2 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 2 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 2 ? "white" : "transparent"}
          borderRight={
            menuSelection === 2 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 2 ? "white" : "#FBFCFE",
            color: menuSelection !== 2 && "#00ceea",
            fontWeight: menuSelection === 2 ? "500" : "300",
          }}
          onClick={() => {
            // setSquadronSelection(user === "hq" ? "40 SQN/CFS" : "14 SQN/CFS");
            setEditAuthorisation(false);
            setAuthorisationData(null);
            setMenuSelection(2);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Flight Authorisations
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 2 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Crew Assignments
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/aircrew"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Aircraft / Systems
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Risk Assessments
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
      </VStack>
    </Flex>
  );
}

export const MobileNav = (props) => {
  const location = useLocation();

  const { setShowMobileMenu, homepage, user } = props;

  const labelFilter = user === "hq" ? "Planning Board" : "Tasking Board";

  return (
    <Stack
      bg={"#0D3856"}
      px={4}
      paddingTop="5px"
      paddingBottom="15px"
      display={{ lg: "none" }}
    >
      {!homepage ? (
        NAV_ITEMS.filter((feat) => feat.label !== labelFilter).map(
          (navItem, i) => (
            <Stack
              key={`NavItemStack${navItem.label}${i}`}
              spacing={0}
              {...navItem}
            >
              <Flex
                py={4}
                as={Link}
                to={navItem.path}
                justify={"flex-start"}
                align={"center"}
                _hover={{
                  textDecoration: "none",
                }}
                onClick={() => setShowMobileMenu(false)}
              >
                {location.pathname === navItem.path && (
                  <Icon
                    as={BsCaretRightFill}
                    width="16px"
                    height="auto"
                    color="brand.100"
                  />
                )}
                <Text
                  px="12px"
                  color={
                    location.pathname === navItem.path ? "brand.100" : "white"
                  }
                  fontWeight={
                    location.pathname === navItem.path ? "500" : "400"
                  }
                >
                  {navItem.label}
                </Text>
              </Flex>
              {i < NAV_ITEMS.length - 1 && (
                <Divider borderColor="#335771" width="100%" />
              )}
            </Stack>
          )
        )
      ) : (
        <Text px="12px" py="10px" color={"white"} fontWeight={"400"}>
          Select a mode below
        </Text>
      )}
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Planning Board",
    path: "/taskings",
  },
  {
    label: "Tasking Board",
    path: "/taskings",
  },
  {
    label: "Flight Authorisations",
    path: "/authorisations",
  },
  {
    label: "Unit Readiness",
    path: "",
  },
  {
    label: "Aircraft / Systems Readiness",
    path: "",
  },
  {
    label: "Risk Assessments",
    path: "",
  },
];
