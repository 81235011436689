import { IoAirplane } from "react-icons/io5";
import { BsTools } from "react-icons/bs";
import { PiWarningDiamond } from "react-icons/pi";
import { IoIosPeople } from "react-icons/io";

export const statsData = [
  {
    title: "Risks",
    subTitle: "Mitigated",
    total: 14,
    initialValue: 0,
    targetValue: 92,
    color: "red.500",
    statsColor: "red",
  },
  {
    title: "Occurrences",
    subTitle: "Closed",
    total: 2,
    initialValue: 0,
    targetValue: 50,
    color: "purple.500",
    statsColor: "purple",
  },
  {
    title: "Currencies Due",
    subTitle: "Completed",
    total: 7,
    initialValue: 0,
    targetValue: 57,
    color: "cyan.500",
    statsColor: "cyan",
  },
  {
    title: "Fatigue Events",
    subTitle: "Remedied",
    total: 4,
    initialValue: 0,
    targetValue: 75,
    color: "teal.500",
    statsColor: "teal",
  },
  {
    title: "Deficiencies",
    subTitle: "Resolved",
    total: 12,
    initialValue: 0,
    targetValue: 71,
    color: "yellow.500",
    statsColor: "yellow",
  },
];

export const healthData = [
  [
    {
      title: "Aircraft / Systems:",
      initialValue: 0,
      targetValue: 82,
      color: "cyan",
      statusColor: "#11EC4E",
    },
    {
      title: "Personnel (crews):",
      initialValue: 0,
      targetValue: 65,
      color: "pink",
      statusColor: "yellow",
    },
  ],
  [
    {
      title: "Aircraft / Systems:",
      initialValue: 0,
      targetValue: 95,
      color: "cyan",
      statusColor: "#11EC4E",
    },
    {
      title: "Personnel (crews):",
      initialValue: 0,
      targetValue: 84,
      color: "pink",
      statusColor: "#11EC4E",
    },
  ],
  [
    {
      title: "Aircraft / Systems:",
      initialValue: 0,
      targetValue: 85,
      color: "cyan",
      statusColor: "#11EC4E",
    },
    {
      title: "Personnel (crews):",
      initialValue: 0,
      targetValue: 76,
      color: "pink",
      statusColor: "#11EC4E",
    },
  ],
  [
    {
      title: "Aircraft / Systems:",
      initialValue: 0,
      targetValue: 69,
      color: "cyan",
      statusColor: "yellow",
    },
    {
      title: "Personnel (crews):",
      initialValue: 0,
      targetValue: 50,
      color: "pink",
      statusColor: "red",
    },
  ],
];

export const rateOfEffortData = [
  {
    firstTotal: 68,
    firstLabel: "Tasking Authorised",
    secondTotal: 49,
    secondLabel: "Tasking Completed",
    thirdTotal: 3,
    thirdLabel: "Tasking Denied",
    initialValue: 0,
    targetValue: 75,
    color: "cyan",
    image: IoAirplane,
    imageSize: "1.5rem",
    marginTop: "0px",
  },
  {
    firstTotal: 103,
    firstLabel: "Maintenance",
    secondTotal: 25,
    secondLabel: "Defects Lodged",
    thirdTotal: 19,
    thirdLabel: "Defects Resolved",
    initialValue: 0,
    targetValue: 70,
    color: "cyan",
    image: BsTools,
    imageSize: "1.1rem",
    marginTop: "0px",
  },
  {
    firstTotal: 68,
    firstLabel: "FSE Actioned",
    secondTotal: 52,
    secondLabel: "FSE Completed",
    thirdTotal: 2,
    thirdLabel: "FSE Issues",
    initialValue: 0,
    targetValue: 85,
    color: "cyan",
    image: PiWarningDiamond,
    imageSize: "1.5rem",
    marginTop: "0px",
  },
  {
    firstTotal: 20,
    firstLabel: "Staff Levels",
    secondTotal: 5,
    secondLabel: "Currency Events",
    thirdTotal: 5,
    thirdLabel: "Fatigue Events",
    initialValue: 0,
    targetValue: 62,
    color: "cyan",
    image: IoIosPeople,
    imageSize: "1.7rem",
    marginTop: "-4px",
  },
];

export const currencyData = [
  {
    name: "Anthony Kerr",
    currency: "SAR Expired",
    date: "2 weeks ago",
    expired: true,
    color: "#6C82AF",
  },
  {
    name: "Kahu Tuhiwai",
    currency: "Exceeded Fatigue Limits",
    date: "1 day ago",
    expired: false,
    color: "#B9C7E5",
  },
  {
    name: "Jane Miller",
    currency: "Exceeded Fatigue Limits",
    date: "3 hours ago",
    expired: false,
    color: "#00CEEA",
  },
  {
    name: "Rebecca Ashton",
    currency: "SAR Expiring",
    date: "in 1 week",
    expired: false,
    color: "#6C82AF",
  },
];

export const aircraftSystemsData = [
  {
    aircraft: "TEX06",
    issue: "Landing Gear Damage",
    date: "2 days ago",
    expired: true,
    color: "#FF1414",
  },
  {
    aircraft: "WRR03",
    issue: "100 Hour Service Due",
    date: "in 3 flight hours",
    expired: false,
    color: "yellow",
  },
  {
    aircraft: "PDN01",
    issue: "Avionics Tests Upcoming",
    date: "in 2 days",
    expired: false,
    color: "yellow",
  },
  {
    aircraft: "TEX01",
    issue: "VHF2 Comms Unit U/S",
    date: "3 weeks ago",
    expired: false,
    color: "yellow",
  },
  {
    aircraft: "TEX04",
    issue: "Phase Inspection 2 Due",
    date: "in 1 week",
    expired: false,
    color: "#11EC4E",
  },
  {
    aircraft: "TEX05",
    issue: "Phase Inspection 1 Due",
    date: "in 1 week",
    expired: false,
    color: "#11EC4E",
  },
];
