import React from "react";
import { Icon, FormControl, Flex, Textarea, Button } from "@chakra-ui/react";
import { ReactComponent as RiskMatrix } from "../../Assets/risk-matrix.svg";
import { MdOutlineAdd, MdDeleteForever } from "react-icons/md";

export default function RiskAssessmentItem({
  index,
  riskItems,
  // register,
  setShowRiskMatrixModal,
  boxColors,
  handleRiskChange,
  setRiskIndex,
  setRiskItem,
  handleRemoveLine,
  handleAddNewLine,
  isApprover,
}) {
  const inherentRisk =
    riskItems &&
    riskItems.length > 0 &&
    riskItems[index] &&
    riskItems[index].inherentRisk;

  const controlledRisk =
    riskItems &&
    riskItems.length > 0 &&
    riskItems[index] &&
    riskItems[index].controlledRisk;

  const inherentRiskBg = inherentRisk
    ? boxColors[inherentRisk].color || "gray.100"
    : "gray.100";

  const controlledRiskBg = controlledRisk
    ? boxColors[controlledRisk].color || "gray.100"
    : "gray.100";

  const inherentRiskValue =
    inherentRisk && boxColors[inherentRisk].label
      ? boxColors[inherentRisk].label
      : "";

  const controlledRiskValue =
    controlledRisk && boxColors[controlledRisk].label
      ? boxColors[controlledRisk].label
      : "";

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  return (
    <Flex flexDirection="row" width="100%" gap={{ base: "10px", lg: "15px" }}>
      <Flex
        borderRadius="10px"
        border="1px solid"
        borderColor="gray.200"
        height="30px"
        width="34px"
        minWidth="34px"
        fontWeight="600"
        fontSize="0.9rem"
        justifyContent="center"
        alignItems="center"
        bg="gray.100"
      >
        {index + 1}
      </Flex>

      <FormControl>
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Textarea
            variant="outline"
            background={isApprover ? "#F5F8FB" : "white"}
            pointerEvents={isApprover ? "none" : "auto"}
            size="sm"
            minHeight={{ base: "120px", lg: "80px" }}
            borderRadius="10px"
            borderTopRightRadius="0px"
            cursor="pointer"
            id={`inherentRisk${index}`}
            defaultValue={riskItems[index]?.riskIdentified || ""}
            onChange={(e) =>
              handleRiskChange(index, "riskIdentified", e.target.value)
            }
          />

          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            gap="6px"
            border="1px solid"
            borderColor="#E2E8F0"
            borderRadius="10px"
            borderTopLeftRadius="0px"
            borderBottomLeftRadius="0px"
            ml="5px"
            bg="white"
            zIndex="2"
            py="5px"
            px="6px"
            cursor="pointer"
            transition="all ease 100ms"
            _hover={{
              borderColor: "#CBD5E0",
              boxShadow: "0px 4px 12px -5px rgba(10, 20, 50, 0.2)",
              transform: "translateY(-1px)",
            }}
            onClick={() => {
              setShowRiskMatrixModal("inherent");
              setRiskIndex(index);
              setRiskItem(inherentRisk);
            }}
          >
            <Flex
              borderRadius="6px"
              border="1px solid"
              borderColor="gray.200"
              height="26px"
              width="54px"
              minWidth="54px"
              fontWeight="600"
              fontSize="0.6rem"
              lineHeight="1.2"
              textTransform="capitalize"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              px="2px"
              color={
                inherentRiskBg === "#FFF850" || inherentRiskBg === "#B6FF6D"
                  ? "gray.700"
                  : "white"
              }
              bg={inherentRiskBg}
            >
              {inherentRisk ? inherentRiskValue : ""}
            </Flex>
            <Icon as={RiskMatrix} height={"26px"} width="auto" />
          </Flex>
        </Flex>
      </FormControl>

      <FormControl>
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Textarea
            variant="outline"
            background={isApprover ? "#F5F8FB" : "white"}
            pointerEvents={isApprover ? "none" : "auto"}
            size="sm"
            minHeight={{ base: "120px", lg: "80px" }}
            borderRadius="10px"
            borderTopRightRadius="0px"
            cursor="pointer"
            id={`controlledRisk${index}`}
            defaultValue={riskItems[index]?.riskMitigation || ""}
            onChange={(e) =>
              handleRiskChange(index, "riskMitigation", e.target.value)
            }
          />

          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            gap="6px"
            border="1px solid"
            borderColor="#E2E8F0"
            borderRadius="10px"
            borderTopLeftRadius="0px"
            borderBottomLeftRadius="0px"
            ml="5px"
            bg="white"
            zIndex="2"
            py="5px"
            px="6px"
            cursor="pointer"
            transition="all ease 100ms"
            _hover={{
              borderColor: "#CBD5E0",
              boxShadow: "0px 4px 12px -5px rgba(10, 20, 50, 0.2)",
              transform: "translateY(-1px)",
            }}
            onClick={() => {
              setShowRiskMatrixModal("controlled");
              setRiskIndex(index);
              setRiskItem(controlledRisk);
            }}
          >
            <Flex
              borderRadius="6px"
              border="1px solid"
              borderColor="gray.200"
              height="26px"
              width="54px"
              minWidth="54px"
              fontWeight="600"
              fontSize="0.6rem"
              lineHeight="1.2"
              textTransform="capitalize"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              px="2px"
              color={
                controlledRiskBg === "#FFF850" || controlledRiskBg === "#B6FF6D"
                  ? "gray.700"
                  : "white"
              }
              bg={controlledRiskBg}
            >
              {controlledRisk ? controlledRiskValue : ""}
            </Flex>
            <Icon as={RiskMatrix} height={"26px"} width="auto" />
          </Flex>
        </Flex>
      </FormControl>

      <Flex
        gap="10px"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        {riskItems && riskItems.length > 1 && (
          <Button
            {...noFocus}
            size="sm"
            height="30px"
            width="34px"
            minWidth="34px"
            variant="outline"
            onClick={() => handleRemoveLine(index)}
            bg="gray.50"
            color="gray.700"
            _hover={{ background: "gray.100" }}
            _focus={{ background: "gray.100" }}
            _active={{ background: "gray.100" }}
            borderRadius="10px"
          >
            <Icon as={MdDeleteForever} fontSize="1rem" />
          </Button>
        )}
        {riskItems && riskItems.length === index + 1 && (
          <Button
            {...noFocus}
            size="sm"
            height="30px"
            width="34px"
            minWidth="34px"
            variant="outline"
            onClick={handleAddNewLine}
            bg="gray.50"
            color="gray.700"
            _hover={{ background: "gray.100" }}
            _focus={{ background: "gray.100" }}
            _active={{ background: "gray.100" }}
            borderRadius="10px"
          >
            <Icon as={MdOutlineAdd} fontSize="1rem" />
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
