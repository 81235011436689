import React, { useEffect, useRef } from "react";
import {
  // useSetRecoilState,
  useRecoilState,
} from "recoil";
import {
  Flex,
  Button,
  VStack,
  Stack,
  Text,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { BsCaretRightFill } from "react-icons/bs";
import { menuSelectionState } from "../globalState";

export default function CrewMenu() {
  const location = useLocation();
  const [menuSelection, setMenuSelection] = useRecoilState(menuSelectionState);

  const menuChanged = useRef(false);

  const aircrewManagementTable = location.pathname === "/aircrew-management";
  const maintenanceCrewManagementTable =
    location.pathname === "/maintenance-crew-management";
  const airMovementsCrewManagementTable =
    location.pathname === "/air-movements-crew-management";
  const trainingRecords = location.pathname === "/training-records";
  const authorisations = location.pathname === "/authorisations";

  useEffect(() => {
    // added useRef to stop the menu changing when manually selecting menu item if still on same page (i.e. /taskings)
    if (!menuChanged.current) {
      if (aircrewManagementTable) {
        setMenuSelection(1);
      } else if (airMovementsCrewManagementTable) {
        setMenuSelection(2);
      } else if (maintenanceCrewManagementTable) {
        setMenuSelection(3);
      } else if (trainingRecords) {
        setMenuSelection(4);
      }
      menuChanged.current = true;
    }
  }, [
    aircrewManagementTable,
    airMovementsCrewManagementTable,
    maintenanceCrewManagementTable,
    trainingRecords,
    authorisations,
    setMenuSelection,
  ]);

  // console.log(location, "location");

  return (
    <Flex
      margin="0"
      padding="0"
      height="100%"
      width="240px"
      minWidth="240px"
      alignItems="flex-start"
      // justifyContent="center"
      backgroundColor="#f5f7fa"
      // boxShadow="5px 0px 30px -5px rgba(10, 20, 30, 0.15)"
      borderRight="1px solid #dde4eb"
      zIndex="2"
      flexDirection="column"
      position="fixed"
      paddingTop="50px"
      left={{ base: "0px", lg: "64px" }}
      display={{ base: "none", lg: "flex" }} // TODO -to finish
    >
      <Flex
        width="100%"
        minHeight="55px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="#00ceea"
        // borderBottom="1px solid #e2e7eb"
      >
        <Text
          fontSize="1.05rem"
          width="100%"
          fontWeight="600"
          textAlign="center"
        >
          PERSONNEL
        </Text>
      </Flex>
      <VStack width="100%" spacing="0px">
        <Divider color="#e2e7eb" width="100%" />
        <Button
          as={!aircrewManagementTable && Link}
          to={"/aircrew-management"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 1 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 1 ? "500" : "300"}
          mr={menuSelection === 1 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 1 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 1 ? "white" : "transparent"}
          borderRight={
            menuSelection === 1 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 1 ? "white" : "#FBFCFE",
            color: menuSelection !== 1 && "#00ceea",
            fontWeight: menuSelection === 1 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(1);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Aircrew
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 1 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={!airMovementsCrewManagementTable && Link}
          to={"/air-movements-crew-management"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 2 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 2 ? "500" : "300"}
          mr={menuSelection === 2 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 2 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 2 ? "white" : "transparent"}
          borderRight={
            menuSelection === 2 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 2 ? "white" : "#FBFCFE",
            color: menuSelection !== 2 && "#00ceea",
            fontWeight: menuSelection === 2 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(2);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Air Movements Crew
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 2 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={!maintenanceCrewManagementTable && Link}
          to={"/maintenance-crew-management"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 3 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 3 ? "500" : "300"}
          mr={menuSelection === 3 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 3 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 3 ? "white" : "transparent"}
          borderRight={
            menuSelection === 3 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 3 ? "white" : "#FBFCFE",
            color: menuSelection !== 3 && "#00ceea",
            fontWeight: menuSelection === 3 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(3);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Maintenance Crew
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 3 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={!trainingRecords && Link}
          to={"/training-records"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 4 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 4 ? "500" : "300"}
          mr={menuSelection === 4 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 4 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 4 ? "white" : "transparent"}
          borderRight={
            menuSelection === 4 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 4 ? "white" : "#FBFCFE",
            color: menuSelection !== 4 && "#00ceea",
            fontWeight: menuSelection === 4 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(4);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Training Records
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 4 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/aircrew"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Courses
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Qualifications
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Permits
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Action Items
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
      </VStack>
    </Flex>
  );
}

export const MobileNavCrew = (props) => {
  const location = useLocation();

  const { setShowMobileMenu, homepage } = props;
  return (
    <Stack
      bg={"#0D3856"}
      px={4}
      paddingTop="5px"
      paddingBottom="15px"
      display={{ lg: "none" }}
    >
      {!homepage ? (
        NAV_ITEMS.map((navItem, i) => (
          <Stack
            key={`NavItemStack${navItem.label}${i}`}
            spacing={0}
            {...navItem}
          >
            <Flex
              py={4}
              as={Link}
              to={navItem.path}
              justify={"flex-start"}
              align={"center"}
              _hover={{
                textDecoration: "none",
              }}
              onClick={() => setShowMobileMenu(false)}
            >
              {location.pathname === navItem.path && (
                <Icon
                  as={BsCaretRightFill}
                  width="16px"
                  height="auto"
                  color="brand.100"
                />
              )}
              <Text
                px="12px"
                color={
                  location.pathname === navItem.path ? "brand.100" : "white"
                }
                fontWeight={location.pathname === navItem.path ? "500" : "400"}
              >
                {navItem.label}
              </Text>
            </Flex>
            {i < NAV_ITEMS.length - 1 && (
              <Divider borderColor="#335771" width="100%" />
            )}
          </Stack>
        ))
      ) : (
        <Text px="12px" py="10px" color={"white"} fontWeight={"400"}>
          Select a mode below
        </Text>
      )}
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Aircrew Management",
    path: "/aircrew-management",
  },
  {
    label: "Air Movements Crew Management",
    path: "/air-movements-crew-management",
  },
  {
    label: "Maintenance Crew Management",
    path: "/maintenance-crew-management",
  },
  {
    label: "Training Records",
    path: "",
  },
  {
    label: "Courses",
    path: "",
  },
  {
    label: "Qualifications",
    path: "",
  },
  {
    label: "Permits",
    path: "",
  },
  {
    label: "Action Items",
    path: "",
  },
];
