import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Flex, Stack, Icon, Text, Collapse } from "@chakra-ui/react";
import { ReactComponent as Airplane } from "../Assets/airplane.svg";
import { ReactComponent as Personnel } from "../Assets/personnel.svg";
import { ReactComponent as Calendar } from "../Assets/calendar.svg";
import { ReactComponent as Safety } from "../Assets/safety.svg";
import { ReactComponent as Hamburger } from "../Assets/hamburger.svg";
import { MobileNav } from "../Menu";
import { MobileNavFacilitation } from "../Menu/FacilitationMenu";
import { MobileNavCrew } from "../Menu/CrewMenu";
import { MobileNavSafety } from "../Menu/SafetyMenu";

export default function Sidebar(props) {
  const {
    showMobileMenu,
    setShowMobileMenu,
    showMobileMenuCrew,
    setShowMobileMenuCrew,
    showMobileMenuFacilitation,
    setShowMobileMenuFacilitation,
    showMobileMenuSafety,
    setShowMobileMenuSafety,
    showHeaderMenu,
    setShowHeaderMenu,
    showHeaderTools,
    setShowHeaderTools,
    homepage,
    user,
  } = props;
  const location = useLocation();

  const planningBoard =
    location.pathname === "/aircrew" ||
    location.pathname === "/taskings" ||
    location.pathname === "/area" ||
    location.pathname === "/sortie" ||
    location.pathname === "/authorisations";

  const aircrewManagementTable = location.pathname === "/aircrew-management";
  const airMovementsCrewManagementTable =
    location.pathname === "/air-movements-crew-management";
  const maintenanceCrewManagementTable =
    location.pathname === "/maintenance-crew-management";
  const trainingRecords = location.pathname === "/training-records";
  const checkIns = location.pathname === "/check-ins";
  const departures = location.pathname === "/departures";
  const safetyAndQuality =
    location.pathname === "/safety-and-quality-assurance";
  const riskMatrix = location.pathname === "/risk-matrix";
  const hazardReporting = location.pathname === "/hazard-reporting";

  return (
    <>
      <Flex
        margin="0"
        padding="0"
        height={{ base: "80px", lg: "100%" }}
        width={{ base: "100%", lg: "64px" }}
        minWidth={{ base: "100%", lg: "64px" }}
        justifyContent="center"
        alignItems="flex-start"
        backgroundColor="#FFFFFF"
        boxShadow={{
          base: "0px -5px 20px -5px rgba(10, 20, 80, 0.08)",
          lg: "5px 0px 20px -5px rgba(10, 20, 80, 0.05)",
        }}
        borderTop={{ base: "1px solid #dde4eb", lg: "none" }}
        borderRight={{ base: "100%", lg: "1px solid #dde4eb" }}
        zIndex="101"
        position="fixed"
        top={{ base: "unset", lg: "50px" }}
        bottom={{ base: "0px", lg: "unset" }}
        left="0px"
      >
        <Stack
          width="100%"
          spacing="0px"
          direction={{ base: "row", lg: "column" }}
          px={{ base: "5%", smd: "7%", lg: "0px" }}
        >
          <Flex
            display={{ base: "none", lg: "flex" }}
            width="100%"
            minHeight={{ base: "64px", lg: "80px" }}
            gap="5px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="#08273E"
            cursor="pointer"
            _hover={{
              background: { base: "none", lg: "#f5f7f9" },
            }}
            onClick={() => {
              if (showHeaderMenu || showHeaderTools) {
                setShowHeaderMenu(false);
                setShowHeaderTools(false);
              }
            }}
          >
            <Icon as={Hamburger} width="24px" height="auto" />
            {/* <Text fontSize="0.5rem" lineHeight="1.2" width="100%" textAlign="center">FLIGHT OPS</Text> */}
          </Flex>
          <Flex
            as={!planningBoard && Link}
            to="/taskings"
            width="100%"
            minHeight={{ base: "64px", lg: "80px" }}
            gap="5px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color={planningBoard ? "#00E0FF" : "#08273E"}
            bg={{ base: "none", lg: planningBoard ? "#F8FAFD" : "none" }}
            borderTop={{ base: "none", lg: "1px solid #eff2f6" }}
            borderBottom={{ base: "none", lg: "1px solid #eff2f6" }}
            cursor="pointer"
            _hover={{
              background: { base: "none", lg: "#f5f7f9" },
            }}
            onClick={() => {
              if (!homepage) {
                setShowMobileMenu((prevState) => !prevState);
              }
              if (showHeaderMenu || showHeaderTools) {
                setShowHeaderMenu(false);
                setShowHeaderTools(false);
              }
              if (
                showMobileMenuCrew ||
                showMobileMenuFacilitation ||
                showMobileMenuSafety
              ) {
                setShowMobileMenuCrew(false);
                setShowMobileMenuFacilitation(false);
                setShowMobileMenuSafety(false);
              }
            }}
          >
            <Icon as={Airplane} width="26px" height="auto" />
            <Text
              fontSize="0.5rem"
              lineHeight="1.2"
              width="100%"
              fontWeight="500"
              textAlign="center"
            >
              FLIGHT OPS
            </Text>
          </Flex>

          <Flex
            as={!departures && !checkIns && Link}
            to="/departures"
            width="100%"
            minHeight={{ base: "64px", lg: "80px" }}
            gap="5px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color={departures || checkIns ? "#00E0FF" : "#08273E"}
            bg={{
              base: "none",
              lg: departures || checkIns ? "#F8FAFD" : "none",
            }}
            borderTop={{ base: "none", lg: "1px solid #eff2f6" }}
            borderBottom={{ base: "none", lg: "1px solid #eff2f6" }}
            cursor="pointer"
            _hover={{
              background: { base: "none", lg: "#f5f7f9" },
            }}
            onClick={() => {
              if (!homepage) {
                setShowMobileMenuFacilitation((prevState) => !prevState);
              }
              if (showHeaderMenu || showHeaderTools) {
                setShowHeaderMenu(false);
                setShowHeaderTools(false);
              }
              if (
                showMobileMenu ||
                showMobileMenuCrew ||
                showMobileMenuSafety
              ) {
                setShowMobileMenu(false);
                setShowMobileMenuCrew(false);
                setShowMobileMenuSafety(false);
              }
            }}
          >
            <Icon my="1px" as={Calendar} width="24px" height="auto" />
            <Text
              fontSize="0.5rem"
              lineHeight="1.2"
              width="100%"
              textAlign="center"
            >
              FACILITATION
            </Text>
          </Flex>

          <Flex
            as={
              !aircrewManagementTable &&
              !maintenanceCrewManagementTable &&
              !airMovementsCrewManagementTable &&
              !trainingRecords &&
              Link
            }
            to="/aircrew-management"
            width="100%"
            minHeight={{ base: "64px", lg: "80px" }}
            gap="5px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color={
              aircrewManagementTable ||
              maintenanceCrewManagementTable ||
              airMovementsCrewManagementTable ||
              trainingRecords
                ? "#00E0FF"
                : "#08273E"
            }
            bg={{
              base: "none",
              lg:
                aircrewManagementTable ||
                maintenanceCrewManagementTable ||
                airMovementsCrewManagementTable ||
                trainingRecords
                  ? "#F8FAFD"
                  : "none",
            }}
            borderTop={{ base: "none", lg: "1px solid #eff2f6" }}
            borderBottom={{ base: "none", lg: "1px solid #eff2f6" }}
            cursor="pointer"
            _hover={{
              background: { base: "none", lg: "#f5f7f9" },
            }}
            onClick={() => {
              if (!homepage) {
                setShowMobileMenuCrew((prevState) => !prevState);
              }
              if (showHeaderMenu || showHeaderTools) {
                setShowHeaderMenu(false);
                setShowHeaderTools(false);
              }
              if (
                showMobileMenu ||
                showMobileMenuFacilitation ||
                showMobileMenuSafety
              ) {
                setShowMobileMenu(false);
                setShowMobileMenuFacilitation(false);
                setShowMobileMenuSafety(false);
              }
            }}
            pb={{ base: "2px", lg: "0px" }}
          >
            <Icon
              as={Personnel}
              width={{ base: "32px", lg: "26px" }}
              height="auto"
            />
            <Text
              mt={{ base: "-4px", lg: "0px" }}
              fontSize="0.5rem"
              lineHeight="1.2"
              width="100%"
              textAlign="center"
            >
              PERSONNEL
            </Text>
          </Flex>

          <Flex
            as={!(safetyAndQuality || riskMatrix || hazardReporting) && Link}
            to="/safety-and-quality-assurance"
            width="100%"
            minHeight={{ base: "64px", lg: "80px" }}
            gap="5px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color={
              safetyAndQuality || riskMatrix || hazardReporting
                ? "#00E0FF"
                : "#08273E"
            }
            bg={{
              base: "none",
              lg:
                safetyAndQuality || riskMatrix || hazardReporting
                  ? "#F8FAFD"
                  : "none",
            }}
            borderTop={{ base: "none", lg: "1px solid #eff2f6" }}
            borderBottom={{ base: "none", lg: "1px solid #eff2f6" }}
            cursor="pointer"
            _hover={{
              background: { base: "none", lg: "#f5f7f9" },
            }}
            onClick={() => {
              if (!homepage) {
                setShowMobileMenuSafety((prevState) => !prevState);
              }
              if (showHeaderMenu || showHeaderTools) {
                setShowHeaderMenu(false);
                setShowHeaderTools(false);
              }
              if (
                showMobileMenu ||
                showMobileMenuCrew ||
                showMobileMenuFacilitation
              ) {
                setShowMobileMenu(false);
                setShowMobileMenuCrew(false);
                setShowMobileMenuFacilitation(false);
              }
            }}
          >
            <Icon as={Safety} width="26px" height="auto" />
            <Text
              fontSize="0.5rem"
              lineHeight="1.2"
              width="100%"
              textAlign="center"
            >
              SAFETY / QUALITY
            </Text>
          </Flex>
        </Stack>
      </Flex>

      <Collapse // MOBILE NAV MENU
        style={{
          position: "fixed",
          bottom: "80px",
          zIndex: "100",
          width: "100%",
          background: "dark.20",
        }}
        in={showMobileMenu}
        animateOpacity
      >
        <Flex
          display={{ base: "flex", lg: "none" }}
          width={{ base: "100%", lg: "auto" }}
          alignItems="center"
          minHeight="100vh"
          justifyContent={{ base: "flex-start", lg: "center" }}
          flexDirection="column"
          onClick={() => {
            setShowMobileMenu(false);
            setShowMobileMenuCrew(false);
            setShowMobileMenuFacilitation(false);
            setShowMobileMenuSafety(false);
          }}
        ></Flex>
        <MobileNav
          setShowMobileMenu={setShowMobileMenu}
          homepage={homepage}
          user={user}
        />
      </Collapse>

      <Collapse // MOBILE NAV MENU
        style={{
          position: "fixed",
          bottom: "80px",
          zIndex: "100",
          width: "100%",
          background: "dark.20",
        }}
        in={showMobileMenuFacilitation}
        animateOpacity
      >
        <Flex
          display={{ base: "flex", lg: "none" }}
          width={{ base: "100%", lg: "auto" }}
          alignItems="center"
          minHeight="100vh"
          justifyContent={{ base: "flex-start", lg: "center" }}
          flexDirection="column"
          onClick={() => {
            setShowMobileMenu(false);
            setShowMobileMenuCrew(false);
            setShowMobileMenuSafety(false);
          }}
        ></Flex>
        <MobileNavFacilitation
          setShowMobileMenu={setShowMobileMenuFacilitation}
          homepage={homepage}
        />
      </Collapse>

      <Collapse // MOBILE NAV MENU
        style={{
          position: "fixed",
          bottom: "80px",
          zIndex: "100",
          width: "100%",
          background: "dark.20",
        }}
        in={showMobileMenuCrew}
        animateOpacity
      >
        <Flex
          display={{ base: "flex", lg: "none" }}
          width={{ base: "100%", lg: "auto" }}
          alignItems="center"
          minHeight="100vh"
          justifyContent={{ base: "flex-start", lg: "center" }}
          flexDirection="column"
          onClick={() => {
            setShowMobileMenu(false);
            setShowMobileMenuCrew(false);
            setShowMobileMenuFacilitation(false);
            setShowMobileMenuSafety(false);
          }}
        ></Flex>
        <MobileNavCrew
          setShowMobileMenu={setShowMobileMenuCrew}
          homepage={homepage}
        />
      </Collapse>

      <Collapse // MOBILE NAV MENU
        style={{
          position: "fixed",
          bottom: "80px",
          zIndex: "100",
          width: "100%",
          background: "dark.20",
        }}
        in={showMobileMenuSafety}
        animateOpacity
      >
        <Flex
          display={{ base: "flex", lg: "none" }}
          width={{ base: "100%", lg: "auto" }}
          alignItems="center"
          minHeight="100vh"
          justifyContent={{ base: "flex-start", lg: "center" }}
          flexDirection="column"
          onClick={() => {
            setShowMobileMenu(false);
            setShowMobileMenuCrew(false);
            setShowMobileMenuFacilitation(false);
          }}
        ></Flex>
        <MobileNavSafety
          setShowMobileMenu={setShowMobileMenuSafety}
          homepage={homepage}
        />
      </Collapse>
    </>
  );
}
