import React, { useEffect, useRef } from "react";
import {
  // useSetRecoilState,
  useRecoilState,
} from "recoil";
import {
  Flex,
  Button,
  VStack,
  Stack,
  Text,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { BsCaretRightFill } from "react-icons/bs";
import { menuSelectionState } from "../globalState";

export default function CrewMenu() {
  const location = useLocation();
  const [menuSelection, setMenuSelection] = useRecoilState(menuSelectionState);

  const menuChanged = useRef(false);

  const safetyAndQuality =
    location.pathname === "/safety-and-quality-assurance";
  const riskMatrix = location.pathname === "/risk-matrix";
  const hazardReporting = location.pathname === "/hazard-reporting";
  const authorisations = location.pathname === "/authorisations";

  useEffect(() => {
    // added useRef to stop the menu changing when manually selecting menu item if still on same page (i.e. /taskings)
    if (!menuChanged.current) {
      if (safetyAndQuality) {
        setMenuSelection(5);
      } else if (riskMatrix) {
        setMenuSelection(7);
      } else if (hazardReporting) {
        setMenuSelection(2);
      }
      menuChanged.current = true;
    }
  }, [
    safetyAndQuality,
    riskMatrix,
    hazardReporting,
    authorisations,
    setMenuSelection,
  ]);

  // console.log(location, "location");

  return (
    <Flex
      margin="0"
      padding="0"
      height="100%"
      width="240px"
      minWidth="240px"
      alignItems="flex-start"
      // justifyContent="center"
      backgroundColor="#f5f7fa"
      // boxShadow="5px 0px 30px -5px rgba(10, 20, 30, 0.15)"
      borderRight="1px solid #dde4eb"
      zIndex="2"
      flexDirection="column"
      position="fixed"
      paddingTop="50px"
      left={{ base: "0px", lg: "64px" }}
      display={{ base: "none", lg: "flex" }} // TODO -to finish
    >
      <Flex
        width="100%"
        minHeight="55px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="#00ceea"
        // borderBottom="1px solid #e2e7eb"
      >
        <Text
          fontSize="1.05rem"
          width="100%"
          fontWeight="600"
          textAlign="center"
        >
          SAFETY AND QUALITY
        </Text>
      </Flex>

      <VStack width="100%" spacing="0px">
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Hazard & Risk Register
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={!hazardReporting && Link}
          to={"/hazard-reporting"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 2 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 2 ? "500" : "300"}
          mr={menuSelection === 2 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 2 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 2 ? "white" : "transparent"}
          borderRight={
            menuSelection === 2 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 2 ? "white" : "#FBFCFE",
            color: menuSelection !== 2 && "#00ceea",
            fontWeight: menuSelection === 2 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(2);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Hazard Reporting
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 2 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Risk Assessments
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Risk Control Measures
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={!safetyAndQuality && Link}
          to={"/safety-and-quality-assurance"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 5 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 5 ? "500" : "300"}
          mr={menuSelection === 5 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 5 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 5 ? "white" : "transparent"}
          borderRight={
            menuSelection === 5 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 5 ? "white" : "#FBFCFE",
            color: menuSelection !== 5 && "#00ceea",
            fontWeight: menuSelection === 5 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(5);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Flight Safety Events
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 5 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Trend Analysis
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          as={!riskMatrix && Link}
          to={"/risk-matrix"}
          height={"46px"}
          minHeight={"46px"}
          width={menuSelection === 7 ? "calc(100% + 8px)" : "100%"}
          fontWeight={menuSelection === 7 ? "500" : "300"}
          mr={menuSelection === 7 ? "-8px" : "0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={menuSelection === 7 ? "8px" : "0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={menuSelection === 7 ? "white" : "transparent"}
          borderRight={
            menuSelection === 7 ? "1px solid #e2e7eb" : "transparent"
          }
          _hover={{
            background: menuSelection === 7 ? "white" : "#FBFCFE",
            color: menuSelection !== 7 && "#00ceea",
            fontWeight: menuSelection === 7 ? "500" : "300",
          }}
          onClick={() => {
            setMenuSelection(7);
          }}
        >
          <Flex gap="10px" justifyContent="flex-start" alignItems="center">
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
              Risk Matrix
            </Text>
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          </Flex>
          {menuSelection === 7 && (
            <Icon as={BsCaretRightFill} width="16px" height="auto" />
          )}
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Audits
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Inspections
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/taskings"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Deficiencies and Actions
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Quality Assurance
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            EMS Plans
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />

        <Button
          // as={Link}
          // to="/area"
          height={"46px"}
          minHeight={"46px"}
          width={"100%"}
          fontWeight={"300"}
          mr={"0px"}
          pl="20px"
          pr="10px"
          borderRadius="0px"
          borderRightRadius={"0px"}
          justifyContent="space-between"
          alignItems="center"
          bg={"transparent"}
          _hover={{
            background: "#FBFCFE",
            color: "#00ceea",
            fontWeight: "300",
          }}
        >
          <Text
            fontSize="0.8rem"
            fontFamily="'Poppins', 'Open Sans', sans-serif"
          >
            Document Management
          </Text>
        </Button>
        <Divider color="#e2e7eb" width="100%" />
      </VStack>
    </Flex>
  );
}

export const MobileNavSafety = (props) => {
  const location = useLocation();

  const { setShowMobileMenu, homepage } = props;
  return (
    <Stack
      bg={"#0D3856"}
      px={4}
      paddingTop="5px"
      paddingBottom="15px"
      display={{ lg: "none" }}
    >
      {!homepage ? (
        NAV_ITEMS.map((navItem, i) => (
          <Stack
            key={`NavItemStack${navItem.label}${i}`}
            spacing={0}
            {...navItem}
          >
            <Flex
              py={4}
              as={Link}
              to={navItem.path}
              justify={"flex-start"}
              align={"center"}
              _hover={{
                textDecoration: "none",
              }}
              onClick={() => setShowMobileMenu(false)}
            >
              {location.pathname === navItem.path && (
                <Icon
                  as={BsCaretRightFill}
                  width="16px"
                  height="auto"
                  color="brand.100"
                />
              )}
              <Text
                px="12px"
                color={
                  location.pathname === navItem.path ? "brand.100" : "white"
                }
                fontWeight={location.pathname === navItem.path ? "500" : "400"}
              >
                {navItem.label}
              </Text>
            </Flex>
            {i < NAV_ITEMS.length - 1 && (
              <Divider borderColor="#335771" width="100%" />
            )}
          </Stack>
        ))
      ) : (
        <Text px="12px" py="10px" color={"white"} fontWeight={"400"}>
          Select a mode below
        </Text>
      )}
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Hazard & Risk Register",
    path: "",
  },
  {
    label: "Hazard Reporting",
    path: "/hazard-reporting",
  },
  {
    label: "Risk Assessments",
    path: "",
  },
  {
    label: "Risk Control Measures",
    path: "",
  },
  {
    label: "Flight Safety Events",
    path: "/safety-and-quality-assurance",
  },
  {
    label: "Trend Analysis",
    path: "",
  },
  {
    label: "Risk Matrix",
    path: "/risk-matrix",
  },
  {
    label: "Audits",
    path: "",
  },
  {
    label: "Inspections",
    path: "",
  },
  {
    label: "Deficiencies & Actions",
    path: "",
  },
  {
    label: "Quality Assurance",
    path: "",
  },
  {
    label: "EMS Plans",
    path: "",
  },
  {
    label: "Document Management",
    path: "",
  },
];
