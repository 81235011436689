import React, { useState, useRef, useMemo } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  ButtonGroup,
  Button,
  Text,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Tooltip,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Tabs from "../Menu/Tabs";
import { parseISO, intervalToDuration } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { Select } from "chakra-react-select";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  authorisationsModalState,
  authorisationDataState,
  newAuthorisationState,
  editAuthorisationState,
  deleteAuthorisationState,
  deleteModalState,
  squadronSelectionState,
} from "../globalState";
// import generateSorties from "../Data/generateSorties";

import { FiEdit } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { MdDeleteForever, MdOutlineAdd } from "react-icons/md";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import {
  BsSearch,
  BsFiletypePdf,
  BsEnvelopeAt,
  BsPrinter,
  BsFolderPlus,
} from "react-icons/bs";
import { useApproverRole } from "../Auth/Hooks";

export default function AuthorisationsTable(props) {
  const { data } = props;
  const isApprover = useApproverRole();
  const location = useLocation();
  const setShowAuthorisationsModal = useSetRecoilState(
    authorisationsModalState
  );
  const setAuthorisationData = useSetRecoilState(authorisationDataState);
  const setNewAuthorisation = useSetRecoilState(newAuthorisationState);
  const setEditAuthorisation = useSetRecoilState(editAuthorisationState);
  const setDeleteAuthorisation = useSetRecoilState(deleteAuthorisationState);
  const setShowDeleteModal = useSetRecoilState(deleteModalState);
  const [squadronSelection, setSquadronSelection] = useRecoilState(
    squadronSelectionState
  );

  const [tab, setTab] = useState("all");
  const authorisations = location.pathname === "/authorisations";

  const columnHelper = createColumnHelper();
  const [sorting, setSorting] = useState([]);

  const selectStyle = {
    container: (styles) => ({
      ...styles,
      fontWeight: "500",
    }),
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
      paddingTop: "6px",
      paddingBottom: "6px",
      background: "#ffffff",
      color: "light.100",
      fontWeight: "500",
      _hover: {
        background: "cyan.400",
        color: "white",
        fontWeight: "500",
      },
      transition: "all ease 100ms",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
      borderColor: "#e2e8f0",
      minHeight: "30px",
      height: "30px",
      background: "#ffffff",
      minWidth: { base: "120px", md: "120px", lg: "158px" },
      _focusVisible: { boxShadow: "none !important", borderColor: "cyan.500" },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: "4px",
      paddingBottom: "4px",
      background: "gray.50",
      _hover: {
        background: "#e7edf3",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100",
      marginTop: "4px",
    }),
  };

  const unitOptions = [
    { value: "14 SQN/CFS", label: "14 Squadron" },
    { value: "40 SQN/CFS", label: "40 Squadron" },
  ];

  const squadron =
    squadronSelection === "40 SQN/CFS" ? unitOptions[1] : unitOptions[0];
  const [selectedOptions, setSelectedOptions] = useState(squadron);

  const noFocus = {
    _focus: { boxShadow: "none !important", borderColor: "initial" },
  };
  //  *** Authorisations from local file for testing only ***
  // const bookings = useMemo(
  //   () =>
  //     generateSorties().map((sortie) => ({
  //       ...sortie,
  //       channelUuid: sortie.airframe,
  //     })),
  //   []
  // );

  //  *** Authorisations from Database ***
  const bookings = useMemo(
    () =>
      data &&
      data
        .filter((sqd) => sqd.data?.squadron === squadronSelection)
        //  filtered authorisations to remove any where status has changed to 'deleted'
        .filter((booking) => booking?.data?.status !== "deleted")
        .filter((booking) => booking?.data?.id)
        .filter((booking) =>
          tab === "active" ? booking?.data?.status === "approved" : true
        )
        .filter((booking) =>
          tab === "draft" ? booking?.data?.status === "draft" : true
        )
        .filter((booking) =>
          tab === "pending" ? booking?.data?.status === "pending" : true
        )
        .filter((booking) =>
          tab === "approved" ? booking?.data?.status === "approved" : true
        )
        .filter((booking) =>
          tab === "declined" ? booking?.data?.status === "declined" : true
        )
        .filter((booking) =>
          tab === "completed" ? booking?.data?.status === "completed" : true
        )
        .map((booking) => ({
          ...booking.id,
          ...booking.data,
        })),
    [data, tab, squadronSelection]
  );

  const columns = [
    columnHelper.accessor("id", {
      cell: (info) => (
        <Flex justifyContent="center" alignItems="center">
          <strong>
            {squadronSelection === "40 SQN/CFS" ? "40" : "14"}SQN-
            {info.getValue() > 99 ? "" : info.getValue() > 9 ? "0" : "00"}
            {info.getValue()}
          </strong>
        </Flex>
      ),
      sortingFn: "sortId",
    }),
    columnHelper.accessor("airframe", {
      header: "Airframe",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("title", {
      header: "Sortie",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("activity", {
      header: "Activity",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("area", {
      header: "Area",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("since", {
      header: "ETD (NZST)",
      cell: (info) =>
        formatToTimeZone(parseISO(info.getValue()), "HH:mm DD-MM-YY", {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
    }),
    columnHelper.accessor("till", {
      header: "ETA (NZST)",
      cell: (info) =>
        formatToTimeZone(parseISO(info.getValue()), "HH:mm DD-MM-YY", {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
    }),
    columnHelper.accessor("duration", {
      header: "Duration",
      cell: ({ row }) => {
        const since = parseISO(row.original?.since);
        const till = parseISO(row.original?.till);
        const duration = intervalToDuration({ start: since, end: till });
        const padWithZero = (value) => (value < 10 ? `0${value}` : value);
        const durationHasDays = duration?.days ? duration.days * 24 : 0;
        const formattedDuration = `${
          durationHasDays + duration?.hours
        }:${padWithZero(duration?.minutes)}`;
        return formattedDuration;
      },
    }),
    columnHelper.accessor(() => {}, {
      id: "priority",
      header: "Priority",
      cell: ({ row }) => (
        <Flex
          fontSize="1.1rem"
          minWidth="10px"
          justifyContent="center"
          alignItems="center"
          color={
            row.original.priority === "High"
              ? "#fa4c00"
              : row.original.priority === "Medium"
              ? "#ffd100"
              : row.original?.priority === "Low"
              ? "#00fb00"
              : "white"
          }
        >
          {row.original.priority ? <GoDotFill /> : ""}
        </Flex>
      ),
    }),
    // columnHelper.accessor("user", {
    //   header: "user",
    //   cell: (info) =>
    //     info.getValue().substring(0, info.getValue().indexOf("@")),
    // }),
    columnHelper.accessor(() => {}, {
      id: "status",
      // cell: (info) => info.getValue(),
      cell: ({ row }) => `${
        row.original.status === "completed" ? "COMPLETED" : ""
      }
      ${row.original.status === "pending" ? "PENDING" : ""}
      ${row.original.status === "approved" ? "APPROVED" : ""}
      ${row.original.status === "active" ? "ACTIVE" : ""}
      ${row.original.status === "draft" ? "DRAFT" : ""}
      ${row.original.status === "declined" ? "DECLINED" : ""}
      ${!row.original.status ? "Unknown" : ""}`,
    }),
    columnHelper.accessor(() => {}, {
      id: "actions",
      cell: ({ row }) => (
        <ButtonGroup
          size="sm"
          borderRadius="10px"
          isAttached
          variant="outline"
          px="5px"
        >
          <Tooltip
            hasArrow
            placement="top"
            label="Edit Authorisation"
            bg="gray.600"
            color="white"
            borderRadius="5px"
            fontSize="0.7rem"
          >
            <Button
              fontSize="1.1rem"
              minWidth="50px"
              borderRadius="10px"
              background={"gray.50"}
              color={"#00c4ce"}
              borderColor={"#D5DEE7"}
              _hover={{
                background: "#00c4ce",
                borderColor: "#00c4ce",
                color: "white",
              }}
              transition="all ease 200ms"
              onClick={() => {
                // navigate(`/edit?id=${row.original.id}`);
                setAuthorisationData(row.original);
                setEditAuthorisation(true);
              }}
            >
              <FiEdit />
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            placement="top"
            label="Delete Authorisation"
            bg="gray.600"
            color="white"
            borderRadius="5px"
            fontSize="0.7rem"
          >
            <Button
              fontSize="1.3rem"
              minWidth="50px"
              borderRadius="10px"
              background={"gray.50"}
              color={"#DB2828"}
              borderColor={"#D5DEE7"}
              _hover={{
                background: "#DB2828",
                borderColor: "#DB2828",
                color: "white",
              }}
              transition="all ease 200ms"
              onClick={() => {
                setDeleteAuthorisation(row.original);
                setShowDeleteModal(true);
              }}
            >
              <MdDeleteForever />
            </Button>
          </Tooltip>
        </ButtonGroup>
      ),
    }),
  ];

  const table = useReactTable({
    data: bookings,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
    sortingFns: {
      sortId: (rowA, rowB, columnId) => {
        // console.log("RowA: ", rowA.getValue(columnId));
        return rowA.getValue(columnId).value < rowB.getValue(columnId).value
          ? 1
          : -1;
      },
    },
  });

  //PORTAL REFERENCES
  const footerPlacement = useRef();
  // const footerPlacement = { containerRef: footerPlacement };

  // const noFocus = {
  //   _focus: { boxShadow: "none !important" },
  // };

  // console.log(bookings, "bookings");
  // console.log(table, "table");
  // console.log(tab, "table tab");

  return (
    <>
      <Flex
        margin={0}
        px={{ base: "0px", smd: "15px", lg: "15px" }}
        pt={{ base: "15px", smd: "10px", lg: "15px" }}
        pb={{ base: "79px", smd: "95px", lg: "15px" }}
        flex={1}
        flexDirection="column"
        // overflowY="auto"
        overflowY={{ base: "auto", lg: "hidden" }}
        zIndex="1"
        width="100%"
        height="100%"
      >
        <Flex
          position="absolute"
          marginTop={{ base: "75px", "2xl": "38px" }}
          background="gray.200"
          height="60px"
          width="50px"
        ></Flex>
        <Flex
          width="100%"
          px={{ base: "10px", smd: "0px" }}
          height={{ base: "76px", "2xl": "40px" }}
          minHeight={{ base: "76px", "2xl": "40px" }}
          justifyContent="space-between"
          alignItems={{ base: "flex-start", "2xl": "flex-end" }}
          zIndex="2"
          flexDirection={{ base: "column-reverse", "2xl": "row" }}
          mb={{ base: "0px", smd: "0px" }}
          gap={{ base: "0px", "2xl": "10px" }}
        >
          <Tabs
            authorisations={authorisations}
            tab={tab}
            setTab={setTab}
            tab1={"all"}
            // tab2={"active"}
            tab3={"pending"}
            tab4={"approved"}
            tab5={"declined"}
            tab6={"completed"}
          />

          <Flex
            gap={{ base: "0.5rem", md: "15px", lg: "20px" }}
            width={{ base: "100%", lg: "auto" }}
            mb={{ base: "0px", "2xl": "10px" }}
          >
            <Flex
              alignItems="center"
              gap={{ base: "0px", smd: "7px" }}
              width={{ base: "100%", lg: "auto" }}
            >
              <Text
                display={{ base: "none", smd: "block" }}
                fontSize="0.9rem"
                htmlFor="aircraft"
                fontFamily="'Poppins'"
                fontWeight="500"
                color="gray.500"
              >
                UNIT:
              </Text>
              <Select
                {...noFocus}
                chakraStyles={selectStyle}
                colorScheme="cyan"
                variant="outline"
                background="white"
                size="xs"
                cursor="pointer"
                id="aircraft"
                value={selectedOptions}
                options={unitOptions}
                onChange={(v) => {
                  setSelectedOptions(v);
                  setSquadronSelection(v.value);
                }}
              />
            </Flex>
            <ButtonGroup spacing={{ base: "5px", md: "0.5rem" }}>
              <IconButton
                aria-label="Search database"
                icon={<BsSearch />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Save to PDF"
                icon={<BsFiletypePdf />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Email"
                icon={<BsEnvelopeAt />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Print"
                icon={<BsPrinter />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.95rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Save to File"
                icon={<BsFolderPlus />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.95rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
            </ButtonGroup>
          </Flex>
        </Flex>

        <Flex
          zIndex="1"
          width="100%"
          height={{
            base: "auto",
            lg: "calc(100% - 40px)",
          }}
          minHeight={{
            base: "auto",
            lg: "calc(100% - 40px)",
          }}
          bg="white"
          borderRadius={{
            base: "0px",
            smd: "15px",
          }}
          borderTopLeftRadius={{
            base: "0px",
            smd: tab === "all" ? "0px" : "15px",
          }}
          boxShadow={{
            base: "none",
            smd: "0px 10px 40px -15px rgba(10, 20, 80, 0.4)",
          }}
        >
          <Flex flexDirection="column" width="100%">
            <Flex
              pl="17px"
              pr="13px"
              justifyContent={{ base: "space-between", smd: "space-between" }}
              alignItems="center"
              width="100%"
              height={{ base: "68px", smd: "48px" }}
              minHeight={{ base: "68px", smd: "48px" }}
              borderBottom="1px solid"
              borderColor="#e5e7ea"
              fontSize="0.9rem"
              flexDirection={{ base: "row", smd: "row" }}
            >
              <Flex
                width={{ base: "100%", smd: "auto" }}
                justifyContent="flex-start"
                alignItems="center"
                gap="5px"
              >
                <Text
                  color="brand.100"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {tab === "pending"
                    ? "Pending"
                    : tab === "approved"
                    ? "Approved"
                    : tab === "declined"
                    ? "Declined"
                    : tab === "completed"
                    ? "Completed"
                    : "All"}
                </Text>
                <Text
                  color="gray.700"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  Flight Authorisations
                </Text>
              </Flex>

              {!isApprover && (
                <ButtonGroup size="sm" isAttached={true} mr="5px">
                  <Button
                    variant="solid"
                    borderRadius="20px"
                    size="sm"
                    bg="brand.100"
                    color="white"
                    pl="10px"
                    pr={{ base: "15px", sm: "15px" }}
                    width={{ base: "80px", "2xl": "90px" }}
                    minWidth={{ base: "auto", sm: "80px", "2xl": "90px" }}
                    height="30px"
                    fontSize="0.8rem"
                    justifyContent="center"
                    alignItems="center"
                    lineHeight="1.4"
                    gap="2px"
                    _hover={{
                      background: "brand.200",
                    }}
                    onClick={() => {
                      setNewAuthorisation(true);
                      // setShowAuthorisationsModal(true);
                    }}
                  >
                    <Icon
                      as={MdOutlineAdd}
                      color="white"
                      width="22px"
                      height="auto"
                    />
                    <Text display={{ base: "block", sm: "block" }}>NEW</Text>
                  </Button>
                </ButtonGroup>
              )}
            </Flex>

            <Flex
              flexDirection="column"
              zIndex="1"
              width="100%"
              height="100%"
              background="#FBFDFF"
              overflowY={{ base: "hidden", lg: "auto" }}
              minHeight={{ base: "calc(100% - 360px)", lg: "auto" }}
            >
              <TableContainer width="100%" pt="5px">
                <Flex width="100%" height="100%" overflow="auto">
                  {!data && (
                    <Flex
                      width="100%"
                      justifyContent="center"
                      alignItems="flex-start"
                      pt="50px"
                    >
                      <Spinner
                        zIndex="1"
                        color={"brand.100"}
                        emptyColor={"white"}
                        thickness="3px"
                        speed="0.5s"
                        boxSize="40px"
                      />
                    </Flex>
                  )}
                  {data && (
                    <Table
                      variant="striped"
                      size="sm"
                      mx="15px"
                      className="auth-table"
                      pr={{ base: "15px", smd: "initial" }}
                    >
                      <Thead
                        position="sticky"
                        top="0px"
                        height="36px"
                        maxHeight="36px"
                        zIndex="90"
                        borderRadius="10px"
                        boxShadow={"0px 5px 10px -3px rgba(0,0,0,0.25)"}
                      >
                        {table.getHeaderGroups().map((headerGroup) => (
                          <Tr
                            key={headerGroup.id}
                            border="1px solid"
                            borderRadius="10px"
                          >
                            {headerGroup.headers.map((header) => {
                              const cellStyle = {
                                width:
                                  header.column.id === "actions"
                                    ? "125px"
                                    : header.column.id === "status"
                                    ? "90px"
                                    : "initial",
                                fontWeight: 500,
                              };
                              return (
                                <Th
                                  key={header.id}
                                  style={cellStyle}
                                  background={"#436781"}
                                  color={"white"}
                                  borderTop="hidden"
                                  borderBottom="hidden"
                                  borderColor="#5c87a5"
                                >
                                  {header.isPlaceholder ? null : (
                                    <Flex
                                      justifyContent="center"
                                      alignItems="center"
                                      gap="3px"
                                      fontSize="0.7rem"
                                      cursor="pointer"
                                      {...{
                                        className: header.column.getCanSort()
                                          ? "cursor-pointer select-none"
                                          : "",
                                        onClick:
                                          header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: (
                                          <TiArrowSortedDown fontSize="16px" />
                                        ),
                                        desc: (
                                          <TiArrowSortedUp fontSize="16px" />
                                        ),
                                      }[header.column.getIsSorted()] ?? null}
                                    </Flex>
                                  )}
                                </Th>
                              );
                            })}
                          </Tr>
                        ))}
                      </Thead>
                      {data && (
                        <Tbody
                          borderTop="1px solid"
                          borderColor="gray.400"
                          overflowX="auto"
                          overflowY={{ base: "hidden", lg: "auto" }}
                        >
                          {table.getRowModel().rows.map((row, index) => {
                            const isEvenRow = index % 2 === 0;
                            const backgroundColor = isEvenRow
                              ? "#f3f7fb"
                              : "white";

                            return (
                              <Tr
                                key={row.id}
                                border="1px solid"
                                borderColor="gray.200"
                                cursor={"pointer"}
                                backgroundColor={backgroundColor}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  const cellStyle = {
                                    background:
                                      cell.column.id === "status" &&
                                      (row.original.status === "approved"
                                        ? "#00fb00"
                                        : row.original.status === "pending"
                                        ? "yellow"
                                        : row.original.status === "completed"
                                        ? "#00E0FF"
                                        : row.original.status === "declined"
                                        ? "#ff1b1b"
                                        : "initial"),
                                    color:
                                      cell.column.id === "status" &&
                                      (row.original.status === "approved" ||
                                        row.original.status === "completed" ||
                                        row.original.status === "declined")
                                        ? "white"
                                        : "initial",
                                    fontWeight:
                                      cell.column.id === "status" &&
                                      row.original.status === "approved"
                                        ? 700
                                        : cell.column.id === "status"
                                        ? 600
                                        : 400,
                                    letterSpacing:
                                      cell.column.id === "status"
                                        ? "0.5px"
                                        : "0",
                                    textAlign:
                                      cell.column.id === "title"
                                        ? "left"
                                        : "center",
                                    padding: "5px",
                                    paddingLeft:
                                      cell.column.id === "title"
                                        ? "10px"
                                        : "5px",
                                    width:
                                      cell.column.id !== "id" &&
                                      (cell.column.id === "actions"
                                        ? "125px"
                                        : cell.column.id === "status"
                                        ? "90px"
                                        : "initial"),
                                  };
                                  return (
                                    <Td
                                      key={cell.id}
                                      style={cellStyle}
                                      borderColor="gray.200"
                                      onClick={() => {
                                        if (cell.column.id !== "actions") {
                                          setAuthorisationData(row.original);
                                          setShowAuthorisationsModal(true);
                                        }
                                      }}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </Td>
                                  );
                                })}
                              </Tr>
                            );
                          })}
                        </Tbody>
                      )}
                    </Table>
                  )}
                </Flex>
              </TableContainer>
            </Flex>
            <Flex
              pl="17px"
              pr="17px"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height={{ base: "54px", lg: "48px" }}
              minHeight={{ base: "54px", lg: "48px" }}
              borderTop="1px solid"
              borderColor="#e5e7ea"
              fontSize="0.95rem"
            >
              <Flex
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
                fontSize="0.75rem"
              >
                <Box ref={footerPlacement}></Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
