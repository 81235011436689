import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { formatToTimeZone } from "date-fns-timezone";
import { Select } from "chakra-react-select";
import {
  parseISO,
  format,
  addMinutes,
  formatDuration,
  intervalToDuration,
} from "date-fns";
import {
  FormLabel,
  FormHelperText,
  FormControl,
  Input,
  Flex,
  Text,
  Textarea,
  Portal,
  Box,
  useBreakpointValue,
  Button,
  Collapse,
  Link,
} from "@chakra-ui/react";
import PilotLog from "../PilotLog";
import FlightSafetyReport from "../../Assets/images/report-flight-safety-event.png";

export default function PostFlightTab(props) {
  const {
    authorisationData,
    aircrewData,
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
    editAuthorisation,
    timeZone,
    selectStyle,
  } = props;

  const flightSafetyEventMobile = useRef();
  const flightSafetyEventDesktop = useRef();
  const [switchSelection, setSwitchSelection] = useState("lbs");

  const flightSafetyEventContainer = useBreakpointValue({
    base: { containerRef: flightSafetyEventMobile },
    xl: { containerRef: flightSafetyEventDesktop },
  });

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };
  // flatpickr refs
  const fpDateActualDep = useRef(null);
  const fpDateActualArr = useRef(null);
  const now = Date.now();

  const isFlightCompleted = watch("flightcompletion");
  const cabinPressure = watch("cabinalt");
  const fuelCategory = watch("fuelcategory");
  const giveReason = isFlightCompleted && isFlightCompleted !== "Completed";

  // Periodic update of dateapproved Date/Time
  const timeWatch = watch(["actualdeparturetime", "actualarrivaltime"]);
  const timeResetDep = parseISO(timeWatch?.[0]) < addMinutes(now, 5);
  const timeResetArr = parseISO(timeWatch?.[1]) < addMinutes(now, 5);
  useEffect(() => {
    if (timeResetDep) {
      setValue(
        "actualdeparturetime",
        formatToTimeZone(addMinutes(now, 5), "YYYY-MM-DD HH:mmZ", {
          timeZone: timeZone,
        })
      );
    }

    if (timeResetArr) {
      setValue(
        "actualarrivaltime",
        formatToTimeZone(addMinutes(now, 5), "YYYY-MM-DD HH:mmZ", {
          timeZone: timeZone,
        })
      );
    }
  }, [
    now,
    editAuthorisation,
    timeResetDep,
    timeResetArr,
    setValue,
    getValues,
    timeWatch,
    timeZone,
  ]);

  const flightStatus = [
    { value: "not-completed", label: "Not Completed" },
    { value: "partially-completed", label: "Partially Completed" },
    { value: "completed", label: "Completed" },
  ];

  const fuelCat = [
    { value: "jet-a1", label: "Jet A1" },
    { value: "avgas", label: "AVGAS" },
  ];

  const cabinAlt = [
    { value: "1000", label: "1000 ft" },
    { value: "2000", label: "2000 ft" },
    { value: "3000", label: "3000 ft" },
    { value: "4000", label: "4000 ft" },
    { value: "5000", label: "5000 ft" },
    { value: "6000", label: "6000 ft" },
    { value: "7000", label: "7000 ft" },
    { value: "8000", label: "8000 ft" },
    { value: "9000", label: "9000 ft" },
    { value: "10000", label: "10000 ft" },
    { value: "11000", label: "11000 ft" },
    { value: "12000", label: "12000 ft" },
    { value: "13000", label: "13000 ft" },
  ];

  // determine duration
  // const since = parseISO(authorisationData?.actualdeparturetime);
  // const till = parseISO(authorisationData?.actualarrivaltime);
  // const duration =
  //   authorisationData?.actualdeparturetime &&
  //   authorisationData?.actualarrivaltime &&
  //   intervalToDuration({ start: since, end: till });
  // const durationDetail =
  //   duration &&
  //   formatDuration(duration, {
  //     format: ["hours", "minutes"],
  //   });
  // const padWithZero = (value) => (value < 10 ? `0${value}` : value);
  // const durationHasDays = duration && duration?.days ? duration.days * 24 : 0;
  // const formattedDuration =
  //   duration &&
  //   durationHasDays &&
  //   `${durationHasDays + duration?.hours}:${padWithZero(duration?.minutes)}`;

  // determine duration
  const since = timeWatch?.[0] !== undefined && parseISO(timeWatch[0]);
  const till = timeWatch?.[1] !== undefined && parseISO(timeWatch[1]);
  const duration =
    since && till && since <= till
      ? intervalToDuration({ start: since, end: till })
      : null;
  const durationDetail =
    timeWatch &&
    timeWatch.length &&
    duration &&
    formatDuration(duration, {
      format: ["days", "hours", "minutes"],
    });
  const padWithZero = (value) => (value < 10 ? `0${value}` : value);
  const durationHasDays = duration?.days ? duration.days * 24 : 0;
  const formattedDuration = `${durationHasDays + duration?.hours}:${padWithZero(
    duration?.minutes
  )}`;

  const pilotDetails =
    authorisationData?.pilot &&
    aircrewData.find((member) => member.id === authorisationData.pilot);

  const passengerDetails =
    authorisationData?.passenger &&
    aircrewData.find((member) => member.id === authorisationData.passenger);

  const buttonBgActive = "#F2F5F8";
  const buttonBgHover = "gray.200";

  // console.log("cumulativeRisk", cumulativeRisk);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          width="100%"
          height="100%"
          bg="brand.400"
        >
          <Box ref={flightSafetyEventMobile}></Box>
          <Flex
            background="#FBFDFF"
            flexDirection="column"
            width={{ base: "100%", xl: "calc(100% - 340px)" }}
            height="100%"
            borderRight={{ base: "0px solid", xl: "1px solid #e5e7ea" }}
            borderColor="#e5e7ea"
          >
            <Flex width="100%" flexDirection="column">
              <Flex
                width="100%"
                gap="20px"
                flexDirection="column"
                px="20px"
                pt="15px"
                pb="10px"
              >
                <Text
                  fontSize="0.8rem"
                  fontWeight="700"
                  textTransform="uppercase"
                >
                  Flight Completion
                </Text>

                <Flex
                  alignItems="baseline"
                  gap="20px"
                  flexDirection={{ base: "column", md: "row" }}
                  width="100%"
                >
                  <FormControl>
                    <FormLabel
                      fontSize="0.8rem"
                      htmlFor="actualdeparturetime"
                      fontFamily="'Poppins'"
                    >
                      Actual Departure Time
                    </FormLabel>
                    <Controller
                      control={control}
                      name="actualdeparturetime"
                      rules={{
                        validate: {
                          notInvalid: (value) =>
                            (value !== null && value !== "Invalid Date") ||
                            "Please select date/time",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Flatpickr
                          id="actualdeparturetime"
                          ref={fpDateActualDep}
                          placeholder="Select date/time..."
                          style={{
                            width: "100%",
                            fontFamily: "'Open Sans', sans-serif",
                            height: "32px",
                            borderRadius: "7px",
                            fontSize: "0.875rem",
                            paddingLeft: "0.75rem",
                            paddingRight: "0.75rem",
                            border: "1px solid",
                            borderColor: errors.actualdeparturetime
                              ? "#E53E3E"
                              : "#ced4da",
                          }}
                          className="flatpickr form-control form-control-sm"
                          data-enable-time
                          value={formatToTimeZone(value, "D MMM YY HH:mm", {
                            timeZone: timeZone,
                          })}
                          onChange={(value) => {
                            onChange(
                              value.length
                                ? format(value[0], "yyyy-MM-dd HH:mm")
                                : null
                            );
                          }}
                          options={{
                            allowClearing: false,
                            dateFormat: "j M y H:i",
                            time_24hr: true,
                            minuteIncrement: 1,
                            defaultDate: formatToTimeZone(
                              addMinutes(now, 5),
                              "D MMM YY HH:mm",
                              { timeZone: timeZone }
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      fontSize="0.8rem"
                      htmlFor="actualarrivaltime"
                      fontFamily="'Poppins'"
                    >
                      Actual Arrival Time
                    </FormLabel>
                    <Controller
                      control={control}
                      name="actualarrivaltime"
                      rules={{
                        validate: {
                          notInvalid: (value) =>
                            (value !== null && value !== "Invalid Date") ||
                            "Please select date/time",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Flatpickr
                          id="actualarrivaltime"
                          ref={fpDateActualArr}
                          placeholder="Select date/time..."
                          style={{
                            width: "100%",
                            fontFamily: "'Open Sans', sans-serif",
                            height: "32px",
                            borderRadius: "7px",
                            fontSize: "0.875rem",
                            paddingLeft: "0.75rem",
                            paddingRight: "0.75rem",
                            border: "1px solid",
                            borderColor: errors.actualarrivaltime
                              ? "#E53E3E"
                              : "#ced4da",
                          }}
                          className="flatpickr form-control form-control-sm"
                          data-enable-time
                          value={formatToTimeZone(value, "D MMM YY HH:mm", {
                            timeZone: timeZone,
                          })}
                          onChange={(value) => {
                            onChange(
                              value.length
                                ? format(value[0], "yyyy-MM-dd HH:mm")
                                : null
                            );
                          }}
                          options={{
                            allowClearing: false,
                            dateFormat: "j M y H:i",
                            time_24hr: true,
                            minuteIncrement: 1,
                            defaultDate: formatToTimeZone(
                              addMinutes(now, 5),
                              "D MMM YY HH:mm",
                              { timeZone: timeZone }
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <Flex
                    alignItems="baseline"
                    flexDirection="column"
                    width="100%"
                  >
                    <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                      Duration
                    </FormLabel>
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      width="100%"
                      bg="#fdfeff"
                      borderRadius="10px"
                      height="32px"
                      px="12px"
                      border="1px solid"
                      borderColor="gray.100"
                      fontSize="0.875rem"
                    >
                      {duration && (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          gap="10px"
                        >
                          <Text fontSize="0.85rem">{formattedDuration}</Text>
                          <Text
                            color="gray.400"
                            opacity="0.7"
                            fontStyle="italic"
                            fontSize="0.7rem"
                          >{`${durationDetail}`}</Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                flexDirection={{ base: "column", md: "row" }}
                width="100%"
                justifyContent="stretch"
                px="20px"
                pt="15px"
                pb={{ base: "40px", md: "25px" }}
                gap="20px"
              >
                <Flex
                  gap="20px"
                  flexDirection="column"
                  width={{ base: "100%", md: "32%" }}
                >
                  <FormControl>
                    <FormLabel
                      fontSize="0.8rem"
                      htmlFor="flightcompletion"
                      fontFamily="'Poppins'"
                      overflow="hidden"
                      height="20px"
                      maxHeight="20px"
                      opacity={isFlightCompleted ? "1" : "1"}
                    >
                      Flight Completed?
                    </FormLabel>

                    <Select
                      id="flightcompletion"
                      {...noFocus}
                      chakraStyles={selectStyle}
                      variant="outline"
                      background="white"
                      size="sm"
                      cursor="pointer"
                      {...register("flightcompletion")}
                      value={flightStatus.filter((option) => {
                        return option.label === getValues("flightcompletion");
                      })}
                      onChange={(value) =>
                        setValue("flightcompletion", value.label)
                      }
                      options={flightStatus}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontSize="0.8rem"
                      htmlFor="cabinalt"
                      fontFamily="'Poppins'"
                      overflow="hidden"
                      height="20px"
                      maxHeight="20px"
                      opacity={cabinPressure ? "1" : "1"}
                    >
                      Average Cabin Altitude
                    </FormLabel>
                    <FormHelperText
                      mb="12px"
                      mt={{ base: "-27px", smd: "-27px" }}
                      color="gray.400"
                      fontSize="0.75rem"
                      textAlign="right"
                      whiteSpace="pre"
                    >
                      Per 1000 ft
                    </FormHelperText>

                    <Select
                      id="cabinalt"
                      {...noFocus}
                      chakraStyles={selectStyle}
                      variant="outline"
                      background="white"
                      size="sm"
                      cursor="pointer"
                      {...register("cabinalt")}
                      value={cabinAlt.filter((option) => {
                        return option.label === getValues("cabinalt");
                      })}
                      onChange={(value) => setValue("cabinalt", value.label)}
                      options={cabinAlt}
                    />
                  </FormControl>
                </Flex>

                <Flex
                  gap="20px"
                  flexDirection={{ base: "column", md: "column" }}
                  width={{ base: "100%", md: "68%" }}
                >
                  <Collapse
                    style={{ width: "100%", zIndex: "2" }}
                    in={giveReason}
                    animateOpacity
                    unmountOnExit={true}
                  >
                    {(isFlightCompleted === "Not Completed" ||
                      isFlightCompleted === "Partially Completed") && (
                      <FormControl>
                        <FormLabel
                          fontSize="0.8rem"
                          htmlFor="flightcompletionreason"
                          fontFamily="'Poppins'"
                          overflow="hidden"
                          height="20px"
                          maxHeight="20px"
                        >
                          {`Reason for Flight ${
                            isFlightCompleted === "Not Completed"
                              ? "Not"
                              : "Partially"
                          } Completed?`}
                        </FormLabel>

                        <Textarea
                          variant="outline"
                          background="white"
                          size="sm"
                          minHeight={{ base: "120px", lg: "80px" }}
                          borderRadius="10px"
                          cursor="pointer"
                          id="flightcompletionreason"
                          {...register("flightcompletionreason", {})}
                        />
                      </FormControl>
                    )}
                  </Collapse>
                  <FormControl>
                    <FormLabel
                      fontSize="0.8rem"
                      htmlFor="postflightcomments"
                      fontFamily="'Poppins'"
                      overflow="hidden"
                      height="20px"
                      maxHeight="20px"
                    >
                      General Comments
                    </FormLabel>

                    <Textarea
                      variant="outline"
                      background="white"
                      size="sm"
                      minHeight={{ base: "80px", lg: "113px" }}
                      borderRadius="10px"
                      cursor="pointer"
                      id="postflightcomments"
                      {...register("postflightcomments", {})}
                    />
                  </FormControl>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                width="100%"
                justifyContent="stretch"
                bg="brand.400"
                borderTop="1px solid #e5e7ea"
                borderBottom={{ base: "1px solid #e5e7ea", md: "0px solid" }}
                px="20px"
                pt="15px"
                pb={{ base: "40px", md: "25px" }}
                gap="10px"
              >
                <Flex width="100%" gap="20px" justifyContent="space-between">
                  <Text
                    fontSize="0.8rem"
                    fontWeight="700"
                    textTransform="uppercase"
                  >
                    Flight Details
                  </Text>

                  <Flex alignItems="baseline" flexDirection="row">
                    <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                      Priority:
                    </FormLabel>

                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      color={
                        authorisationData?.priority === "High"
                          ? "white"
                          : "initial"
                      }
                      fontWeight="600"
                      textTransform="uppercase"
                      minWidth="70px"
                      px="10px"
                      height="20px"
                      fontSize="0.75rem"
                      lineHeight="1"
                      borderRadius="15px"
                      background={
                        authorisationData?.priority === "High"
                          ? "#ff6a00"
                          : authorisationData?.priority === "Medium"
                          ? "yellow"
                          : authorisationData?.priority === "Low"
                          ? "#00fb00"
                          : "gray.100"
                      }
                    >
                      {authorisationData?.priority}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  width="100%"
                  gap="20px"
                  flexDirection={{ base: "column", xl: "row" }}
                >
                  <Flex
                    gap="20px"
                    flexDirection="column"
                    width={{ base: "100%", xl: "32.5%" }}
                  >
                    <Flex alignItems="baseline" flexDirection="column">
                      <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                        Activity
                      </FormLabel>
                      <Text
                        width="100%"
                        borderRadius="10px"
                        px="10px"
                        py="5px"
                        bg="#F1F6FA"
                        border="1px solid"
                        borderColor="gray.200"
                        fontSize="0.85rem"
                      >
                        {authorisationData?.activity}
                      </Text>
                    </Flex>

                    <Flex alignItems="baseline" flexDirection="column">
                      <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                        Area
                      </FormLabel>
                      <Text
                        width="100%"
                        borderRadius="10px"
                        px="10px"
                        py="5px"
                        bg="#F1F6FA"
                        border="1px solid"
                        borderColor="gray.200"
                        fontSize="0.85rem"
                      >
                        {authorisationData?.area}
                      </Text>
                    </Flex>

                    <Flex alignItems="baseline" flexDirection="column">
                      <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                        Cost Centre
                      </FormLabel>
                      <Text
                        width="100%"
                        borderRadius="10px"
                        px="10px"
                        py="5px"
                        bg="#F1F6FA"
                        border="1px solid"
                        borderColor="gray.200"
                        fontSize="0.85rem"
                        textTransform="uppercase"
                        minWidth="120px"
                      >
                        {authorisationData?.costcentre
                          ? authorisationData.costcentre
                          : "None"}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex
                    gap="20px"
                    flexDirection="column"
                    width={{ base: "100%", xl: "67.5%" }}
                  >
                    <Flex
                      gap="20px"
                      flexDirection={{ base: "column", lg: "row" }}
                      width="100%"
                    >
                      <Flex
                        alignItems="baseline"
                        flexDirection="column"
                        width="100%"
                      >
                        <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                          Aircraft
                        </FormLabel>
                        <Text
                          width="100%"
                          borderRadius="10px"
                          px="10px"
                          py="5px"
                          bg="#F1F6FA"
                          border="1px solid"
                          borderColor="gray.200"
                          fontSize="0.85rem"
                          textTransform="uppercase"
                          minWidth="120px"
                        >
                          {authorisationData?.aircraft}
                        </Text>
                      </Flex>

                      <Flex
                        alignItems="baseline"
                        flexDirection="column"
                        width="100%"
                      >
                        <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                          Airframe
                        </FormLabel>
                        <Text
                          width="100%"
                          borderRadius="10px"
                          px="10px"
                          py="5px"
                          bg="#F1F6FA"
                          border="1px solid"
                          borderColor="gray.200"
                          fontSize="0.85rem"
                          textTransform="uppercase"
                          minWidth="120px"
                        >
                          {authorisationData?.airframe}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex alignItems="baseline" flexDirection="column">
                      <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                        Intentions / Description
                      </FormLabel>
                      <Text
                        width="100%"
                        minHeight={{ base: "80px", lg: "110px" }}
                        borderRadius="10px"
                        px="10px"
                        py="5px"
                        bg="#F1F6FA"
                        border="1px solid"
                        borderColor="gray.200"
                        fontSize="0.85rem"
                        textTransform="uppercase"
                        minWidth="120px"
                      >
                        {authorisationData?.intentions}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            flexDirection={{ base: "column", xl: "column" }}
            width={{ base: "100%", xl: "340px" }}
            maxWidth={{ base: "100%", xl: "340px" }}
            height="100%"
            minHeight="100%"
            justifyContent="stretch"
            bg="brand.400"
          >
            <Portal {...flightSafetyEventContainer}>
              <Flex
                width="100%"
                height="60px"
                p={{ base: "20px", xl: "15px" }}
                paddingTop="10px"
                paddingBottom="10px"
                borderBottom="1px solid #e5e7ea"
                gap="10px"
                bg="brand.400"
                justifyContent={{ base: "center", xl: "center" }}
                alignItems="center"
              >
                <Link
                  href={FlightSafetyReport}
                  isExternal
                  bg={"pink.400"}
                  width="220px"
                  height="26px"
                  borderRadius="25px"
                  gap="20px"
                  color={"white"}
                  display="flex"
                  justifyContent="center"
                  fontWeight={"600"}
                  fontSize="0.85rem"
                  alignItems="center"
                  lineHeight="1.2"
                  textTransform="capitalize"
                  cursor="pointer"
                  _hover={{ bg: "pink.500" }}
                >
                  Lodge a Flight Safety Event
                </Link>
              </Flex>
            </Portal>
            <Box ref={flightSafetyEventDesktop}></Box>
            <Flex
              flexDirection={{ base: "column", xl: "column" }}
              width="100%"
              height="100%"
              minHeight="100%"
              p={{ base: "20px", xl: "20px" }}
              paddingTop={{ base: "15px", xl: "15px" }}
              paddingBottom={{ base: "40px", xl: "40px" }}
              gap="20px"
            >
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                Personnel Details
              </Text>
              <Flex gap="20px" flexDirection="column" width="100%">
                <Flex alignItems="baseline" flexDirection="column">
                  <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                    Pilot in Command
                  </FormLabel>
                  <Flex
                    bg="#F1F6FA"
                    width="100%"
                    px="15px"
                    py="5px"
                    minHeight="32px"
                    height="32px"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="10px"
                    alignItems="baseline"
                    justifyContent="space-between"
                    gap="5px"
                  >
                    {pilotDetails && (
                      <Text fontSize="0.85rem" fontWeight="500">
                        {pilotDetails?.name}
                      </Text>
                    )}
                    <Text
                      fontSize="0.8rem"
                      fontWeight="300"
                      color={pilotDetails ? "initial" : "gray.400"}
                      fontStyle={pilotDetails ? "normal" : "italic"}
                    >
                      {pilotDetails ? pilotDetails?.callsign : "None assigned"}
                    </Text>
                  </Flex>
                </Flex>

                <Flex alignItems="baseline" flexDirection="column">
                  <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                    Co-Pilot / Instructor / Passenger
                  </FormLabel>
                  <Flex
                    bg="#F1F6FA"
                    width="100%"
                    px="15px"
                    py="5px"
                    minHeight="32px"
                    height="32px"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="10px"
                    alignItems="baseline"
                    justifyContent="space-between"
                    gap="5px"
                  >
                    {passengerDetails && (
                      <Text fontSize="0.85rem" fontWeight="500">
                        {passengerDetails?.name}
                      </Text>
                    )}
                    <Text
                      fontSize="0.8rem"
                      fontWeight="300"
                      color={passengerDetails ? "initial" : "gray.400"}
                      fontStyle={passengerDetails ? "normal" : "italic"}
                    >
                      {passengerDetails
                        ? passengerDetails?.callsign
                        : "None assigned"}
                    </Text>
                  </Flex>
                </Flex>

                <Flex alignItems="baseline" flexDirection="column">
                  <FormLabel fontSize="0.8rem" fontFamily="'Poppins'">
                    Approved By
                  </FormLabel>
                  <Text
                    width="100%"
                    borderRadius="10px"
                    px="10px"
                    py="5px"
                    bg="#F1F6FA"
                    border="1px solid"
                    borderColor="gray.200"
                    fontSize="0.85rem"
                    minHeight="32px"
                    height="32px"
                  >
                    {authorisationData?.approvedby
                      ? authorisationData.approvedby
                      : "None"}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        width="100%"
        justifyContent="stretch"
        px="20px"
        pt="15px"
        pb={{ base: "40px", md: "25px" }}
        gap="20px"
        borderTop="1px solid"
        borderColor="#e5e7ea"
      >
        <Flex width="100%" gap="20px" justifyContent="space-between">
          <Text fontSize="0.8rem" fontWeight="700" textTransform="uppercase">
            Fuel and Stores
          </Text>
          <Button
            bg={"#F0F5FA"}
            p="0px"
            opacity="1"
            variant="outline"
            fontSize="11px"
            height={{
              base: "26px",
              md: "24px",
            }}
            width={"104px"}
            style={{
              minWidth: "104px",
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius={"20px"}
            boxShadow={"inset 0px 0px 3px 0px rgba(0,0,0,0.15)"}
            border={"none"}
            borderColor="rgba(255,255,255,0.1)"
            {...noFocus}
            index={switchSelection === "lbs" ? 0 : 1}
            _active={{
              bg: buttonBgActive,
            }}
            _focus={{
              bg: buttonBgActive,
            }}
            _hover={{
              bg: buttonBgHover,
            }}
          >
            <Box
              zIndex="1"
              display={"flex"}
              ml="2px"
              height={{ base: "22px", md: "20px" }}
              width="50px"
              minWidth="50px"
              background="white"
              borderRadius="20px"
              bg={"#ffffff"}
              boxShadow="0px 0px 1px 0px rgba(0,0,0,0.25)"
              borderColor="#E9EAEB"
              transition="all ease 300ms"
              transform={
                switchSelection === "kg" ? "translateX(100%)" : "translateX(0%)"
              }
            ></Box>
            <Flex
              zIndex="2"
              opacity={"1"}
              _hover={{ opacity: "1" }}
              justifyContent="space-evenly"
              marginLeft={"-50px"}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "lbs" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "lbs" ? "700" : "500"}
                opacity={switchSelection === "lbs" ? "1" : "0.8"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                _hover={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="50px"
                onClick={() => {
                  setSwitchSelection("lbs");
                }}
              >
                lbs
              </Flex>
              <Flex
                zIndex="1"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "kg" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "kg" ? "700" : "500"}
                opacity={switchSelection === "kg" ? "1" : "0.8"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                _hover={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="50px"
                onClick={() => {
                  setSwitchSelection("kg");
                }}
              >
                kg
              </Flex>
            </Flex>
          </Button>
        </Flex>
        <Flex
          width="100%"
          flexDirection={{ base: "column", md: "row" }}
          gap="20px"
        >
          <Flex
            width="100%"
            flexDirection={{ base: "column", sm: "row" }}
            gap="20px"
          >
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="fuelab"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
              >
                Fuel A/B
              </FormLabel>
              <FormHelperText
                mb="12px"
                mt={{ base: "-27px", smd: "-27px" }}
                color="gray.400"
                fontSize="0.75rem"
                textAlign="right"
                whiteSpace="pre"
              >
                {switchSelection === "kg" ? "kg" : "lbs"}
              </FormHelperText>

              <Input
                transition="all ease 250ms"
                fontSize="0.9rem"
                fontWeight="400"
                width={{ base: "100%", lg: "100%" }}
                variant="outline"
                background="white"
                size="sm"
                borderRadius="10px"
                cursor="pointer"
                id="fuelab"
                {...register("fuelab", {})}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="burnrate"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
              >
                Burn Rate
              </FormLabel>
              <FormHelperText
                mb="12px"
                mt={{ base: "-27px", smd: "-27px" }}
                color="gray.400"
                fontSize="0.75rem"
                textAlign="right"
                whiteSpace="pre"
              >
                {switchSelection === "kg" ? "kg / hour" : "lbs / hour"}
              </FormHelperText>

              <Input
                transition="all ease 250ms"
                fontSize="0.9rem"
                fontWeight="400"
                width={{ base: "100%", lg: "100%" }}
                variant="outline"
                background="white"
                size="sm"
                borderRadius="10px"
                cursor="pointer"
                id="burnrate"
                {...register("burnrate", {})}
              />
            </FormControl>
          </Flex>
          <Flex
            width="100%"
            flexDirection={{ base: "column", sm: "row" }}
            gap="20px"
          >
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="fuelland"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
              >
                Fuel Land
              </FormLabel>
              <FormHelperText
                mb="12px"
                mt={{ base: "-27px", smd: "-27px" }}
                color="gray.400"
                fontSize="0.75rem"
                textAlign="right"
                whiteSpace="pre"
              >
                {switchSelection === "kg" ? "kg" : "lbs"}
              </FormHelperText>

              <Input
                transition="all ease 250ms"
                fontSize="0.9rem"
                fontWeight="400"
                width={{ base: "100%", lg: "100%" }}
                variant="outline"
                background="white"
                size="sm"
                borderRadius="10px"
                cursor="pointer"
                id="fuelland"
                {...register("fuelland", {})}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="fuelcategory"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
                opacity={fuelCategory ? "1" : "1"}
              >
                Fuel Category
              </FormLabel>

              <Select
                id="fuelcategory"
                {...noFocus}
                chakraStyles={selectStyle}
                variant="outline"
                background="white"
                size="sm"
                cursor="pointer"
                {...register("fuelcategory")}
                value={fuelCat.filter((option) => {
                  return option.label === getValues("fuelcategory");
                })}
                onChange={(value) => setValue("fuelcategory", value.label)}
                options={fuelCat}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        width="100%"
        justifyContent="stretch"
        px="20px"
        pt="15px"
        pb="35px"
        gap="5px"
        borderTop="1px solid"
        borderColor="#e5e7ea"
      >
        <Text fontSize="0.8rem" fontWeight="700" textTransform="uppercase">
          Flight Log
        </Text>
        <PilotLog authorisationData={authorisationData} />
      </Flex>
    </Flex>
  );
}
