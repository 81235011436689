import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useApproverRole } from "../Auth/Hooks";
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useDisclosure,
  Text,
  LightMode,
  ModalCloseButton,
  Container,
} from "@chakra-ui/react";
import FlightAuthSelected from "../Forms/FlightAuthSelected";
import {
  authorisationsModalState,
  authorisationDataState,
  editAuthorisationState,
  authorisationTabState,
  squadronSelectionState,
  airframeDataState,
} from "../globalState";

export default function AuthorisationsModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const isApprover = useApproverRole();
  const airframeData = useRecoilValue(airframeDataState);
  const authorisations = location.pathname === "/authorisations";
  const [showAuthorisationsModal, setShowAuthorisationsModal] = useRecoilState(
    authorisationsModalState
  );
  const [authorisationData, setAuthorisationData] = useRecoilState(
    authorisationDataState
  );
  const squadronSelection = useRecoilValue(squadronSelectionState);
  const setTab = useSetRecoilState(authorisationTabState);

  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone = "Pacific/Auckland";

  const setEditAuthorisation = useSetRecoilState(editAuthorisationState);

  useEffect(() => {
    showAuthorisationsModal ? onOpen() : onClose();
  }, [showAuthorisationsModal, onOpen, onClose]);

  function onCloseHandler() {
    setAuthorisationData(null);
    setShowAuthorisationsModal(false);
    setEditAuthorisation(null);
    onClose();
  }

  // const airframeSerials = useMemo(
  //   () => airframeData.map((airframe) => airframe.serial),
  //   []
  // );
  const airframeSerials = airframeData;

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  return (
    <>
      <LightMode>
        <Modal
          variant="primary"
          size="4xl"
          transition="all ease 300ms"
          onClose={onCloseHandler}
          isOpen={isOpen}
          closeOnOverlayClick={true}
          isCentered
          trapFocus={false}
          blockScrollOnMount={true}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              zIndex="1"
              textAlign="center"
              backgroundImage={
                authorisationData?.status === "approved"
                  ? "linear-gradient(345deg, #235f88 10%, #0d3856 90%)"
                  : authorisationData?.status === "pending"
                  ? "linear-gradient(345deg, #fbf42b 10%, #f1ca00 90%)"
                  : authorisationData?.status === "completed"
                  ? "linear-gradient(345deg, #80e0fd 10%, #15a9d5 90%)"
                  : authorisationData?.status === "declined"
                  ? "linear-gradient(345deg, #c9d8e1 10%, #a4b8c5 90%)"
                  : "linear-gradient(345deg, #c9d8e1 10%, #a4b8c5 90%)"
              }
            >
              <Flex
                gap="5px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex
                  gap="5px"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="white">FLIGHT AUTHORISATION</Text>
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody
              style={{ padding: 0 }}
              fontSize="0.8rem"
              fontFamily="'Open Sans',sans-serif"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                minHeight="60px"
                fontSize="1.1rem"
                fontWeight="700"
                bg="white"
                borderBottom="1px solid"
                borderColor="gray.100"
                py={{ base: "20px", lg: "15px" }}
                px={{ base: "20px", lg: "30px" }}
                gap={{ base: "5px", lg: "25px" }}
                flexDirection={{ base: "column", lg: "row" }}
                boxShadow="0px 0px 15px -1px rgba(0,0,0,0.1)"
                zIndex="2"
                position="relative"
              >
                <Text
                  width="100%"
                  fontSize={{ base: "0.9rem", lg: "1rem", xl: "1.1rem" }}
                  fontWeight="700"
                  textAlign="left"
                >
                  {authorisationData?.title}
                </Text>

                <Flex
                  gap={{ base: "5px", lg: "25px" }}
                  width={{ base: "100%", lg: "auto" }}
                  justifyContent={{ base: "space-between", lg: "center" }}
                  alignItems="flex-end"
                  flexDirection={{ base: "row", lg: "row" }}
                >
                  <Flex
                    gap="10px"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Text
                      color="gray.400"
                      fontWeight="400"
                      fontSize="0.8rem"
                      minWidth="55px"
                      textAlign={{ base: "left", lg: "right" }}
                    >
                      Flight ID:
                    </Text>
                    <Text
                      minWidth="80px"
                      whiteSpace="pre"
                      color="gray.600"
                      fontWeight="600"
                      fontSize="0.9rem"
                      // width="90px"
                      textAlign={{ base: "left", lg: "right" }}
                    >
                      {authorisationData?.id
                        ? `${
                            squadronSelection === "40 SQN/CFS" ? "40" : "14"
                          }SQN-${
                            authorisationData.id > 99
                              ? ""
                              : authorisationData.id > 9
                              ? "0"
                              : "00"
                          }${authorisationData.id}`
                        : "TBC"}
                    </Text>
                  </Flex>
                  {authorisationData?.status && (
                    <Flex
                      gap="10px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* <Text
                        color="gray.400"
                        fontWeight="400"
                        fontSize="0.75rem"
                        minWidth="50px"
                        textAlign="right"
                      >
                        Status:
                      </Text> */}
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        color={
                          authorisationData?.status === "approved" ||
                          authorisationData?.status === "completed" ||
                          authorisationData?.status === "declined"
                            ? "white"
                            : "gray.600"
                        }
                        fontWeight="700"
                        textTransform="uppercase"
                        width="95px"
                        height="22px"
                        fontSize="0.75rem"
                        letterSpacing="0.5px"
                        borderRadius="15px"
                        background={
                          authorisationData?.status === "approved"
                            ? "#00fb00"
                            : authorisationData?.status === "pending"
                            ? "yellow"
                            : authorisationData?.status === "completed"
                            ? "#00E0FF"
                            : authorisationData?.status === "declined"
                            ? "#ff1b1b"
                            : "gray.100"
                        }
                      >
                        {authorisationData?.status
                          ? authorisationData?.status
                          : "Draft"}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                pt="20px"
                pb="45px"
                width="100%"
                justifyContent="center"
                alignItems="center"
                background="#FBFDFF"
                px={{ base: "20px", lg: "30px" }}
                zIndex="1"
                position="relative"
              >
                <Container maxW="3xl" px="0px">
                  <FlightAuthSelected
                    showAuthorisationsModal={showAuthorisationsModal}
                    authorisationData={authorisationData}
                    airframeSerials={airframeSerials}
                    modalOpen={isOpen}
                    timeZone={timeZone}
                  />
                </Container>
              </Flex>
            </ModalBody>
            <ModalFooter borderTop="1px solid #e5e7e9" pt={4} pb={4}>
              <Flex
                flexDirection="row"
                gap="10px"
                width="100%"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="outline"
                  onClick={() => {
                    onCloseHandler();
                  }}
                  bg="gray.50"
                  color="gray.700"
                  _hover={{ background: "gray.100" }}
                  _focus={{ background: "gray.100" }}
                  _active={{ background: "gray.100" }}
                  borderRadius="25px"
                >
                  Close
                </Button>

                <Button
                  {...noFocus}
                  onClick={() => {
                    if (!authorisations) {
                      navigate("/authorisations");
                    }
                    setEditAuthorisation(true);
                    setShowAuthorisationsModal(false);
                    onClose();
                  }}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  bg="white"
                  color="light.100"
                  variant="outline"
                  _hover={{ background: "gray.50" }}
                  _focus={{ background: "gray.50" }}
                  _active={{ background: "gray.50" }}
                  borderRadius="25px"
                >
                  Edit
                </Button>

                {isApprover &&
                  authorisationData?.status !== "draft" &&
                  authorisationData?.status !== "approved" &&
                  authorisationData?.status !== "completed" && (
                    <Button
                      {...noFocus}
                      w="100%"
                      size="sm"
                      maxWidth="180px"
                      variant="primary"
                      bg="brand.100"
                      _hover={{ background: "brand.200" }}
                      _focus={{ background: "brand.200" }}
                      _active={{ background: "brand.200" }}
                      borderRadius="25px"
                      onClick={() => {
                        if (!authorisations) {
                          navigate("/authorisations");
                        }
                        setTab("approval");
                        setEditAuthorisation(true);
                        setShowAuthorisationsModal(false);
                        onClose();
                      }}
                    >
                      Approve
                    </Button>
                  )}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  );
}
