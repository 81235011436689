// COMPONENT PARTS CAN BE IDENTIFIED HERE:
// client > node_modeuls > @chakra-ui > theme > dist > declarations > src > components

import { mode } from "@chakra-ui/theme-tools";

// Switch
export const switchStyles = {
  parts: ["track", "thumb"],
  colorScheme: "teal",
  baseStyle: {
    track: {
      _focus: {
        border: "none",
        boxShadow: "none",
      },
      _checked: {
        background: "brand.100",
      },
    },
    // TODO - to fix
    colorScheme: {
      active: (props) => ({
        background: "brand.100",
      }),
      normal: (props) => ({
        background: "light.100",
      }),
    },
    thumb: {
      _focus: {
        border: "none",
        boxShadow: "none",
      },
    },
  },
  variants: {
    localtime: (props) => ({
      track: {
        _focus: {
          border: "none",
          boxShadow: "none",
        },
        _checked: {
          background: "light.400",
        },
      },
    }),
  },
};

// Checkbox
export const checkboxStyles = {
  parts: ["label", "icon", "container", "control"],
  colorScheme: "teal",
  baseStyle: {
    control: {
      borderRadius: "100%",
      border: "2px solid",
      borderColor: "#d5deea",
      padding: "3px",
      width: { base: "24px", lg: "20px" },
      height: { base: "24px", lg: "20px" },
      fontSize: { base: "0.8rem", lg: "0.7rem" },
      _focus: {
        boxShadow: "none",
      },
      _checked: {
        background: "brand.100",
        borderColor: "brand.100",
      },
      icon: {
        width: { base: "1rem", lg: "0.9rem" },
      },
      label: {
        width: { base: "1rem", lg: "0.9rem" },
      },
    },
    variants: {
      desktop: (props) => ({
        control: {
          padding: "2px",
          width: "14px",
          height: "14px",
          borderRadius: "100%",
          border: "2px solid",
          borderColor: "#d5deea",
          background: "brand.100",
          _focus: {
            background: "light.201",
          },
          _hover: {
            background: "light.201",
          },
          _active: {
            background: "light.201",
          },
        },
      }),
      mobile: (props) => ({
        control: {
          width: "26px",
          height: "26px",
          borderRadius: "100%",
          border: "2px solid",
          borderColor: "#d5deea",
          background: "brand.100",
          _focus: {
            background: "light.201",
          },
          _hover: {
            background: "light.201",
          },
          _active: {
            background: "light.201",
          },
        },
      }),
    },
  },
};

// Inputs (Form Fields)
export const inputStyles = {
  parts: ["element", "field", "addon"],
  colorScheme: "teal",
  baseStyle: {
    field: {
      borderRadius: "10px",
      _focus: {
        borderColor: "brand.200",
      },
    },
  },
  variants: {
    primary: (props) => ({
      field: {
        borderColor: "#E1E7EE",
        border: "1px solid #E1E7EE",
        borderRadius: "10px",
        background: "#FDFEFF",
        _focus: {
          borderColor: "brand.200",
        },
      },
      element: {
        color: "brand.100",
      },
      addon: {
        color: "brand.100",
      },
    }),
  },
};

// Modals
export const modalStyles = {
  parts: ["dialogContainer", "dialog", "overlay", "header", "closeButton"],
  baseStyle: {
    dialogContainer: {
      zIndex: { base: "102", lg: "51" },
      height: "100%",
      minHeight: "100%",
      paddingTop: {
        base: "0px",
        lg: "0px",
        // lg: "50px",
      },
      paddingBottom: {
        base: "0px",
        lg: "0",
      },
      width: { base: "100%", lg: "calc(100% - 64px)" },
      // marginLeft: { base: "0px", lg: "64px" },
      marginLeft: { base: "0px", lg: "0px" },
      right: "0px",
    },
    dialog: {
      borderRadius: 20,
      boxShadow: "0px 10px 30px -10px rgba(0,0,0,0.5)",
      overflow: "hidden",
      my: "auto",
      mx: { base: "15px", lg: "25px" },
      maxHeight: "calc(100% - 50px)",
    },
    overlay: {
      zIndex: { base: "101", lg: "50" },
      left: "unset",
      right: 0,
      width: { base: "100%", lg: "calc(100% - 0px)" },
      marginRight: "0px",
      height: {
        base: "calc(100% - 0px)",
        lg: "100%",
      },
      minHeight: {
        base: "calc(100% - 0px)",
        lg: "100%",
      },
    },
    header: {
      bg: mode("#4a5568"),
      paddingStart: "5",
      paddingEnd: "4",
      paddingTop: "3",
      paddingBottom: "3",
      fontSize: "1.1rem",
    },
    closeButton: {
      fontSize: "1rem",
      color: mode("white", "gray.800"),
      top: "3",
      right: "3",
      opacity: "0.7",
      zIndex: "1",
      _hover: {
        opacity: "1",
      },
      _focus: {
        border: "none",
      },
    },
    body: {
      fontSize: "0.9rem",
      color: mode("gray.600", "gray.200"),
      // bg: mode('white', 'white'),
    },
  },

  variants: {
    primary: (props) => ({
      dialogContainer: {
        paddingBottom: {
          base: "0px",
          lg: "0",
        },
      },
      header: {
        bg: mode("#4a5568")(props),
      },
      closeButton: {
        top: "9px",
        opacity: "0.6",
      },
    }),
  },
};

// Headings
export const headingStyles = {
  baseStyle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: "white",
  },
  variants: {
    primary: (props) => ({
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "white",
      // need to add light and dark mode color options as a test
    }),
  },
};

// Tables
export const tableStyles = {
  parts: ["Tbody", "Tr", "Td"],

  // style object for base or default style
  baseStyle: {},

  // styles for different sizes ("sm", "md", "lg")
  sizes: {},

  variants: {
    striped: (props) => ({
      th: {
        fontFamily: "Open Sans",
        fontWeight: "700",
        fontSize: "0.7rem",
        textAlign: "center",
        px: "5px",
      },
      td: {
        fontFamily: "Open Sans",
        fontWeight: "normal",
        fontSize: "0.7rem",
        textAlign: "center",
        px: "5px",
      },
    }),
    normal: (props) => ({
      td: {
        paddingStart: "1",
        paddingEnd: "1",
        paddingTop: "1",
        paddingBottom: "1",
        fontFamily: "Open Sans",
        letterSpacing: "0",
        lineHeight: "1.2",
        fontWeight: "normal",
        verticalAlign: "baseline",
      },
    }),
  },

  defaultProps: {
    size: "",
    variant: "",
  },
};

// Buttons
export const brandRing = {
  _focus: {
    ring: 2,
    ringColor: "brand.500",
  },
};
export const buttonStyles = {
  variants: {
    primary: (props) => ({
      rounded: "none",
      ...brandRing,
      color: mode("white", "gray.800")(props),
      backgroundColor: mode("brand.500", "brand.200")(props),

      _hover: {
        backgroundColor: mode("brand.600", "brand.300")(props),
      },

      _active: {
        backgroundColor: mode("brand.700", "brand.400")(props),
      },
    }),
  },
};
