import React, { useMemo, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { format } from "date-fns";
import { useBreakpointValue, Flex, Portal, Button } from "@chakra-ui/react";
import { useEpg, Epg, Layout } from "@nessprim/planby";
import { v4 as uuid } from "uuid";
import Airframe from "./Airframe";
import Booking from "./Booking";
// import Timeline from "./Timeline";
import calculateAvailableSlots, {
  splitAvailableSlots,
} from "../../helpers/calculateAvailableSlots";
import { theme, globalStyles } from "../../Theme/Components/PlanningBoard";
import {
  boardViewState,
  airframeDataState,
  aircrewDataState,
} from "../../globalState";

export default function AirframeBoard(props) {
  const {
    data,
    zoomLevel,
    selectedMonth,
    // selectedWeek,
    dayStart,
    dayEnd,
    weekStart,
    weekEnd,
    nowButtonContainer,
    // selectedDay,
    squadronSelection,
    setSelectedMonth,
    setSelectedWeek,
    setSelectedDay,
  } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const boardView = useRecoilValue(boardViewState);
  const airframeData = useRecoilValue(airframeDataState);
  const aircrewData = useRecoilValue(aircrewDataState);

  const screenWidthCalc = useBreakpointValue({
    base: 140,
    lg: 460,
  });
  const screenWidthAdjusted = screenWidth - screenWidthCalc;

  const filteredAircrewData = aircrewData
    ?.filter((item) => item.booking)
    .map((item) => ({
      id: item?.booking?.id,
      type: "tempBooking",
      data: item?.booking,
    }));

  // used to add temp unavailable aircrew
  const updatedData = useMemo(
    () => data && [...data, ...filteredAircrewData],
    [data, filteredAircrewData]
  );

  // console.log("data: ", data);
  // console.log("filteredAircrewData", filteredAircrewData);
  // console.log("updatedData", updatedData);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const serials = useMemo(
    () =>
      airframeData
        .filter((data) => data?.squadron === squadronSelection)
        .map((airframe) => airframe.serial),
    [squadronSelection, airframeData]
  );

  const channels = useMemo(
    () => [
      ...serials.map((serial, index) => {
        const airframes = airframeData.find((item) => item.serial === serial);
        const lastAirframe = serials.length - 1 === index;

        return {
          type: "airframe",
          serial: serial,
          uuid: serial,
          data: airframes,
          lastAirframe: lastAirframe,
          zoomLevel: zoomLevel,
        };
      }),

      ...aircrewData
        .filter((data) => data?.callsign !== "Callsign") // remove dummy data used for headings in Pre-Flight Tab Select component
        .filter(
          (sqd) =>
            sqd?.squadron === (squadronSelection === "40 SQN/CFS" ? 40 : 14)
        )
        .map((crew) => {
          const tempUnavailable = Boolean(
            crew?.booking?.status === "tempUnavailable"
          );
          return {
            type: "aircrew",
            serial: crew.callsign,
            available: crew.available,
            uuid: "aircrew" + crew.id,
            squadron: crew.squadron,
            tempUnavailable: tempUnavailable,
          };
        }),
    ],

    [serials, squadronSelection, zoomLevel, airframeData, aircrewData]
  );

  const bookings = useMemo(
    () =>
      updatedData && [
        ...updatedData.map((booking, index) => {
          const lastAirframe = updatedData.length - 1 === index;
          return {
            ...booking.data,
            lastAirframe: lastAirframe,
            channelUuid: booking.data.airframe,
            airframe: booking.data.airframe,
          };
        }),
        ...updatedData
          .filter((booking) => booking.data?.pilot)
          .filter((sqd) => sqd.data?.squadron === squadronSelection)
          .map((booking) => ({
            ...booking.data,
            channelUuid: "aircrew" + booking.data.pilot,
            aircrew: booking.data.pilot,
          })),
        ...updatedData
          .filter((booking) => booking.data?.passenger)
          .filter((sqd) => sqd.data?.squadron === squadronSelection)
          .map((booking) => ({
            ...booking.data,
            channelUuid: "aircrew" + booking.data.passenger,
            aircrew: booking.data.passenger,
          })),
      ],
    [updatedData, squadronSelection]
  );
  // console.log("bookings", bookings);

  const epg = useMemo(() => {
    const afb = serials.flatMap((serial) => {
      const acBookings =
        bookings
          ?.filter((booking) => serial === booking.channelUuid)
          .filter((booking) => booking?.status !== "declined") ?? []; // added ?? [] as sometimes the board crashes which I suspect may be due to scrolling before data has fully loaded
      // .filter((booking) => booking.status !== "draft");

      const aircraft = airframeData.find(
        (aircraft) => aircraft.serial === serial
      );
      const aircraftAvailability = aircraft?.status
        ? aircraft.status.airworthy
        : null;

      const availableTimes =
        acBookings && calculateAvailableSlots(acBookings, selectedMonth);
      const splitSlots = availableTimes && splitAvailableSlots(availableTimes);
      const availableBookingSlots = splitSlots?.map((slot) => ({
        id: uuid(),
        channelUuid: serial,
        type: !aircraftAvailability ? "unavailable" : "available",
        ...slot,
      }));

      return [...acBookings, ...availableBookingSlots];
    });

    const acb = aircrewData
      ? aircrewData.flatMap((crew) => {
          const crewBookings =
            bookings
              ?.filter((booking) => "aircrew" + crew.id === booking.channelUuid)
              .filter((booking) => booking?.status !== "declined") ?? []; // added ?? [] as sometimes the board crashes which I suspect may be due to scrolling before data has fully loaded

          const availableTimes =
            crewBookings &&
            calculateAvailableSlots(crewBookings, selectedMonth);
          const splitSlots =
            availableTimes && splitAvailableSlots(availableTimes);
          const availableBookingSlots = splitSlots?.map((slot) => ({
            id: uuid(),
            channelUuid: "aircrew" + crew.id,
            type: crew.available || crew.booking ? "available" : "unavailable",
            ...slot,
          }));
          return [...crewBookings, ...availableBookingSlots];
        })
      : [];

    return [...afb, ...acb];
  }, [serials, bookings, selectedMonth, airframeData, aircrewData]);

  const boardViewType =
    boardView === "month" ? "week" : boardView === "week" ? "week" : "day";

  const {
    getEpgProps,
    getLayoutProps,
    onScrollToNow,
    // onScrollLeft,
    // onScrollRight,
  } = useEpg({
    epg,
    channels,
    startDate:
      boardView === "day"
        ? dayStart
        : boardView === "week"
        ? weekStart
        : format(new Date(2024, selectedMonth - 1, 1), "yyyy-MM-dd'T'HH:mm:SS"), // First day of the month with required time 00:00:00
    endDate:
      boardView === "day"
        ? dayEnd
        : boardView === "week"
        ? weekEnd
        : format(new Date(2024, selectedMonth, 1), "yyyy-MM-dd'T'HH:mm:SS"), // Last day of the month with required time 00:00:00
    // startDate: format(Date.now(), "yyyy/MM/dd"), // "2023/08/22", or 2022-02-02T00:00:00
    // width: 1200,
    // height: 940,
    theme,
    isCurrentTime: true,
    isInitialScrollToNow: true,
    isLine: true,
    mode: { type: boardViewType, style: "modern" }, // for a day view change this to day - also need to shorten the start and end date to a 24 hour period -- this could be manipulated programatically with buttons?
    globalStyles,
    dayWidth:
      zoomLevel === 0 ? screenWidthAdjusted : zoomLevel === 1 ? 4800 : 7200,
    itemHeight: zoomLevel === 0 ? 30 : zoomLevel === 1 ? 50 : 72,
    // overlap: { enabled: true, mode: "stack", layerOverlapLevel: 50 },
  });

  useEffect(() => {
    onScrollToNow();
  }, [boardView, onScrollToNow]);

  return (
    <>
      <Epg
        {...getEpgProps()}
        className={`planby ${
          zoomLevel === 0 ? "small" : zoomLevel === 1 ? "medium" : ""
        } ${
          boardView === "month"
            ? "month"
            : boardView === "week"
            ? "week"
            : "day"
        }`}
        style={{ borderRadius: "15px 15px 15px 15px" }}
      >
        <Layout
          className="layout"
          style={{
            zIndex: "1001",
          }}
          {...getLayoutProps()}
          // renderTimeline={(props) => (
          //   <Timeline {...props} zoomLevel={zoomLevel} />
          // )}
          renderChannel={({ channel }) => (
            <>
              {channel.index === 0 && (
                <Flex
                  bg="white"
                  width={{ base: "100px", smd: "100px" }}
                  height="15px"
                  mt="-10px"
                  position="absolute"
                  zIndex="0"
                  alignItems={"center"}
                  justifyContent="center"
                  flexDirection="column"
                  gap="0px"
                  cursor="pointer"
                ></Flex>
              )}

              <Airframe
                key={channel.serial}
                airframe={channel}
                zoomLevel={zoomLevel}
                data={channel.data}
              />
            </>
          )}
          renderProgram={({ program, ...rest }) => (
            <Booking
              key={"" + program.data.channelUuid + program.data.id}
              program={program}
              {...rest}
              zoomLevel={zoomLevel}
              type={program.data.channelUuid}
              airframe={program.data.airframe}
            />
          )}
        />
      </Epg>
      <Portal {...nowButtonContainer}>
        <Button
          borderRadius="20px"
          onClick={() => {
            onScrollToNow();
            setSelectedMonth(new Date().getMonth() + 1);
            setSelectedWeek(0);
            setSelectedDay(0);
          }}
          variant="outline"
          size="sm"
          bg="gray.50"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={{ base: "50px", sm: "50px", smd: "60px", "2xl": "70px" }}
          height="30px"
          fontSize="0.7rem"
          color="gray.600"
          _hover={{
            background: "white",
            color: "gray.700",
          }}
        >
          NOW
        </Button>
      </Portal>
    </>
  );
}
