import React from "react";
import { useRecoilValue } from "recoil";
import AuthorisationsTable from "./AuthorisationsTable";
import Authorisation from "./Authorisation";
import { editAuthorisationState, newAuthorisationState } from "../globalState";

export default function Authorisations(props) {
  const { data } = props;
  const editAuthorisation = useRecoilValue(editAuthorisationState);
  const newAuthorisation = useRecoilValue(newAuthorisationState);

  if (data) {
    return (
      <>
        {editAuthorisation || newAuthorisation ? (
          <Authorisation data={data} />
        ) : (
          <AuthorisationsTable data={data} />
        )}
      </>
    );
  }
}
