import React, {
  useEffect,
  // useRef,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { formatToTimeZone } from "date-fns-timezone";
// import { Select } from "chakra-react-select";
import { format, addMinutes } from "date-fns";
import briefingOptions from "../../Data/briefing.json";
import customsDocsData from "../../Data/international-documents.json";
import {
  FormLabel,
  FormControl,
  Input,
  Flex,
  Text,
  Textarea,
  // Portal,
  Box,
  Button,
  // Collapse,
  Icon,
  // Badge,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useTab,
  Link,
} from "@chakra-ui/react";
import { GiCheckMark } from "react-icons/gi";
import { FaExclamation } from "react-icons/fa";
import { ReactComponent as BriefingMainIcon } from "../../Assets/briefing-options-main.svg";
import { ReactComponent as BriefingTabbedIcon } from "../../Assets/briefing-options-tabbed.svg";
import { ReactComponent as PdfIcon } from "../../Assets/pdf.svg";
import IntelBrief from "../../Assets/pdf/FOMS-Demo-Intel-Briefing-Example.pdf";
// import NZCustomsForm from "../../Assets/pdf/FOMS-Demo-Intel-Briefing-Example.pdf";
import NZCustomsForm1 from "../../Assets/nz-customs-forms/cr2018-arr1-customs-arriving-passenger-and-crew-declarations-rules-2018.pdf";
import NZCustomsForm2 from "../../Assets/nz-customs-forms/form-03a-customs-declared-goods.pdf";
import NZCustomsForm3 from "../../Assets/nz-customs-forms/form-5-aircrew-declaration-non-new-zealand-domiciled-crew-march-2023.pdf";
import NZCustomsForm4 from "../../Assets/nz-customs-forms/form-c1a-inward-report-aircraft.pdf";
import NZCustomsForm5 from "../../Assets/nz-customs-forms/form-c2a-advance-notice-of-departure-aircraft.pdf";
import NZCustomsForm6 from "../../Assets/nz-customs-forms/form-c3a-certificate-of-clearance-aircraft.pdf";
import NZCustomsForm7 from "../../Assets/nz-customs-forms/nzcs-218-bringing-personal-household-items-into-new-zealand.pdf";
import NZCustomsForm8 from "../../Assets/nz-customs-forms/nzcs-337-border-cash-report-august-2022.pdf";
import NZCustomsForm9 from "../../Assets/nz-customs-forms/nzcs-344-new-zealand-border-agencies-advance-notice-of-arrival.pdf";
import NZCustomsForm10 from "../../Assets/nz-customs-forms/nzcs-502-certificate-of-export.pdf";

export default function PostFlightTab(props) {
  const {
    authorisationData,
    aircrewData,
    register,
    control,
    // getValues,
    // setValue,
    errors,
    // watch,
    // editAuthorisation,
    timeZone,
    // selectStyle,
    // squadronSelection,
  } = props;

  // const flightSafetyEventMobile = useRef();
  // const flightSafetyEventDesktop = useRef();
  const [tabbedView, setTabbedView] = useState(true);

  const hideViewSelectSwitch = window.innerWidth < "864px";
  useEffect(() => {
    if (hideViewSelectSwitch) {
      setTabbedView(false);
    }
  }, [hideViewSelectSwitch, setTabbedView]);

  // const flightSafetyEventContainer = useBreakpointValue({
  //   base: { containerRef: flightSafetyEventMobile },
  //   '2xl': { containerRef: flightSafetyEventDesktop },
  // });

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };
  // flatpickr refs
  // const fpDateActualDep = useRef(null);
  // const fpDateActualArr = useRef(null);
  const now = Date.now();

  // determine duration
  // const since = format(parseISO(authorisationData?.since), "HH:mm");
  // const till = format(parseISO(authorisationData?.till), "HH:mm");
  // const duration =
  //   since && till && since <= till
  //     ? intervalToDuration({
  //         start: parseISO(authorisationData?.since),
  //         end: parseISO(authorisationData?.till),
  //       })
  //     : null;

  // const durationDetail =
  //   duration &&
  //   formatDuration(duration, {
  //     format: ["hours", "minutes"],
  //   });
  // const padWithZero = (value) => (value < 10 ? `0${value}` : value);
  // const durationHasDays = duration && duration?.days ? duration.days * 24 : 0;
  // const formattedDuration =
  //   duration &&
  //   durationHasDays &&
  //   `${durationHasDays + duration?.hours}:${padWithZero(duration?.minutes)}`;

  const getAircrewDetails = (id) => {
    const aircrew = aircrewData.find((crew) => crew.id === id);
    return aircrew && aircrew.id ? aircrew : null;
  };

  const pilotName = getAircrewDetails(authorisationData?.pilot)?.name;
  const pilotCallsign = getAircrewDetails(authorisationData?.pilot)?.callsign;
  const passengerName = getAircrewDetails(authorisationData?.passenger)?.name;
  const passengerCallsign = getAircrewDetails(
    authorisationData?.passenger
  )?.callsign;

  const buttonBgActive = "#F2F5F8";
  const buttonBgHover = "gray.200";

  // calc to work out how to space options in an exponential radius
  const calculateMargin = (number) => {
    const baseMargin = 40;
    if (number >= 1 && number <= 10) {
      const difference = Math.abs(number - 5.5);
      return baseMargin * Math.exp(-0.8 * (4.5 - difference));
    } else if (number >= 11 && number <= 20) {
      const difference = Math.abs(number - 15.5);
      return baseMargin * Math.exp(-0.8 * (4.5 - difference));
    } else {
      return 0;
    }
  };

  const BriefingOption = ({ label, id, completed, index }) => {
    return (
      <Link
        href={
          label === "International Documents"
            ? NZCustomsForm6
            : label === "Intelligence Brief"
            ? IntelBrief
            : null
        }
        isExternal
        minWidth={{ base: "100%", ml: "60%" }}
        pl={{ base: "0px", ml: id <= 10 ? "7px" : "4px" }}
        pr={{ base: "7px", ml: id > 10 ? "7px" : "4px" }}
        py="2px"
        borderRadius="20px"
        border="1px solid"
        borderColor="#e5e5e5"
        minHeight="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "row", ml: id > 10 ? "row" : "row-reverse" }}
        gap="10px"
        bg="#F7FAFC"
        cursor="pointer"
        transition="all ease 150ms"
        _hover={{
          bg: "#FDFEFF",
          boxShadow: "0px 5px 15px -4px rgba(0, 45, 78, 0.3)",
          transform: {
            base: "scale(1.03) translateY(-1px)",
            lg: "scale(1.07) translateY(-1px)",
          },
        }}
        marginLeft={{
          base: "0px",
          ml: id <= 10 && `${calculateMargin(id)}%`,
        }}
        marginRight={{
          base: "0px",
          ml: id > 10 && `${calculateMargin(id)}%`,
        }}
      >
        <Flex
          zIndex="10"
          mt="-4px"
          mb="-4px"
          mr={{ base: "0px", ml: id <= 10 ? "-5px" : "0px" }}
          ml={{ base: "0px", ml: id > 10 ? "-5px" : "0px" }}
          width={{ base: "34px", ml: "34px" }}
          minWidth={{ base: "34px", ml: "34px" }}
          minHeight={{ base: "34px", ml: "34px" }}
          borderRadius="50px"
          justifyContent="center"
          alignItems="center"
          bg={
            completed === "warning" || completed === "caution"
              ? "gray.400"
              : completed
              ? "cyan.400"
              : "cyan.700"
          }
          fontSize="0.75rem"
          fontWeight="600"
          p="0px"
          color="white"
        >
          {id}
        </Flex>
        <Flex
          width="100%"
          gap="5px"
          minHeight={{ base: "16px", ml: "18px" }}
          fontSize={{ base: "0.7rem", ml: "0.75rem" }}
          justifyContent={{
            base: "flex-start",
            ml: id > 10 ? "flex-start" : "flex-end",
          }}
          alignItems="center"
        >
          <Text
            textAlign={{ base: "left", ml: id > 10 ? "left" : "right" }}
            color="gray.600"
            fontWeight="600"
          >
            {label}
          </Text>
          {(label === "International Documents" ||
            label === "Intelligence Brief") && (
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          )}
        </Flex>

        <Flex
          width={{ base: "18px", ml: "20px" }}
          minWidth={{ base: "18px", ml: "20px" }}
          minHeight={{ base: "18px", ml: "20px" }}
          borderRadius="50px"
          border="1px solid"
          borderColor={
            completed === "warning"
              ? "#ff0000"
              : completed === "caution"
              ? "#ffdb00"
              : completed
              ? "rgb(0, 251, 0)"
              : "#eaeaea"
          }
          justifyContent="center"
          alignItems="center"
          bg={
            completed === "warning"
              ? "#ff0000"
              : completed === "caution"
              ? "#ffdb00"
              : completed
              ? "rgb(0, 251, 0)"
              : "white"
          }
          fontSize="0.7rem"
          fontWeight="600"
          p="0px"
          color="white"
        >
          <Icon
            as={
              completed === "warning" || completed === "caution"
                ? FaExclamation
                : GiCheckMark
            }
            color="white"
            fontSize="0.8rem"
            pt={completed !== "warning" && completed !== "caution" && "1px"}
            pr={completed !== "warning" && completed !== "caution" && "1px"}
          />
        </Flex>
      </Link>
    );
  };

  const BriefingTab = ({ label, id, completed }) => {
    const tabProps = useTab({ ...props });
    const isSelected = !!tabProps["aria-selected"];
    return (
      <Tab
        {...tabProps}
        minWidth={{ base: "100%", ml: "260px" }}
        pl="12px"
        pr={isSelected ? "15px" : "14px"}
        py="2px"
        ml={isSelected ? "0px" : "10px"}
        mr={isSelected ? "-2px" : "-1px"}
        borderLeftRadius="15px"
        borderRightRadius="0px"
        borderColor="#e5e5e5"
        borderBottomColor="#e5e5e5 !important"
        borderRightColor={isSelected ? "white !important" : "#e5e5e5"}
        minHeight="46px"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        bg={isSelected ? "#FBFDFF" : "gray.100"}
        cursor="pointer"
        transition="all ease 150ms"
        clipPath="inset(-50px 0px -50px -50px)"
        zIndex={isSelected ? "2" : "1"}
        boxShadow={
          isSelected
            ? "0px 4px 10px -3px rgba(0, 45, 78, 0.15)"
            : "inset -6px 0px 15px -10px rgba(0, 45, 78, 0.15)"
        }
        _hover={{
          marginLeft: isSelected ? "0px" : "5px",
          zIndex: "3",
          bg: "#FDFEFF",
          boxShadow: "0px 5px 15px -4px rgba(0, 45, 78, 0.3)",
        }}
      >
        <Flex
          zIndex="10"
          width="24px"
          minWidth="24px"
          minHeight="24px"
          borderRadius="50px"
          justifyContent="center"
          alignItems="center"
          bg={
            completed === "warning" || completed === "caution"
              ? "gray.400"
              : completed
              ? "cyan.400"
              : "cyan.700"
          }
          fontSize="0.75rem"
          fontWeight="600"
          p="0px"
          color="white"
        >
          {id}
        </Flex>
        <Flex
          width="100%"
          gap="5px"
          minHeight={{ base: "16px", ml: "18px" }}
          fontSize={{ base: "0.7rem", ml: "0.75rem" }}
          justifyContent="flex-start"
        >
          <Text color="gray.600" fontWeight="600">
            {label}
          </Text>
          {(label === "International Documents" ||
            label === "Intelligence Brief") && (
            <Text color="brand.100" fontSize="0.75rem">
              *
            </Text>
          )}
        </Flex>

        <Flex
          width={{ base: "18px", ml: "20px" }}
          minWidth={{ base: "18px", ml: "20px" }}
          minHeight={{ base: "18px", ml: "20px" }}
          borderRadius="50px"
          border="1px solid"
          borderColor={
            completed === "warning"
              ? "#ff0000"
              : completed === "caution"
              ? "#ffdb00"
              : completed
              ? "rgb(0, 251, 0)"
              : "#eaeaea"
          }
          justifyContent="center"
          alignItems="center"
          bg={
            completed === "warning"
              ? "#ff0000"
              : completed === "caution"
              ? "#ffdb00"
              : completed
              ? "rgb(0, 251, 0)"
              : "white"
          }
          fontSize="0.7rem"
          fontWeight="600"
          p="0px"
          color="white"
        >
          <Icon
            as={
              completed === "warning" || completed === "caution"
                ? FaExclamation
                : GiCheckMark
            }
            color="white"
            fontSize="0.8rem"
            pt={completed !== "warning" && completed !== "caution" && "1px"}
            pr={completed !== "warning" && completed !== "caution" && "1px"}
          />
        </Flex>
      </Tab>
    );
  };

  const pdfImports = {
    NZCustomsForm1: NZCustomsForm1,
    NZCustomsForm2: NZCustomsForm2,
    NZCustomsForm3: NZCustomsForm3,
    NZCustomsForm4: NZCustomsForm4,
    NZCustomsForm5: NZCustomsForm5,
    NZCustomsForm6: NZCustomsForm6,
    NZCustomsForm7: NZCustomsForm7,
    NZCustomsForm8: NZCustomsForm8,
    NZCustomsForm9: NZCustomsForm9,
    NZCustomsForm10: NZCustomsForm10,
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex
          flexDirection={{ base: "column", "2xl": "row" }}
          width="100%"
          height="100%"
        >
          {/* <Box ref={flightSafetyEventMobile}></Box> */}
          <Flex
            background="#FBFDFF"
            flexDirection="column"
            width={{ base: "100%", "2xl": "calc(100% - 340px)" }}
            height="100%"
          >
            <Flex width="100%" flexDirection="column">
              <Flex
                display={{ base: "none", ml: "flex" }}
                width="100%"
                justifyContent={{ base: "flex-end", ml: "flex-start" }}
                alignItems="center"
                px="15px"
                pt={{ base: "10px", ml: "15px" }}
                pb={{ base: "10px", ml: "0px" }}
                mb={{ base: "0px", ml: tabbedView ? "-40px" : "-30px" }}
                zIndex="9"
              >
                <Button
                  bg={"#F0F5FA"}
                  p="0px"
                  opacity="1"
                  variant="outline"
                  fontSize="11px"
                  height={{
                    base: "26px",
                    md: "24px",
                  }}
                  width={"104px"}
                  style={{
                    minWidth: "104px",
                  }}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  borderRadius={"20px"}
                  boxShadow={"inset 0px 0px 3px 0px rgba(0,0,0,0.15)"}
                  border={"none"}
                  borderColor="rgba(255,255,255,0.1)"
                  {...noFocus}
                  index={tabbedView ? 1 : 0}
                  _active={{
                    bg: buttonBgActive,
                  }}
                  _focus={{
                    bg: buttonBgActive,
                  }}
                  _hover={{
                    bg: buttonBgHover,
                  }}
                  onClick={() => {
                    setTabbedView((prevState) => !prevState);
                  }}
                >
                  <Box
                    zIndex="1"
                    display={"flex"}
                    ml="2px"
                    height={{ base: "22px", md: "20px" }}
                    width="50px"
                    minWidth="50px"
                    background="white"
                    borderRadius="20px"
                    bg={"#ffffff"}
                    boxShadow="0px 0px 1px 0px rgba(0,0,0,0.25)"
                    borderColor="#E9EAEB"
                    transition="all ease 300ms"
                    transform={
                      tabbedView ? "translateX(100%)" : "translateX(0%)"
                    }
                  ></Box>
                  <Flex
                    zIndex="2"
                    opacity={"1"}
                    _hover={{ opacity: "1" }}
                    justifyContent="space-evenly"
                    marginLeft={"-50px"}
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.3"
                      color={!tabbedView ? "cyan.400" : "gray.500"}
                      fontWeight={!tabbedView ? "700" : "500"}
                      opacity={!tabbedView ? "1" : "0.8"}
                      transition="all ease 300ms"
                      _selected={{
                        opacity: "1",
                      }}
                      _active={{
                        opacity: "1",
                      }}
                      _hover={{
                        opacity: "1",
                      }}
                      height={{
                        base: "26px",
                        md: "24px",
                      }}
                      minWidth="50px"
                    >
                      <Icon as={BriefingMainIcon} width="16px" height="16px" />
                    </Flex>
                    <Flex
                      zIndex="1"
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.3"
                      color={tabbedView ? "cyan.400" : "gray.500"}
                      fontWeight={tabbedView ? "700" : "500"}
                      opacity={tabbedView ? "1" : "0.8"}
                      transition="all ease 300ms"
                      _selected={{
                        opacity: "1",
                      }}
                      _active={{
                        opacity: "1",
                      }}
                      _hover={{
                        opacity: "1",
                      }}
                      height={{
                        base: "26px",
                        md: "24px",
                      }}
                      minWidth="50px"
                    >
                      <Icon
                        as={BriefingTabbedIcon}
                        width="16px"
                        height="16px"
                      />
                    </Flex>
                  </Flex>
                </Button>
              </Flex>

              {!tabbedView && (
                <Flex
                  width="100%"
                  flexDirection={{ base: "column", smd: "row", ml: "row" }}
                  alignItems="center"
                  pt={{ base: "20px", ml: "5px" }}
                  pb={{ base: "30px", ml: "20px" }}
                >
                  <Flex
                    width="100%"
                    gap="10px"
                    flexDirection="column"
                    justifyContent={{ base: "flex-start", ml: "flex-start" }}
                    alignItems={{ base: "center", ml: "flex-start" }}
                    px="15px"
                    pt={{ base: "5px", ml: "15px" }}
                    pb={{ base: "5px", ml: "20px" }}
                  >
                    {briefingOptions &&
                      briefingOptions
                        .filter((feat) => feat.side === "left")
                        .map((item, index) => (
                          <BriefingOption
                            key={`label.${index}`}
                            {...item}
                            index={index}
                          />
                        ))}
                  </Flex>

                  <Flex
                    display={{ base: "none", ml: "flex" }}
                    width={{ base: "180px", lg: "220px" }}
                    minWidth={{ base: "180px", lg: "220px" }}
                    minHeight={{ base: "180px", lg: "220px" }}
                    mx={{ base: "-90px", lg: "-110px" }}
                    justifyContent="center"
                    alignItems="center"
                    fontSize="0.7rem"
                    fontWeight="600"
                    pt="10px"
                    pb="15px"
                    px="15px"
                    borderRadius="20px"
                    border="1px solid"
                    borderColor="#e5e5e5"
                    bg="white"
                    cursor="pointer"
                    transition="all ease 150ms"
                    boxShadow="0px 8px 30px -10px rgba(0, 45, 78, 0.3)"
                  >
                    <Flex
                      gap="2px"
                      width="100%"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                      zIndex="2"
                    >
                      <Flex
                        gap="10px"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderBottom="1px solid"
                        borderColor="gray.200"
                        pb="8px"
                        mb="5px"
                      >
                        <Text fontSize="0.85rem" fontWeight="600">
                          {authorisationData?.title}
                        </Text>

                        {/* {authorisationData?.priority && (
                        <Badge
                          variant="solid"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color={
                            authorisationData?.priority === "High"
                              ? "white"
                              : "initial"
                          }
                          textTransform="uppercase"
                          minWidth="50px"
                          px="3px"
                          mt="2px"
                          lineHeight="1"
                          background={
                            authorisationData?.priority === "High"
                              ? "#ff6a00"
                              : authorisationData?.priority === "Medium"
                              ? "yellow"
                              : authorisationData?.priority === "Low"
                              ? "#00fb00"
                              : "gray.100"
                          }
                          height={"16px"}
                          borderRadius="25px"
                          fontSize="0.6rem"
                          fontWeight="600"
                        >
                          {authorisationData?.priority}
                        </Badge>
                      )} */}
                      </Flex>

                      <Flex
                        gap="10px"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        width="100%"
                        pl="5px"
                      >
                        {/* <Flex gap="10px" alignItems="baseline">
                        <Text fontSize="0.8rem" fontWeight="500">
                          {since} - {till}
                        </Text>
                        <Text
                          fontSize="0.75rem"
                          opacity="0.6"
                          fontWeight="400"
                          fontStyle="italic"
                          pr="2px"
                        >
                          {durationDetail}
                        </Text>
                      </Flex> */}
                        {/* <Text
                        fontSize="0.75rem"
                        fontWeight="400"
                        textTransform="uppercase"
                        color={
                          authorisationData?.status === "pending" && upcoming
                            ? "yellow"
                            : authorisationData?.status === "draft" && upcoming
                            ? "gray.300"
                            : upcoming
                            ? "#00fb00"
                            : expired
                            ? "#ff6a00"
                            : "cyan.300"
                        }
                        pr="2px"
                      >
                        {authorisationData?.status === "pending" && upcoming
                          ? "Proposed"
                          : authorisationData?.status === "draft" && upcoming
                          ? "Tentative"
                          : upcoming
                          ? "Upcoming"
                          : expired
                          ? "Expired"
                          : "Active"}
                      </Text> */}
                      </Flex>

                      <Flex gap="10px" fontSize="0.75rem" pl="5px" width="100%">
                        <Text fontWeight="500" opacity="0.65">
                          Activity:
                        </Text>
                        <Text fontWeight="400" pr="2px">
                          {authorisationData?.activity}
                        </Text>
                      </Flex>

                      <Flex gap="10px" fontSize="0.75rem" pl="5px" width="100%">
                        <Text fontWeight="500" opacity="0.65">
                          Area:
                        </Text>
                        <Text fontWeight="400" pr="2px">
                          {authorisationData?.area}
                        </Text>
                      </Flex>

                      <Flex
                        gap="10px"
                        fontSize="0.75rem"
                        borderBottom="1px solid"
                        borderColor="gray.200"
                        pb="7px"
                        mb="5px"
                        pl="5px"
                        width="100%"
                      >
                        <Text fontWeight="500" opacity="0.65">
                          Intentions:
                        </Text>
                        <Text fontWeight="400" pr="2px">
                          {authorisationData?.intentions}
                        </Text>
                      </Flex>

                      <Flex gap="10px" fontSize="0.75rem" pl="5px" width="100%">
                        <Text fontWeight="500" opacity="0.65">
                          Aircraft:
                        </Text>
                        <Text fontWeight="400" pr="2px">
                          {authorisationData?.aircraft}
                        </Text>
                      </Flex>

                      <Flex
                        gap="10px"
                        fontSize="0.75rem"
                        pl="5px"
                        borderBottom="1px solid"
                        borderColor="gray.200"
                        pb="7px"
                        mb="5px"
                        width="100%"
                      >
                        <Text fontWeight="500" opacity="0.65">
                          Airframe:
                        </Text>
                        <Text fontWeight="400" pr="2px">
                          {authorisationData?.airframe}
                        </Text>
                      </Flex>

                      <Flex
                        gap="10px"
                        fontSize="0.75rem"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="baseline"
                        pl="5px"
                      >
                        <Flex gap="10px">
                          <Text fontWeight="500" opacity="0.65">
                            Pilot:
                          </Text>
                          <Text
                            fontWeight="400"
                            pr="2px"
                            opacity={pilotName ? "1" : "0.4"}
                            fontStyle={pilotName ? "normal" : "italic"}
                          >
                            {pilotName ? pilotName : "Not assigned"}
                          </Text>
                        </Flex>
                        <Text
                          fontWeight="300"
                          pr="2px"
                          fontStyle="italic"
                          opacity="0.5"
                          fontSize="0.7rem"
                        >
                          {pilotCallsign ? pilotCallsign : ""}
                        </Text>
                      </Flex>

                      <Flex
                        gap="10px"
                        fontSize="0.75rem"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="baseline"
                        pl="5px"
                      >
                        <Flex gap="10px">
                          <Text fontWeight="500" opacity="0.65">
                            Co-Pilot:
                          </Text>
                          <Text
                            fontWeight="400"
                            pr="2px"
                            opacity={passengerName ? "1" : "0.4"}
                            fontStyle={passengerName ? "normal" : "italic"}
                          >
                            {passengerName ? passengerName : "Not assigned"}
                          </Text>
                        </Flex>
                        <Text
                          fontWeight="300"
                          pr="2px"
                          fontStyle="italic"
                          opacity="0.5"
                          fontSize="0.7rem"
                        >
                          {passengerCallsign ? passengerCallsign : ""}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex
                    width="100%"
                    gap="10px"
                    flexDirection="column"
                    justifyContent={{ base: "flex-start", ml: "flex-end" }}
                    alignItems={{ base: "center", ml: "flex-end" }}
                    px="15px"
                    pt={{ base: "5px", ml: "15px" }}
                    pb={{ base: "5px", ml: "20px" }}
                  >
                    {briefingOptions &&
                      briefingOptions
                        .filter((feat) => feat.side === "right")
                        .map((item, index) => (
                          <BriefingOption
                            key={`label.${index}`}
                            {...item}
                            index={index}
                          />
                        ))}
                  </Flex>
                </Flex>
              )}

              {tabbedView && (
                <Tabs
                  size="md"
                  variant="enclosed"
                  orientation={"vertical"}
                  defaultIndex={7}
                >
                  <TabList
                    gap="5px"
                    pt="55px"
                    pb="25px"
                    pl="10px"
                    marginBottom="0px !important"
                    borderBottom="0px solid !important"
                    borderRight="1px solid"
                    borderColor="#e5e5e5"
                    bg="gray.50"
                    minHeight={{ base: "auto", "2xl": "1054px" }}
                  >
                    {briefingOptions &&
                      briefingOptions.map((item, index) => (
                        <BriefingTab
                          key={`label.${index}`}
                          {...item}
                          index={index}
                        />
                      ))}
                  </TabList>
                  <TabPanels>
                    {briefingOptions &&
                      briefingOptions.map((item, index) => (
                        <TabPanel
                          key={`panel.${index}`}
                          {...item}
                          index={index}
                        >
                          {item?.label === "International Documents" ? (
                            <Flex
                              flexDirection="column"
                              width="100%"
                              p={{ base: "0px", xl: "5px" }}
                              paddingTop="10px"
                              paddingBottom="10px"
                              justifyContent={{ base: "center", xl: "center" }}
                              alignItems="center"
                              gap="17px"
                            >
                              <Text width="100%">Example PDFs:</Text>
                              {customsDocsData &&
                                customsDocsData.map((item, index) => (
                                  <Link
                                    key={`label.${index}`}
                                    index={index}
                                    href={pdfImports[item?.link]}
                                    isExternal
                                    bg="brand.400"
                                    minWidth="220px"
                                    maxWidth="700px"
                                    px="12px"
                                    minHeight="36px"
                                    width="100%"
                                    borderRadius="25px"
                                    color={"gray.500"}
                                    display="flex"
                                    justifyContent="flex-start"
                                    fontWeight={"600"}
                                    fontSize="0.75rem"
                                    textAlign="center"
                                    alignItems="center"
                                    lineHeight="1.2"
                                    textTransform="capitalize"
                                    cursor="pointer"
                                    _hover={{ bg: "white", color: "gray.600" }}
                                    border="1px solid"
                                    borderColor="gray.200"
                                    gap="10px"
                                  >
                                    <Icon as={PdfIcon} boxSize="18px" />
                                    {item?.label}
                                  </Link>
                                ))}
                            </Flex>
                          ) : item?.label === "Intelligence Brief" ? (
                            <Flex
                              width="100%"
                              px={{ base: "0px", xl: "5px" }}
                              paddingTop="15px"
                              paddingBottom="15px"
                              justifyContent={{ base: "center", xl: "center" }}
                              alignItems="center"
                            >
                              <Link
                                href={IntelBrief}
                                isExternal
                                bg="brand.400"
                                minWidth="220px"
                                px="12px"
                                minHeight="36px"
                                width="100%"
                                borderRadius="25px"
                                color={"gray.500"}
                                display="flex"
                                justifyContent="flex-start"
                                fontWeight={"600"}
                                fontSize="0.75rem"
                                textAlign="center"
                                alignItems="center"
                                lineHeight="1.2"
                                textTransform="capitalize"
                                cursor="pointer"
                                _hover={{ bg: "white", color: "gray.600" }}
                                border="1px solid"
                                borderColor="gray.200"
                                gap="10px"
                              >
                                <Icon as={PdfIcon} boxSize="18px" />
                                {item?.label} Example
                              </Link>
                            </Flex>
                          ) : (
                            <Text>{item?.label} content to display here</Text>
                          )}
                        </TabPanel>
                      ))}
                  </TabPanels>
                </Tabs>
              )}
            </Flex>
          </Flex>

          <Flex
            flexDirection={{ base: "column", "2xl": "column" }}
            width={{ base: "100%", "2xl": "340px" }}
            maxWidth={{ base: "100%", "2xl": "340px" }}
            height="100%"
            minHeight={tabbedView ? "1075px" : "100%"}
            justifyContent="stretch"
            bg="brand.400"
            borderLeft={{ base: "0px solid", "2xl": "1px solid #e5e7ea" }}
            borderColor="#e5e7ea"
          >
            {/* <Portal {...flightSafetyEventContainer}>
              <Flex
                width="100%"
                height="60px"
                p={{ base: "20px", '2xl': "15px" }}
                paddingTop="10px"
                paddingBottom="10px"
                borderBottom="1px solid #e5e7ea"
                gap="10px"
                bg="brand.400"
                justifyContent={{ base: "center", '2xl': "center" }}
                alignItems="center"
              >
                <Flex
                  bg={"pink.400"}
                  width="220px"
                  height="26px"
                  borderRadius="25px"
                  gap="20px"
                  color={"white"}
                  justifyContent="center"
                  fontWeight={"600"}
                  fontSize="0.85rem"
                  alignItems="center"
                  lineHeight="1.2"
                  textTransform="capitalize"
                  cursor="pointer"
                  _hover={{ bg: "pink.500" }}
                >
                  Lodge a Flight Safety Event
                </Flex>
              </Flex>
            </Portal>
            <Box ref={flightSafetyEventDesktop}></Box> */}

            {/* {squadronSelection === "40 SQN/CFS" && (
              <Flex
                width="100%"
                height="60px"
                p={{ base: "20px", xl: "15px" }}
                paddingTop="10px"
                paddingBottom="10px"
                borderTop={{
                  base: "1px solid #e5e7ea",
                  xl: "0px solid #e5e7ea",
                }}
                borderBottom={{
                  base: "0px solid #e5e7ea",
                  xl: "1px solid #e5e7ea",
                }}
                gap="10px"
                bg="brand.400"
                justifyContent={{ base: "center", xl: "center" }}
                alignItems="center"
              >
                <Link
                  href={IntelBrief}
                  isExternal
                  bg={"white"}
                  width="220px"
                  height="28px"
                  borderRadius="25px"
                  gap="20px"
                  color={"gray.600"}
                  display="flex"
                  justifyContent="center"
                  fontWeight={"600"}
                  fontSize="0.85rem"
                  alignItems="center"
                  lineHeight="1.2"
                  border="1px solid"
                  borderColor="gray.200"
                  textTransform="capitalize"
                  cursor="pointer"
                  _hover={{ bg: "brand.500" }}
                >
                  Intel Brief
                </Link>
              </Flex>
            )} */}

            <Flex // Operations Squadron
              flexDirection={{ base: "column", "2xl": "column" }}
              width="100%"
              height="100%"
              minHeight="100%"
              p={{ base: "20px", "2xl": "20px" }}
              paddingTop={{ base: "15px", "2xl": "15px" }}
              paddingBottom={{ base: "30px", "2xl": "30px" }}
              gap="20px"
              borderTop={{ base: "1px solid", "2xl": "none" }}
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                Operations Squadron
              </Text>

              <Flex
                flexDirection="column"
                width="100%"
                height="100%"
                gap="20px"
              >
                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="acceptedbyopssqd"
                    fontFamily="'Poppins'"
                    overflow="hidden"
                    height="20px"
                    maxHeight="20px"
                  >
                    Accepted By
                  </FormLabel>

                  <Input
                    transition="all ease 250ms"
                    fontSize="0.9rem"
                    fontWeight="400"
                    width={{ base: "100%", lg: "100%" }}
                    variant="outline"
                    background="white"
                    size="sm"
                    borderRadius="10px"
                    cursor="pointer"
                    id="acceptedbyopssqd"
                    {...register("acceptedbyopssqd", {})}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="accepteddateopssqd"
                    fontFamily="'Poppins'"
                  >
                    Date of Acceptance
                  </FormLabel>
                  <Controller
                    control={control}
                    name="accepteddateopssqd"
                    rules={{
                      validate: {
                        notInvalid: (value) =>
                          (value !== null && value !== "Invalid Date") ||
                          "Please select date/time",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Flatpickr
                        id="accepteddateopssqd"
                        // ref={fpDateapproved}
                        placeholder="Select date/time..."
                        style={{
                          width: "100%",
                          fontFamily: "'Open Sans', sans-serif",
                          height: "32px",
                          borderRadius: "7px",
                          fontSize: "0.875rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid",
                          borderColor: errors.accepteddateopssqd
                            ? "#E53E3E"
                            : "#ced4da",
                        }}
                        className="flatpickr form-control form-control-sm"
                        data-enable-time
                        value={formatToTimeZone(value, "D MMM YY HH:mm", {
                          timeZone: timeZone,
                        })}
                        onChange={(value) => {
                          onChange(
                            value.length
                              ? format(value[0], "yyyy-MM-dd HH:mm")
                              : null
                          );
                        }}
                        options={{
                          allowClearing: false,
                          // disableMobile: true,
                          dateFormat: "j M y H:i",
                          time_24hr: true,
                          minuteIncrement: 1,
                          // minDate: formatToTimeZone(now, "D MMM YY HH:mm", {
                          //   timeZone: timeZone,
                          // }),
                          defaultDate: formatToTimeZone(
                            addMinutes(now, 5),
                            "D MMM YY HH:mm",
                            { timeZone: timeZone }
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="acceptedrefopssqd"
                    fontFamily="'Poppins'"
                  >
                    References
                  </FormLabel>
                  <Textarea
                    variant="outline"
                    background="white"
                    size="sm"
                    minHeight={{ base: "120px", lg: "80px" }}
                    borderRadius="10px"
                    cursor="pointer"
                    id="acceptedrefopssqd"
                    {...register("acceptedrefopssqd", {})}
                  />
                </FormControl>
              </Flex>
            </Flex>

            <Flex // 230 Squadron
              flexDirection={{ base: "column", "2xl": "column" }}
              width="100%"
              height="100%"
              minHeight="100%"
              p={{ base: "20px", "2xl": "20px" }}
              paddingTop={{ base: "15px", "2xl": "15px" }}
              paddingBottom={{ base: "30px", "2xl": "30px" }}
              gap="20px"
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                230 Squadron
              </Text>

              <Flex
                flexDirection="column"
                width="100%"
                height="100%"
                gap="20px"
              >
                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="acceptedby230sqd"
                    fontFamily="'Poppins'"
                    overflow="hidden"
                    height="20px"
                    maxHeight="20px"
                  >
                    Accepted By
                  </FormLabel>

                  <Input
                    transition="all ease 250ms"
                    fontSize="0.9rem"
                    fontWeight="400"
                    width={{ base: "100%", lg: "100%" }}
                    variant="outline"
                    background="white"
                    size="sm"
                    borderRadius="10px"
                    cursor="pointer"
                    id="acceptedby230sqd"
                    {...register("acceptedby230sqd", {})}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="accepteddate230sqd"
                    fontFamily="'Poppins'"
                  >
                    Date of Acceptance
                  </FormLabel>
                  <Controller
                    control={control}
                    name="accepteddate230sqd"
                    rules={{
                      validate: {
                        notInvalid: (value) =>
                          (value !== null && value !== "Invalid Date") ||
                          "Please select date/time",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Flatpickr
                        id="accepteddate230sqd"
                        // ref={fpDateapproved}
                        placeholder="Select date/time..."
                        style={{
                          width: "100%",
                          fontFamily: "'Open Sans', sans-serif",
                          height: "32px",
                          borderRadius: "7px",
                          fontSize: "0.875rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid",
                          borderColor: errors.accepteddate230sqd
                            ? "#E53E3E"
                            : "#ced4da",
                        }}
                        className="flatpickr form-control form-control-sm"
                        data-enable-time
                        value={formatToTimeZone(value, "D MMM YY HH:mm", {
                          timeZone: timeZone,
                        })}
                        onChange={(value) => {
                          onChange(
                            value.length
                              ? format(value[0], "yyyy-MM-dd HH:mm")
                              : null
                          );
                        }}
                        options={{
                          allowClearing: false,
                          // disableMobile: true,
                          dateFormat: "j M y H:i",
                          time_24hr: true,
                          minuteIncrement: 1,
                          // minDate: formatToTimeZone(now, "D MMM YY HH:mm", {
                          //   timeZone: timeZone,
                          // }),
                          defaultDate: formatToTimeZone(
                            addMinutes(now, 5),
                            "D MMM YY HH:mm",
                            { timeZone: timeZone }
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="acceptedref230sqd"
                    fontFamily="'Poppins'"
                  >
                    References
                  </FormLabel>
                  <Textarea
                    variant="outline"
                    background="white"
                    size="sm"
                    minHeight={{ base: "120px", lg: "80px" }}
                    borderRadius="10px"
                    cursor="pointer"
                    id="acceptedref230sqd"
                    {...register("acceptedref230sqd", {})}
                  />
                </FormControl>
              </Flex>
            </Flex>

            <Flex // Base HQ
              flexDirection={{ base: "column", "2xl": "column" }}
              width="100%"
              height="100%"
              minHeight="100%"
              p={{ base: "20px", "2xl": "20px" }}
              paddingTop={{ base: "15px", "2xl": "15px" }}
              paddingBottom={{ base: "30px", "2xl": "30px" }}
              gap="20px"
            >
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                Base HQ
              </Text>

              <Flex
                flexDirection="column"
                width="100%"
                height="100%"
                gap="20px"
              >
                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="acceptedbybasehq"
                    fontFamily="'Poppins'"
                    overflow="hidden"
                    height="20px"
                    maxHeight="20px"
                  >
                    Accepted By
                  </FormLabel>

                  <Input
                    transition="all ease 250ms"
                    fontSize="0.9rem"
                    fontWeight="400"
                    width={{ base: "100%", lg: "100%" }}
                    variant="outline"
                    background="white"
                    size="sm"
                    borderRadius="10px"
                    cursor="pointer"
                    id="acceptedbybasehq"
                    {...register("acceptedbybasehq", {})}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="accepteddatebasehq"
                    fontFamily="'Poppins'"
                  >
                    Date of Acceptance
                  </FormLabel>
                  <Controller
                    control={control}
                    name="accepteddatebasehq"
                    rules={{
                      validate: {
                        notInvalid: (value) =>
                          (value !== null && value !== "Invalid Date") ||
                          "Please select date/time",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Flatpickr
                        id="accepteddatebasehq"
                        // ref={fpDateapproved}
                        placeholder="Select date/time..."
                        style={{
                          width: "100%",
                          fontFamily: "'Open Sans', sans-serif",
                          height: "32px",
                          borderRadius: "7px",
                          fontSize: "0.875rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid",
                          borderColor: errors.accepteddatebasehq
                            ? "#E53E3E"
                            : "#ced4da",
                        }}
                        className="flatpickr form-control form-control-sm"
                        data-enable-time
                        value={formatToTimeZone(value, "D MMM YY HH:mm", {
                          timeZone: timeZone,
                        })}
                        onChange={(value) => {
                          onChange(
                            value.length
                              ? format(value[0], "yyyy-MM-dd HH:mm")
                              : null
                          );
                        }}
                        options={{
                          allowClearing: false,
                          // disableMobile: true,
                          dateFormat: "j M y H:i",
                          time_24hr: true,
                          minuteIncrement: 1,
                          // minDate: formatToTimeZone(now, "D MMM YY HH:mm", {
                          //   timeZone: timeZone,
                          // }),
                          defaultDate: formatToTimeZone(
                            addMinutes(now, 5),
                            "D MMM YY HH:mm",
                            { timeZone: timeZone }
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="acceptedrefbasehq"
                    fontFamily="'Poppins'"
                  >
                    References
                  </FormLabel>
                  <Textarea
                    variant="outline"
                    background="white"
                    size="sm"
                    minHeight={{ base: "120px", lg: "80px" }}
                    borderRadius="10px"
                    cursor="pointer"
                    id="acceptedrefbasehq"
                    {...register("acceptedrefbasehq", {})}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        width="100%"
        justifyContent="stretch"
        px="20px"
        pt="15px"
        pb={{ base: "40px", md: "25px" }}
        gap="20px"
        borderTop="1px solid"
        borderColor="#e5e7ea"
      >
        {/* <Flex width="100%" gap="20px" justifyContent="space-between">
          <Text fontSize="0.8rem" fontWeight="700" textTransform="uppercase">
            Fuel and Stores
          </Text>
          <Button
            bg={"#F0F5FA"}
            p="0px"
            opacity="1"
            variant="outline"
            fontSize="11px"
            height={{
              base: "26px",
              md: "24px",
            }}
            width={"104px"}
            style={{
              minWidth: "104px",
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius={"20px"}
            boxShadow={"inset 0px 0px 3px 0px rgba(0,0,0,0.15)"}
            border={"none"}
            borderColor="rgba(255,255,255,0.1)"
            {...noFocus}
            index={switchSelection === "lbs" ? 0 : 1}
            _active={{
              bg: buttonBgActive,
            }}
            _focus={{
              bg: buttonBgActive,
            }}
            _hover={{
              bg: buttonBgHover,
            }}
          >
            <Box
              zIndex="1"
              display={"flex"}
              ml="2px"
              height={{ base: "22px", md: "20px" }}
              width="50px"
              minWidth="50px"
              background="white"
              borderRadius="20px"
              bg={"#ffffff"}
              boxShadow="0px 0px 1px 0px rgba(0,0,0,0.25)"
              borderColor="#E9EAEB"
              transition="all ease 300ms"
              transform={
                switchSelection === "kg" ? "translateX(100%)" : "translateX(0%)"
              }
            ></Box>
            <Flex
              zIndex="2"
              opacity={"1"}
              _hover={{ opacity: "1" }}
              justifyContent="space-evenly"
              marginLeft={"-50px"}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "lbs" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "lbs" ? "700" : "500"}
                opacity={switchSelection === "lbs" ? "1" : "0.8"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                _hover={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="50px"
                onClick={() => {
                  setSwitchSelection("lbs");
                }}
              >
                lbs
              </Flex>
              <Flex
                zIndex="1"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "kg" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "kg" ? "700" : "500"}
                opacity={switchSelection === "kg" ? "1" : "0.8"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                _hover={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="50px"
                onClick={() => {
                  setSwitchSelection("kg");
                }}
              >
                kg
              </Flex>
            </Flex>
          </Button>
        </Flex> */}
        {/* <Flex
          width="100%"
          flexDirection={{ base: "column", md: "row" }}
          gap="20px"
        >
          <Flex
            width="100%"
            flexDirection={{ base: "column", sm: "row" }}
            gap="20px"
          >
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="fuelab"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
              >
                Fuel A/B
              </FormLabel>
              <FormHelperText
                mb="12px"
                mt={{ base: "-27px", smd: "-27px" }}
                color="gray.400"
                fontSize="0.75rem"
                textAlign="right"
                whiteSpace="pre"
              >
                {switchSelection === "kg" ? "kg" : "lbs"}
              </FormHelperText>

              <Input
                transition="all ease 250ms"
                fontSize="0.9rem"
                fontWeight="400"
                width={{ base: "100%", lg: "100%" }}
                variant="outline"
                background="white"
                size="sm"
                borderRadius="10px"
                cursor="pointer"
                id="fuelab"
                {...register("fuelab", {})}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="burnrate"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
              >
                Burn Rate
              </FormLabel>
              <FormHelperText
                mb="12px"
                mt={{ base: "-27px", smd: "-27px" }}
                color="gray.400"
                fontSize="0.75rem"
                textAlign="right"
                whiteSpace="pre"
              >
                {switchSelection === "kg" ? "kg / hour" : "lbs / hour"}
              </FormHelperText>

              <Input
                transition="all ease 250ms"
                fontSize="0.9rem"
                fontWeight="400"
                width={{ base: "100%", lg: "100%" }}
                variant="outline"
                background="white"
                size="sm"
                borderRadius="10px"
                cursor="pointer"
                id="burnrate"
                {...register("burnrate", {})}
              />
            </FormControl>
          </Flex>
          <Flex
            width="100%"
            flexDirection={{ base: "column", sm: "row" }}
            gap="20px"
          >
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="fuelland"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
              >
                Fuel Land
              </FormLabel>
              <FormHelperText
                mb="12px"
                mt={{ base: "-27px", smd: "-27px" }}
                color="gray.400"
                fontSize="0.75rem"
                textAlign="right"
                whiteSpace="pre"
              >
                {switchSelection === "kg" ? "kg" : "lbs"}
              </FormHelperText>

              <Input
                transition="all ease 250ms"
                fontSize="0.9rem"
                fontWeight="400"
                width={{ base: "100%", lg: "100%" }}
                variant="outline"
                background="white"
                size="sm"
                borderRadius="10px"
                cursor="pointer"
                id="fuelland"
                {...register("fuelland", {})}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="fuelcategory"
                fontFamily="'Poppins'"
                overflow="hidden"
                height="20px"
                maxHeight="20px"
                opacity={fuelCategory ? "1" : "1"}
              >
                Fuel Category
              </FormLabel>

              <Select
                id="fuelcategory"
                {...noFocus}
                chakraStyles={selectStyle}
                variant="outline"
                background="white"
                size="sm"
                cursor="pointer"
                {...register("fuelcategory")}
                value={fuelCat.filter((option) => {
                  return option.label === getValues("fuelcategory");
                })}
                onChange={(value) => setValue("fuelcategory", value.label)}
                options={fuelCat}
              />
            </FormControl>
          </Flex>
        </Flex> */}
      </Flex>

      {/* <Flex
        flexDirection="column"
        width="100%"
        justifyContent="stretch"
        px="20px"
        pt="15px"
        pb="35px"
        gap="5px"
        borderTop="1px solid"
        borderColor="#e5e7ea"
      >
        <Text fontSize="0.8rem" fontWeight="700" textTransform="uppercase">
          Flight Log
        </Text>
        <PilotLog authorisationData={authorisationData} />
      </Flex> */}
    </Flex>
  );
}
