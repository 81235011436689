import React from "react";
import AircrewManagementTable from "./AircrewManagementTable";
import MaintenanceCrewManagementTable from "./MaintenanceCrewManagementTable";
import AirMovementsCrewManagementTable from "./AirMovementsCrewManagementTable";
import TrainingRecordsLink from "./TrainingRecordsLink";

export default function CrewManagement(props) {
  const { data, type } = props;

  if (data) {
    return (
      <>
        {type === "aircrew" && <AircrewManagementTable data={data} />}
        {type === "maintenance" && (
          <MaintenanceCrewManagementTable data={data} />
        )}
        {type === "air-movements" && (
          <AirMovementsCrewManagementTable data={data} />
        )}
      </>
    );
  } else if (type === "training-records") {
    return (
      <>
        <TrainingRecordsLink />
      </>
    );
  }
}
