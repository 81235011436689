import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Link } from "@chakra-ui/react";
import TrainingRecord from "../Assets/images/aircrew-training-record.png";

export default function TrainingRecordsLink() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  if (isAuthenticated) {
    return (
      <Flex
        width="100%"
        height="70%"
        p={{ base: "20px", xl: "15px" }}
        paddingTop="10px"
        paddingBottom="10px"
        justifyContent={{ base: "center", xl: "center" }}
        alignItems="center"
      >
        <Link
          href={TrainingRecord}
          isExternal
          bg="brand.400"
          width="220px"
          height="36px"
          borderRadius="25px"
          gap="20px"
          color={"gray.500"}
          display="flex"
          justifyContent="center"
          fontWeight={"600"}
          fontSize="0.85rem"
          alignItems="center"
          lineHeight="1.2"
          textTransform="capitalize"
          cursor="pointer"
          _hover={{ bg: "white", color: "gray.600" }}
          border="1px solid"
          borderColor="gray.200"
        >
          Open Example Image
        </Link>
      </Flex>
    );
  }
}
