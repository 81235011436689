import { v4 as uuid } from "uuid";

export default function generateSorties() {
  const res = [
    {
      id: uuid(),
      pilot: "Pilot 1",
      approach: "Hand Flown ILS",
      crewrole: "Captain",
      day1st: "1.1",
      day2nd: "",
      daydual: "0.2",
      night1st: "0.2",
      night2nd: "",
      nightdual: "",
      captain: "1.3",
      di: "",
      instact: "0.3",
      instsim: "0.2",
    },
    {
      id: uuid(),
      pilot: "Pilot 2",
      approach: "Coupled ILS",
      crewrole: "Co-pilot",
      day1st: "",
      day2nd: "1.1",
      daydual: "",
      night1st: "",
      night2nd: "0.2",
      nightdual: "",
      captain: "0.0",
      di: "",
      instact: "0.0",
      instsim: "0.2",
    },
    {
      id: uuid(),
      pilot: " ",
      approach: "",
      crewrole: "",
      day1st: "",
      day2nd: "",
      daydual: "",
      night1st: "",
      night2nd: "",
      nightdual: "",
      captain: "",
      di: "",
      instact: "",
      instsim: "",
    },
  ];

  // console.log(res);

  return res;
}
