import { set } from "date-fns";

export function splitAvailableSlots(availableSlots) {
  const slots12Hours = [];

  for (const slot of availableSlots) {
    const since = new Date(slot.since);
    const till = new Date(slot.till);

    while (since < till) {
      const nextSlotTill = new Date(since);
      nextSlotTill.setMinutes(since.getMinutes() + 720);

      if (nextSlotTill > till) {
        nextSlotTill.setTime(till.getTime());
      }

      slots12Hours.push({
        since: new Date(since),
        till: nextSlotTill,
      });

      since.setTime(nextSlotTill.getTime());
    }
  }

  return slots12Hours;
}

export default function calculateAvailableSlots(occupiedSlots, selectedMonth) {
  const bookingStartTime = set(Date.now(), {
    month: selectedMonth - 1,
    date: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const bookingEndTime = set(Date.now(), {
    month: selectedMonth,
    date: selectedMonth + 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const available = [];

  const sortedOccupiedSlots = occupiedSlots?.map((slot) => ({
    since: new Date(slot.since),
    till: new Date(slot.till),
  }));

  sortedOccupiedSlots?.sort((a, b) => {
    if (a.since < b.since) {
      return -1;
    }
    if (a.since > b.since) {
      return 1;
    }
    return 0;
  });

  // Initialize the first available slot from the start of booking hours
  let currentSlot = new Date(bookingStartTime);

  // Loop through the occupiedSlots and find available slots
  for (const slot of sortedOccupiedSlots) {
    const since = new Date(slot.since);
    const till = new Date(slot.till);

    // Check if there's a gap between the currentSlot and the next occupied slot
    if (currentSlot < since) {
      available.push({
        since: currentSlot,
        till: since,
      });
    }

    // Update the currentSlot to the end of the current occupied slot
    currentSlot = new Date(till);
  }

  // Check if there's an available slot between the last occupied slot and the end of booking hours
  if (currentSlot < bookingEndTime) {
    available.push({
      since: currentSlot,
      till: bookingEndTime,
    });
  }

  return available;
}
