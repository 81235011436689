import {
  atom,
  // selector
} from "recoil";

// export const selectedTimeState = atom({
//   key: "selectedTimeState", // unique ID (with respect to other atoms/selectors)
//   default: new Date(), // default value (aka initial value)
// });

// export const zoomLevelState = atom({
//   key: "zoomLevelState",
//   default: 0,
// });

export const isRenderedState = atom({
  key: "isRenderedState",
  default: false,
});

export const selectedMonthState = atom({
  key: "selectedMonthState",
  default: new Date().getMonth() + 1,
});

export const selectedWeekState = atom({
  key: "selectedWeekState",
  default: 0,
});

export const selectedDayState = atom({
  key: "selectedDayState",
  default: 0,
});

export const authorisationsModalState = atom({
  key: "authorisationsModalState",
  default: false,
});

export const airframeModalState = atom({
  key: "airframeModalState",
  default: null,
});

export const deleteModalState = atom({
  key: "deleteModalState",
  default: false,
});

export const personnelModalState = atom({
  key: "personnelModalState",
  default: false,
});

export const notificationModalState = atom({
  key: "notificationModalState",
  default: false,
});

export const riskMatrixModalState = atom({
  key: "riskMatrixModalState",
  default: null,
});

export const authorisationDataState = atom({
  key: "authorisationDataState",
  default: null,
});

export const editAuthorisationState = atom({
  key: "editAuthorisationState",
  default: false,
});

export const editAircrewState = atom({
  key: "editAircrewState",
  default: null,
});

export const newAuthorisationState = atom({
  key: "newAuthorisationState",
  default: false,
});

export const authorisationTabState = atom({
  key: "authorisationTabState",
  default: "pre-flight",
});

export const menuSelectionState = atom({
  key: "menuSelectionState",
  default: 1,
});

export const deleteAuthorisationState = atom({
  key: "deleteAuthorisationState",
  default: null,
});

export const riskItemsState = atom({
  key: "riskItemsState",
  default: [],
});

export const boardViewState = atom({
  key: "boardViewState",
  default: "day",
});

export const squadronSelectionState = atom({
  key: "squadronSelectionState",
  default: "14 SQN/CFS",
});

export const userState = atom({
  key: "userState",
  default: "ops",
});

export const overrideState = atom({
  key: "overrideState",
  default: false,
});

export const airframeDataState = atom({
  key: "airframeDataState",
  default: [],
});

export const aircrewDataState = atom({
  key: "aircrewDataState",
  default: [],
});

export const userProfileState = atom({
  key: "userProfileState",
  default: null,
});

export const notificationsState = atom({
  key: "notificationsState",
  default: [],
});

export const hasNotificationState = atom({
  key: "hasNotificationState",
  default: false,
});
