import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Flex, Progress, Text, Box, Button } from "@chakra-ui/react";
import { healthData } from "./data";
import { addDays, getMonth } from "date-fns";

import {
  airframeDataState,
  aircrewDataState,
  squadronSelectionState,
} from "../globalState";

export default function OverallHealth({ isLoading }) {
  const [switchSelection, setSwitchSelection] = useState("1");
  const airframeData = useRecoilValue(airframeDataState);
  const aircrewData = useRecoilValue(aircrewDataState);
  const squadronSelection = useRecoilValue(squadronSelectionState);

  const sqn = squadronSelection === "40 SQN/CFS" ? 40 : 14;
  const texanSqn = Boolean(squadronSelection === "14 SQN/CFS");

  function calculateRoundedPercentage(value, total) {
    return Math.round((value / total) * 100);
  }

  // Airframe
  const filteredAirframeData = airframeData.filter(
    (feat) => feat.squadron === squadronSelection
  );
  const totalAirframe = Array.from(
    { length: filteredAirframeData.length + 1 },
    (_, index) => index.toString()
  );
  const airframeAvailable = filteredAirframeData.filter(
    (feat) => feat.status.airworthy
  ).length;

  const currentAirframeAvailablePercentage = calculateRoundedPercentage(
    airframeAvailable,
    filteredAirframeData.length
  );
  const airframeAvailableNumber =
    switchSelection === "4"
      ? filteredAirframeData.length - (texanSqn ? 4 : 3)
      : switchSelection === "3"
      ? filteredAirframeData.length - (texanSqn ? 2 : 2)
      : switchSelection === "2"
      ? filteredAirframeData.length - (texanSqn ? 2 : 2)
      : airframeAvailable;

  // these stats just help level the progress bars for each date selection
  const airframeAvailablePercentage =
    switchSelection === "4"
      ? calculateRoundedPercentage(
          filteredAirframeData.length - (texanSqn ? 4 : 2.9),
          filteredAirframeData.length
        )
      : switchSelection === "3"
      ? calculateRoundedPercentage(
          filteredAirframeData.length - (texanSqn ? 2 : 1.9),
          filteredAirframeData.length
        )
      : switchSelection === "2"
      ? calculateRoundedPercentage(
          filteredAirframeData.length - (texanSqn ? 2 : 1.9),
          filteredAirframeData.length
        )
      : currentAirframeAvailablePercentage;

  // Aircrew
  const filteredAircrewData = aircrewData.filter(
    (feat) => feat.squadron === sqn
  );
  const totalAircrew = Array.from(
    { length: filteredAircrewData.length + 1 },
    (_, index) => index.toString()
  );
  const aircrewAvailable = filteredAircrewData.filter(
    (feat) => feat.available
  ).length;

  const filteredAircrewUnavailable = filteredAircrewData.filter(
    (feat) => feat.unavailablefromdate && feat.unavailabletodate
  );

  // default object to calculate the months an aircrew member is unavailable
  const today = new Date();
  const monthsAircrewUnavailable = {
    0: 0, // current month
    1: 0, // + 1 month
    2: 0, // +2 months
    3: 0, // +3 months
  };

  filteredAircrewUnavailable.forEach((member) => {
    // work out new date
    const fromDate = addDays(today, member.unavailablefromdate);
    const toDate = addDays(today, member.unavailabletodate);

    // calculate the month differences
    const monthFromDiff = getMonth(fromDate) - getMonth(today);
    const monthToDiff = getMonth(toDate) - getMonth(today);

    // defined false in case it has null/undefined etc
    if (monthFromDiff === 0 && member?.available !== false) {
      monthsAircrewUnavailable[0]++;
    } else if (monthFromDiff === 1) {
      monthsAircrewUnavailable[1]++;
    } else if (monthFromDiff === 2) {
      monthsAircrewUnavailable[2]++;
    } else if (monthFromDiff === 3) {
      monthsAircrewUnavailable[3]++;
    }

    // only add if monthToDiff is different from monthFromDiff
    if (monthToDiff !== monthFromDiff) {
      if (monthToDiff === 0 && member?.available !== false) {
        monthsAircrewUnavailable[0]++;
      } else if (monthToDiff === 1) {
        monthsAircrewUnavailable[1]++;
      } else if (monthToDiff === 2) {
        monthsAircrewUnavailable[2]++;
      } else if (monthToDiff >= 3) {
        monthsAircrewUnavailable[3]++;
      }
    }
  });

  const aircrewAvailableNumber =
    switchSelection === "4"
      ? filteredAircrewData.length -
        (texanSqn ? 7 : 3) -
        monthsAircrewUnavailable[3]
      : switchSelection === "3"
      ? filteredAircrewData.length -
        (texanSqn ? 4 : 7) -
        monthsAircrewUnavailable[2]
      : switchSelection === "2"
      ? filteredAircrewData.length -
        (texanSqn ? 2 : 1) -
        monthsAircrewUnavailable[1]
      : aircrewAvailable - monthsAircrewUnavailable[0];

  const currentAircrewAvailablePercentage = calculateRoundedPercentage(
    aircrewAvailable - monthsAircrewUnavailable[0],
    filteredAircrewData.length
  );

  // these stats just help level the progress bars for each date selection
  const aircrewAvailablePercentage =
    switchSelection === "4"
      ? calculateRoundedPercentage(
          filteredAircrewData.length -
            (texanSqn ? 7.4 : 3) -
            monthsAircrewUnavailable[3],
          filteredAircrewData.length
        )
      : switchSelection === "3"
      ? calculateRoundedPercentage(
          filteredAircrewData.length -
            (texanSqn ? 4.2 : 6.9) -
            monthsAircrewUnavailable[2],
          filteredAircrewData.length
        )
      : switchSelection === "2"
      ? calculateRoundedPercentage(
          filteredAircrewData.length -
            (texanSqn ? 2 : 1) -
            monthsAircrewUnavailable[1],
          filteredAircrewData.length
        )
      : currentAircrewAvailablePercentage;

  // current status
  const overallStatus = Math.round(
    (airframeAvailablePercentage + aircrewAvailablePercentage) / 2
  );

  // console.log("airframeData", airframeData);
  // console.log("aircrewData", aircrewData);
  // console.log("filteredAircrewData", filteredAircrewData);
  // // console.log("airframeAvailablePercentage", airframeAvailablePercentage);
  // console.log("aircrewAvailablePercentage", aircrewAvailablePercentage);
  // console.log("aircrewAvailableNumber", aircrewAvailableNumber);
  // console.log("filteredAircrewUnavailable", filteredAircrewUnavailable);
  // console.log("monthsAircrewUnavailable", monthsAircrewUnavailable);
  // console.log("switchSelection", switchSelection);

  const formattedHealthData =
    switchSelection === "4"
      ? healthData[3]
      : switchSelection === "3"
      ? healthData[2]
      : switchSelection === "2"
      ? healthData[1]
      : healthData[0];

  const StatsBar = ({
    title,
    initialValue,
    // targetValue,
    color,
    // statusColor,
    airframePercentage,
    aircrewPercentage,
    totalAirframe,
    totalAircrew,
  }) => {
    const [value, setValue] = useState(initialValue);
    const statusColor =
      title === "Aircraft / Systems:" ? airframePercentage : aircrewPercentage;
    const axisBar =
      title === "Aircraft / Systems:" ? totalAirframe : totalAircrew;
    const axisHighlight =
      title === "Aircraft / Systems:"
        ? airframeAvailableNumber
        : aircrewAvailableNumber;
    const axisHighlightColor =
      title === "Aircraft / Systems:" ? "cyan.500" : "pink.500";

    useEffect(() => {
      if (!isLoading) {
        const newValue =
          title === "Aircraft / Systems:"
            ? airframePercentage
            : aircrewPercentage;

        const interval = setInterval(() => {
          setValue((prevValue) =>
            prevValue < newValue ? prevValue + 1 : prevValue
          );
        }, 2);

        return () => clearInterval(interval);
      }
    }, [title, airframePercentage, aircrewPercentage]);

    return (
      <Flex width="100%" flexDirection="column" gap="0px">
        <Flex
          width="100%"
          flexDirection={{ base: "column", xl: "row" }}
          justifyContent={{ base: "flex-start", xl: "space-between" }}
          alignItems={{ base: "flex-start", xl: "center" }}
          px="0px"
          gap="5px"
        >
          <Flex minWidth="130px">
            <Text
              textTransform="uppercase"
              fontSize={{ base: "0.65rem", lg: "0.75rem" }}
              lineHeight="1.2"
              py="1px"
              color="gray.500"
            >
              {title}
            </Text>
          </Flex>
          <Flex
            width="100%"
            flexDirection="row"
            gap="10px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Progress
              hasStripe
              my="4px"
              width="100%"
              colorScheme={color}
              size="lg"
              value={value}
              borderRadius="25px"
            />
            <Box
              // bg={statusColor}
              background={
                statusColor < 60
                  ? "red"
                  : statusColor < 80
                  ? "yellow"
                  : "#11EC4E"
              }
              borderRadius="100%"
              height="16px"
              width="16px"
            ></Box>
          </Flex>
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          width="100%"
          color="gray.400"
          fontSize="0.6rem"
          pt="5px"
          pl={{ base: "0px", xl: "135px" }}
          pr="25px"
          pb="5px"
        >
          {axisBar &&
            axisBar.map((item, index) => (
              <Flex
                width="auto"
                key={index}
                fontWeight={axisHighlight === Number(item) ? "700" : "400"}
                color={
                  axisHighlight === Number(item)
                    ? axisHighlightColor
                    : "gray.400"
                }
              >
                {item}
              </Flex>
            ))}
        </Flex>
      </Flex>
    );
  };

  const buttonBgActive = "#F2F5F8";
  const buttonBgHover = "gray.200";

  const noFocus = {
    _focus: { boxShadow: "none" },
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      borderRadius="10px"
      border="1px solid"
      borderColor="#e5e5e5"
      bg="white"
      boxShadow="0px 10px 40px -15px rgba(0, 45, 78, 0.15)"
    >
      <Flex
        minHeight="44px"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        width="100%"
        color="light.100"
        borderBottom="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        fontWeight="700"
        textTransform="uppercase"
      >
        Overall Unit Health / Readiness
      </Flex>
      <Flex gap="5px" width="100%" flexDirection="column" py="20px" px="15px">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          fontSize="0.8rem"
          color={"light.100"}
          flexDirection={{ base: "column-reverse", "2xl": "row" }}
          gap={{ base: "30px", "2xl": "5px" }}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            fontSize="0.8rem"
            color={"light.100"}
            gap="5px"
          >
            <Text
              display={"flex"}
              pl={"0px"}
              pr="5px"
              fontSize={{ base: "0.65rem", lg: "0.75rem" }}
              color="gray.500"
            >
              STATUS:
            </Text>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              fontSize="0.8rem"
              color={"light.100"}
              gap="5px"
              height="24px"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                fontSize="0.8rem"
                color={
                  overallStatus < 60
                    ? "white"
                    : overallStatus < 80
                    ? "light.100"
                    : "white"
                }
                borderRadius="20px"
                background={
                  overallStatus < 60
                    ? "red"
                    : overallStatus < 80
                    ? "yellow"
                    : "#11EC4E"
                }
                height="24px"
                width="70px"
                fontWeight="600"
                letterSpacing={"1px"}
              >
                {`${overallStatus}%`}
              </Flex>
              <Flex
                justifyContent="space-around"
                height="100%"
                alignItems="center"
                fontSize="0.8rem"
                color={"light.100"}
                flexDirection="column"
              >
                <Box
                  bg="red"
                  borderRadius="100%"
                  height="6px"
                  width="6px"
                ></Box>
                <Box
                  bg="yellow"
                  borderRadius="100%"
                  height="6px"
                  width="6px"
                ></Box>
                <Box
                  bg="#11EC4E"
                  borderRadius="100%"
                  height="6px"
                  width="6px"
                ></Box>
              </Flex>
            </Flex>
          </Flex>
          <Button
            bg={"#F0F5FA"}
            p="0px"
            opacity="1"
            variant="outline"
            fontSize="11px"
            fontWeight="500"
            height={{
              base: "26px",
              md: "24px",
            }}
            width={"324px"}
            style={{
              minWidth: "324px",
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius={"20px"}
            boxShadow={"inset 0px 0px 3px 0px rgba(0,0,0,0.15)"}
            border={"none"}
            borderColor="rgba(255,255,255,0.1)"
            {...noFocus}
            index={switchSelection === "UTC" ? 0 : 1}
            _active={{
              bg: buttonBgActive,
            }}
            _focus={{
              bg: buttonBgActive,
            }}
            _hover={{
              bg: buttonBgHover,
            }}
          >
            <Box
              zIndex="1"
              display={"flex"}
              ml="2px"
              px="40px"
              height={{ base: "22px", md: "20px" }}
              width="80px"
              minWidth="80px"
              background="white"
              borderRadius="20px"
              bg={"#ffffff"}
              boxShadow="0px 0px 1px 0px rgba(0,0,0,0.25)"
              borderColor="#E9EAEB"
              transition="all ease 300ms"
              transform={
                switchSelection === "4"
                  ? "translateX(300%)"
                  : switchSelection === "3"
                  ? "translateX(200%)"
                  : switchSelection === "2"
                  ? "translateX(100%)"
                  : "translateX(0%)"
              }
            ></Box>
            <Flex
              zIndex="2"
              opacity={"1"}
              _hover={{ opacity: "1" }}
              justifyContent="space-evenly"
              marginLeft={"-80px"}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "1" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "1" ? "600" : "500"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="80px"
                onClick={() => {
                  setSwitchSelection("1");
                }}
              >
                Current
              </Flex>
              <Flex
                zIndex="1"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "2" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "2" ? "600" : "500"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="80px"
                onClick={() => {
                  setSwitchSelection("2");
                }}
              >
                Next Month
              </Flex>
              <Flex
                zIndex="1"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "3" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "3" ? "600" : "500"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="80px"
                onClick={() => {
                  setSwitchSelection("3");
                }}
              >
                + 2 Months
              </Flex>
              <Flex
                zIndex="1"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.3"
                color={switchSelection === "4" ? "cyan.400" : "gray.500"}
                fontWeight={switchSelection === "4" ? "600" : "500"}
                transition="all ease 300ms"
                _selected={{
                  opacity: "1",
                }}
                _active={{
                  opacity: "1",
                }}
                height={{
                  base: "26px",
                  md: "24px",
                }}
                minWidth="80px"
                onClick={() => {
                  setSwitchSelection("4");
                }}
              >
                + 3 Months
              </Flex>
            </Flex>
          </Button>
        </Flex>

        <Flex
          gap="20px"
          flexDirection="column"
          width="100%"
          overflowX="auto"
          pt={{ base: "20px", xl: "30px" }}
          pb={{ base: "5px", xl: "0px" }}
        >
          {formattedHealthData.map((item, index) => (
            <StatsBar
              key={index}
              {...item}
              totalAirframe={totalAirframe}
              totalAircrew={totalAircrew}
              airframeAvailable={airframeAvailable}
              aircrewAvailable={aircrewAvailable}
              airframePercentage={airframeAvailablePercentage}
              aircrewPercentage={aircrewAvailablePercentage}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
