import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from "@chakra-ui/react";

import {
  modalStyles as Modal,
  headingStyles as Heading,
  tableStyles as Table,
  buttonStyles as Button,
  checkboxStyles as Checkbox,
  inputStyles as Input,
} from "./Components/ComponentStyles";

const inputSelectStyles = {
  variants: {
    filled: {
      field: {
        _focus: {
          borderColor: "brand.200",
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: "none",
      },
    },
  },
};

const theme = extendTheme(
  {
    initialColorMode: "light",
    cssVarPrefix: "preflight",

    // THEME COLORS
    colors: {
      brand: {
        100: "#00E0FF", // Light Blue
        200: "#00d0ed", // Light Blue
        300: "#55eaff", // Light Blue
        400: "#F5F8FB", // light gray
        500: "#eaeff5", // light gray
      },
    },

    // FONTS
    fonts: {
      heading: "Open Sans",
      body: "Open Sans",
    },

    // OVERALL BORDER RADIUS
    radii: {
      none: "0",
      sm: "0.125rem",
      base: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
      full: "9999px",
    },

    // BREAKPOINT VALUES (Responsive styling)
    // DO NOT CHANGE! as this will likely break responsive behaviours
    // Most components are set from mobile to change at "md" to desktop view
    breakpoints: {
      xs: "25em", // 400px (custom)
      sm: "30em", // 480px - DO NOT CHANGE
      smd: "36em", // 576px (custom)
      md: "48em", // 768px - DO NOT CHANGE
      ml: "54em", // 864px (custom)
      lg: "62em", // 992px - DO NOT CHANGE
      xl: "80em", // 1280px - DO NOT CHANGE
      "2xl": "96em", // 1536px - DO NOT CHANGE
      "3xl": "110em", // 1760px (custom)
    },

    // REMOVE FOCUS FROM ALL BUTTONS
    _focus: {
      border: "none",
    },

    // HEADING STYLES
    styles: {
      global: {
        h1: {
          fontWeight: "600",
          lineHeight: "110%",
          fontSize: " 3xl",
        },
        h2: {
          fontSize: ["md", "xl"],
          fontWeight: "bold",
          lineHeight: "110%",
          fontFamily: ["Open Sans", "sans-serif"],
          letterSpacing: "1%",
        },
        h3: {
          fontSize: ["36px", "48px"],
          fontWeight: "semibold",
          lineHeight: "110%",
          letterSpacing: "1%",
        },
      },
    },

    //COMPONENTS (Imported from ComponentStyles file)
    components: {
      Table,
      Button,
      Modal,
      Heading,
      Checkbox,
      Input,
      Select: { ...inputSelectStyles },
    },
  },
  withDefaultColorScheme({
    colorScheme: "brand",
    components: ["Checkbox", "Modal"],
  }),
  withDefaultVariant({
    variant: "filled",
    components: ["Input", "Select"],
  })
);

export default theme;
