import {
  ChannelBox,
  // ChannelLogo
} from "@nessprim/planby";
import { Text, Stack, Image } from "@chakra-ui/react";
import Pilot from "../../Assets/avatar-pilot.png";
// import Avatar, { genConfig } from "react-nice-avatar";

export default function Aircrew({ aircrew, zoomLevel }) {
  const {
    position,
    // logo
  } = aircrew;

  // const config = genConfig({ sex: "man" });

  return (
    <ChannelBox {...position}>
      <Stack
        minHeight={zoomLevel === 0 ? "24px" : zoomLevel === 1 ? "40px" : "64px"}
        maxHeight={zoomLevel === 0 ? "24px" : zoomLevel === 1 ? "40px" : "64px"}
        minWidth={"80px"}
        borderRadius="9px"
        border="1px solid #eceff4"
        boxShadow="0px 3px 6px -2px rgba(5, 30, 50, 0.06)"
        direction={zoomLevel === 0 ? "row" : "column"}
        gap={zoomLevel === 0 ? "5px" : "5px"}
        justifyContent="center"
        alignItems="center"
        bg="#FBFDFF"
        cursor="pointer"
        transition="all ease 200ms"
        _hover={{
          boxShadow: "0px 3px 15px -5px rgba(5, 30, 50, 0.3)",
          // transform: "translateY(-1px)",
        }}
        // onClick={() => setShowAirframeModal(airframe.serial)}
      >
        {/* <ChannelLogo
          onClick={() => console.log("channel", aircrew)}
          src={logo}
          alt="Logo"
        /> */}
        {/* <Avatar style={{ width: "3rem", height: "3rem" }} {...config} /> */}
        <Image
          src={Pilot}
          mt={zoomLevel !== 2 ? "1px" : "1px"}
          mb={zoomLevel === 0 ? "0px" : zoomLevel === 1 ? "-8px" : "-6px"}
          height={zoomLevel === 0 ? "16px" : zoomLevel === 1 ? "22px" : "32px"}
          width="auto"
        />
        <Text color="white">{aircrew.serial}</Text>
      </Stack>
    </ChannelBox>
  );
}
