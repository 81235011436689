import React from "react";
import { useRecoilValue } from "recoil";
import { Text, Flex, Icon } from "@chakra-ui/react";
import { parseISO, formatDuration, intervalToDuration } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { GiCheckMark } from "react-icons/gi";
import { FaExclamation } from "react-icons/fa";
import briefingOptions from "../Data/briefing.json";
import { userState, aircrewDataState } from "../globalState";

export default function FlightAuthSelected(props) {
  const {
    authorisationData,
    airframeSerials,
    showAuthorisationsModal,
    override,
    timeZone,
  } = props;

  const selectedAirframe = airframeSerials.find(
    (airframe) => airframe.serial === authorisationData?.airframe
  );
  const user = useRecoilValue(userState);
  const aircrewData = useRecoilValue(aircrewDataState);

  // determine duration
  const since = parseISO(authorisationData?.since);
  const till = parseISO(authorisationData?.till);

  const now = Date.now();
  const upcoming = parseISO(authorisationData?.since) > now;
  const expired = parseISO(authorisationData?.till) < now;

  const duration = intervalToDuration({ start: since, end: till });
  const durationDetail = formatDuration(duration, {
    format: ["days", "hours", "minutes"],
  });
  const padWithZero = (value) => (value < 10 ? `0${value}` : value);
  const durationHasDays = duration?.days ? duration.days * 24 : 0;
  const formattedDuration = `${durationHasDays + duration?.hours}:${padWithZero(
    duration?.minutes
  )}`;

  // function to get aircrew details
  function getAircrewDetails(authorisationData, aircrewData) {
    const pilotDetails =
      authorisationData?.pilot &&
      aircrewData.find((member) => member.id === authorisationData.pilot);

    const passengerDetails =
      authorisationData?.passenger &&
      aircrewData.find((member) => member.id === authorisationData.passenger);

    const updatedAircrew = [
      pilotDetails
        ? {
            callsign: pilotDetails.callsign,
            name: pilotDetails.name,
            available: pilotDetails.available,
            role: "pilot",
          }
        : null,
      passengerDetails
        ? {
            callsign: passengerDetails.callsign,
            name: passengerDetails.name,
            available: passengerDetails.available,
            role: "passenger",
          }
        : null,
    ];

    return updatedAircrew.filter((detail) => detail !== null);
  }

  const aircrewDetails = getAircrewDetails(authorisationData, aircrewData);

  const BriefingOption = ({ label, id, completed, index }) => {
    return (
      <Flex
        minWidth="100%"
        pl="0px"
        pr="7px"
        py="2px"
        borderRadius="20px"
        border="1px solid"
        borderColor="#e5e5e5"
        minHeight="32px"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        gap="10px"
        bg="#F7FAFC"
        // cursor="pointer"
        transition="all ease 150ms"
        // _hover={{
        //   background: "#FDFEFF",
        //   boxShadow: "0px 5px 15px -4px rgba(0, 45, 78, 0.3)",
        //   transform: "scale(1.03) translateY(-1px)",
        // }}
      >
        <Flex
          zIndex="10"
          mt="-4px"
          mb="-4px"
          width={{ base: "34px", ml: "34px" }}
          minWidth={{ base: "34px", ml: "34px" }}
          minHeight={{ base: "34px", ml: "34px" }}
          borderRadius="50px"
          justifyContent="center"
          alignItems="center"
          bg={
            completed === "warning" || completed === "caution"
              ? "gray.400"
              : completed
              ? "cyan.400"
              : "cyan.700"
          }
          fontSize="0.75rem"
          fontWeight="600"
          p="0px"
          color="white"
        >
          {id}
        </Flex>
        <Flex
          width="100%"
          gap="5px"
          minHeight={{ base: "16px", ml: "18px" }}
          fontSize={{ base: "0.7rem", ml: "0.75rem" }}
          justifyContent={{
            base: "flex-start",
            ml: "flex-start",
          }}
        >
          <Text textAlign="left" color="gray.600" fontWeight="600">
            {label}
          </Text>
        </Flex>

        <Flex
          width={{ base: "18px", ml: "20px" }}
          minWidth={{ base: "18px", ml: "20px" }}
          minHeight={{ base: "18px", ml: "20px" }}
          borderRadius="50px"
          border="1px solid"
          borderColor={
            completed === "warning"
              ? "#ff0000"
              : completed === "caution"
              ? "#ffdb00"
              : completed
              ? "rgb(0, 251, 0)"
              : "#eaeaea"
          }
          justifyContent="center"
          alignItems="center"
          bg={
            completed === "warning"
              ? "#ff0000"
              : completed === "caution"
              ? "#ffdb00"
              : completed
              ? "rgb(0, 251, 0)"
              : "white"
          }
          fontSize="0.7rem"
          fontWeight="600"
          p="0px"
          color="white"
        >
          <Icon
            as={
              completed === "warning" || completed === "caution"
                ? FaExclamation
                : GiCheckMark
            }
            color="white"
            fontSize="0.8rem"
            pt={completed !== "warning" && completed !== "caution" && "1px"}
            pr={completed !== "warning" && completed !== "caution" && "1px"}
          />
        </Flex>
      </Flex>
    );
  };

  // console.log("aircrewDetails", aircrewDetails);
  // console.log("authorisationData", authorisationData);

  return (
    <>
      <Flex alignItems="flex-start" gap="20px" flexDirection="column">
        <Flex
          width="100%"
          flexDirection={{ base: "column", md: "row" }}
          gap="20px"
          alignItems="baseline"
        >
          <Flex
            boxShadow={
              showAuthorisationsModal &&
              "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
            }
            area={"time"}
            width={{ base: "100%", md: "90%", ml: "100%" }}
            alignItems="baseline"
            fontSize="0.85rem"
            background={
              override || showAuthorisationsModal ? "white" : "brand.400"
            }
            borderRadius="15px"
            border="1px solid"
            borderColor={
              override || showAuthorisationsModal ? "gray.100" : "gray.200"
            }
            py="12px"
            px="15px"
          >
            <Flex gap="15px" flexDirection="column" width="100%">
              <Flex
                gap="10px"
                alignItems="baseline"
                justifyContent="space-between"
              >
                <Flex
                  alignItems="baseline"
                  gap={{
                    base: "0px",
                    xs: "10px",
                  }}
                  flexDirection={{
                    base: "column",
                    xs: "row",
                  }}
                >
                  <Text
                    fontSize="0.85rem"
                    fontWeight="600"
                    fontFamily="'Poppins'"
                  >
                    Flight Start:
                  </Text>
                  <Text fontSize="0.85rem">
                    {authorisationData?.since &&
                      formatToTimeZone(
                        parseISO(authorisationData.since),
                        "D MMM YY HH:mm",
                        {
                          timeZone: timeZone,
                        }
                      )}
                  </Text>
                </Flex>

                <Text
                  fontSize="0.8rem"
                  fontWeight="600"
                  textTransform="uppercase"
                  color={
                    upcoming ? "#00fb00" : expired ? "#ff6a00" : "cyan.400"
                  }
                  pr="2px"
                >
                  {upcoming ? "Upcoming" : expired ? "Expired" : "Active"}
                </Text>
              </Flex>

              <Flex
                alignItems="baseline"
                gap={{
                  base: "0px",
                  xs: "10px",
                }}
                flexDirection={{
                  base: "column",
                  xs: "row",
                }}
              >
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Flight End:
                </Text>
                <Text fontSize="0.85rem">
                  {authorisationData?.till &&
                    formatToTimeZone(
                      parseISO(authorisationData.till),
                      "D MMM YY HH:mm",
                      {
                        timeZone: timeZone,
                      }
                    )}
                </Text>
              </Flex>

              <Flex alignItems="baseline" gap="10px">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Duration:
                </Text>
                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  width="100%"
                  gap="10px"
                >
                  <Text fontSize="0.85rem">{formattedDuration}</Text>
                  <Text
                    color="gray.400"
                    opacity="0.7"
                    fontStyle="italic"
                    fontSize="0.7rem"
                  >{`${durationDetail}`}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            boxShadow={
              showAuthorisationsModal &&
              "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
            }
            area={"activity"}
            width="100%"
            alignItems="baseline"
            fontSize="0.85rem"
            background={
              override || showAuthorisationsModal ? "white" : "brand.400"
            }
            borderRadius="15px"
            border="1px solid"
            borderColor={
              override || showAuthorisationsModal ? "gray.100" : "gray.200"
            }
            py="12px"
            px="15px"
          >
            <Flex gap="15px" flexDirection="column" width="100%">
              <Flex alignItems="baseline" gap="10px">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Activity:
                </Text>
                <Text fontSize="0.85rem">{authorisationData?.activity}</Text>
              </Flex>

              <Flex alignItems="baseline" gap="10px">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Area:
                </Text>
                <Text fontSize="0.85rem">{authorisationData?.area}</Text>
              </Flex>

              <Flex
                alignItems="baseline"
                gap={{ base: "15px", md: "10px" }}
                pb={{ base: "2px", md: "0px" }}
                justifyContent="space-between"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Flex alignItems="center" gap="10px">
                  <Text
                    fontSize="0.85rem"
                    fontWeight="600"
                    fontFamily="'Poppins'"
                  >
                    Priority:
                  </Text>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    color={
                      authorisationData?.priority === "High" ||
                      authorisationData?.priority === "Low"
                        ? "white"
                        : "initial"
                    }
                    fontWeight="600"
                    textTransform="uppercase"
                    minWidth="70px"
                    px="10px"
                    height="20px"
                    fontSize="0.75rem"
                    lineHeight="1"
                    borderRadius="15px"
                    background={
                      authorisationData?.priority === "High"
                        ? "#ff6a00"
                        : authorisationData?.priority === "Medium"
                        ? "yellow"
                        : authorisationData?.priority === "Low"
                        ? "#00fb00"
                        : "gray.100"
                    }
                  >
                    {authorisationData?.priority}
                  </Flex>
                </Flex>

                <Flex alignItems="baseline" gap="10px">
                  <Text
                    fontSize="0.85rem"
                    fontWeight="600"
                    fontFamily="'Poppins'"
                  >
                    Cost Centre:
                  </Text>
                  <Text
                    fontSize="0.85rem"
                    fontWeight={authorisationData?.costcentre ? "500" : "400"}
                    color={
                      authorisationData?.costcentre ? "inherit" : "gray.300"
                    }
                    fontStyle={
                      authorisationData?.costcentre ? "normal" : "italic"
                    }
                    textTransform={
                      authorisationData?.costcentre ? "uppercase" : "initial"
                    }
                    minWidth="70px"
                  >
                    {authorisationData?.costcentre
                      ? authorisationData.costcentre
                      : "None"}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          gap="20px"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            boxShadow={
              showAuthorisationsModal &&
              "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
            }
            area={"aircraft"}
            width="100%"
            minHeight="240px"
            alignItems="baseline"
            fontSize="0.85rem"
            background={
              override || showAuthorisationsModal ? "white" : "brand.400"
            }
            borderRadius="15px"
            border="1px solid"
            borderColor={
              override || showAuthorisationsModal ? "gray.100" : "gray.200"
            }
            py="12px"
            px="15px"
          >
            <Flex gap="15px" flexDirection="column" width="100%">
              <Flex alignItems="baseline" gap="10px">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Aircraft:
                </Text>
                <Text fontSize="0.85rem">{authorisationData?.aircraft}</Text>
              </Flex>

              <Flex alignItems="baseline" gap="10px">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Airframe:
                </Text>
                <Text fontSize="0.85rem">{authorisationData?.airframe}</Text>
              </Flex>

              <Flex alignItems="baseline" gap="10px" flexDirection="column">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Airframe Status:
                </Text>

                <Flex
                  justifyContent="center"
                  alignItems="flex-start"
                  flexDirection="column"
                  width="100%"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width="100%"
                    bg={
                      override || showAuthorisationsModal
                        ? "brand.400"
                        : "brand.500"
                    }
                    borderRadius="10px"
                    px="15px"
                    py="7px"
                    fontSize="0.8rem"
                    gap="3px"
                    mb="3px"
                  >
                    <Flex
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="row"
                      width="100%"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight={"700"}
                        minWidth="160px"
                      >
                        Airworthy:
                      </Text>
                      <Flex
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                        width="100%"
                        mb="5px"
                      >
                        <Text
                          fontSize="0.8rem"
                          fontWeight={"700"}
                          color={
                            selectedAirframe?.status?.airworthy === "caution"
                              ? "#ffd600"
                              : selectedAirframe?.status?.airworthy
                              ? "#00fb00"
                              : "#ff0000"
                          }
                        >
                          {selectedAirframe?.status?.airworthy ? "YES" : "NO"}
                        </Text>

                        {(selectedAirframe?.status?.airworthy ||
                          selectedAirframe?.status?.airworthy ===
                            "caution") && (
                          <Text
                            fontSize="0.7rem"
                            fontWeight={"400"}
                            fontStyle="italic"
                            textTransform={"uppercase"}
                          >
                            {selectedAirframe?.status?.airworthy === "caution"
                              ? "– with current defects"
                              : selectedAirframe?.status?.airworthy
                              ? ""
                              : "– unavailable"}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                    <Flex
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row"
                      width="100%"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight={"700"}
                        minWidth="160px"
                      >
                        Hours Total:
                      </Text>
                      <Text fontSize="0.8rem" fontWeight={"400"}>
                        {selectedAirframe?.status?.hourstotal}
                      </Text>
                    </Flex>
                    <Flex
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row"
                      width="100%"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight={"700"}
                        minWidth="160px"
                      >
                        Hours to Maintenance:
                      </Text>
                      <Text fontSize="0.8rem" fontWeight={"400"}>
                        {selectedAirframe?.status?.hourstomaintenance}
                      </Text>
                    </Flex>
                    <Flex
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="row"
                      width="100%"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight={"700"}
                        minWidth="160px"
                      >
                        Defects:
                      </Text>
                      <Flex
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                        width="100%"
                        ml={
                          selectedAirframe?.status?.defects.length
                            ? "15px"
                            : "0px"
                        }
                      >
                        {selectedAirframe?.status?.defects.length ? (
                          <ul>
                            {selectedAirframe?.status?.defects.map(
                              (defect, i) => (
                                <li key={`defect.${i}`}>
                                  <Flex fontSize="0.8rem" fontWeight={"400"}>
                                    {defect}
                                  </Flex>
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          "None"
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            boxShadow={
              showAuthorisationsModal &&
              "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
            }
            minHeight="240px"
            height="100%"
            area={"aircrew"}
            width="100%"
            alignItems="baseline"
            fontSize="0.85rem"
            background={
              override || showAuthorisationsModal ? "white" : "brand.400"
            }
            borderRadius="15px"
            border="1px solid"
            borderColor={
              override || showAuthorisationsModal ? "gray.100" : "gray.200"
            }
            py="12px"
            px="15px"
          >
            <Flex
              gap="15px"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
              width="100%"
            >
              <Flex alignItems="baseline" gap="10px" pb="5px" minHeight="55px">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                  minWidth={
                    aircrewDetails && aircrewDetails.length ? "80px" : "60px"
                  }
                >
                  Aircrew:
                </Text>
                <Flex fontSize="0.85rem" width="100%">
                  {(authorisationData?.pilot || authorisationData?.passenger) &&
                  aircrewDetails &&
                  aircrewDetails.length ? (
                    <ul style={{ width: "100%" }}>
                      {aircrewDetails.map((feat, i) => (
                        <li
                          key={`name.${i}`}
                          style={{ width: "100%", paddingBottom: "5px" }}
                        >
                          <Flex
                            fontSize="0.8rem"
                            gap="10px"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Flex gap="10px" alignItems="baseline">
                              <Text fontWeight="400">{feat.name}</Text>
                              <Text
                                fontWeight="400"
                                fontSize="0.75rem"
                                color="gray.400"
                              >
                                {feat.callsign}
                              </Text>
                            </Flex>
                            <Flex // AVAILABLE
                              minWidth="10px"
                              width="10px"
                              height="10px"
                              borderRadius="100%"
                              bg={!feat.available ? "#ff0000" : "#11EC4E"}
                            ></Flex>
                          </Flex>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Text color="gray.300" fontStyle="italic">
                      None selected
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Flex alignItems="baseline" gap="10px" flexDirection="column">
                <Text
                  fontSize="0.85rem"
                  fontWeight="600"
                  fontFamily="'Poppins'"
                >
                  Qualifications & Currencies Required:
                </Text>

                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                  bg={
                    override || showAuthorisationsModal
                      ? "brand.400"
                      : "brand.500"
                  }
                  borderRadius="10px"
                  fontSize="0.8rem"
                  gap="3px"
                  pb="7px"
                  mb="3px"
                >
                  <Flex
                    justifyContent="flex-start"
                    alignItems="center"
                    flexDirection="row"
                    width="100%"
                    borderBottom="2px solid"
                    borderColor={
                      override || showAuthorisationsModal
                        ? "white"
                        : "brand.400"
                    }
                    px="15px"
                    py="5px"
                    mb="3px"
                  >
                    <Text fontSize="0.8rem" fontWeight={"700"} width="50%">
                      Qualification
                    </Text>
                    <Text fontSize="0.8rem" fontWeight={"700"} width="50%">
                      Currency
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="flex-start"
                    alignItems="center"
                    flexDirection="row"
                    width="100%"
                    px="15px"
                  >
                    <Text fontSize="0.8rem" fontWeight={"400"} width="50%">
                      Radar Operator
                    </Text>
                    <Text fontSize="0.8rem" fontWeight={"400"} width="50%">
                      1 per year
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="flex-start"
                    alignItems="center"
                    flexDirection="row"
                    width="100%"
                    px="15px"
                  >
                    <Text fontSize="0.8rem" fontWeight={"400"} width="50%">
                      SAR
                    </Text>
                    <Text fontSize="0.8rem" fontWeight={"400"} width="50%">
                      3 per year
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexDirection="row"
                    width="100%"
                    px="15px"
                  >
                    <Text fontSize="0.8rem" fontWeight={"400"} width="50%">
                      Night Flying
                    </Text>
                    <Text fontSize="0.8rem" fontWeight={"400"} width="50%">
                      1 per 90 days
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          boxShadow={
            showAuthorisationsModal &&
            "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
          }
          area={"intentions"}
          width="100%"
          alignItems="baseline"
          fontSize="0.85rem"
          background={
            override || showAuthorisationsModal ? "white" : "brand.400"
          }
          borderRadius="15px"
          border="1px solid"
          borderColor={
            override || showAuthorisationsModal ? "gray.100" : "gray.200"
          }
          py="12px"
          px="15px"
          minHeight={{ base: "160px", lg: "100px" }}
        >
          <Flex gap="15px" flexDirection="column" width="100%">
            <Flex
              alignItems="baseline"
              gap={{ base: "3px", lg: "10px" }}
              flexDirection={{ base: "column", lg: "row" }}
            >
              <Text fontSize="0.85rem" fontWeight="600" fontFamily="'Poppins'">
                Intentions / Description:
              </Text>
              <Text fontSize="0.85rem">{authorisationData?.intentions}</Text>
            </Flex>
          </Flex>
        </Flex>

        {user === "hq" &&
          showAuthorisationsModal &&
          (authorisationData?.status === "pending" ||
            authorisationData?.status === "approved" ||
            authorisationData?.status === "completed" ||
            authorisationData?.status === "declined") && (
            <Flex
              boxShadow={
                showAuthorisationsModal &&
                "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
              }
              area={"intentions"}
              width="100%"
              alignItems="baseline"
              fontSize="0.85rem"
              background={
                override || showAuthorisationsModal ? "white" : "brand.400"
              }
              borderRadius="15px"
              border="1px solid"
              borderColor={
                override || showAuthorisationsModal ? "gray.100" : "gray.200"
              }
              mt="20px"
              pt="12px"
              pb="20px"
              px="15px"
              minHeight={{ base: "160px", lg: "100px" }}
            >
              <Flex gap="10px" flexDirection="column" width="100%">
                <Flex
                  alignItems="baseline"
                  gap={{ base: "3px", lg: "10px" }}
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  <Text
                    fontSize="0.85rem"
                    fontWeight="600"
                    fontFamily="'Poppins'"
                  >
                    Flight Folder / Briefing:
                  </Text>
                </Flex>
                <Flex
                  alignItems={{ base: "flex-start", ml: "flex-start" }}
                  justifyContent={{ base: "flex-start", ml: "flex-start" }}
                  gap={{ base: "15px", md: "20px" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    pt="5px"
                    width="100%"
                    gap="10px"
                    flexDirection="column"
                    justifyContent={{ base: "flex-start", ml: "flex-start" }}
                    alignItems={{ base: "center", ml: "flex-start" }}
                  >
                    {briefingOptions &&
                      briefingOptions.map((item, index) => (
                        <BriefingOption
                          key={`label.${index}`}
                          {...item}
                          index={index}
                        />
                      ))}
                  </Flex>

                  <Flex
                    flexDirection="column"
                    width="100%"
                    height="100%"
                    minHeight="100%"
                    justifyContent="flex-start"
                    gap={{ base: "25px", md: "34px" }}
                    pt={{ base: "20px", md: "0px" }}
                  >
                    <Flex
                      alignItems="baseline"
                      gap="10px"
                      flexDirection="column"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight="600"
                        fontFamily="'Poppins'"
                        textTransform="uppercase"
                      >
                        Operations Squadron
                      </Text>

                      <Flex
                        justifyContent="center"
                        alignItems="flex-start"
                        flexDirection="column"
                        width="100%"
                      >
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          width="100%"
                          bg={
                            override || showAuthorisationsModal
                              ? "brand.400"
                              : "brand.500"
                          }
                          borderRadius="10px"
                          px="15px"
                          pt="7px"
                          pb="25px"
                          fontSize="0.8rem"
                          gap="8px"
                        >
                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              Accepted By:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.acceptedbyopssqd}
                            </Text>
                          </Flex>

                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              Date of Acceptance:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.accepteddateopssqd}
                            </Text>
                          </Flex>

                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              References:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.acceptedrefopssqd}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      alignItems="baseline"
                      gap="10px"
                      flexDirection="column"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight="600"
                        fontFamily="'Poppins'"
                        textTransform="uppercase"
                      >
                        230 Squadron
                      </Text>

                      <Flex
                        justifyContent="center"
                        alignItems="flex-start"
                        flexDirection="column"
                        width="100%"
                      >
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          width="100%"
                          bg={
                            override || showAuthorisationsModal
                              ? "brand.400"
                              : "brand.500"
                          }
                          borderRadius="10px"
                          px="15px"
                          pt="7px"
                          pb="25px"
                          fontSize="0.8rem"
                          gap="8px"
                        >
                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              Accepted By:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.acceptedby230sqd}
                            </Text>
                          </Flex>

                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              Date of Acceptance:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.accepteddate230sqd}
                            </Text>
                          </Flex>

                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              References:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.acceptedref230sqd}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      alignItems="baseline"
                      gap="10px"
                      flexDirection="column"
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight="600"
                        fontFamily="'Poppins'"
                        textTransform="uppercase"
                      >
                        Base HQ
                      </Text>

                      <Flex
                        justifyContent="center"
                        alignItems="flex-start"
                        flexDirection="column"
                        width="100%"
                      >
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          width="100%"
                          bg={
                            override || showAuthorisationsModal
                              ? "brand.400"
                              : "brand.500"
                          }
                          borderRadius="10px"
                          px="15px"
                          pt="7px"
                          pb="25px"
                          fontSize="0.8rem"
                          gap="8px"
                        >
                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              Accepted By:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.acceptedbybasehq}
                            </Text>
                          </Flex>

                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              Date of Acceptance:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.accepteddatebasehq}
                            </Text>
                          </Flex>

                          <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                            width="100%"
                          >
                            <Text
                              fontSize="0.8rem"
                              fontWeight={"700"}
                              minWidth="140px"
                            >
                              References:
                            </Text>
                            <Text fontSize="0.8rem" fontWeight={"400"}>
                              {authorisationData?.acceptedrefbasehq}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}

        {showAuthorisationsModal &&
          (authorisationData?.status === "approved" ||
            authorisationData?.status === "completed" ||
            authorisationData?.status === "declined") && (
            <Flex
              width="100%"
              alignItems="baseline"
              gap="20px"
              flexDirection={{ base: "column", lg: "row" }}
            >
              <Flex
                mt="20px"
                boxShadow={
                  showAuthorisationsModal &&
                  "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
                }
                area={"restrictions"}
                width="100%"
                alignItems="baseline"
                fontSize="0.85rem"
                background={"white"}
                borderRadius="15px"
                border="1px solid"
                borderColor={"gray.100"}
                py="12px"
                px="15px"
              >
                <Flex gap="15px" flexDirection="column" width="100%">
                  <Flex
                    alignItems="baseline"
                    gap={{
                      base: "0px",
                      xs: showAuthorisationsModal ? "10px" : "0px",
                      xl: "10px",
                    }}
                    flexDirection={{
                      base: "column",
                      xs: showAuthorisationsModal ? "row" : "column",
                      xl: "row",
                    }}
                  >
                    <Text
                      fontSize="0.85rem"
                      fontWeight="600"
                      fontFamily="'Poppins'"
                    >
                      Restrictions:
                    </Text>

                    <Text fontSize="0.85rem">
                      {authorisationData?.restrictions}
                    </Text>
                  </Flex>

                  <Flex
                    alignItems="baseline"
                    gap={{
                      base: "0px",
                      xs: showAuthorisationsModal ? "10px" : "0px",
                      xl: "10px",
                    }}
                    flexDirection={{
                      base: "column",
                      xs: showAuthorisationsModal ? "row" : "column",
                      xl: "row",
                    }}
                  >
                    <Text
                      fontSize="0.85rem"
                      fontWeight="600"
                      fontFamily="'Poppins'"
                    >
                      Comments:
                    </Text>

                    <Text fontSize="0.85rem">
                      {authorisationData?.approvedcomments}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                mt={{
                  base: "0px",
                  lg: "20px",
                }}
                boxShadow={
                  showAuthorisationsModal &&
                  "0px 10px 35px -12px rgba(0, 45, 78, 0.15)"
                }
                area={"approvedby"}
                width="100%"
                alignItems="baseline"
                fontSize="0.85rem"
                background={
                  authorisationData?.status === "declined"
                    ? "#ffeadd"
                    : "#e7f3ff"
                }
                borderRadius="15px"
                border="1px solid"
                borderColor={
                  authorisationData?.status === "declined"
                    ? "#e5f2ff"
                    : "#e2f0ff"
                }
                py="12px"
                px="15px"
              >
                <Flex gap="15px" flexDirection="column" width="100%">
                  <Flex
                    alignItems="baseline"
                    gap={{
                      base: "0px",
                      xs: showAuthorisationsModal ? "10px" : "0px",
                      xl: "10px",
                    }}
                    flexDirection={{
                      base: "column",
                      xs: showAuthorisationsModal ? "row" : "column",
                      xl: "row",
                    }}
                  >
                    <Text
                      fontSize="0.85rem"
                      fontWeight="600"
                      fontFamily="'Poppins'"
                    >
                      {authorisationData?.status === "declined"
                        ? "Declined By:"
                        : "Approved By:"}
                    </Text>
                    <Text fontSize="0.85rem">
                      {authorisationData?.approvedby}
                    </Text>
                  </Flex>

                  <Flex
                    alignItems="baseline"
                    gap={{
                      base: "0px",
                      xs: showAuthorisationsModal ? "10px" : "0px",
                      xl: "10px",
                    }}
                    flexDirection={{
                      base: "column",
                      xs: showAuthorisationsModal ? "row" : "column",
                      xl: "row",
                    }}
                  >
                    <Text
                      fontSize="0.85rem"
                      fontWeight="600"
                      fontFamily="'Poppins'"
                    >
                      {authorisationData?.status === "declined"
                        ? "Declined Date:"
                        : "Approved Date:"}
                    </Text>
                    <Text fontSize="0.85rem">
                      {authorisationData?.approveddate &&
                        formatToTimeZone(
                          parseISO(authorisationData.approveddate),
                          "D MMM YY HH:mm",
                          {
                            timeZone: timeZone,
                          }
                        )}
                    </Text>
                  </Flex>

                  <Flex alignItems="baseline" gap="10px">
                    <Text
                      fontSize="0.85rem"
                      fontWeight="600"
                      fontFamily="'Poppins'"
                    >
                      {authorisationData?.status === "declined"
                        ? "Declined Reference:"
                        : "Approval Reference:"}
                    </Text>
                    <Text fontSize="0.85rem">
                      {authorisationData?.approvedref}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
      </Flex>
    </>
  );
}
