import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Center, Spinner } from "@chakra-ui/react";

export default function PubNubClient(props) {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const [res, setRes] = useState(
    <Center flexDirection="column" position="fixed" w="100%" h="100%">
      <Spinner size="4xl" />
    </Center>
  );

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }

    if (user) {
      const pubNubClient = new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUB_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUB_KEY,
        uuid: user.sub,
      });

      setRes(
        <PubNubProvider client={pubNubClient}>{props.children}</PubNubProvider>
      );
    }
  }, [user, isLoading, loginWithRedirect, isAuthenticated, props.children]);

  return res;
}
