import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useDisclosure,
  Text,
  LightMode,
  ModalCloseButton,
  Icon,
} from "@chakra-ui/react";
import { deleteModalState, deleteAuthorisationState } from "../globalState";
import { MdDeleteForever } from "react-icons/md";

export default function DeleteModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);
  const [deleteAuthorisation, setDeleteAuthorisation] = useRecoilState(
    deleteAuthorisationState
  );

  useEffect(() => {
    showDeleteModal ? onOpen() : onClose();
  }, [showDeleteModal, onOpen, onClose]);

  function onCloseHandler() {
    setShowDeleteModal(false);
    setDeleteAuthorisation(null);
    onClose();
  }

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };

  return (
    <>
      <LightMode>
        <Modal
          variant="primary"
          size="xl"
          transition="all ease 300ms"
          onClose={onCloseHandler}
          isOpen={isOpen}
          closeOnOverlayClick={true}
          isCentered
          trapFocus={false}
          blockScrollOnMount={true}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              zIndex="1"
              textAlign="center"
              backgroundImage="linear-gradient(345deg, #ff4b4b 10%, #ff0000 90%)"
            >
              <Flex
                gap="5px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex
                  gap="10px"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon as={MdDeleteForever} fontSize="1.8rem" color="white" />
                  <Text color="white" pr="10px">
                    Confirm Delete
                  </Text>
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              style={{ padding: 0 }}
              fontSize="0.8rem"
              fontFamily="'Open Sans',sans-serif"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                minHeight="120px"
                fontSize="1.1rem"
                fontWeight="700"
                bg="white"
                py={{ base: "20px", lg: "15px" }}
                px={{ base: "20px", lg: "30px" }}
                gap={{ base: "5px", lg: "25px" }}
                flexDirection={{ base: "column", lg: "row" }}
                boxShadow="0px 0px 15px -1px rgba(0,0,0,0.1)"
                zIndex="2"
                position="relative"
              >
                <Text
                  width="100%"
                  fontSize={{ base: "0.85rem", lg: "0.9rem", xl: "1rem" }}
                  fontWeight="400"
                  textAlign="center"
                >
                  Are you sure you want to delete authorisation: <br />
                  <span style={{ fontWeight: "700" }}>
                    {deleteAuthorisation && deleteAuthorisation?.title}
                  </span>
                  ?
                </Text>
              </Flex>
            </ModalBody>
            <ModalFooter borderTop="1px solid #e5e7e9" pt={4} pb={4}>
              <Flex
                flexDirection="row"
                gap="10px"
                width="100%"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  {...noFocus}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="outline"
                  onClick={() => {
                    onCloseHandler();
                  }}
                  bg="gray.50"
                  color="gray.700"
                  _hover={{ background: "gray.100" }}
                  _focus={{ background: "gray.100" }}
                  _active={{ background: "gray.100" }}
                  borderRadius="25px"
                >
                  Cancel
                </Button>

                <Button
                  {...noFocus}
                  w="100%"
                  size="sm"
                  maxWidth="180px"
                  variant="primary"
                  bg="#FF1B1B"
                  _hover={{ background: "#e90000" }}
                  _focus={{ background: "#e90000" }}
                  _active={{ background: "#e90000" }}
                  borderRadius="25px"
                  onClick={() => {
                    if (deleteAuthorisation) {
                      setShowDeleteModal(false);
                      onCloseHandler();
                    }
                  }}
                >
                  Delete
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  );
}
