import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import {
  addWeeks,
  subWeeks,
  format,
  addDays,
  addMonths,
  subDays,
  startOfWeek,
  endOfWeek,
  isThisWeek,
} from "date-fns";
import { Select } from "chakra-react-select";
import AirframeBoard from "./Board";
import {
  ButtonGroup,
  Button,
  Flex,
  Icon,
  Text,
  Divider,
  Box,
  useBreakpointValue,
  IconButton,
} from "@chakra-ui/react";
// import Tabs from "../Menu/Tabs";
import { TbZoomOutArea, TbZoomInArea } from "react-icons/tb";
import { MdOutlineAdd } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { ReactComponent as DayIcon } from "../Assets/calendar-day.svg";
import { ReactComponent as WeekIcon } from "../Assets/calendar-week.svg";
import { ReactComponent as MonthIcon } from "../Assets/calendar-month.svg";
import {
  // BsSearch,
  // BsFiletypePdf,
  BsEnvelopeAt,
  BsPrinter,
  BsFolderPlus,
} from "react-icons/bs";
import { useApproverRole } from "../Auth/Hooks";
import {
  newAuthorisationState,
  selectedMonthState,
  selectedWeekState,
  selectedDayState,
  boardViewState,
  squadronSelectionState,
} from "../globalState";

export default function Airframe(props) {
  const { data, zoomLevel, setZoomLevel } = props;

  const isApprover = useApproverRole();
  const [selectedMonth, setSelectedMonth] = useRecoilState(selectedMonthState);
  const [selectedDay, setSelectedDay] = useRecoilState(selectedDayState);
  const [selectedWeek, setSelectedWeek] = useRecoilState(selectedWeekState);
  const [boardView, setBoardView] = useRecoilState(boardViewState);
  const [squadronSelection, setSquadronSelection] = useRecoilState(
    squadronSelectionState
  );

  const setNewAuthorisation = useSetRecoilState(newAuthorisationState);

  useEffect(() => {
    document.title = "Airframe Bookings // TASKR";
  }, []);

  // day calcs
  const today = new Date();
  const newDay =
    selectedDay > 0
      ? addDays(today, selectedDay)
      : subDays(today, Math.abs(selectedDay));

  const adjustedDay = format(newDay, "yyyy-MM-dd");
  const dayStart = adjustedDay + "T00:00:00";
  const dayEnd = format(addDays(newDay, 1), "yyyy-MM-dd") + "T00:00:00";

  // week calcs
  const dayOfWeek = format(newDay, "EEEE");
  const dateOfWeek = format(newDay, "dd");
  const getWeek = isThisWeek(new Date()) && 0;
  const newWeek =
    selectedWeek >= getWeek
      ? addWeeks(today, selectedWeek)
      : subWeeks(today, Math.abs(selectedWeek));
  const startOfTheWeek = startOfWeek(newWeek);
  const endOfTheWeek = endOfWeek(newWeek);
  const weekStart = format(startOfTheWeek, "yyyy-MM-dd") + "T00:00:00";
  const weekEnd = format(endOfTheWeek, "yyyy-MM-dd") + "T23:59:59";

  // month calcs
  const month = format(newDay, "MMMM"); // for week view

  const getMonthName = (selectedMonth) => {
    const currentDate = new Date();
    const targetDate = addMonths(currentDate, selectedMonth - 3);
    const monthName = format(targetDate, "MMMM");
    return monthName;
  };
  const monthName = getMonthName(selectedMonth); // for month view

  // console.log(getWeek);
  // console.log("newWeek", newWeek);
  // console.log("weekStart", weekStart);
  // console.log("weekEnd", weekEnd);

  const smallScreen = useBreakpointValue({
    base: true,
    "2xl": false,
  });

  const nowButtonDesktop = useRef();
  const nowButtonContainer = { containerRef: nowButtonDesktop };

  const selectStyle = {
    container: (styles) => ({
      ...styles,
      fontWeight: "500",
    }),
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
      paddingTop: "6px",
      paddingBottom: "6px",
      background: "#ffffff",
      color: "light.100",
      fontWeight: "500",
      _hover: {
        background: "cyan.400",
        color: "white",
        fontWeight: "500",
      },
      transition: "all ease 100ms",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
      borderColor: "#e2e8f0",
      minHeight: "30px",
      height: "30px",
      background: "#ffffff",
      minWidth: {
        base: "140px",
        md: "140px",
        lg: "158px",
        xl: "140px",
        "2xl": "158px",
      },
      _focusVisible: { boxShadow: "none !important", borderColor: "cyan.500" },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: "4px",
      paddingBottom: "4px",
      background: "gray.50",
      _hover: {
        background: "#e7edf3",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100",
      marginTop: "4px",
    }),
  };

  const unitOptions = [
    { value: "14 SQN/CFS", label: "14 Squadron" },
    { value: "40 SQN/CFS", label: "40 Squadron" },
  ];

  const squadron =
    squadronSelection === "40 SQN/CFS" ? unitOptions[1] : unitOptions[0];
  const [selectedOptions, setSelectedOptions] = useState(squadron);

  const noFocus = {
    _focus: { boxShadow: "none !important", borderColor: "initial" },
  };
  const buttonBgActive = "#e7ecf3";
  const buttonBgHover = "gray.200";

  // console.log(selectedWeek);
  // console.log("weekStart", weekStart);
  // console.log("weekEnd", weekEnd);
  // // // console.log('Formatted Date:', formattedDate);
  // console.log("dayStart", dayStart);
  // console.log("dayEnd", dayEnd);
  // console.log("dayOfWeek:", dayOfWeek);
  // console.log("dateOfWeek:", dateOfWeek);
  // console.log("adjustedDate", adjustedDate);
  // console.log("getWeek:", getWeek);
  // console.log("selectedDay:", selectedDay);
  // console.log("selectedWeek:", selectedWeek);

  return (
    <Flex
      margin={0}
      px={{ base: "0px", smd: "15px", lg: "15px" }}
      pt={{ base: "15px", smd: "15px", lg: "15px" }}
      pb={{ base: "79px", smd: "95px", lg: "15px" }}
      flex={1}
      flexDirection="column"
      overflowY="auto"
      zIndex="1"
      width="100%"
      height="100%"
      minHeight="100%"
    >
      {/* <Flex
        position="absolute"
        marginTop={{ base: "78px", "2xl": "38px" }}
        background="gray.200"
        height="60px"
        width="50px"
      ></Flex> */}
      <Flex
        width="100%"
        height={{ base: "80px", xl: "40px" }}
        minHeight={{ base: "80px", xl: "40px" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", xl: "flex-end" }}
        zIndex="2"
        px={{ base: "10px", smd: "0px" }}
      >
        {/* <Tabs tab1={"airframe"} tab2={"aircrew"} tab3={"area"} /> */}

        <Flex
          gap={{ base: "10px", sm: "10px", md: "10px" }}
          width={{ base: "100%", xl: "100%" }}
          mb={{ base: "10px", sm: "10px" }}
          // marginLeft={{ base: "auto", "2xl": "0px" }}
          flexDirection={{ base: "column-reverse", xl: "row" }}
          alignItems="center"
        >
          <Flex
            alignItems="center"
            justifyContent={{ base: "space-between", xl: "flex-start" }}
            width="100%"
            gap={{ base: "10px", smd: "10px" }}
          >
            <Button
              bg="#e7ecf3"
              p="0px"
              opacity="1"
              variant="outline"
              fontSize="11px"
              height={{
                base: "30px",
                md: "30px",
              }}
              width={{
                base: "139px",
                sm: "154px",
                smd: "184px",
                "2xl": "244px",
              }}
              minWidth={{
                base: "139px",
                sm: "154px",
                smd: "184px",
                "2xl": "244px",
              }}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              borderRadius={"20px"}
              boxShadow={"inset 0px 0px 3px 0px rgba(0,0,0,0.18)"}
              border={"none"}
              borderColor="rgba(255,255,255,0.1)"
              {...noFocus}
              index={boardView === "day" ? 0 : 1}
              _active={{
                bg: buttonBgActive,
              }}
              _focus={{
                bg: buttonBgActive,
              }}
              _hover={{
                bg: buttonBgHover,
              }}
            >
              <Box
                zIndex="1"
                display={"flex"}
                ml="2px"
                height={{ base: "26px", md: "26px" }}
                width={{ base: "45px", sm: "50px", smd: "60px", "2xl": "80px" }}
                minWidth={{
                  base: "45px",
                  sm: "50px",
                  smd: "60px",
                  "2xl": "80px",
                }}
                background="white"
                borderRadius="20px"
                bg={"#ffffff"}
                boxShadow="0px 0px 1px 0px rgba(0,0,0,0.25)"
                borderColor="#E9EAEB"
                transition="all ease 300ms"
                transform={
                  boardView === "month"
                    ? "translateX(200%)"
                    : boardView === "week"
                    ? "translateX(100%)"
                    : "translateX(0%)"
                }
              ></Box>
              <Flex
                zIndex="2"
                opacity={"1"}
                _hover={{ opacity: "1" }}
                justifyContent="space-evenly"
                marginLeft={{
                  base: "-45px",
                  sm: "-50px",
                  smd: "-60px",
                  "2xl": "-80px",
                }}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.3"
                  color={boardView === "day" ? "cyan.400" : "gray.500"}
                  fontWeight={boardView === "day" ? "600" : "500"}
                  opacity={boardView === "day" ? "1" : "0.8"}
                  transition="all ease 300ms"
                  _selected={{
                    opacity: "1",
                  }}
                  _active={{
                    opacity: "1",
                  }}
                  _hover={{
                    opacity: "1",
                  }}
                  height={{
                    base: "30px",
                    md: "30px",
                  }}
                  minWidth={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "80px",
                  }}
                  onClick={() => {
                    setBoardView("day");
                  }}
                  gap={{ base: "0px", "2xl": "3px" }}
                >
                  <Icon
                    as={DayIcon}
                    width={{ base: "20px", "2xl": "18px" }}
                    height="auto"
                  />
                  <Text display={{ base: "none", "2xl": "block" }}>DAY</Text>
                </Flex>
                <Flex
                  zIndex="1"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.3"
                  color={boardView === "week" ? "cyan.400" : "gray.500"}
                  fontWeight={boardView === "week" ? "600" : "500"}
                  opacity={boardView === "week" ? "1" : "0.8"}
                  transition="all ease 300ms"
                  _selected={{
                    opacity: "1",
                  }}
                  _active={{
                    opacity: "1",
                  }}
                  _hover={{
                    opacity: "1",
                  }}
                  height={{
                    base: "30px",
                    md: "30px",
                  }}
                  minWidth={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "80px",
                  }}
                  onClick={() => {
                    setBoardView("week");
                  }}
                  gap={{ base: "0px", "2xl": "3px" }}
                >
                  <Icon
                    as={WeekIcon}
                    width={{ base: "20px", "2xl": "18px" }}
                    height="auto"
                  />
                  <Text display={{ base: "none", "2xl": "block" }}>WEEK</Text>
                </Flex>
                <Flex
                  zIndex="1"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.3"
                  color={boardView === "month" ? "cyan.400" : "gray.500"}
                  fontWeight={boardView === "month" ? "600" : "500"}
                  opacity={boardView === "month" ? "1" : "0.8"}
                  transition="all ease 300ms"
                  _selected={{
                    opacity: "1",
                  }}
                  _active={{
                    opacity: "1",
                  }}
                  _hover={{
                    opacity: "1",
                  }}
                  height={{
                    base: "30px",
                    md: "30px",
                  }}
                  minWidth={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "80px",
                  }}
                  onClick={() => {
                    setBoardView("month");
                  }}
                  gap={{ base: "0px", "2xl": "3px" }}
                >
                  <Icon
                    as={MonthIcon}
                    width={{ base: "20px", "2xl": "18px" }}
                    height="auto"
                  />
                  <Text display={{ base: "none", "2xl": "block" }}>MONTH</Text>
                </Flex>
              </Flex>
            </Button>

            <Flex alignItems="center" gap={{ base: "10px", smd: "10px" }}>
              <ButtonGroup size="sm" isAttached={true} variant="outline">
                <Button
                  borderTopLeftRadius="20px"
                  borderBottomLeftRadius="20px"
                  // isDisabled={selectedMonth === 1}
                  onClick={() => {
                    if (boardView === "day") {
                      setSelectedDay((prevState) => prevState - 1);
                    }
                    if (boardView === "week") {
                      setSelectedWeek((prevState) => prevState - 1);
                    }
                    if (boardView === "month") {
                      setSelectedMonth((prevState) => prevState - 1);
                    }
                  }}
                  variant="outline"
                  size="sm"
                  bg="gray.50"
                  width={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "80px",
                  }}
                  fontSize="0.7rem"
                  fontWeight="600"
                  height="30px"
                  color="gray.600"
                  _hover={{
                    background: "white",
                    color: "gray.700",
                  }}
                >
                  <Icon
                    as={FaChevronLeft}
                    width="14px"
                    height="auto"
                    display={{ base: "block", sm: "none" }}
                  />
                  <Text display={{ base: "none", sm: "block" }}>
                    {smallScreen
                      ? "Prev"
                      : boardView === "month"
                      ? "Prev Month"
                      : boardView === "week"
                      ? "Prev Week"
                      : "Prev Day"}
                  </Text>
                </Button>
                <Button
                  borderTopRightRadius="20px"
                  borderBottomRightRadius="20px"
                  // isDisabled={selectedMonth === 12}
                  onClick={() => {
                    if (boardView === "day") {
                      setSelectedDay((prevState) => prevState + 1);
                    }
                    if (boardView === "week") {
                      setSelectedWeek((prevState) => prevState + 1);
                    }
                    if (boardView === "month") {
                      setSelectedMonth((prevState) => prevState + 1);
                    }
                  }}
                  variant="outline"
                  size="sm"
                  bg="gray.50"
                  width={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "80px",
                  }}
                  fontSize="0.7rem"
                  fontWeight="600"
                  height="30px"
                  color="gray.600"
                  _hover={{
                    background: "white",
                    color: "gray.700",
                  }}
                >
                  <Icon
                    as={FaChevronRight}
                    width="14px"
                    height="auto"
                    display={{ base: "block", sm: "none" }}
                  />
                  <Text display={{ base: "none", sm: "block" }}>
                    {smallScreen
                      ? "Next"
                      : boardView === "month"
                      ? "Next Month"
                      : boardView === "week"
                      ? "Next Week"
                      : "Next Day"}
                  </Text>
                </Button>
              </ButtonGroup>

              {/* <Box ref={nowButtonMobile}></Box> */}
              <Box
                dispay={{ base: "none", md: "block" }}
                ml={{ base: "0px", md: "0px" }}
                ref={nowButtonDesktop}
              ></Box>

              <ButtonGroup size="sm" isAttached={true} variant="outline">
                <Button
                  borderTopLeftRadius="20px"
                  borderBottomLeftRadius="20px"
                  onClick={() => {
                    if (zoomLevel > 0) {
                      setZoomLevel(zoomLevel - 1);
                    }
                  }}
                  isDisabled={zoomLevel === 0}
                  variant="outline"
                  size="sm"
                  bg="gray.50"
                  width={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "60px",
                  }}
                  height="30px"
                  color="gray.600"
                  _hover={{
                    background: "white",
                    color: "gray.700",
                  }}
                >
                  <Icon as={TbZoomOutArea} width="18px" height="auto" />
                </Button>
                <Button
                  borderTopRightRadius="20px"
                  borderBottomRightRadius="20px"
                  onClick={() => {
                    if (zoomLevel < 2) {
                      setZoomLevel(zoomLevel + 1);
                    }
                  }}
                  isDisabled={zoomLevel === 2}
                  variant="outline"
                  size="sm"
                  bg="gray.50"
                  width={{
                    base: "45px",
                    sm: "50px",
                    smd: "60px",
                    "2xl": "60px",
                  }}
                  height="30px"
                  color="gray.600"
                  _hover={{
                    background: "white",
                    color: "gray.700",
                  }}
                >
                  <Icon as={TbZoomInArea} width="18px" height="auto" />
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent={{ base: "space-between", xl: "flex-end" }}
            gap={{ base: "0.5rem", md: "15px", lg: "15px" }}
            width={{ base: "100%", xl: "auto" }}
          >
            <Flex
              alignItems="center"
              gap={{ base: "0.5rem", md: "15px", lg: "15px" }}
            >
              <Flex
                alignItems="center"
                gap={{ base: "0px", smd: "7px" }}
                width={{ base: "100%", lg: "auto" }}
              >
                <Text
                  display={{ base: "none", smd: "block" }}
                  fontSize="0.9rem"
                  htmlFor="aircraft"
                  fontFamily="'Poppins'"
                  fontWeight="500"
                  color="gray.500"
                >
                  UNIT:
                </Text>
                <Select
                  {...noFocus}
                  chakraStyles={selectStyle}
                  colorScheme="cyan"
                  variant="outline"
                  background="white"
                  size="xs"
                  cursor="pointer"
                  id="aircraft"
                  value={selectedOptions}
                  options={unitOptions}
                  onChange={(v) => {
                    setSelectedOptions(v);
                    setSquadronSelection(v.value);
                  }}
                />
              </Flex>
              <ButtonGroup spacing={{ base: "5px", md: "0.5rem" }}>
                {/* <IconButton
                aria-label="Search database"
                icon={<BsSearch />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              />
              <IconButton
                aria-label="Save to PDF"
                icon={<BsFiletypePdf />}
                borderRadius="10px"
                variant="outline"
                bg="gray.50"
                width={{ base: "30px", lg: "40px" }}
                height="30px"
                fontSize="0.9rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                _hover={{
                  background: "#e7edf3",
                }}
              /> */}
                <IconButton
                  aria-label="Email"
                  icon={<BsEnvelopeAt />}
                  borderRadius="10px"
                  variant="outline"
                  bg="gray.50"
                  width={{ base: "30px", lg: "40px" }}
                  height="30px"
                  fontSize="0.9rem"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  _hover={{
                    background: "#e7edf3",
                  }}
                />
                <IconButton
                  aria-label="Print"
                  icon={<BsPrinter />}
                  borderRadius="10px"
                  variant="outline"
                  bg="gray.50"
                  width={{ base: "30px", lg: "40px" }}
                  height="30px"
                  fontSize="0.95rem"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  _hover={{
                    background: "#e7edf3",
                  }}
                />
                <IconButton
                  aria-label="Save to File"
                  icon={<BsFolderPlus />}
                  borderRadius="10px"
                  variant="outline"
                  bg="gray.50"
                  width={{ base: "30px", lg: "40px" }}
                  height="30px"
                  fontSize="0.95rem"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  _hover={{
                    background: "#e7edf3",
                  }}
                />
              </ButtonGroup>
            </Flex>

            {!isApprover && (
              <Button
                as={Link}
                to="/authorisations"
                borderRadius="20px"
                size="sm"
                bg="brand.100"
                color="white"
                pl="10px"
                pr={{ base: "15px", sm: "15px" }}
                width={{ base: "80px", "2xl": "90px" }}
                minWidth={{ base: "auto", sm: "80px", "2xl": "90px" }}
                height="30px"
                fontSize="0.8rem"
                justifyContent="center"
                alignItems="center"
                lineHeight="1.4"
                gap="2px"
                _hover={{
                  background: "brand.200",
                }}
                onClick={() => {
                  setNewAuthorisation(true);
                }}
              >
                <Icon
                  as={MdOutlineAdd}
                  color="white"
                  width="22px"
                  height="auto"
                />
                <Text display={{ base: "block", sm: "block" }}>NEW</Text>
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        zIndex="1"
        width="100%"
        height={{
          base: "calc(100% - 80px)",
          xl: "calc(100% - 40px)",
        }}
        minHeight={{
          base: "calc(100% - 80px)",
          xl: "calc(100% - 40px)",
        }}
      >
        <Flex
          bg="white"
          width={{ base: "100px", smd: "105px" }}
          height={{ base: "70px", smd: "75px" }}
          pl={{ base: "0px", smd: "4px" }}
          borderRadius="16px 0px 0px 0px"
          position="absolute"
          zIndex="1000"
          alignItems={"center"}
          justifyContent="center"
          flexDirection="column"
          gap="0px"
        >
          {boardView === "day" && (
            <>
              <Text
                textTransform="uppercase"
                fontSize="0.9rem"
                fontWeight="700"
                color="#52c7d7"
                whiteSpace="pre"
                lineHeight="1.2"
              >
                {dayOfWeek}
              </Text>
              <Text
                textTransform="uppercase"
                fontSize="0.8rem"
                fontWeight="600"
                color="gray.500"
                whiteSpace="pre"
                lineHeight="1.2"
                pt="3px"
              >
                {dateOfWeek}
              </Text>
            </>
          )}
          <Text
            textTransform="uppercase"
            fontSize={boardView === "day" ? "0.7rem" : "1rem"}
            fontWeight="600"
            color={boardView !== "day" ? "#52c7d7" : "gray.500"}
            whiteSpace="pre"
            lineHeight="1.2"
            pb={boardView !== "day" && "15px"}
          >
            {boardView === "day" ? month : monthName}
          </Text>
        </Flex>
        <Divider
          zIndex="1001"
          width="100%"
          marginTop={{ base: "70px", smd: "75px" }}
          position="absolute"
        />
        <AirframeBoard
          data={data}
          zoomLevel={zoomLevel}
          selectedMonth={selectedMonth}
          selectedWeek={selectedWeek}
          dayStart={dayStart}
          dayEnd={dayEnd}
          nowButtonContainer={nowButtonContainer}
          weekStart={weekStart}
          weekEnd={weekEnd}
          squadronSelection={squadronSelection}
          setSelectedMonth={setSelectedMonth}
          setSelectedWeek={setSelectedWeek}
          setSelectedDay={setSelectedDay}
        />
      </Flex>
    </Flex>
  );
}
