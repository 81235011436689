import React, { useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Flex, Text, Badge, Image, Portal } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { parseISO, intervalToDuration, isPast } from "date-fns";
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
} from "@nessprim/planby";
import { useApproverRole } from "../../Auth/Hooks";
import {
  authorisationsModalState,
  authorisationDataState,
  newAuthorisationState,
  aircrewDataState,
} from "../../globalState";
import Airborne from "../../Assets/airborne.svg";

export default function Booking({
  zoomLevel,
  type,
  aircrew,
  airframe,
  program,
  ...rest
}) {
  const { styles, formatTime, isLive, isMinWidth } = useProgram({
    program,
    ...rest,
  });
  const setShowAuthorisationsModal = useSetRecoilState(
    authorisationsModalState
  );
  const setAuthorisationData = useSetRecoilState(authorisationDataState);
  const setNewAuthorisation = useSetRecoilState(newAuthorisationState);
  const aircrewData = useRecoilValue(aircrewDataState);
  const [showPopup, setShowPopup] = useState(false);
  const isApprover = useApproverRole();

  const { data } = program;
  const {
    // image,
    id,
    title,
    since,
    till,
    status,
    pilot,
    passenger,
    activity,
    area,
    priority,
    intentions,
  } = data;

  const now = Date.now();
  const sinceTime = formatTime(since);
  const tillTime = formatTime(till);
  const duration =
    since &&
    till &&
    intervalToDuration({ start: parseISO(since), end: parseISO(till) });
  const padWithZero = (value) => (value < 10 ? `0${value}` : value);
  const durationHasDays = duration?.days ? duration.days * 24 : 0;
  const formattedDuration = `${durationHasDays + duration?.hours}:${padWithZero(
    duration?.minutes
  )}`;
  const upcoming = parseISO(since) > now;
  const expired = parseISO(till) < now;
  const typeIsAircrew = type.substring(0, 7) === "aircrew";

  const bookingEndTime = parseISO(till);

  const getAircrewDetails = (id) => {
    const aircrew = aircrewData.find((crew) => crew.id === id);
    return aircrew && aircrew.id ? aircrew : null;
  };

  const pilotName = getAircrewDetails(pilot)?.name;
  const pilotCallsign = getAircrewDetails(pilot)?.callsign;
  const passengerName = getAircrewDetails(passenger)?.name;
  const passengerCallsign = getAircrewDetails(passenger)?.callsign;

  const unavailable =
    (data && data.type === "unavailable") || status === "tempUnavailable";

  // console.log("Booking data", data);
  // console.log("Booking status", status);
  // const lastCharacter = airframe && airframe.slice(-1);
  // const firstThreeRows = lastCharacter < 5;

  return (
    <ProgramBox
      width={styles.width}
      style={{
        ...styles.position,
        // display: "flex",
        // width: "inherit",
        // overflow: "visible",
        // flexDirection: "column",
        // justifyContent: "flex-end",
        // alignItems: "flex-start",
      }}
      className={
        (title ? "booking " : "") +
        (typeIsAircrew ? "aircrew" : "airframe") +
        " planby-program-box"
      }
      as={!unavailable && !status && Link}
      to={!unavailable && !status && "/authorisations"}
      onClick={(e) => {
        if (!unavailable) {
          if (status) {
            // console.log(program.data);
            setAuthorisationData(program.data);
            setShowAuthorisationsModal(true);
          } else if (isApprover) {
            console.log("Approver cannot issue an Authorisation at this time");
            e.preventDefault();
          } else {
            setNewAuthorisation(true);
          }
        }
      }}
    >
      <ProgramContent // BOOKING BAR
        width={styles.width}
        className={`planby-program-content ${
          zoomLevel === 0 ? "small" : zoomLevel === 1 ? "medium" : ""
        } ${status ? "booking" : ""} ${
          status === "draft"
            ? "draft"
            : status === "pending"
            ? "pending"
            : status === "approved"
            ? "approved"
            : status === "declined"
            ? "declined"
            : status === "completed"
            ? "completed"
            : ""
        } ${unavailable ? "unavailable" : ""} ${
          status === "tempUnavailable" ? "tempUnavailable" : ""
        }`}
        style={{
          opacity: isPast(bookingEndTime) && showPopup?.id !== id ? "0.5" : "1",
          cursor: unavailable ? "not-allowed" : "pointer",
          // filter:
          //   isPast(bookingEndTime) && showPopup?.id !== id && title
          //     ? "contrast(0.35) saturate(0.6) brightness(1.5)"
          //     : "contrast(1) saturate(1)",
        }}
        isLive={isLive}
        onMouseEnter={() => status && !unavailable && setShowPopup(data)}
        onMouseLeave={() => setShowPopup(null)}
      >
        <ProgramFlex
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            opacity:
              isPast(bookingEndTime) && showPopup?.id !== id ? "0.5" : "1",
            // filter:
            //   isPast(bookingEndTime) && showPopup?.id !== id && title
            //     ? "contrast(0.35) saturate(0.6) brightness(1.5)"
            //     : "contrast(1) saturate(1)",
          }}
        >
          {isLive && isMinWidth && title && !unavailable && (
            <ProgramImage
              src={Airborne}
              alt="Preview"
              style={{
                marginLeft: zoomLevel === 0 ? "0px" : "5px",
                width:
                  zoomLevel === 0 ? "10px" : zoomLevel === 1 ? "30px" : "50px",
                paddingTop: "2px",
                paddingBottom: "2px",
              }}
            />
          )}
          <ProgramStack
            style={{
              width: "100%",
              display: "flex",
              gap: zoomLevel === 0 ? "4px" : "0px",
              flexDirection: zoomLevel === 0 ? "row" : "column",
              justifyContent: "flex-start",
              alignItems: zoomLevel === 0 ? "center" : "flex-start",
            }}
          >
            {/* {type === "booking" && ( */}
            <>
              <Flex
                gap="10px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <ProgramTitle
                  className={`planby-program-title ${
                    type === "booking" ? "booking" : ""
                  }`}
                  style={{
                    marginBottom:
                      zoomLevel === 0
                        ? "0px"
                        : zoomLevel === 1
                        ? "-1px"
                        : "1px",
                    fontSize:
                      zoomLevel === 0
                        ? "0.6rem"
                        : zoomLevel === 1
                        ? "0.75rem"
                        : "0.85rem",
                  }}
                >
                  {title}
                </ProgramTitle>

                {((pilot && aircrew === pilot) ||
                  (passenger && aircrew === passenger)) && (
                  <Badge
                    variant="solid"
                    colorScheme={aircrew === pilot ? "cyan" : "green"}
                    bg={aircrew === pilot ? "blue.400" : "green.400"}
                    color="rgba(255,255,255,0.8)"
                    height={
                      zoomLevel === 0
                        ? "8px"
                        : zoomLevel === 1
                        ? "12px"
                        : "15px"
                    }
                    width={
                      zoomLevel === 0
                        ? "8px"
                        : zoomLevel === 1
                        ? "28px"
                        : "32px"
                    }
                    borderRadius="25px"
                    fontSize={
                      zoomLevel === 0
                        ? "0px"
                        : zoomLevel === 1
                        ? "0.6rem"
                        : "0.7rem"
                    }
                    display="flex"
                    alignItems="center"
                    fontWeight="500"
                    justifyContent="center"
                    cursor="pointer"
                  >
                    {aircrew === pilot ? "PIC" : "CO"}
                  </Badge>
                )}
              </Flex>

              <Flex gap="10px">
                {!unavailable && (
                  <ProgramText
                    className={`planby-program-text ${
                      type === "booking" ? "booking" : ""
                    }`}
                    style={{
                      whiteSpace: "pre",
                      lineHeight: "1.2",
                      fontSize:
                        zoomLevel === 0
                          ? "8px"
                          : zoomLevel === 1
                          ? "11px"
                          : "13px",
                    }}
                  >
                    {sinceTime} - {tillTime}
                  </ProgramText>
                )}
                {title && styles.width > 150 && (
                  <ProgramText
                    className={`planby-program-text ${
                      type === "booking" ? "booking" : ""
                    }`}
                    style={{
                      fontSize:
                        zoomLevel === 0
                          ? "8px"
                          : zoomLevel === 1
                          ? "11px"
                          : "13px",
                    }}
                  >
                    <Text
                      fontWeight="400"
                      fontStyle="italic"
                      pr="2px"
                      whiteSpace="pre"
                      lineHeight="1.2"
                    >
                      {formattedDuration}
                    </Text>
                  </ProgramText>
                )}
              </Flex>
            </>
            {/* )} */}
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>

      <Portal>
        <Flex // BOOKING HOVER POPUP
          // display={id === showPopup?.id ? "flex" : "none"}
          display={{ base: "none", lg: "flex" }}
          opacity={id === showPopup?.id ? "1" : "0"}
          visibility={id === showPopup?.id ? "visible" : "none"}
          pointerEvents="none"
          transition="all ease 200ms"
          position="absolute"
          width="max-content"
          // minWidth="350px"
          minWidth="297px"
          maxWidth="297px"
          borderRadius="15px"
          height="auto"
          zIndex="10000"
          justifyContent="flex-start"
          flexDirection="column"
          alignItems="flex-start"
          boxShadow="0px 20px 50px -18px rgba(10, 20, 80, 0.6)"
          color="white"
          gap="1px"
          bg="rgba(10, 20, 80, 0.13)"
          // top={
          //   !firstThreeRows
          //     ? "unset"
          //     : zoomLevel === 0
          //     ? "35px"
          //     : zoomLevel === 1
          //     ? "57px"
          //     : "79px"
          // }
          // bottom={
          //   firstThreeRows
          //     ? "unset"
          //     : zoomLevel === 0
          //     ? "38px"
          //     : zoomLevel === 1
          //     ? "60px"
          //     : "82px"
          // }
          bottom="30px"
          // ml="-5px"
          left="10px"
        >
          <Flex
            py="10px"
            px="15px"
            width="100%"
            height="40px"
            alignItems="center"
            justifyContent="flex-start"
            bg="blue.900"
            borderTopRadius="15px"
            textTransform="uppercase"
            gap="8px"
            fontSize="0.85rem"
            fontWeight="600"
            letterSpacing="0.5px"
            color={
              status === "completed"
                ? "cyan.300"
                : status === "expired"
                ? "#FF1414"
                : status === "pending"
                ? "yellow"
                : status === "draft"
                ? "gray.300"
                : "#11EC4E"
            }
          >
            <Flex
              width="10px"
              borderRadius="3px"
              height="18px"
              background={
                status === "completed"
                  ? "cyan.300"
                  : status === "expired"
                  ? "#FF1414"
                  : status === "pending"
                  ? "yellow"
                  : status === "draft"
                  ? "gray.300"
                  : "#11EC4E"
              }
            ></Flex>

            {status}
          </Flex>
          <Flex
            pt="10px"
            pb="13px"
            px="15px"
            width="100%"
            minHeight="120px"
            height="auto"
            alignItems="flex-start"
            justifyContent="flex-start"
            bg="blue.900"
            borderBottomRadius="15px"
          >
            <Flex
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {isLive && isMinWidth && title && (
                <Flex
                  gap="2px"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="column"
                  width={
                    zoomLevel === 0 ? "30px" : zoomLevel === 1 ? "30px" : "50px"
                  }
                  paddingBottom="2px"
                  marginRight="15px"
                >
                  <Image src={Airborne} />
                  <Text fontSize="0.55rem" fontWeight="200">
                    Airborne
                  </Text>
                </Flex>
              )}
              <Flex
                gap="2px"
                width="100%"
                justifyContent="flex-start"
                alignItems="flex-start"
                flexDirection="column"
                zIndex="2"
              >
                <Flex
                  gap="10px"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  borderBottom="1px solid"
                  borderColor="rgba(255,255,255,0.2)"
                  pb="8px"
                  mb="5px"
                >
                  <Text fontSize="0.85rem" fontWeight="600">
                    {title}
                  </Text>

                  {priority && (
                    <Badge
                      variant="solid"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color={priority === "High" ? "white" : "initial"}
                      textTransform="uppercase"
                      minWidth="50px"
                      px="3px"
                      mt="2px"
                      lineHeight="1"
                      background={
                        priority === "High"
                          ? "#ff6a00"
                          : priority === "Medium"
                          ? "yellow"
                          : priority === "Low"
                          ? "#00fb00"
                          : "gray.100"
                      }
                      height={
                        zoomLevel === 0
                          ? "14px"
                          : zoomLevel === 1
                          ? "16px"
                          : "16px"
                      }
                      borderRadius="25px"
                      fontSize="0.6rem"
                      fontWeight="600"
                    >
                      {priority}
                    </Badge>
                  )}
                </Flex>

                <Flex
                  gap="10px"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  width="100%"
                  pl="5px"
                >
                  <Flex gap="10px" alignItems="baseline">
                    <Text fontSize="0.8rem">
                      {sinceTime} - {tillTime}
                    </Text>

                    <Text
                      fontSize="0.75rem"
                      opacity="0.6"
                      fontWeight="400"
                      fontStyle="italic"
                      pr="2px"
                    >
                      {formattedDuration}
                    </Text>
                  </Flex>
                  <Text
                    fontSize="0.75rem"
                    fontWeight="400"
                    textTransform="uppercase"
                    color={
                      status === "pending" && upcoming
                        ? "yellow"
                        : status === "draft" && upcoming
                        ? "gray.300"
                        : upcoming
                        ? "#00fb00"
                        : expired
                        ? "#ff6a00"
                        : "cyan.300"
                    }
                    pr="2px"
                  >
                    {status === "pending" && upcoming
                      ? "Proposed"
                      : status === "draft" && upcoming
                      ? "Tentative"
                      : upcoming
                      ? "Upcoming"
                      : expired
                      ? "Expired"
                      : "Active"}
                  </Text>
                </Flex>

                <Flex gap="10px" fontSize="0.75rem" pl="5px">
                  <Text fontWeight="400" opacity="0.65">
                    Activity:
                  </Text>
                  <Text fontWeight="300" pr="2px">
                    {activity}
                  </Text>
                </Flex>

                <Flex gap="10px" fontSize="0.75rem" pl="5px">
                  <Text fontWeight="400" opacity="0.65">
                    Area:
                  </Text>
                  <Text fontWeight="300" pr="2px">
                    {area}
                  </Text>
                </Flex>

                <Flex
                  gap="10px"
                  fontSize="0.75rem"
                  borderBottom="1px solid"
                  borderColor="rgba(255,255,255,0.2)"
                  pb="7px"
                  mb="5px"
                  pl="5px"
                  width="100%"
                >
                  <Text fontWeight="400" opacity="0.65">
                    Intentions:
                  </Text>
                  <Text fontWeight="300" pr="2px">
                    {intentions}
                  </Text>
                </Flex>

                <Flex
                  gap="10px"
                  fontSize="0.75rem"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="baseline"
                  pl="5px"
                >
                  <Flex gap="10px">
                    <Text fontWeight="400" opacity="0.65">
                      Pilot:
                    </Text>
                    <Text
                      fontWeight="300"
                      pr="2px"
                      opacity={pilotName ? "1" : "0.4"}
                      fontStyle={pilotName ? "normal" : "italic"}
                    >
                      {pilotName ? pilotName : "Not assigned"}
                    </Text>
                  </Flex>
                  <Text
                    fontWeight="300"
                    pr="2px"
                    fontStyle="italic"
                    opacity="0.5"
                    fontSize="0.7rem"
                  >
                    {pilotCallsign ? pilotCallsign : ""}
                  </Text>
                </Flex>

                <Flex
                  gap="10px"
                  fontSize="0.75rem"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="baseline"
                  pl="5px"
                >
                  <Flex gap="10px">
                    <Text fontWeight="400" opacity="0.65">
                      Co-Pilot:
                    </Text>
                    <Text
                      fontWeight="300"
                      pr="2px"
                      opacity={passengerName ? "1" : "0.4"}
                      fontStyle={passengerName ? "normal" : "italic"}
                    >
                      {passengerName ? passengerName : "Not assigned"}
                    </Text>
                  </Flex>
                  <Text
                    fontWeight="300"
                    pr="2px"
                    fontStyle="italic"
                    opacity="0.5"
                    fontSize="0.7rem"
                  >
                    {passengerCallsign ? passengerCallsign : ""}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {/* <Flex
            width="12px"
            height="12px"
            transform="rotate(45deg)"
            position="absolute"
            top={!firstThreeRows ? "unset" : "-5px"}
            bottom={firstThreeRows ? "unset" : "-5px"}
            left="25px"
            bg="blue.900"
            zIndex="1"
          ></Flex> */}
          </Flex>
        </Flex>
      </Portal>
    </ProgramBox>
  );
}
