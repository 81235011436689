import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Flex, Text } from "@chakra-ui/react";
import { riskMatrixModalState } from "../../globalState";
import RiskAssessmentItem from "./RiskAssessmentItem";
import RiskMatrixModal from "../../Modals/RiskMatrixModal";

export default function RiskAssessmentTable(props) {
  const { register, boxColors, riskItems, setRiskItems, setValue, isApprover } =
    props;
  const [showRiskMatrixModal, setShowRiskMatrixModal] =
    useRecoilState(riskMatrixModalState);
  const [riskIndex, setRiskIndex] = useState(null);
  const [riskItem, setRiskItem] = useState(null);

  const handleAddNewLine = () => {
    setRiskItems((prevItems) => [
      ...prevItems,
      {
        riskIdentified: null,
        inherentRisk: null,
        riskMitigation: null,
        controlledRisk: null,
      },
    ]);
  };

  const handleRemoveLine = (index) => {
    setRiskItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleRiskChange = (index, type, value) => {
    setRiskItems((prevItems) => {
      const newItems = prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [type]: value };
        }
        return item;
      });
      setValue("riskdata", newItems);
      return newItems;
    });
  };
  // useEffect(() => {
  //   setCumulativeRisk(cumulativeRisk);
  //   console.log("cumulativeRisk", cumulativeRisk);
  // }, [cumulativeRisk, setCumulativeRisk]);

  return (
    <>
      <Flex width="100%" overflowX="auto">
        <Flex
          flexDirection="column"
          width="100%"
          gap="0px"
          minWidth="765px"
          pr={{ base: "10px", lg: "0px" }}
        >
          <Flex
            flexDirection="row"
            width="100%"
            gap={{ base: "10px", lg: "15px" }}
            pt="5px"
          >
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins'"
              mb="2"
              fontWeight="500"
              textAlign="center"
              width="34px"
              minWidth="34px"
            >
              #
            </Text>

            <Flex flexDirection="row" width="100%">
              <Text
                fontSize="0.8rem"
                fontFamily="'Poppins'"
                mb="2"
                fontWeight="500"
                whiteSpace="pre"
                width="100%"
              >
                Risk Identified
              </Text>

              <Text
                fontSize="0.8rem"
                fontFamily="'Poppins'"
                mb="2"
                fontWeight="500"
                whiteSpace="pre"
                width="100px"
                minWidth="100px"
              >
                Inherent Risk
              </Text>
            </Flex>

            <Flex flexDirection="row" width="100%">
              <Text
                fontSize="0.8rem"
                fontFamily="'Poppins'"
                mb="2"
                fontWeight="500"
                whiteSpace="pre"
                width="100%"
              >
                Risk Mitigation
              </Text>

              <Text
                fontSize="0.8rem"
                fontFamily="'Poppins'"
                mb="2"
                fontWeight="500"
                whiteSpace="pre"
                width="100px"
                minWidth="100px"
              >
                Controlled Risk
              </Text>
            </Flex>
            <Text
              fontSize="0.8rem"
              fontFamily="'Poppins'"
              mb="2"
              fontWeight="500"
              textAlign="center"
              width="34px"
              minWidth="34px"
            ></Text>
          </Flex>
          <Flex flexDirection="column" width="100%" gap="20px" pb="10px">
            {riskItems &&
              riskItems.length > 0 &&
              riskItems.map((item, index) => (
                <RiskAssessmentItem
                  key={index}
                  index={index}
                  setShowRiskMatrixModal={setShowRiskMatrixModal}
                  boxColors={boxColors}
                  riskItem={riskItem}
                  riskItems={riskItems}
                  register={register}
                  setRiskItem={setRiskItem}
                  handleRiskChange={handleRiskChange}
                  setRiskIndex={setRiskIndex}
                  handleRemoveLine={handleRemoveLine}
                  handleAddNewLine={handleAddNewLine}
                  isApprover={isApprover}
                />
              ))}
          </Flex>
        </Flex>
      </Flex>

      {showRiskMatrixModal === "inherent" && (
        <RiskMatrixModal
          boxColors={boxColors}
          riskIndex={riskIndex}
          setRiskIndex={setRiskIndex}
          riskItem={riskItem}
          setRiskItem={setRiskItem}
          setSelectedRisk={(value) =>
            handleRiskChange(riskIndex, "inherentRisk", value)
          }
        />
      )}
      {showRiskMatrixModal === "controlled" && (
        <RiskMatrixModal
          boxColors={boxColors}
          riskIndex={riskIndex}
          setRiskIndex={setRiskIndex}
          riskItem={riskItem}
          setRiskItem={setRiskItem}
          setSelectedRisk={(value) =>
            handleRiskChange(riskIndex, "controlledRisk", value)
          }
        />
      )}
    </>
  );
}
