import React from "react";
import { ButtonGroup, Button, Text, Flex, Icon } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

export default function Tabs(props) {
  const {
    authorisations,
    editingAuth,
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab,
    setTab,
    newAuthorisation,
    user,
  } = props;
  const location = useLocation();

  const tabOne = location.pathname === `/${tab1}` || tab === tab1;
  const tabTwo = location.pathname === `/${tab2}` || tab === tab2;
  const tabThree = location.pathname === `/${tab3}` || tab === tab3;
  const tabFour = location.pathname === `/${tab4}` || tab === tab4;
  const tabFive = location.pathname === `/${tab5}` || tab === tab5;
  const tabSix = location.pathname === `/${tab6}` || tab === tab6;

  const userHq = Boolean(user === "hq");

  return (
    <ButtonGroup
      spacing="5px"
      marginBottom="-1px"
      width={{ base: "100%", smd: "auto" }}
    >
      {tab1 && (
        <Button
          as={Link}
          to={!authorisations && `/${tab1}`}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          width={{
            base: newAuthorisation
              ? "120px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            xs: newAuthorisation
              ? "140px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          minWidth={{
            base: newAuthorisation ? "120px" : "auto",
            xs: newAuthorisation ? "140px" : "auto",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          transform="margin ease 250ms, height ease 250ms"
          height={tabOne ? "35px" : "32px"}
          mt={tabOne ? "1px" : "3px"}
          fontWeight={tabOne ? "600" : "400"}
          px="10px"
          borderRadius="0px"
          borderTopRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg={tabOne ? "white" : "gray.200"}
          _hover={{
            background: tabOne ? "white" : "#d7dce4",
            color: tabOne ? "initial" : "gray.900",
          }}
          boxShadow={
            tabOne
              ? "0px 8px 30px -8px rgba(10, 20, 80, 0.15)"
              : "inset 0px 7px 10px -5px rgba(10, 20, 80, 0.05)"
          }
          clipPath="inset(-50px -50px 0px -50px)"
          onClick={() => {
            if (authorisations) {
              setTab(tab1);
            }
          }}
        >
          <Text
            textTransform="capitalize"
            style={{ textWrap: "wrap" }}
            textAlign="center"
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {tab1}
          </Text>
        </Button>
      )}

      {tab2 && (
        <Flex
          width="20px"
          pt="4px"
          justifyContent="center"
          alignItems="center"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          <Icon as={FaChevronRight} color="gray.300" fontSize="0.9rem" />
        </Flex>
      )}

      {tab2 && (
        <Button
          as={Link}
          to={!authorisations && `/${tab2}`}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          width={{
            base: newAuthorisation
              ? "120px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            xs: newAuthorisation
              ? "140px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          minWidth={{
            base: newAuthorisation ? "120px" : "auto",
            xs: newAuthorisation ? "140px" : "auto",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          transform="margin ease 250ms, height ease 250mss"
          height={tabTwo ? "35px" : "32px"}
          mt={tabTwo ? "1px" : "3px"}
          fontWeight={tabTwo ? "600" : "400"}
          px="10px"
          borderRadius="0px"
          borderTopRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg={tabTwo ? "white" : "gray.200"}
          _hover={{
            background: tabTwo ? "white" : "#d7dce4",
            color: tabTwo ? "initial" : "gray.900",
          }}
          boxShadow={
            tabTwo
              ? "0px 8px 30px -8px rgba(10, 20, 80, 0.15)"
              : "inset 0px 7px 10px -5px rgba(10, 20, 80, 0.05)"
          }
          clipPath="inset(-50px -50px 0px -50px)"
          onClick={() => {
            if (authorisations) {
              setTab(tab2);
            }
          }}
        >
          <Text
            textTransform="capitalize"
            style={{ textWrap: "wrap" }}
            textAlign="center"
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {tab2}
          </Text>
        </Button>
      )}

      {editingAuth && tab3 && (
        <Flex
          width="20px"
          pt="4px"
          justifyContent="center"
          alignItems="center"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          <Icon as={FaChevronRight} color="gray.300" fontSize="0.9rem" />
        </Flex>
      )}

      {tab3 && (
        <Button
          as={Link}
          to={!authorisations && `/${tab3}`}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          width={{
            base: newAuthorisation
              ? "120px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            xs: newAuthorisation
              ? "140px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          minWidth={{
            base: newAuthorisation ? "120px" : "auto",
            xs: newAuthorisation ? "140px" : "auto",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          transform="margin ease 250ms, height ease 250mss"
          height={tabThree ? "35px" : "32px"}
          mt={tabThree ? "1px" : "3px"}
          fontWeight={tabThree ? "600" : "400"}
          px="10px"
          borderRadius="0px"
          borderTopRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg={tabThree ? "white" : "gray.200"}
          _hover={{
            background: tabThree ? "white" : "#d7dce4",
            color: tabThree ? "initial" : "gray.900",
          }}
          boxShadow={
            tabThree
              ? "0px 8px 30px -8px rgba(10, 20, 80, 0.15)"
              : "inset 0px 7px 10px -5px rgba(10, 20, 80, 0.05)"
          }
          clipPath="inset(-50px -50px 0px -50px)"
          onClick={() => {
            if (authorisations) {
              setTab(tab3);
            }
          }}
        >
          <Text
            textTransform="capitalize"
            style={{ textWrap: "wrap" }}
            textAlign="center"
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {tab3}
          </Text>
        </Button>
      )}

      {editingAuth && tab4 && (
        <Flex
          width="20px"
          pt="4px"
          justifyContent="center"
          alignItems="center"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          <Icon as={FaChevronRight} color="gray.300" fontSize="0.9rem" />
        </Flex>
      )}

      {tab4 && (
        <Button
          as={Link}
          to={!authorisations && `/${tab4}`}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          width={{
            base: newAuthorisation
              ? "120px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            xs: newAuthorisation
              ? "140px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          minWidth={{
            base: newAuthorisation ? "120px" : "auto",
            xs: newAuthorisation ? "140px" : "auto",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          transform="margin ease 250ms, height ease 250mss"
          height={tabFour ? "35px" : "32px"}
          mt={tabFour ? "1px" : "3px"}
          fontWeight={tabFour ? "600" : "400"}
          px="10px"
          borderRadius="0px"
          borderTopRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg={
            tabFour
              ? "white"
              : editingAuth || newAuthorisation
              ? "#ccdef0"
              : "gray.200"
          }
          _hover={{
            background: tabFour
              ? "white"
              : editingAuth || newAuthorisation
              ? "#BDD4EB"
              : "#d7dce4",
            color: tabFour ? "initial" : "gray.900",
          }}
          boxShadow={
            tabFour
              ? "0px 8px 30px -8px rgba(10, 20, 80, 0.15)"
              : "inset 0px 7px 10px -5px rgba(10, 20, 80, 0.05)"
          }
          clipPath="inset(-50px -50px 0px -50px)"
          onClick={() => {
            if (authorisations) {
              setTab(tab4);
            }
          }}
        >
          <Text
            textTransform="capitalize"
            style={{ textWrap: "wrap" }}
            textAlign="center"
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {tab4}
          </Text>
        </Button>
      )}

      {tab5 && editingAuth && (
        <Flex
          width="20px"
          pt="4px"
          justifyContent="center"
          alignItems="center"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          <Icon as={FaChevronRight} color="gray.300" fontSize="0.9rem" />
        </Flex>
      )}

      {tab5 && (
        <Button
          as={Link}
          to={!authorisations && `/${tab5}`}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          width={{
            base: newAuthorisation
              ? "120px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            xs: newAuthorisation
              ? "140px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          minWidth={{
            base: newAuthorisation ? "120px" : "auto",
            xs: newAuthorisation ? "140px" : "auto",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          transform="margin ease 250ms, height ease 250mss"
          height={tabFive ? "35px" : "32px"}
          mt={tabFive ? "1px" : "3px"}
          fontWeight={tabFive ? "600" : "400"}
          px="10px"
          borderRadius="0px"
          borderTopRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg={
            tabFive
              ? "white"
              : editingAuth || newAuthorisation
              ? "#ccdef0"
              : "gray.200"
          }
          _hover={{
            background: tabFive
              ? "white"
              : editingAuth || newAuthorisation
              ? "#BDD4EB"
              : "#d7dce4",
            color: tabFive ? "initial" : "gray.900",
          }}
          boxShadow={
            tabFive
              ? "0px 8px 30px -8px rgba(10, 20, 80, 0.15)"
              : "inset 0px 7px 10px -5px rgba(10, 20, 80, 0.05)"
          }
          clipPath="inset(-50px -50px 0px -50px)"
          onClick={() => {
            if (authorisations) {
              setTab(tab5);
            }
          }}
        >
          <Text
            textTransform="capitalize"
            style={{ textWrap: "wrap" }}
            textAlign="center"
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {tab5}
          </Text>
        </Button>
      )}

      {tab6 && editingAuth && (
        <Flex
          width="20px"
          pt="4px"
          justifyContent="center"
          alignItems="center"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          <Icon as={FaChevronRight} color="gray.300" fontSize="0.9rem" />
        </Flex>
      )}

      {tab6 && (
        <Button
          as={Link}
          to={!authorisations && `/${tab6}`}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          width={{
            base: newAuthorisation
              ? "120px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            xs: newAuthorisation
              ? "140px"
              : editingAuth && !userHq
              ? "25%"
              : "20%",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          minWidth={{
            base: newAuthorisation ? "120px" : "auto",
            xs: newAuthorisation ? "140px" : "auto",
            smd: newAuthorisation
              ? "150px"
              : editingAuth && !userHq
              ? "120px"
              : "100px",
            md: "120px",
            xl: "130px",
          }}
          transform="margin ease 250ms, height ease 250mss"
          height={tabSix ? "35px" : "32px"}
          mt={tabSix ? "1px" : "3px"}
          fontWeight={tabSix ? "600" : "400"}
          px="10px"
          borderRadius="0px"
          borderTopRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg={
            tabSix
              ? "white"
              : editingAuth || newAuthorisation
              ? "#ccdef0"
              : "gray.200"
          }
          _hover={{
            background: tabSix
              ? "white"
              : editingAuth || newAuthorisation
              ? "#BDD4EB"
              : "#d7dce4",
            color: tabSix ? "initial" : "gray.900",
          }}
          boxShadow={
            tabSix
              ? "0px 8px 30px -8px rgba(10, 20, 80, 0.15)"
              : "inset 0px 7px 10px -5px rgba(10, 20, 80, 0.05)"
          }
          clipPath="inset(-50px -50px 0px -50px)"
          onClick={() => {
            if (authorisations) {
              setTab(tab6);
            }
          }}
        >
          <Text
            textTransform="capitalize"
            style={{ textWrap: "wrap" }}
            textAlign="center"
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {tab6}
          </Text>
        </Button>
      )}
    </ButtonGroup>
  );
}
