import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Flex, Text } from "@chakra-ui/react";
import { addDays, formatDistanceToNow } from "date-fns";
import { aircrewDataState, airframeModalState } from "../globalState";

export default function PersonnelCurrency({ squadronSelection }) {
  const calculateDate = (daysToAdd) => {
    const today = new Date();
    return addDays(today, daysToAdd);
  };
  const aircrewData = useRecoilValue(aircrewDataState);
  const setShowAirframeModal = useSetRecoilState(airframeModalState);

  const CurrencyBar = ({
    name,
    callsign,
    concern,
    squadron,
    concerndate,
    color,
  }) => {
    return (
      <Flex
        width="100%"
        flexDirection="row"
        px="4px"
        py="3px"
        borderRadius="16px"
        border="1px solid"
        borderColor="#e5e5e5"
        minHeight="32px"
        justifyContent="center"
        alignItems="center"
        gap="5px"
        bg="#F7FAFC"
        cursor="pointer"
        transition="all ease 150ms"
        _hover={{
          bg: "#FDFEFF",
          boxShadow: "0px 3px 15px -4px rgba(0, 45, 78, 0.2)",
        }}
        onClick={() => setShowAirframeModal([callsign, "aircrew"])}
      >
        <Flex
          width={{ base: "24px", lg: "24px" }}
          minWidth={{ base: "24px", lg: "24px" }}
          minHeight={{ base: "24px", lg: "24px" }}
          borderRadius="50px"
          justifyContent="center"
          alignItems="center"
          bg={concerndate < 0 ? "#F7FAFC" : color}
          border={concerndate < 0 ? "2px solid #FF1414" : "none"}
          mr="10px"
        >
          <Flex
            width={{ base: "16px", lg: "16px" }}
            minWidth={{ base: "16px", lg: "16px" }}
            height={{ base: "16px", lg: "16px" }}
            borderRadius="50px"
            bg={color}
            justifyContent="center"
            alignItems="center"
          >
            <Text color="white" fontSize="0.65rem" fontWeight="600">
              {squadron}
            </Text>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          gap="5px"
          minHeight={{ base: "16px", lg: "18px" }}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          // pt="2px"
        >
          <Text
            minWidth={{ base: "30%", lg: "27%" }}
            textAlign="left"
            color="gray.600"
            fontWeight="600"
          >
            {name}
          </Text>
          <Text
            fontWeight={concerndate < 0 ? "600" : "500"}
            color={concerndate < 0 ? "#FF1414" : "#FDAC30"}
            width="100%"
          >
            {concern}
          </Text>
          {concerndate !== null && concerndate !== undefined && (
            <Text
              fontSize={{ base: "0.65rem", lg: "0.7rem" }}
              fontWeight={concerndate < 0 ? "500" : "400"}
              color="gray.400"
              width="100%"
              textAlign="right"
              pr="5px"
              pt="1px"
            >
              {concerndate === 0
                ? "Today"
                : formatDistanceToNow(calculateDate(concerndate), {
                    addSuffix: true,
                  })}
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      borderRadius="10px"
      border="1px solid"
      borderColor="#e5e5e5"
      bg="white"
      boxShadow="0px 10px 40px -15px rgba(0, 45, 78, 0.15)"
    >
      <Flex
        minHeight="44px"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        width="100%"
        color="light.100"
        borderBottom="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        fontWeight="700"
        textTransform="uppercase"
      >
        Personnel Currency Renewal and Fatigue Notifications
      </Flex>
      <Flex
        gap="10px"
        width="100%"
        flexDirection="column"
        pt="20px"
        pb="25px"
        px="15px"
      >
        {aircrewData
          .filter(
            (feat) =>
              feat.squadron === (squadronSelection === "40 SQN/CFS" ? 40 : 14)
          )
          .filter((feat) => feat.name !== "Name")
          .filter((feat) => feat.concern)
          .sort((a, b) => {
            if (a.concerndate < b.concerndate) {
              return -1;
            }
            if (a.concerndate > b.concerndate) {
              return 1;
            }
            return 0;
          })
          .map((item, index) => (
            <CurrencyBar key={index} {...item} />
          ))}
      </Flex>
    </Flex>
  );
}
