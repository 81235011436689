import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { parseISO, format, addMinutes } from "date-fns";
import Flatpickr from "react-flatpickr";
import { formatToTimeZone } from "date-fns-timezone";
import { Select } from "chakra-react-select";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Flex,
  Text,
  Textarea,
  Portal,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useBreakpointValue,
  Button,
  VStack,
  Icon,
} from "@chakra-ui/react";
import RiskAssessmentTable from "./RiskAssessmentTable";
import { CgClose } from "react-icons/cg";

export default function RiskAssessmentTab(props) {
  const {
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
    editAuthorisation,
    timeZone,
    tab,
    riskItems,
    setRiskItems,
    selectStyle,
    authorisationData,
    squadronSelection,
    isApprover,
  } = props;

  const cumulativeRiskMobile = useRef();
  const cumulativeRiskDesktop = useRef();

  const cumulativeRiskContainer = useBreakpointValue({
    base: { containerRef: cumulativeRiskMobile },
    xl: { containerRef: cumulativeRiskDesktop },
  });

  const noFocus = {
    _focus: { boxShadow: "none !important" },
  };
  // flatpickr refs
  const fpDateCompiled = useRef(null);
  const fpDateApproved = useRef(null);
  const now = Date.now();

  const riskStatus = watch("riskstatus");
  // Periodic update of dateapproved Date/Time
  const timeWatch = watch(["risksapproveddate"]);
  const timeWatchCompiled = watch(["riskscompileddate"]);
  const timeReset = parseISO(timeWatch[0]) < addMinutes(now, 5);
  const timeResetCompiled = parseISO(timeWatchCompiled[0]) < addMinutes(now, 5);
  useEffect(() => {
    if (!editAuthorisation && timeReset) {
      setValue(
        "risksapproveddate",
        formatToTimeZone(addMinutes(now, 5), "YYYY-MM-DD HH:mmZ", {
          timeZone: timeZone,
        })
      );
    }

    if (!editAuthorisation && timeResetCompiled) {
      setValue(
        "riskscompileddate",
        formatToTimeZone(addMinutes(now, 5), "YYYY-MM-DD HH:mmZ", {
          timeZone: timeZone,
        })
      );
    }
  }, [
    now,
    editAuthorisation,
    timeReset,
    timeResetCompiled,
    setValue,
    getValues,
    timeWatch,
    timeZone,
  ]);

  useEffect(() => {
    const riskArray = Array.from({ length: 3 });
    if (authorisationData?.riskdata) {
      setRiskItems(authorisationData.riskdata);
    } else if (riskItems && riskItems.length === 0) {
      setRiskItems(
        riskArray.map(() => ({
          riskIdentified: null,
          inherentRisk: null,
          riskMitigation: null,
          controlledRisk: null,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tab,
    setRiskItems,
    authorisationData,
    // riskItems,
  ]);

  // console.log("riskItems", riskItems);

  const boxColors = {
    A1: { color: "#ff0000", label: "extreme" },
    A2: { color: "#ff5f3a", label: "very high" },
    A3: { color: "#ff5f3a", label: "very high" },
    A4: { color: "#FFB13B", label: "high" },
    A5: { color: "#FFF850", label: "medium" },
    A6: { color: "#FFF850", label: "medium" },
    B1: { color: "#ff5f3a", label: "very high" },
    B2: { color: "#ff5f3a", label: "very high" },
    B3: { color: "#FFB13B", label: "high" },
    B4: { color: "#FFF850", label: "medium" },
    B5: { color: "#FFF850", label: "medium" },
    B6: { color: "#FFF850", label: "medium" },
    C1: { color: "#ff5f3a", label: "very high" },
    C2: { color: "#FFB13B", label: "high" },
    C3: { color: "#FFF850", label: "medium" },
    C4: { color: "#FFF850", label: "medium" },
    C5: { color: "#FFF850", label: "medium" },
    C6: { color: "#B6FF6D", label: "low" },
    D1: { color: "#FFB13B", label: "high" },
    D2: { color: "#FFF850", label: "medium" },
    D3: { color: "#FFF850", label: "medium" },
    D4: { color: "#FFF850", label: "medium" },
    D5: { color: "#B6FF6D", label: "low" },
    D6: { color: "#2cff67", label: "very low" },
    E1: { color: "#FFF850", label: "medium" },
    E2: { color: "#FFF850", label: "medium" },
    E3: { color: "#FFF850", label: "medium" },
    E4: { color: "#B6FF6D", label: "low" },
    E5: { color: "#2cff67", label: "very low" },
    E6: { color: "#2cff67", label: "very low" },
    F1: { color: "#FFF850", label: "medium" },
    F2: { color: "#FFF850", label: "medium" },
    F3: { color: "#B6FF6D", label: "low" },
    F4: { color: "#2cff67", label: "very low" },
    F5: { color: "#2cff67", label: "very low" },
    F6: { color: "#00e942", label: "no risk" },
  };

  const statusOptions = [
    { value: "requires-assessment-risk", label: "Requires Assesssment" },
    { value: "requesting-approval", label: "Requesting Approval" },
    { value: "pending-mitigation", label: "Pending Mitigation" },
    { value: "accepted-risk", label: "Accepted Risk" },
  ];

  const findCumulativeRiskColor = (riskItems) => {
    const riskLevelsOrder = [
      "extreme",
      "very high",
      "high",
      "medium",
      "low",
      "very low",
      "no risk",
    ];
    const cumulativeRiskValue =
      riskItems &&
      riskItems.length &&
      riskLevelsOrder.find((level) =>
        riskItems.some(
          (item) => boxColors[item.controlledRisk]?.label === level
        )
      );

    const cumulativeRiskColor = Object.values(boxColors).find(
      (color) => color.label === cumulativeRiskValue
    ) || { color: "#e2e8f0", label: "not assessed" };

    return cumulativeRiskColor;
  };

  const popoverPlacement = useBreakpointValue({
    base: "top",
    lg: "left",
  });

  const cumulativeRisk = findCumulativeRiskColor(riskItems);

  // console.log("cumulativeRisk", cumulativeRisk);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        flexDirection={{ base: "column", xl: "row" }}
        width="100%"
        height="100%"
        bg="#F1F6FA"
      >
        <Box ref={cumulativeRiskMobile}></Box>
        <Flex
          bg="#FBFDFF"
          padding="20px"
          paddingTop={{ base: "15px", lg: "15px" }}
          paddingBottom={{ base: "30px", xl: "80px" }}
          flexDirection="column"
          width={{ base: "100%", xl: "calc(100% - 340px)" }}
          height="100%"
          borderRight={{ base: "0px solid", xl: "1px solid #e5e7ea" }}
          borderBottom={{ base: "1px solid #e5e7ea", xl: "0px solid" }}
          borderColor="#e5e7ea"
        >
          <Flex width="100%" gap="50px">
            <Flex width="100%" gap="20px" flexDirection="column">
              <Text
                fontSize="0.8rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                Risk Assessment
              </Text>

              <Flex
                flexDirection={{ base: "column", lg: "row" }}
                width={{ base: "100%", lg: "65%" }}
                gap={{ base: "30px", lg: "20px" }}
              >
                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="riskstatus"
                    fontFamily="'Poppins'"
                    overflow="hidden"
                    height="20px"
                    maxHeight="20px"
                    opacity={riskStatus ? "1" : "1"}
                  >
                    Status
                  </FormLabel>

                  <Select
                    id="riskstatus"
                    {...noFocus}
                    chakraStyles={selectStyle}
                    variant="outline"
                    size="sm"
                    cursor="pointer"
                    {...register("riskstatus")}
                    value={statusOptions.filter((option) => {
                      return option.label === getValues("riskstatus");
                    })}
                    onChange={(value) => setValue("riskstatus", value.label)}
                    options={statusOptions}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="riskscompileddate"
                    fontFamily="'Poppins'"
                  >
                    Date Compiled
                  </FormLabel>
                  <Controller
                    control={control}
                    name="riskscompileddate"
                    rules={{
                      validate: {
                        notInvalid: (value) =>
                          (value !== null && value !== "Invalid Date") ||
                          "Please select date/time",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Flatpickr
                        id="riskscompileddate"
                        ref={fpDateCompiled}
                        placeholder="Select date/time..."
                        style={{
                          width: "100%",
                          fontFamily: "'Open Sans', sans-serif",
                          height: "32px",
                          borderRadius: "7px",
                          fontSize: "0.875rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid",
                          background: isApprover ? "#F5F8FB" : "white",
                          pointerEvents: isApprover ? "none" : "auto",
                          borderColor: errors.riskscompileddate
                            ? "#E53E3E"
                            : "#ced4da",
                        }}
                        className="flatpickr form-control form-control-sm"
                        data-enable-time
                        value={formatToTimeZone(value, "D MMM YY HH:mm", {
                          timeZone: timeZone,
                        })}
                        onChange={(value) => {
                          onChange(
                            value.length
                              ? format(value[0], "yyyy-MM-dd HH:mm")
                              : null
                          );
                        }}
                        options={{
                          allowClearing: false,
                          // disableMobile: true,
                          dateFormat: "j M y H:i",
                          time_24hr: true,
                          minuteIncrement: 1,
                          // minDate: formatToTimeZone(now, "D MMM YY HH:mm", {
                          //   timeZone: timeZone,
                          // }),
                          defaultDate: formatToTimeZone(
                            addMinutes(now, 5),
                            "D MMM YY HH:mm",
                            { timeZone: timeZone }
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Flex>

              <RiskAssessmentTable
                register={register}
                boxColors={boxColors}
                riskItems={riskItems}
                setRiskItems={setRiskItems}
                setValue={setValue}
                isApprover={isApprover}
              />

              <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="risklimitations"
                  fontFamily="'Poppins'"
                  overflow="hidden"
                  height="20px"
                  maxHeight="20px"
                >
                  Risk Assessment Limitations
                </FormLabel>

                <Textarea
                  variant="outline"
                  background={isApprover ? "#F5F8FB" : "white"}
                  pointerEvents={isApprover ? "none" : "auto"}
                  size="sm"
                  minHeight={{ base: "120px", lg: "80px" }}
                  borderRadius="10px"
                  cursor="pointer"
                  id="risklimitations"
                  {...register("risklimitations", {})}
                />

                {/* <FormErrorMessage>
                  {errors.risklimitations && errors.risklimitations.message}
                </FormErrorMessage> */}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="riskref"
                  fontFamily="'Poppins'"
                >
                  References
                </FormLabel>
                <Textarea
                  variant="outline"
                  background={isApprover ? "#F5F8FB" : "white"}
                  pointerEvents={isApprover ? "none" : "auto"}
                  size="sm"
                  minHeight={{ base: "120px", lg: "120px" }}
                  borderRadius="10px"
                  cursor="pointer"
                  id="riskref"
                  {...register("riskref", {})}
                />
              </FormControl>

              {/* <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="approvedcomments"
                  fontFamily="'Poppins'"
                >
                  Comments
                </FormLabel>
                <Textarea
                  variant="outline"
                  background="white"
                  size="sm"
                  minHeight={{ base: "160px", lg: "120px" }}
                  borderRadius="10px"
                  cursor="pointer"
                  id="approvedcomments"
                  // {...register("approvedcomments", {
                  //   required: "This is required",
                  // })}
                />
              </FormControl> */}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection={{ base: "column", xl: "column" }}
          width={{ base: "100%", xl: "340px" }}
          maxWidth={{ base: "100%", xl: "340px" }}
          height="100%"
          minHeight="100%"
          justifyContent="stretch"
          bg="#F1F6FA"
        >
          <Portal {...cumulativeRiskContainer}>
            <Flex
              width="100%"
              height="60px"
              p={{ base: "20px", xl: "15px" }}
              paddingTop="10px"
              paddingBottom="10px"
              borderBottom="1px solid #e5e7ea"
              gap="10px"
              bg="#F8FAFD"
              justifyContent={{ base: "center", xl: "space-between" }}
              alignItems="center"
            >
              <Text
                fontSize="0.8rem"
                fontWeight="500"
                fontFamily="'Poppins'"
                height="20px"
                maxHeight="20px"
              >
                Assessed Cumulative Risk:
              </Text>
              <Flex
                bg={
                  cumulativeRisk && cumulativeRisk?.color
                    ? cumulativeRisk.color
                    : "gray.100"
                }
                width="110px"
                height="26px"
                borderRadius="10px"
                gap="20px"
                color={
                  cumulativeRisk?.color === "#e2e8f0" ||
                  cumulativeRisk?.color === "#FFF850" ||
                  cumulativeRisk?.color === "#B6FF6D"
                    ? "gray.700"
                    : "white"
                }
                justifyContent="center"
                fontWeight={cumulativeRisk?.color === "#e2e8f0" ? "400" : "600"}
                fontSize="0.85rem"
                alignItems="center"
                lineHeight="1.2"
                textTransform="capitalize"
              >
                {cumulativeRisk && cumulativeRisk?.label
                  ? cumulativeRisk.label
                  : ""}
              </Flex>
            </Flex>
          </Portal>

          <Box className="test" ref={cumulativeRiskDesktop}></Box>

          <Flex
            gap="25px"
            alignItems="center"
            justifyContent="center"
            py={{ base: "30px", lg: "25px" }}
            px="40px"
            bg={{ base: "#F8FAFD", lg: "#FFFFFF" }}
            borderBottom="1px solid #e5e7ea"
          >
            <Popover placement={popoverPlacement} closeOnBlur={true}>
              <PopoverTrigger>
                <Flex
                  {...noFocus}
                  borderRadius="25px"
                  variant="outline"
                  size="sm"
                  bg={{ base: "#FFFFFF", lg: "gray.50" }}
                  cursor="pointer"
                  border="1px solid"
                  borderColor="#e2e8f0"
                  color="gray.600"
                  width={{ base: "160px", lg: "100%" }}
                  height="28px"
                  fontWeight="600"
                  fontSize="0.75rem"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  _hover={{
                    background: "gray.100",
                    color: "gray.600",
                  }}
                >
                  MRP
                  <Text
                    ml="10px"
                    fontSize="0.7rem"
                    fontWeight="300"
                    color="gray.400"
                  >
                    (1)
                  </Text>
                </Flex>
              </PopoverTrigger>
              <PopoverContent
                color="white"
                bg="blue.800"
                borderColor="blue.800"
                px="5px"
                borderRadius="15px"
              >
                <PopoverHeader
                  pt={4}
                  fontWeight="bold"
                  fontSize="0.9rem"
                  border="0"
                >
                  Mission Risk Profile
                </PopoverHeader>
                <PopoverArrow bg="blue.800" />
                <PopoverCloseButton cursor="pointer" zIndex="10" />
                <PopoverBody fontSize="0.75rem">
                  <Text pb="15px">Upload or view the Mission Risk Profile</Text>
                  <VStack>
                    <Button
                      width="100%"
                      colorScheme="gray"
                      size="xs"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text ml="5px">
                        {squadronSelection === "40 SQN/CFS"
                          ? "B757 MRP 2024"
                          : "T-6c MRP 2024"}
                      </Text>
                      <Icon as={CgClose} width="16px" height="auto" />
                    </Button>
                  </VStack>
                </PopoverBody>
                <PopoverFooter
                  border="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  pb={5}
                  gap="20px"
                >
                  <Text fontSize="0.8rem" fontWeight="300" color="gray.400">
                    (1)
                  </Text>
                  <Button
                    width="50%"
                    colorScheme="cyan"
                    size="xs"
                    color="white"
                    borderRadius="25px"
                  >
                    Upload MRP
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>

            <Popover placement={popoverPlacement} closeOnBlur={true}>
              <PopoverTrigger>
                <Flex
                  {...noFocus}
                  borderRadius="25px"
                  variant="outline"
                  size="sm"
                  bg={{ base: "#FFFFFF", lg: "gray.50" }}
                  cursor="pointer"
                  border="1px solid"
                  borderColor="#e2e8f0"
                  color="gray.600"
                  width={{ base: "160px", lg: "100%" }}
                  height="28px"
                  fontWeight="600"
                  fontSize="0.75rem"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight="1.4"
                  _hover={{
                    background: "gray.100",
                    color: "gray.600",
                  }}
                >
                  RMP
                  <Text
                    ml="10px"
                    fontSize="0.7rem"
                    fontWeight="300"
                    color="gray.400"
                  >
                    (2)
                  </Text>
                </Flex>
              </PopoverTrigger>
              <PopoverContent
                color="white"
                bg="blue.800"
                borderColor="blue.800"
                px="5px"
                borderRadius="15px"
              >
                <PopoverHeader
                  pt={4}
                  fontWeight="bold"
                  fontSize="0.9rem"
                  border="0"
                >
                  Risk Management Plan
                </PopoverHeader>
                <PopoverArrow bg="blue.800" />
                <PopoverCloseButton cursor="pointer" />
                <PopoverBody fontSize="0.75rem">
                  <Text pb="15px">Upload or view a Risk Management Plan</Text>
                  <VStack>
                    <Button
                      width="100%"
                      colorScheme="gray"
                      size="xs"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text ml="5px">
                        {squadronSelection === "40 SQN/CFS"
                          ? "Operation Antarctica 2023-2024"
                          : "Exercise Wise Owl"}
                      </Text>
                      <Icon as={CgClose} width="16px" height="auto" />
                    </Button>

                    <Button
                      width="100%"
                      colorScheme="gray"
                      size="xs"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text ml="5px">
                        {squadronSelection === "40 SQN/CFS"
                          ? "Formation Thunder"
                          : "Solo Display Season"}
                      </Text>
                      <Icon as={CgClose} width="16px" height="auto" />
                    </Button>
                  </VStack>
                </PopoverBody>
                <PopoverFooter
                  border="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  pb={5}
                  gap="20px"
                >
                  <Text fontSize="0.8rem" fontWeight="300" color="gray.400">
                    (2)
                  </Text>
                  <Button
                    width="50%"
                    colorScheme="cyan"
                    size="xs"
                    color="white"
                    borderRadius="25px"
                  >
                    Upload RMP
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </Flex>

          <Flex
            flexDirection={{ base: "column", xl: "column" }}
            width="100%"
            height="100%"
            minHeight="100%"
            p={{ base: "20px", xl: "20px" }}
            paddingTop={{ base: "20px", xl: "15px" }}
            paddingBottom={{ base: "40px", xl: "40px" }}
            gap="20px"
          >
            <Flex
              flexDirection={{ base: "column", sm: "row", xl: "column" }}
              width="100%"
              gap="20px"
            >
              <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="authorisedby"
                  fontFamily="'Poppins'"
                  height="20px"
                  maxHeight="20px"
                >
                  Authorising Officer
                </FormLabel>

                <Input
                  transition="all ease 250ms"
                  fontSize="0.9rem"
                  fontWeight="400"
                  width={{ base: "100%", lg: "100%" }}
                  variant="outline"
                  background="white"
                  size="sm"
                  borderRadius="10px"
                  cursor="pointer"
                  id="authorisedby"
                  {...register("authorisedby", {
                    required: "This is required",
                  })}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize="0.8rem"
                  htmlFor="risksapproveddate"
                  fontFamily="'Poppins'"
                >
                  Date Reviewed
                </FormLabel>
                <Controller
                  control={control}
                  name="risksapproveddate"
                  rules={{
                    validate: {
                      notInvalid: (value) =>
                        (value !== null && value !== "Invalid Date") ||
                        "Please select date/time",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Flatpickr
                      id="risksapproveddate"
                      ref={fpDateApproved}
                      placeholder="Select date/time..."
                      style={{
                        width: "100%",
                        fontFamily: "'Open Sans', sans-serif",
                        height: "32px",
                        borderRadius: "7px",
                        fontSize: "0.875rem",
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        border: "1px solid",
                        borderColor: errors.risksapproveddate
                          ? "#E53E3E"
                          : "#ced4da",
                      }}
                      className="flatpickr form-control form-control-sm"
                      data-enable-time
                      value={formatToTimeZone(value, "D MMM YY HH:mm", {
                        timeZone: timeZone,
                      })}
                      onChange={(value) => {
                        onChange(
                          value.length
                            ? format(value[0], "yyyy-MM-dd HH:mm")
                            : null
                        );
                      }}
                      options={{
                        allowClearing: false,
                        // disableMobile: true,
                        dateFormat: "j M y H:i",
                        time_24hr: true,
                        minuteIncrement: 1,
                        // minDate: formatToTimeZone(now, "D MMM YY HH:mm", {
                        //   timeZone: timeZone,
                        // }),
                        defaultDate: formatToTimeZone(
                          addMinutes(now, 5),
                          "D MMM YY HH:mm",
                          { timeZone: timeZone }
                        ),
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.risksapproveddate && errors.risksapproveddate.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel
                fontSize="0.8rem"
                htmlFor="riskapprovedcomment"
                fontFamily="'Poppins'"
              >
                Comments
              </FormLabel>
              <Textarea
                variant="outline"
                background="white"
                size="sm"
                minHeight={{ base: "120px", lg: "80px" }}
                borderRadius="10px"
                cursor="pointer"
                id="riskapprovedcomment"
                {...register("riskapprovedcomment", {})}
              />
              {/* <FormErrorMessage>
                {errors.riskapprovedcomment && errors.riskapprovedcomment.message}
              </FormErrorMessage> */}
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
