import React from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { ChannelBox } from "@nessprim/planby";
import { Text, Flex, Stack, Icon, Image, Tooltip } from "@chakra-ui/react";
import { ReactComponent as Texan } from "../../Assets/texan.svg";
import { ReactComponent as C130Icon } from "../../Assets/c130.svg";
import { ReactComponent as B757Icon } from "../../Assets/b757.svg";
import { ReactComponent as SimIcon } from "../../Assets/simulator.svg";
import Pilot from "../../Assets/avatar-pilot.png";
// import Avatar, { genConfig } from "react-nice-avatar";
import { airframeModalState, squadronSelectionState } from "../../globalState";

// const config = genConfig({ sex: "man" });

export default function Airframe({ airframe, data, zoomLevel }) {
  const {
    position,
    type,
    available,
    tempUnavailable,
    squadron,
    lastAirframe,
    index,
  } = airframe;

  const setShowAirframeModal = useSetRecoilState(airframeModalState);
  const squadronSelection = useRecoilValue(squadronSelectionState);

  const unavailable = !available && !tempUnavailable;

  const fillColor =
    data && data?.status?.airworthy === false
      ? "#FF0000"
      : data?.status?.airworthy === "caution"
      ? "#FFF500"
      : "gray.400";

  return (
    <ChannelBox
      {...position}
      className={
        (type === "aircrew" ? "aircrew" : "airframe") +
        (lastAirframe ? " lastAirframe" : "")
      }
      style={{ zIndex: `calc(50 - ${index})` }}
    >
      {lastAirframe && ( // this adds a white block in between airframes and aircrew to cover timeline
        <Flex
          bg="white"
          width={{ base: "100px", smd: "100px" }}
          height="45px"
          mb="-45px"
          position="absolute"
          zIndex="0"
          alignItems={"center"}
          justifyContent="center"
          flexDirection="column"
          gap="0px"
          cursor="pointer"
        ></Flex>
      )}
      <Tooltip
        hasArrow
        placement="right"
        label={
          data?.status?.airworthy === "caution"
            ? "Available with defects"
            : tempUnavailable
            ? "Temporarily unavailable"
            : "Unavailable"
        }
        bg="gray.600"
        color="white"
        borderRadius="8px"
        fontSize="0.75rem"
        display={{
          base: "none",
          md:
            (data &&
              (!data?.status?.airworthy ||
                data?.status?.airworthy === "caution")) ||
            (type === "aircrew" && (!available || tempUnavailable))
              ? "flex"
              : "none",
        }}
        justifyContent="center"
        alignItems="center"
        px="10px"
        minHeight="26px"
      >
        <Stack
          zIndex="1"
          minHeight={
            zoomLevel === 0 ? "24px" : zoomLevel === 1 ? "42px" : "64px"
          }
          maxHeight={
            zoomLevel === 0 ? "24px" : zoomLevel === 1 ? "42px" : "64px"
          }
          minWidth={"80px"}
          borderRadius="9px"
          border="1px solid #eaeaea"
          boxShadow="0px 3px 6px -2px rgba(5, 30, 50, 0.1)"
          direction={zoomLevel === 0 ? "row" : "column"}
          gap={zoomLevel === 0 ? "5px" : "5px"}
          justifyContent={zoomLevel === 0 ? "flex-start" : "center"}
          pl={zoomLevel === 0 ? "4px" : "0px"}
          alignItems="center"
          bg="#FBFDFF"
          cursor="pointer"
          transition="all ease 200ms"
          _hover={{
            boxShadow: "0px 5px 15px -5px rgba(5, 30, 50, 0.4)",
            // transform: "translateY(-1px)",
          }}
          onClick={() => setShowAirframeModal([airframe.serial, type])}
        >
          {(data &&
            (!data?.status?.airworthy ||
              data?.status?.airworthy === "caution")) ||
          (type === "aircrew" && !available) ? (
            <Flex
              width={zoomLevel === 0 ? "10px" : "12px"}
              minWidth={zoomLevel === 0 ? "10px" : "12px"}
              height={zoomLevel === 0 ? "10px" : "12px"}
              borderRadius="20px"
              bg={
                data?.status?.airworthy === "caution"
                  ? "#faf001"
                  : tempUnavailable
                  ? "#fff0f0"
                  : "#ff0000"
              }
              border="3px solid"
              borderColor={
                data?.status?.airworthy === "caution" ? "#faf001" : "#ff0000"
              }
              position="absolute"
              right={zoomLevel === 0 ? "7px" : "7px"}
              top={zoomLevel === 0 ? "1px" : "2px"}
            ></Flex>
          ) : null}
          {type === "airframe" && (
            <>
              {zoomLevel !== 0 && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.9rem"
                  fontWeight="600"
                  color="gray.200"
                  opacity="0.8"
                  position="absolute"
                  fontStyle="italic"
                  left={
                    airframe?.data?.squadron === "40 SQN/CFS" ? "15px" : "13px"
                  }
                  top="5px"
                  height="16px"
                  width="16px"
                >
                  {airframe?.data?.squadron === "40 SQN/CFS" ? "40" : "14"}
                </Flex>
              )}
              <Icon
                as={
                  airframe?.data?.callsign === "SIMULATOR"
                    ? SimIcon
                    : squadronSelection === "40 SQN/CFS" &&
                      airframe?.data?.model === "Boeing 757"
                    ? B757Icon
                    : squadronSelection === "40 SQN/CFS"
                    ? C130Icon
                    : Texan
                }
                opacity={data && !data?.status?.airworthy ? "0.25" : "1"}
                mt={zoomLevel !== 2 ? "1px" : "1px"}
                mb={zoomLevel === 0 ? "0px" : zoomLevel === 1 ? "-8px" : "-6px"}
                height={
                  zoomLevel === 0 ? "18px" : zoomLevel === 1 ? "24px" : "36px"
                }
                transform={
                  airframe?.data?.callsign !== "SIMULATOR" &&
                  (airframe?.data?.model === "Boeing 757"
                    ? "scale(1.1) rotate(45deg)"
                    : "scale(1) rotate(45deg)")
                }
                width="auto"
                color={fillColor}
              />
            </>
          )}

          {type === "aircrew" && (
            <>
              {zoomLevel !== 0 && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  // borderRadius="25px"
                  // border="1px solid"
                  // borderColor="gray.200"
                  // bg="white"
                  fontSize="0.9rem"
                  fontWeight="600"
                  color="gray.200"
                  opacity="0.8"
                  position="absolute"
                  fontStyle="italic"
                  left={squadron === 40 ? "15px" : "13px"}
                  top="5px"
                  height="16px"
                  width="16px"
                >
                  {squadron}
                </Flex>
              )}
              <Image
                src={Pilot}
                opacity={unavailable ? "0.3" : "1"}
                mt={zoomLevel !== 2 ? "1px" : "1px"}
                mb={zoomLevel === 0 ? "0px" : zoomLevel === 1 ? "-8px" : "-6px"}
                height={
                  zoomLevel === 0 ? "16px" : zoomLevel === 1 ? "22px" : "32px"
                }
                zIndex="2"
                width="auto"
              />
            </>
          )}
          <Text
            opacity={
              (data && !data?.status?.airworthy) ||
              (type === "aircrew" && unavailable)
                ? "0.5"
                : "1"
            }
            color="gray.500"
            fontSize={
              zoomLevel === 0
                ? "0.7rem"
                : zoomLevel === 1
                ? "0.72rem"
                : "0.8rem"
            }
            letterSpacing="0.5px"
            fontFamily="'Inter'"
            whiteSpace="pre"
          >
            {airframe.serial}
          </Text>
        </Stack>
      </Tooltip>
    </ChannelBox>
  );
}
