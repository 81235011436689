import React from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import FlightAuthForm from "../../Forms/FlightAuthForm";
import FlightAuthSelected from "../../Forms/FlightAuthSelected";

export default function PreFlightTab(props) {
  const {
    airframeSerials,
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
    authorisationData,
    newAuthorisation,
    editAuthorisation,
    editSince,
    timeZone,
    selectStyle,
    setSquadronSelection,
    isApprover,
  } = props;

  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        width="100%"
        height="100%"
      >
        <Flex
          p={{ base: "20px", lg: "20px" }}
          paddingBottom={{ base: "40px", lg: "40px" }}
          flexDirection="column"
          width="100%"
          height="100%"
          borderRight={{ base: "0px solid", lg: "1px solid #e5e7ea" }}
          borderColor="#e5e7ea"
        >
          <Grid
            templateColumns={{
              base: "1fr",
              lg: "repeat(2, 1fr)",
              xl: "repeat(3, 1fr)",
            }}
            templateAreas={{
              base: `"title"
                  "costcentre"`,
              lg: `"title costcentre"`,
            }}
            width="100%"
            columns={{ base: 1, lg: 2, xl: 3 }}
            gap={{ base: "30px", lg: "25px" }}
            pt="5px"
            pb="30px"
            alignItems="baseline"
            fontSize="0.8rem"
            transition="all ease 250ms"
          >
            <GridItem
              area={"title"}
              width="100%"
              colSpan={{ base: 1, xl: 2 }}
              maxWidth={{
                base: "100%",
                lg: "100%",
              }}
            >
              <FormControl isInvalid={errors.title}>
                <Flex
                  alignItems="baseline"
                  justifyContent="flex-start"
                  flexDirection={{ base: "column", lg: "row" }}
                  width="100%"
                >
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="title"
                    fontFamily="'Poppins'"
                    width="30px"
                  >
                    Title
                  </FormLabel>
                  <Input
                    fontSize="0.9rem"
                    fontWeight="600"
                    width="100%"
                    variant="outline"
                    background={isApprover ? "brand.400" : "white"}
                    pointerEvents={isApprover ? "none" : "auto"}
                    size="sm"
                    borderRadius="10px"
                    cursor="pointer"
                    id="title"
                    {...register("title", {
                      required: "This is required",
                    })}
                  />
                </Flex>
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem
              area={"costcentre"}
              width="100%"
              colSpan={1}
              maxWidth={{
                base: "100%",
                lg: "100%",
              }}
            >
              <FormControl>
                <Flex
                  alignItems="baseline"
                  justifyContent="flex-end"
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  <FormLabel
                    fontSize="0.8rem"
                    htmlFor="costcentre"
                    fontFamily="'Poppins'"
                    width={{ base: "80px", lg: "50%" }}
                    minWidth={{ base: "80px", lg: "50%" }}
                    whiteSpace="pre"
                    textAlign="right"
                  >
                    Cost Centre
                  </FormLabel>
                  <Input
                    letterSpacing="1px"
                    width={{ base: "100%", lg: "50%" }}
                    variant="outline"
                    background={isApprover ? "brand.400" : "white"}
                    pointerEvents={isApprover ? "none" : "auto"}
                    size="sm"
                    borderRadius="10px"
                    cursor="pointer"
                    id="costcentre"
                    {...register("costcentre", {})}
                  />
                </Flex>
              </FormControl>
            </GridItem>
          </Grid>

          {!isApprover && (
            <FlightAuthForm
              authorisationData={authorisationData}
              newAuthorisation={newAuthorisation}
              editAuthorisation={editAuthorisation}
              airframeSerials={airframeSerials}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              watch={watch}
              editSince={editSince}
              timeZone={timeZone}
              selectStyle={selectStyle}
              setSquadronSelection={setSquadronSelection}
            />
          )}
          {isApprover && editAuthorisation && (
            <FlightAuthSelected
              authorisationData={authorisationData}
              newAuthorisation={newAuthorisation}
              editAuthorisation={editAuthorisation}
              airframeSerials={airframeSerials}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              watch={watch}
              // override={override}
              timeZone={timeZone}
              selectStyle={selectStyle}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
