import React, { useState, useEffect } from "react";
import { Flex, Progress, Text } from "@chakra-ui/react";
import { statsData } from "./data";

export default function Stats({ isLoading }) {
  const StatsBar = ({
    title,
    subTitle,
    total,
    initialValue,
    targetValue,
    color,
    statsColor,
  }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      if (!isLoading) {
        const interval = setInterval(() => {
          setValue((prevValue) =>
            prevValue < targetValue ? prevValue + 1 : prevValue
          );
        }, 2);

        return () => clearInterval(interval);
      }
    }, [targetValue]);

    return (
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="space-between"
        py="3px"
        px="10px"
        borderRadius="10px"
        border="1px solid"
        borderColor="#e5e5e5"
        bg="rgba(255,255,255,0.2)"
        minWidth="100px"
      >
        <Flex width="100%" flexDirection="column">
          <Text
            height={{ base: "20px", lg: "22px" }}
            fontWeight="600"
            fontSize={{ base: "1rem", lg: "1.1rem" }}
            color={color}
          >
            {total}
          </Text>
          <Text
            textTransform="uppercase"
            fontWeight="600"
            fontSize={{ base: "0.65rem", lg: "0.75rem" }}
            lineHeight="1.2"
            py="1px"
          >
            {title}
          </Text>
        </Flex>
        <Flex width="100%" flexDirection="column">
          <Progress
            hasStripe
            my="4px"
            colorScheme={statsColor}
            size="xs"
            value={value}
            borderRadius="5px"
          />
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            height={{ base: "16px", lg: "18px" }}
            fontSize={{ base: "0.6rem", lg: "0.7rem" }}
            color="gray.500"
          >
            <Text fontWeight="400" textTransform="capitalize">
              {subTitle}
            </Text>
            <Text fontWeight="500">{value}%</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      gap={{ base: "15px", md: "20px" }}
      width="100%"
      overflowX="auto"
      pr={{ base: "5px", md: "0px" }}
      pb={{ base: "5px", md: "0px" }}
    >
      {statsData.map((item, index) => (
        <StatsBar key={index} {...item} />
      ))}
    </Flex>
  );
}
