import React, { useMemo } from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { formatDistanceToNow, parseISO } from "date-fns";
import { ReactComponent as Texan } from "../Assets/texan.svg";
import { ReactComponent as B757Icon } from "../Assets/b757.svg";
import { ReactComponent as C130Icon } from "../Assets/c130.svg";
import { ReactComponent as SimIcon } from "../Assets/simulator.svg";

export default function FlightAuthorisations({ data, squadronSelection }) {
  //  *** Authorisations from Database ***
  const bookings = useMemo(
    () =>
      data &&
      data
        .filter((feat) => feat?.data?.squadron === squadronSelection)
        //  filtered authorisations to remove any where status has changed to 'deleted'
        .filter((booking) => booking?.data?.status !== "deleted")
        // other filtered authorisations
        .filter(
          (booking) =>
            booking?.data?.status === "pending" ||
            booking?.data?.status === "approved" ||
            booking?.data?.status === "draft"
        )
        .map((booking) => ({
          ...booking.id,
          ...booking.data,
        })),
    [data, squadronSelection]
  );

  const StatusBar = ({
    // id,
    title,
    aircraft,
    callsign,
    // activity,
    airframe,
    model,
    squadron,
    // priority,
    status,
    since,
  }) => {
    const expired = status === "expired";
    const color =
      status === "expired"
        ? "#FF1414"
        : status === "pending"
        ? "yellow"
        : status === "draft"
        ? "gray.200"
        : "#11EC4E";
    const borderColor =
      status === "expired"
        ? "red.500"
        : status === "pending"
        ? "#eded00"
        : status === "draft"
        ? "gray.300"
        : "#0bd844";

    return (
      <Flex
        width="100%"
        flexDirection="row"
        px="6px"
        py="5px"
        borderRadius="15px"
        border="1px solid"
        borderColor="#e5e5e5"
        minHeight="32px"
        justifyContent="center"
        alignItems="flex-start"
        gap="10px"
        bg="#F7FAFC"
        cursor="pointer"
        transition="all ease 150ms"
        _hover={{
          bg: "#FDFEFF",
          boxShadow: "0px 3px 15px -4px rgba(0, 45, 78, 0.2)",
        }}
      >
        <Flex
          width="36px"
          minWidth="36px"
          height="36px"
          mt="-8px"
          ml="-8px"
          mb="-8px"
          borderRadius="50px"
          alignItems="center"
          justifyContent="center"
          bg={color}
          border="1px solid"
          borderColor={borderColor}
        >
          <Icon
            as={
              callsign === "SIMULATOR"
                ? SimIcon
                : squadron === "40 SQN/CFS" && model === "Boeing 757"
                ? B757Icon
                : squadron === "40 SQN/CFS"
                ? C130Icon
                : Texan
            }
            width="32px"
            height="32px"
            color={"white"}
            transform={callsign !== "SIMULATOR" && "rotate(45deg)"}
          />
        </Flex>
        <Flex
          width="100%"
          gap="5px"
          minHeight={{ base: "16px", lg: "18px" }}
          fontSize={{ base: "0.7rem", lg: "0.75rem" }}
          pt="1px"
        >
          <Flex
            minWidth="140px"
            gap="5px"
            minHeight={{ base: "16px", lg: "18px" }}
            fontSize={{ base: "0.7rem", lg: "0.75rem" }}
            pt="1px"
          >
            <Text
              minWidth="46px"
              textAlign="left"
              color="gray.600"
              fontWeight="600"
            >
              {airframe}
            </Text>
            <Text
              fontWeight="400"
              color={expired ? "#FF1414" : "gray.400"}
              width="auto"
            >
              {aircraft}
            </Text>
          </Flex>
          <Text
            fontWeight={expired ? "600" : "500"}
            color={expired ? "#FF1414" : "gray.500"}
            width="100%"
            pt="1px"
          >
            {title}
          </Text>
          <Text
            fontSize={{ base: "0.65rem", lg: "0.7rem" }}
            fontWeight={expired ? "500" : "400"}
            color="gray.400"
            width="max-content"
            whiteSpace="pre"
            textAlign="right"
            pr="5px"
            pt="1px"
            fontStyle={
              status === "pending" || status === "draft" ? "italic" : "normal"
            }
          >
            {status === "pending"
              ? "Pending"
              : status === "draft"
              ? "Draft"
              : formatDistanceToNow(parseISO(since)) + " ago"}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      borderRadius="10px"
      border="1px solid"
      borderColor="#e5e5e5"
      bg="white"
      boxShadow="0px 10px 40px -15px rgba(0, 45, 78, 0.15)"
    >
      <Flex
        minHeight="44px"
        px="15px"
        py="10px"
        fontSize="0.9rem"
        width="100%"
        color="light.100"
        borderBottom="1px solid"
        borderColor="#e5e5e5"
        alignItems="center"
        fontWeight="700"
        textTransform="uppercase"
      >
        Latest Flight Authorisations
      </Flex>
      <Flex
        gap="12px"
        width="100%"
        flexDirection="column"
        pt="20px"
        pb="25px"
        px="15px"
      >
        {bookings
          .sort((a, b) => {
            // sort by pending status first
            if (a.status === "pending" && b.status !== "pending") {
              return -1;
            }
            if (a.status !== "pending" && b.status === "pending") {
              return 1;
            }
            // sort by draft status last
            if (a.status === "draft" && b.status !== "draft") {
              return 1;
            }
            if (a.status !== "draft" && b.status === "draft") {
              return -1;
            }
            // then sort by since
            if (a.since < b.since) {
              return 1;
            }
            if (a.since > b.since) {
              return -1;
            }
            return 0;
          })
          .map((item, index) => (
            <StatusBar key={index} {...item} />
          ))}
      </Flex>
    </Flex>
  );
}
