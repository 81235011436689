import React, { useState, forwardRef, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import {
  Container,
  Flex,
  Text,
  ButtonGroup,
  Button,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useTab,
  IconButton,
  Center,
  Spinner,
  Tooltip,
  Portal,
  Box,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import Stats from "./Stats";
import RateOfEffort from "./RateOfEffort";
import UpcomingActivity from "./UpcomingActivity";
import OverallHealth from "./OverallHealth";
import {
  BsFiletypePdf,
  BsEnvelopeAt,
  BsPrinter,
  BsFolderPlus,
} from "react-icons/bs";
import PersonnelCurrency from "./PersonnelCurrency";
import AircraftSystems from "./AircraftSystems";
import FlightAuthorisations from "./FlightAuthorisations";
// import BgBlur from "../Assets/bg-blur.jpg";
import TrainingRecord from "../Assets/images/dashboard-individual.png";
import { isRenderedState, squadronSelectionState } from "../globalState";

export default function Dashboard({ isLoading, data }) {
  const [squadronSelection, setSquadronSelection] = useRecoilState(
    squadronSelectionState
  );

  const CustomTab = forwardRef((props, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps["aria-selected"];
    // const styles = useMultiStyleConfig("Tabs", tabProps);
    const tabStyles = {
      fontSize: { base: "0.6rem", xl: "0.7rem" },
      width: {
        base: "100%",
        xs: "100%",
        sm: "100%",
        lg: "140px",
        xl: "180px",
      },
      minWidth: {
        base: "115px",
        xs: "120px",
        sm: "150px",
        lg: "140px",
        xl: "180px",
      },
      transform: "margin ease 250ms, height ease 250ms",
      height: { base: "38px", xl: "37px" },
      fontWeight: "400",
      px: "0px",
      mt: isSelected ? "0px" : "2px",
      borderRadius: "0px",
      borderTopRadius: "12px",
      justifyContent: "center",
      alignItems: "center",
      bg: "#F8FAFD",
      textTransform: "uppercase",
      textWrap: "wrap",
      textAlign: "center",
      lineHeight: "1",
      color: "light.100",
      border: "1px solid",
      borderColor: "#e5e5e5",
      _selected: {
        color: "cyan.400",
        bg: "#F0F5FA",
        height: "40px",
        fontSize: { base: "0.7rem", xl: "0.75rem" },
        fontWeight: "700",
        borderBottom: "1px solid #F0F5FA",
      },
      _hover: {
        background: isSelected ? "#F0F5FA" : "gray.100",
        color: isSelected ? "brand.100" : "gray.900",
      },
      clipPath: "inset(-50px -50px 0px -50px)",
      transition: "all ease 150ms",
    };

    return (
      <Button __css={tabStyles} {...tabProps}>
        {tabProps.children}
      </Button>
    );
  });

  const selectStyle = {
    container: (styles) => ({
      ...styles,
      fontWeight: "500",
    }),
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
      paddingTop: "6px",
      paddingBottom: "6px",
      background: "#ffffff",
      color: "light.100",
      fontWeight: "500",
      _hover: {
        background: "cyan.400",
        color: "white",
        fontWeight: "500",
      },
      transition: "all ease 100ms",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
      borderColor: "#e2e8f0",
      minHeight: "30px",
      height: "30px",
      background: "#ffffff",
      minWidth: { base: "120px", md: "120px", lg: "158px" },
      _focusVisible: { boxShadow: "none !important", borderColor: "cyan.500" },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: "4px",
      paddingBottom: "4px",
      background: "gray.50",
      _hover: {
        background: "#e7edf3",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100",
      marginTop: "3px",
    }),
  };

  const unitOptions = [
    { value: "14 SQN/CFS", label: "14 Squadron" },
    { value: "40 SQN/CFS", label: "40 Squadron" },
  ];

  const squadron =
    squadronSelection === "40 SQN/CFS" ? unitOptions[1] : unitOptions[0];
  const [selectedOptions, setSelectedOptions] = useState(squadron);

  const noFocus = {
    _focus: { boxShadow: "none !important", borderColor: "initial" },
  };
  const fillColor = "#00E0FF";
  const emptyColor = "rgba(255,255,255,0.2)";
  const emptyColorSecond = "rgba(255,255,255,0.2)";

  //PORTAL REFERENCES

  const mobilePlacement = useRef();
  const desktopPlacement = useRef();
  const thirdColumn = useBreakpointValue({
    base: { containerRef: mobilePlacement },
    xl: { containerRef: desktopPlacement },
  });

  const [isRendered, setIsRendered] = useRecoilState(isRenderedState);
  useEffect(() => {
    // console.log("firing");
    if (!isRendered) {
      const delayTimer = setTimeout(() => {
        setIsRendered(true);
      }, 6000);
      return () => clearTimeout(delayTimer);
    }
  }, [isRendered, setIsRendered]);

  // *** LOCAL STORAGE TESTING ***
  // get updated aircrewData from local storage when dashboard opens
  useEffect(() => {
    const storedAircrewData = JSON.parse(localStorage.getItem("aircrewData"));
    // remove the 'available' key (as this is just used for the Select header labels only)
    const filteredStoredAircrewData = storedAircrewData?.filter(
      (feat) => feat.available !== "Available"
    );
    if (filteredStoredAircrewData) {
      console.log("Locally Stored AircrewData: ", filteredStoredAircrewData);
    }
  }, []);

  return (
    <>
      {!isRendered && (
        <Center
          zIndex="200"
          flexDirection="column"
          position="fixed"
          w="100%"
          h="100%"
          pt={{
            base: "0px",
            lg: "5px",
          }}
          marginTop="-50px"
          background={"#0c131f"}
          backgroundImage={
            "radial-gradient(#29364e 0%, #242e46 30%, #0c131f 90%)"
          }
          // backgroundImage={BgBlur}
          // backgroundPosition="center"
          // backgroundSize="cover"
          transition="all ease-in 500ms"
        >
          <Spinner
            mb={{
              base: "40px",
              lg: "0px",
            }}
            size="xl"
            color={fillColor}
            style={{
              borderBottomColor: emptyColorSecond,
              borderLeftColor: emptyColor,
            }}
            emptyColor={emptyColor}
            thickness="3px"
            speed="0.8s"
            boxSize="60px"
          />
        </Center>
      )}
      <Container
        display="flex"
        flexDir="column"
        height={{ base: "calc(100% - 80px)", lg: "100%" }}
        width="100%"
        maxWidth="100%"
        margin={0}
        padding={0}
        paddingLeft={{ base: "0px", lg: "64px" }}
        overflowY="auto"
      >
        <Flex
          bg="white"
          margin="0px"
          padding="0px"
          flex={1}
          width="100%"
          flexDirection="column"
        >
          <Flex
            margin={0}
            flex={1}
            pt={{ base: "10px", smd: "5px", lg: "5px" }}
            flexDirection="column"
            zIndex="1"
            width="100%"
          >
            <Flex
              px={{ base: "10px", sm: "15px", lg: "20px" }}
              height={{ base: "40px", lg: "44px" }}
              minHeight={{ base: "40px", lg: "44px" }}
              justifyContent="space-between"
              alignItems={{ base: "flex-start", lg: "flex-end" }}
              zIndex="2"
              width={{
                base: "100%",
                lg: "calc(100% - 570px)",
              }}
              flexDirection={{ base: "column-reverse", lg: "row" }}
            >
              <Flex
                gap={{ base: "0.5rem", md: "15px", lg: "20px" }}
                width={{ base: "100%", lg: "auto" }}
              >
                <Flex
                  alignItems="center"
                  gap={{ base: "0px", smd: "7px" }}
                  width={{ base: "100%", lg: "auto" }}
                >
                  <Text
                    display={{ base: "none", smd: "block" }}
                    fontSize="0.9rem"
                    htmlFor="aircraft"
                    fontFamily="'Poppins'"
                    fontWeight="500"
                    color="gray.500"
                  >
                    UNIT:
                  </Text>
                  <Select
                    {...noFocus}
                    chakraStyles={selectStyle}
                    colorScheme="cyan"
                    variant="outline"
                    background="white"
                    size="xs"
                    cursor="pointer"
                    id="aircraft"
                    value={selectedOptions}
                    options={unitOptions}
                    onChange={(v) => {
                      setSelectedOptions(v);
                      setSquadronSelection(v.value);
                    }}
                  />
                </Flex>
                <ButtonGroup spacing={{ base: "5px", md: "0.5rem" }}>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Export to PDF"
                    bg="gray.600"
                    color="white"
                    borderRadius="5px"
                    fontSize="0.7rem"
                  >
                    <IconButton
                      aria-label="Save to PDF"
                      icon={<BsFiletypePdf />}
                      borderRadius="10px"
                      variant="outline"
                      bg="gray.50"
                      width={{ base: "30px", lg: "40px" }}
                      height="30px"
                      fontSize="0.95rem"
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.4"
                      _hover={{
                        background: "#e7edf3",
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Export to File"
                    bg="gray.600"
                    color="white"
                    borderRadius="5px"
                    fontSize="0.7rem"
                  >
                    <IconButton
                      aria-label="Save to File"
                      icon={<BsFolderPlus />}
                      borderRadius="10px"
                      variant="outline"
                      bg="gray.50"
                      width={{ base: "30px", lg: "40px" }}
                      height="30px"
                      fontSize="1rem"
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.4"
                      _hover={{
                        background: "#e7edf3",
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Email"
                    bg="gray.600"
                    color="white"
                    borderRadius="5px"
                    fontSize="0.7rem"
                  >
                    <IconButton
                      aria-label="Email"
                      icon={<BsEnvelopeAt />}
                      borderRadius="10px"
                      variant="outline"
                      bg="gray.50"
                      width={{ base: "30px", lg: "40px" }}
                      height="30px"
                      fontSize="0.95rem"
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.4"
                      _hover={{
                        background: "#e7edf3",
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Print"
                    bg="gray.600"
                    color="white"
                    borderRadius="5px"
                    fontSize="0.7rem"
                  >
                    <IconButton
                      aria-label="Print"
                      icon={<BsPrinter />}
                      borderRadius="10px"
                      variant="outline"
                      bg="gray.50"
                      width={{ base: "30px", lg: "40px" }}
                      height="30px"
                      fontSize="1rem"
                      justifyContent="center"
                      alignItems="center"
                      lineHeight="1.4"
                      _hover={{
                        background: "#e7edf3",
                      }}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Flex>
            </Flex>
            <Tabs
              zIndex="1"
              variant="enclosed-colored"
              align="end"
              mt={{ base: "15px", lg: "-30px" }}
              height="100%"
            >
              <TabList
                gap="5px"
                mb={{ base: "-1px", lg: "-2px" }}
                minHeight="40px"
                height="40px"
                px={{ base: "10px", sm: "15px", lg: "20px" }}
              >
                <CustomTab key="1">Unit Readiness</CustomTab>
                <Link
                  fontSize={{ base: "0.6rem", xl: "0.7rem" }}
                  width={{
                    base: "100%",
                    xs: "100%",
                    sm: "100%",
                    lg: "140px",
                    xl: "180px",
                  }}
                  minWidth={{
                    base: "115px",
                    xs: "120px",
                    sm: "150px",
                    lg: "140px",
                    xl: "180px",
                  }}
                  transform="margin ease 250ms, height ease 250ms"
                  height={{ base: "38px", xl: "37px" }}
                  fontWeight="400"
                  px="0px"
                  mt="2px"
                  borderRadius="0px"
                  borderTopRadius="12px"
                  justifyContent="center"
                  alignItems="center"
                  bg="#F8FAFD"
                  textTransform="uppercase"
                  textWrap="wrap"
                  textAlign="center"
                  lineHeight="1"
                  color="light.100"
                  border="1px solid"
                  borderColor="#e5e5e5"
                  _selected={{
                    color: "cyan.400",
                    bg: "#F0F5FA",
                    height: "40px",
                    fontSize: { base: "0.7rem", xl: "0.75rem" },
                    fontWeight: "700",
                    borderBottom: "1px solid #F0F5FA",
                  }}
                  _hover={{
                    background: "gray.100",
                    color: "gray.900",
                  }}
                  clipPath="inset(-50px -50px 0px -50px)"
                  transition="all ease 150ms"
                  href={TrainingRecord}
                  isExternal
                  display="flex"
                  // onClick={(e) => e.preventDefault}
                >
                  Individual Readiness
                </Link>
                {/* <CustomTab key="3">aircraft readiness</CustomTab> */}
              </TabList>

              <TabPanels
                height="100%"
                minHeight="100%"
                bg="#F0F5FA"
                align="start"
              >
                {/* UNIT READINESS */}
                <TabPanel
                  borderTop="1px solid #e5e5e5"
                  width="100%"
                  height="100%"
                  pt="5px"
                  px={{ base: "15px", md: "20px" }}
                >
                  <Flex
                    justifyContent="space-between"
                    gap={{ base: "15px", md: "20px" }}
                    flexDirection={{ base: "column", xl: "row" }}
                  >
                    <Flex
                      width="100%"
                      flexDirection="column"
                      gap={{ base: "20px", md: "25px" }}
                    >
                      <Flex width="100%" flexDirection="column">
                        <Text
                          color="gray.400"
                          opacity="0.8"
                          fontSize="0.55rem"
                          fontWeight="500"
                          py="2px"
                          height="18px"
                        >
                          SMS AT A GLANCE
                        </Text>
                        <Stats isLoading={isLoading} />
                      </Flex>

                      <Flex
                        width="100%"
                        flexDirection={{ base: "column", md: "row" }}
                        gap={{ base: "20px", xl: "0px" }}
                      >
                        <Flex
                          width={{ base: "100%", md: "61%", xl: "100%" }}
                          flexDirection={{ base: "column", xl: "row" }}
                          gap={{ base: "25px", lg: "20px" }}
                        >
                          <Flex
                            width={{ base: "100%", xl: "50%" }}
                            flexDirection="column"
                            gap={{ base: "25px", lg: "25px" }}
                          >
                            <OverallHealth isLoading={isLoading} />
                            <FlightAuthorisations
                              data={data}
                              squadronSelection={squadronSelection}
                            />
                          </Flex>

                          <Flex
                            width={{ base: "100%", xl: "50%" }}
                            flexDirection="column"
                            gap={{ base: "25px", lg: "25px" }}
                          >
                            <PersonnelCurrency
                              isLoading={isLoading}
                              squadronSelection={squadronSelection}
                            />
                            <AircraftSystems isLoading={isLoading} />
                          </Flex>
                        </Flex>
                        <Box
                          className="mobile"
                          width={{ base: "100%", md: "40%", xl: "0%" }}
                          ref={mobilePlacement}
                        ></Box>
                      </Flex>
                    </Flex>

                    <Portal {...thirdColumn}>
                      <Flex
                        pt={{ base: "0px", xl: "18px" }}
                        gap={{ base: "25px", xl: "25px" }}
                        width="100%"
                        flexDirection={{
                          base: "column",
                          smd: "row",
                          md: "column",
                        }}
                      >
                        <RateOfEffort isLoading={isLoading} />
                        <UpcomingActivity isLoading={isLoading} />
                      </Flex>
                    </Portal>

                    <Box
                      className="desktop"
                      maxWidth={{
                        base: "0%",
                        xl: "28%",
                        "3xl": "33%",
                      }}
                      ref={desktopPlacement}
                    ></Box>
                  </Flex>
                </TabPanel>

                {/* INDIVIDUAL READINESS */}
                {/* <TabPanel
                  bg="#F0F5FA"
                  zIndex="1"
                  borderTop="1px solid #e5e5e5"
                  width="100%"
                  height="100%"
                  pt="5px"
                  px={{ base: "15px", md: "20px" }}
                >
                  <Flex
                    justifyContent="space-between"
                    gap={{ base: "15px", md: "20px" }}
                    flexDirection={{ base: "column", xl: "row" }}
                  >
                    <Flex
                      width="100%"
                      flexDirection="column"
                      gap={{ base: "20px", md: "25px" }}
                    >
                      <Flex width="100%" flexDirection="column">
                        <Text
                          color="gray.400"
                          opacity="0.8"
                          fontSize="0.55rem"
                          fontWeight="500"
                          py="2px"
                          height="18px"
                        >
                          SMS AT A GLANCE
                        </Text>
                        <Stats isLoading={isLoading} />
                      </Flex>

                      <Flex
                        width="100%"
                        flexDirection={{ base: "column", lg: "row" }}
                        gap={{ base: "25px", lg: "20px" }}
                      >
                        <Flex
                          width={{ base: "100%", lg: "50%" }}
                          flexDirection="column"
                          gap={{ base: "25px", lg: "25px" }}
                        >
                          <FlightAuthorisations
                            data={data}
                            squadronSelection={squadronSelection}
                          />
                        </Flex>

                        <Flex
                          width={{ base: "100%", lg: "50%" }}
                          flexDirection="column"
                          gap={{ base: "25px", lg: "25px" }}
                        >
                          <PersonnelCurrency
                            isLoading={isLoading}
                            squadronSelection={squadronSelection}
                          />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      pt={{ base: "10px", md: "18px" }}
                      gap={{ base: "25px", lg: "25px" }}
                      width="100%"
                      maxWidth={{
                        base: "100%",
                        lg: "calc(50% - 10px)",
                        xl: "28%",
                      }}
                      flexDirection="column"
                    >
                      <UpcomingActivity isLoading={isLoading} />
                      <RateOfEffort isLoading={isLoading} />
                    </Flex>
                  </Flex>
                </TabPanel> */}

                {/* AIRCRAFT READINESS */}
                {/* <TabPanel
                  bg="#F0F5FA"
                  zIndex="1"
                  borderTop="1px solid #e5e5e5"
                  width="100%"
                  height="100%"
                  pt="5px"
                  px={{ base: "15px", md: "20px" }}
                >
                  <Flex
                    justifyContent="space-between"
                    gap={{ base: "15px", md: "20px" }}
                    flexDirection={{ base: "column", xl: "row" }}
                  >
                    <Flex
                      width="100%"
                      flexDirection="column"
                      gap={{ base: "20px", md: "25px" }}
                    >
                      <Flex width="100%" flexDirection="column">
                        <Text
                          color="gray.400"
                          opacity="0.8"
                          fontSize="0.55rem"
                          fontWeight="500"
                          py="2px"
                          height="18px"
                        >
                          SMS AT A GLANCE
                        </Text>
                        <Stats isLoading={isLoading} />
                      </Flex>

                      <Flex
                        width="100%"
                        flexDirection={{ base: "column", lg: "row" }}
                        gap={{ base: "25px", lg: "20px" }}
                      >
                        <Flex
                          width={{ base: "100%", lg: "50%" }}
                          flexDirection="column"
                          gap={{ base: "25px", lg: "25px" }}
                        >
                          <OverallHealth isLoading={isLoading} />
                          <FlightAuthorisations
                            data={data}
                            squadronSelection={squadronSelection}
                          />
                        </Flex>

                        <Flex
                          width={{ base: "100%", lg: "50%" }}
                          flexDirection="column"
                          gap={{ base: "25px", lg: "25px" }}
                        >
                          <PersonnelCurrency
                            isLoading={isLoading}
                            squadronSelection={squadronSelection}
                          />
                          <AircraftSystems isLoading={isLoading} />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      pt={{ base: "10px", md: "18px" }}
                      gap={{ base: "25px", lg: "25px" }}
                      width="100%"
                      maxWidth={{
                        base: "100%",
                        lg: "calc(50% - 10px)",
                        xl: "28%",
                      }}
                      flexDirection="column"
                    >
                      <UpcomingActivity isLoading={isLoading} />
                      <RateOfEffort isLoading={isLoading} />
                    </Flex>
                  </Flex>
                </TabPanel> */}
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Container>
    </>
  );
}
