import {
  ChannelBox,
  // , ChannelLogo
} from "@nessprim/planby";
import { Text, VStack } from "@chakra-ui/react";

export default function Area({ area }) {
  const {
    position,
    // logo
  } = area;

  return (
    <ChannelBox {...position}>
      <VStack>
        {/* <ChannelLogo
          onClick={() => console.log("channel", area)}
          src={logo}
          alt="Logo"
        /> */}
        <Text color="white">{area.serial}</Text>
      </VStack>
    </ChannelBox>
  );
}
