import React, { useMemo } from "react";
import { useEpg, Epg, Layout } from "@nessprim/planby";
import { v4 as uuid } from "uuid";
import Aircrew from "./Aircrew";
import Booking from "./Booking";
import calculateAvailableSlots, {
  splitAvailableSlots,
} from "../../helpers/calculateAvailableSlots";
import { theme } from "../../Theme/Components/PlanningBoard";
import data from "../../Data/aircrew.json";

export default function AircrewBoard() {
  const channels = useMemo(
    () =>
      data.map((crew) => ({
        serial: crew.callsign,
        uuid: crew.callsign,
      })),
    []
  );

  const bookings = useMemo(
    () => [
      {
        channelUuid: "Iceman",
        // description: "Ut anim nisi consequat minim deserunt...",
        id: uuid(),
        // image:
        //   "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        since: "2023-08-22T17:00:00",
        till: "2023-08-22T18:00:00",
        title: "Iceman Sortie",
      },
      {
        channelUuid: "Maverick",
        // description: "Ut anim nisi consequat minim deserunt...",
        id: uuid(),
        // image:
        //   "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        since: "2023-08-22T16:45:00",
        till: "2023-08-22T19:00:00",
        title: "Maverick Sortie",
      },
      {
        channelUuid: "Viper",
        // description: "Ut anim nisi consequat minim deserunt...",
        id: uuid(),
        // image:
        //   "https://rnz-ressh.cloudinary.com/image/upload/s--KHfkf8jC--/c_scale,f_auto,q_auto,w_1050/v1643516440/4NNKUR7_image_crop_66796",
        since: "2023-08-22T15:30:00",
        till: "2023-08-22T16:00:00",
        title: "Viper Sortie",
      },
    ],
    []
  );

  const epg = useMemo(() => {
    return channels.flatMap((serial) => {
      const aircrewBookings = bookings.filter(
        (booking) => serial === booking.channelUuid
      );
      const availableTimes = calculateAvailableSlots(aircrewBookings);
      const splitSlots = splitAvailableSlots(availableTimes);
      const availableBookingSlots = splitSlots.map((slot) => ({
        id: uuid(),
        channelUuid: serial,
        title: "Available",
        ...slot,
      }));

      return [...aircrewBookings, ...availableBookingSlots];
    });
  }, [channels, bookings]);

  const {
    getEpgProps,
    getLayoutProps,
    // onScrollToNow,
    // onScrollLeft,
    // onScrollRight,
  } = useEpg({
    epg,
    channels,
    startDate: "2023-08-22T00:00", // or 2022-02-02T00:00:00
    // width: "50%",
    // height: "100%",
    theme: theme,
  });

  // console.log(epg);

  return (
    <>
      <Epg {...getEpgProps()} style={{ borderRadius: "15px" }}>
        <Layout
          {...getLayoutProps()}
          renderChannel={({ channel }) => (
            <Aircrew key={channel.serial} aircrew={channel} />
          )}
          renderProgram={({ program, ...rest }) => (
            <Booking key={program.data.id} program={program} {...rest} />
          )}
        />
      </Epg>
    </>
  );
}
